import { makeStyles, createStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme =>
  createStyles({
    logo: {
      padding: theme.spacing(0.75, 0, 1.25, 0),
    },
    imageButton: {
      position: 'relative',
      height: 95,
      // fill: "#999999",
      '&:hover, &.Mui-focusVisible': {
        color: "#7947F6",
        background: "#F2EDFE",
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
          opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
          opacity: 0,
        },
        '&: menuIcon': {
          width: 24,
          fill: "#333333 !important",
        },
        '&:hover': {
          fill: "#7947F6 !important",
        }
      },
    },

    selectedImageButton: {
      position: 'relative',
      height: 95,
      color: "#7947F6 !important",
      background: "#F2EDFE !important",
      fill: "#7947F6 !important",
      border: "1px solid #9770F8 !important",
      '&:hover, &.Mui-focusVisible': {
        color: "#7947F6",
        background: "#F2EDFE",
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
          opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
          opacity: 0,
        },
      },

      '&:hover': {
        fill: "#7947F6 !important",
      }
    },
    userName: {
      color: "#333333 !important"
    },

  })
);

export default useStyles;
