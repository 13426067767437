import axios from "axios";
import { ErrorResponseMessage } from "constants/constants";
import { getRefreshToken } from "redux/usermanagement/action";
import { checkTokenExpiry, setCookie } from "utils/helper";


// const token =localStorage.getItem("token")
export const postData = async (url, config = {}, headers = {}) => {
  try {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.headers.post["Accept"] = "application/json";
     const response = await axios.post(url, config, {
      headers: headers,
    });
    if (response && response.data) {
      if (response.status === 500 && response.data) {
        response.data = ErrorResponseMessage;
      }
      
      return response;
    }
    return response;
  } catch (err) {
    return err.response;
  }
};

export const postFormData = async (url, config = {}, headers = {}) => {
  try {
    axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
    axios.defaults.headers.post["Accept"] = "application/x-www-form-urlencoded";
    const response = await axios.post(url, config, {
      headers: headers,
    });
    if (response && response.data) {
      if (response.status === 500 && response.data) {
        response.data = ErrorResponseMessage;
      }

      return response;
    }
    return response;
  } catch (err) {
    return err.response;
  }
}



export const getData = async (url, config = {}, headers = {}) => {
  console.log(headers);
  try {
    const response = await axios.get(url, config, { headers: headers });
    if (response && response.data) {
      if (response.status === 500 && response.data) {
        response.data = ErrorResponseMessage;
      }

      return response;
    }
    return response;
  } catch (err) {
    return err.response;
  }
};

export const putData = async (url, config = {}, headers = {}) => {
  try {
    axios.defaults.headers.put["Content-Type"] = "application/json";
    axios.defaults.headers.put["Accept"] = "application/json";
    const response = await axios.put(url, config, { headers: headers });

    if (response && response.data) {
      if (response.status === 500 && response.data) {
        response.data = ErrorResponseMessage;
      }

      return response;
    }
    return response;
  } catch (err) {
    return err.response;
  }
};
export const patchData = async (url, config = {}, headers = {}) => {
  try {
    axios.defaults.headers.patch["Content-Type"] = "application/json";
    axios.defaults.headers.patch["Accept"] = "application/json";
    const response = await axios.patch(url, config, { headers: headers });

    if (response && response.data) {
      if (response.status === 500 && response.data) {
        response.data = ErrorResponseMessage;
      }

      return response;
    }
    return response;
  } catch (err) {
    return err.response;
  }
};
export const deleteData = async (url, config = {}, headers = {}) => {
  try {
    const response = await axios.delete(url, config, { headers: headers });
    if (response && response.data) {
      if (response.status === 500 && response.data) {
        response.data = ErrorResponseMessage;
      }

      return response;
    }
    return response;
  } catch (err) {
    return err.response;
  }
};
