import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    totalResults: {
      fontSize: 12,
      fontFamily: "'Inter', sans-serif",
      fontWeight: "500 !important",
      color: "#333333",
    },
    totalValue: {
      fontSize: 12,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600,
      color: "#2C0957",
    },
    recordText: {
      fontSize: 12,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400,
      color: "#666666",
    },

    // Page Action Area
    pageActionArea: {
      padding: theme.spacing(0, 2, 2, 2),
    },
    uploadFiles: {
      width: "100%",
      border: "1px solid #afafaf !important",
      padding: theme.spacing(0) + "!important",
      borderRadius: "5px",
      background: "#F8F5FF",
    },
    borderLine: {
      border: "1px solid #afafaf !important",
      borderRadius: "14px",
      padding: theme.spacing(2, 3),
    },
    titleLine: {
      color: "#7947F6",
      fontWeight: 600 + "!important",
      fontSize: "16px !important",
    },
    artLine: {
      color: "#333333 !important",
    },
    artSubText: {
      color: "#333333 !important",
    },
    dataIcon: {
      margin: theme.spacing(0.3, 0, -0.3, 0),
    },
    alertDialogbtn: {
      margin: theme.spacing(0, 1, 1, 0),
      color: "#333333",
    },
    statusArea: {
      padding: theme.spacing(0.65, 2.5, 1.5, 2.5),
      margin: theme.spacing(0),
    },
    statusIcon: {
      marginRight: "15px",
    },
    cationText: {
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#191919",
    },
    totalUrl: {
      fontSize: "20px !important",
      fontWeight: "700 !important",
      color: "#2C0957 !important",
    },
    inactiveUrl: {
      fontSize: "20px !important",
      fontWeight: "700 !important",
      color: "#6C2E0B !important",
    },
    successfulUrl: {
      fontSize: "20px !important",
      fontWeight: "700 !important",
      color: "#095728 !important",
    },
    failedUrl: {
      fontSize: "20px !important",
      fontWeight: "700 !important",
      color: "#DB3528 !important",
    },
    viewText: {
      fontSize: "10px !important",
      fontWeight: 600,
      color: "#191919",
      position: "absolute",
      bottom: 6,
      right: 13,
      padding: "5px",
    },
    totalBg: {
      background: "#D7D0DF !important",
    },
    inacBg: {
      background: "#FFDDAC !important",
    },
    succBg: {
      background: "#C2E3A8 !important",
    },
    failBg: {
      background: "#FFB8BC !important",
    },
    listText: {
      fontSize: "12px !important",
      fontFamily: "'Inter', sans-serif",
      fontWeight: "500 !important",
      color: "#333333",
    },
    urlArea: {
      border: "1px solid #c0c0c0 !important",
      borderRadius: "5px",
      overflow: "hidden",
    },
    searchKeyword: {
      margin: "10px 10px 0px 10px ",
    },
    fullHeight: {
      height: "calc(100vh - 140px)",
    },
  })
);

export default useStyles;
