import MaterialUIButton from "components/commonComponents/button/button";
import Datepicker from "components/commonComponents/datepicker/datepicker";
import Dropdown from "components/commonComponents/dropdown/dropdown";
import Textbox from "components/commonComponents/textbox/textbox";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useState } from "react";
import { formatBytes } from "utils/helper";
import { MAX_COUNT } from "constants/constants";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import useStyles from "./manageSources.styles";
import Autocompletedropdown from "components/commonComponents/autocomplete/autocompletedropdown";
import { Required } from "utils/validation helper";
import { useDispatch, usedataor, useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";

import { devcomplexityDropdown, priorityDropdown, rerunDropdown } from "redux/manageissues/action";
import {
  getDevcomplexity,
  getPrioritySource,
  getRerun,
  getUrlStatusSource,
} from "redux/app/actions";

import { useLocation } from "react-router-dom";
import AlertDialog from "components/commonComponents/alertDialog";
import { newsourceStatus, sourceurlStatus } from "redux/managesources/action";
import { getNewSourceStatus } from "redux/app/actions";
function EditSource(props) {
  const classes = useStyles();
  const id=useSelector((state) => state.project.managesource)
  const select = useSelector((state) => state.manageSource);
  const data1 = useSelector((state) => state.manageIssue);

  const data = useSelector((state) => state.manageSource.manageSourcedataid);

  const dispatch = useDispatch();
  const location = useLocation();
  const dataS = select.urlstatus.filter(
    (e) => e.urlstatus1.toLowerCase() !== "DECOMMISSIONED - REPLACED".toLowerCase()
  );

  useEffect(() => {
    callingDropdowndata();
  }, []);
  /******************************************************************************
  Function: callingDropdowndata
  Argument:
  Return:
  Usage:
  1.To Call dropdown API 
  *******************************************************************************/

  const callingDropdowndata = () => {
    dispatch(newsourceStatus(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getNewSourceStatus([]));
        } else {
          dispatch(getNewSourceStatus(res.data.result));
        }
      } else {
        dispatch(getNewSourceStatus([]));
      }
    });
    dispatch(devcomplexityDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getDevcomplexity([]));
        } else dispatch(getDevcomplexity(res.data.result));
      } else {
        dispatch(getDevcomplexity([]));
      }
    });
    dispatch(priorityDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getPrioritySource([]));
        } else {
          dispatch(getPrioritySource(res.data.result));
        }
      } else {
        dispatch(getPrioritySource([]));
      }
    });

    dispatch(sourceurlStatus(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getUrlStatusSource([]));
        } else {
          dispatch(getUrlStatusSource(res.data.result));
        }
      } else {
        dispatch(getUrlStatusSource([]));
      }
    });
  };

  const [showalert, setShowalert] = useState(false);
  const role = localStorage.getItem("role");

  const truncatesource = (str) => {
    return str ? (str.length > 30 ? str.substring(0, 30) + "..." : str) : "";
  };
  /******************************************************************************
  Function: validate
  Argument: values
  Return: errors
  Usage:
  1.To validate the form
  *******************************************************************************/

  const validate = (values) => {
    const comments = values.comments ? values.comments.length : "";
    const errors = {};
    if (comments > 400) {
      errors.comments = "Exceeds 400 characters";
    }
    if (!Required(values.priorityId)) {
      errors.priorityId = true;
    }
    if (role !== "JATO Admin" && !Required(values.urlstatusId)) {
      errors.urlstatusId = true;
    }
    /*  if (role == "Merit Admin") {
      if (!Required(values.priorityId)) {
        errors.priorityId = true;
      }
      if (!Required(values.urlstatusId)) {
        errors.urlstatusId = true;
      }
    } */

    return errors;
  };

  const prenewsource = data.newSourceStatus && {
    id: data.newSourceStatus,
    label: data.newSourceStatus,
  };
  const prepriority = data.make && {
    id: data.make.issuePriority.priorityId,
    label: data.make.issuePriority.issuePriority1,
  };
  const precomplexity = data.complexity && {
    id: data.complexity.issueComplexity1,
    label: data.complexity.issueComplexity1,
  };
  const preurlstatus = data.urlstatus && {
    id: data.urlstatus.urlstatusId,
    label: data.urlstatus.urlstatus1,
  };
  const formik = useFormik({
    initialValues: {
      countryId: data.countryId,
      makeId: data.makeId,
      modelId: data.modelId,
      priorityId: data.make ? prepriority : null,
      receivedDate: data.receivedDate,
      sourceId: data.sourceId,
      sourceUrl: data.sourceUrl,
      complexityString:data.complexity?precomplexity:null,
      urlstatusId: data.urlstatus ? preurlstatus : null,
      newSourceStatus: prenewsource,
      comments: data.comments,
      updatedBy: data.updatedBy,
    },
    enableReinitialize: true,
    validate,
    onSubmit: (values) => {
      console.log(values);
      props.onChangeSave(true);
      props.EditSourcedata({
        countryId: data.countryId,
        makeId: data.makeId,
        modelId: data.modelId,
        priorityId: values.priorityId ? values.priorityId.id : null,
        complexityString:values.complexityString?values.complexityString.label:null,
        urlstatusId: values.urlstatusId ? values.urlstatusId.id : null,
        receivedDate: data.receivedDate,
        sourceId: data.sourceId,
        sourceUrl: data.sourceUrl ? data.sourceUrl : null,
        newSourceStatus: values.newSourceStatus
          ? values.newSourceStatus.id
          : null,
        comments: values.comments ? values.comments.trim() : "",
        updatedBy: localStorage.getItem("firstName"),
      });
    },
  });
  /******************************************************************************
  Function: cancel
  Argument: 
  Return: 
  Usage:
  1.To close the form
  *******************************************************************************/

  const cancel = () => {
    props.close();
  };
  return (
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                Source Details
                <span className={classes.artSubText}>(Edit)</span>
              </Typography>
            </Grid>
            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs="auto">
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid item xs="auto">
                      <MaterialUIButton
                        variant="outlined"
                        message="SAVE"
                        disabled={props.save}
                        onClick={() => formik.handleSubmit()}
                        startIcon={<SaveOutlinedIcon />}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <MaterialUIButton
                        variant="outlined"
                        message="CANCEL"
                        onClick={() => {
                          JSON.stringify(formik.initialValues) ===
                          JSON.stringify(formik.values)
                            ? cancel()
                            : setShowalert(true);
                        }}
                        startIcon={<EventBusyOutlinedIcon />}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton color="secondary" aria-label="Close">
                      <CloseIcon
                        onClick={() => {
                          JSON.stringify(formik.initialValues) ===
                          JSON.stringify(formik.values)
                            ? cancel()
                            : setShowalert(true);
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>

                <AlertDialog
                  open={showalert}
                  onClose={() => setShowalert(false)}
                  // open={isDeleted}
                  title={"Confirmation"}
                  // maxWidth={"sm"}
                  description={`Do you want to discard the changes ?`}
                  action={
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="right"
                      spacing={2}
                      className={classes.alertDialogbtn}
                    >
                      <Grid item xs={"auto"}>
                        <MaterialUIButton
                          variant="outlined"
                          onClick={props.close}
                          message="Yes"
                          startIcon={<DoneIcon />}
                        />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <MaterialUIButton
                          variant="outlined"
                          onClick={() => {
                            setShowalert(false);
                          }}
                          message="No"
                          startIcon={<CloseIcon />}
                        />
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.borderLine}>
            <Grid
              container
              spacing={2}
              alignItems="top"
              justifyContent="flex-start"
            >
              {/* <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  className={classes.titleLine}
                >
                  <span className={classes.artLine}>// </span>Source Details{" "}
                  <span className={classes.artLine}>//</span>
                </Typography>
              </Grid> */}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Textbox
                  size="small"
                  readOnly
                  label="Country"
                  name="countryCode"
                  value={data.country ? data.country.countryCode : ""}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Textbox
                  size="small"
                  readOnly
                  label="Make"
                  name="make1"
                  value={data.make ? data.make.make1 : ""}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Textbox
                  size="small"
                  readOnly
                  label="Model"
                  name="model1"
                  value={data.model ? data.model.model1 : ""}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocompletedropdown
                  required
                  value={formik.values.priorityId}
                  onChange={(_, data) =>
                    formik.setFieldValue("priorityId", data)
                  }
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  labelId="priority"
                  id="priority"
                  name="priority"
                  error={
                    formik.touched.priorityId &&
                    Boolean(formik.errors.priorityId)
                  }
                  helperText={
                    formik.touched.priorityId && formik.errors.priorityId
                  }
                  label="Business Priority"
                  options={select.priority.map((e) => {
                    return {
                      label: e.issuePriority1,
                      id: e.priorityId,
                    };
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Tooltip
                  title={data.sourceUrl ? data.sourceUrl : ""}
                  /* title={
                    "https://meritgroup.atlassian.net/jira/software/projects/JDA/boards/74"
                  } */
                  placement="top"
                  arrow
                >
                  <div>
                    <Textbox
                      size="small"
                      readOnly
                      label="Source URL"
                      name="sourceUrl"
                      value={
                        data.sourceUrl ? truncatesource(data.sourceUrl) : ""
                      }
                      fullWidth
                    />
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Tooltip
                  title={data.previousSourceUrl ? data.previousSourceUrl : ""}
                  placement="top"
                  arrow
                >
                  <div>
                    <Textbox
                      size="small"
                      readOnly
                      label="Pervious Source URL"
                      name="previousSourceUrl"
                      value={
                        data.previousSourceUrl
                          ? truncatesource(data.previousSourceUrl)
                          : ""
                      }
                      fullWidth
                    />
                  </div>
                </Tooltip>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocompletedropdown
                  value={formik.values.complexityString}
                  onChange={(_, data) =>
                    formik.setFieldValue("complexityString", data)
                  }
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  labelId="Scrapper Complexity"
                  id="Scrapper Complexity"
                  name="complexityString"
                  error={
                    formik.touched.complexityString &&
                    Boolean(formik.errors.complexityString)
                  }
                  helperText={
                    formik.touched.complexityString && formik.errors.complexityString
                  }
                  label="Scrapper Complexity"
                  options={data1.developercomplexity.map((e) => {
                    return {
                      label: e,
                      id: e,
                    };
                  })}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocompletedropdown
                  required={role !== "JATO Admin"}
                  value={formik.values.urlstatusId}
                  onChange={(_, data) => {
                    formik.setFieldValue("urlstatusId", data);
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  labelId="URL Status"
                  id="URL Status"
                  label="URL Status"
                  name="urlstatusId"
                  // filterOptions={() => data.urlstatus.filter(e=>e.urlstatus1==="Replaced")}
                  error={
                    formik.touched.urlstatusId &&
                    Boolean(formik.errors.urlstatusId)
                  }
                  helperText={
                    formik.touched.urlstatusId && formik.errors.urlstatusId
                  }
                  options={dataS.map((e) => {
                    return {
                      label: e.urlstatus1,
                      id: e.urlstatusId,
                    };
                  })}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Datepicker
                  readOnly
                  name="receivedDate"
                  value={data.receivedDate}
                  size="small"
                  fullWidth
                  label="Source Received date"
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocompletedropdown
                  onChange={(_, data) => {
                    formik.setFieldValue("newSourceStatus", data);
                    //formik.setFieldValue("assignedToView", data.id);
                    formik.setFieldValue("isupdate", true);
                  }}
                  isOptionEqualToValue={(option, value) => {

                    return option.id === value.id;
                  }}
                  value={formik.values.newSourceStatus}
                  id="newSourceStatus"
                  name="newSourceStatus"
                  label="New Source Status"
                  Required
                  error={
                    formik.touched.newSourceStatus &&
                    Boolean(formik.errors.newSourceStatus)
                  }
                  options={select.newsourcestatus.map((e) => {
                    return {
                      label: e,
                      id: e,
                    };
                  })}
                />
              </Grid> */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Textbox
                  multiline
                  rows={3}
                  label="Comments"
                  fullWidth
                  name="comments"
                  value={formik.values.comments}
                  onChange={(e) => {
                    formik.setFieldValue("comments", e.target.value);
                    formik.setFieldValue("isupdate", true);
                  }}
                  error={
                    formik.touched.comments && Boolean(formik.errors.comments)
                  }
                  helperText={formik.touched.comments && formik.errors.comments}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EditSource;
