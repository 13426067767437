var baseApiUrl = process.env.REACT_APP_MANAGE_ISSUE_BASE_URL;
const baseurl = baseApiUrl + "api";
const baseurlforusermanagement = baseurl + "/v1/users";
export const BASE_URL_USER = baseurlforusermanagement + "/getuserbyid";
export const USER_MANAGEMENT_URL = baseurlforusermanagement;
export const USER_MANAGEMENT_EXPORT_URL =
  baseurlforusermanagement + "/exportusers";
export const USER_MANAGEMENT_DELETE_URL =
  baseurlforusermanagement + "/deleteuser";
export const USER_MANAGEMENT_UPDATE_URL = baseurlforusermanagement;
// export const USER_LOGIN = baseurlforusermanagement + "/authenticate";
export const USER_LOGIN = baseurlforusermanagement + "/getuserdeatilsandrole";
export const LOGOUT = baseurlforusermanagement+"/signout"