import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  circularProgressClasses,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

// Importing Custom Components
import AdvanceFilters from "./advanceFilters";
import BaseLayout from "components/commonComponents/baseLayout";
import MaterialUIModal from "components/commonComponents/modal/modal";
import MaterialUIButton from "components/commonComponents/button/button";

// Importing useStyles
import { styled } from "@mui/material/styles";
import useStyles from "./dashboardAndReports.styles";

// Import Material Icons
import DvrOutlinedIcon from "@mui/icons-material/DvrOutlined";
import StackedBarChart from "./stackeBarChart/stackedBarChart";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  chartDeliverdFiles,
  chartUrlCountry,
  chartUrlNoRecords,
  chartUrlPie,
  chartUrlPricepointChange,
  chartUrlScarper,
} from "redux/dashboard&reports/action";
import PieChart from "./piechart/pieChart";
import PieChartCom from "./piechart/pieChart";
import BarChartCom from "./barchart/barChartCom";
import moment from "moment";

import {
  barChart,
  pieChart,
  scopeIcon,
  scrapedIcon,
  unscrapedIcon,
  barchart2,
  scraperfailedIcon,
} from "../../../../assets/index";
import Customescrollbar from "components/commonComponents/scrollbar/scrollbar";
import Spinner from "components/commonComponents/spinner/spinner";
import { filterDashboard } from "redux/app/actions";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

function DashboardAndReports() {
  const classes = useStyles();
  const select = useSelector((state) => state.dashboardandreports.filter);
  const [advFilterOpen, setAdvFilterOpen] = useState(false);
  const [norecords, setnoRecords] = useState([]);
  const [totalNorecords, setTotalNorecords] = useState(0);
  const [load, setLoad] = useState(true);
  const [bar, setBar] = useState([]);
  const [maxNorecords, setMaxNorecords] = useState(0);
  const [pricepoint, setPricepoint] = useState([]);
  const [data, setdata] = useState([]);
  const id = useSelector((state) => state.project.dashboard);
  const [maxDelivery, setMaxDelivery] = useState(0);
  const [deliveryData, setDeliveryData] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalD, setTotalD] = useState(0);

  const [totalStacked, setTotalStacked] = useState(0);
  const [totalBar, setTotalBar] = useState(0);
  const [max, setMax] = useState(0);

  const navigate = useNavigate();
  const d = {
    timeLineStart: moment().format("YYYY-MM-DD"),
    timeLineEnd: moment().format("YYYY-MM-DD"),
    country: [],
    make: [],
    model: [],
    exportId: [],
    noOfDays: "",
  };
  const dispatch = useDispatch();
  const classNames = [
    classes.progressBar1,
    classes.progressBar2,
    classes.progressBar3,
    classes.progressBar4,
    classes.progressBar5,
    classes.progressBar6,
  ];
  useEffect(() => {
    apicall();
    dispatch(
      filterDashboard({
        timeline: "Today",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: null,
        timeLineEnd: null,
      })
    );
  }, [id]);
  /**************************************
Function: apicall
Argument:
Usage:
1. To do apicall to get the data
*******************************************/
  const apicall = () => {
    dispatch(chartUrlPricepointChange(id)).then((res) => {
      setLoad(true);
      if (res.data.statusCode === 200) {
        setMax(res.data.maxValue);
        setLoad(false);
        setTotal(res.data.total);
        setPricepoint(res.data.result);
      } else {
        setMax(0);
        setLoad(false);
        setTotal(0);
        setPricepoint([]);
      }
    });
    dispatch(chartUrlNoRecords(id)).then((res) => {
      setLoad(true);
      if (res.data.statusCode === 200) {
        setMaxNorecords(res.data.maxValue);
        setTotalNorecords(res.data.total);
        setnoRecords(res.data.result);
        setLoad(false);
      } else {
        setMaxNorecords(0);
        setTotalNorecords(0);
        setLoad(false);
        setnoRecords([]);
      }
    });
    dispatch(chartDeliverdFiles(id)).then((res) => {
      setLoad(true);
      if (res.data.statusCode === 200) {
        setMaxDelivery(100);
        setDeliveryData(res.data.result);

        setTotalD(res.data.totalRecords);
        setLoad(false);
      } else {
        setMaxDelivery(0);
        setDeliveryData([]);
        setTotalD(0);
        setLoad(false);
        // setnoRecords([]);
      }
    });
    dispatch(chartUrlPie(id)).then((res) => {
      setLoad(true);
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          setdata([]);
          setLoad(false);
        } else {
          setdata(res.data.result);
          setLoad(false);
        }
      } else {
        setdata([]);
        setLoad(false);
      }
    });

    dispatch(chartUrlCountry(d, id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result !== "No records found") {
          setBar(res.data.result);
          setTotalStacked(res.data.totalAverage);
        }
      }
    });
    dispatch(chartUrlScarper(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result !== "No records found") {
          setTotalBar(res.data.total);
        }
      }
    });
  };
  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "grey",
    };
    return (
      <div
        className="scrollbar-thumb"
        style={{ ...style, ...thumbStyle }}
        {...props}
      />
    );
  };
  console.log(deliveryData);
  return (
    <div>
      <Box>
        <Spinner open={load} />
        <BaseLayout>
          <Grid
            container
            spacing={2}
            className={classes.pageActionArea}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={12} md={6}>
              <Grid
                container
                alignItems="center"
                justifyContent="left"
                spacing={2}
              >
                <Grid item xs={"auto"}>
                  <p className={classes.statusOn}>
                    Status On: Daily &nbsp;
                    <span className={classes.status}>({select.timeline})</span>
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={1.75}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                {/* <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => setAdvFilterOpen(true)}
                    message="Advance Filter"
                    startIcon={<FilterListOutlinedIcon />}
                  />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </BaseLayout>
        <Grid
          container
          spacing={2}
          className={classes.pageActionArea}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item xs={12} md={6}>
            <div className={classes.graphBox}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="left"
                    spacing={2}
                  >
                    <Grid item xs={"auto"}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        className={classes.titleLine}
                      >
                        <span className={classes.artLine}>// </span>Country Wise
                        Status
                        <span className={classes.artLine}> //</span>
                      </Typography>
                      {/* <h2 className={classes.graphName}>  // Country Wise Status //  </h2> */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid
                    container
                    spacing={1.75}
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Grid item xs={"auto"}>
                      <MaterialUIButton
                        variant="outlined"
                        message="View All"
                        onClick={() =>
                          navigate(
                            "/dashboard-and-reports/country-wise-status",
                            {
                              state: localStorage.getItem("role"),
                            }
                          )
                        }
                        startIcon={<DvrOutlinedIcon />}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} mt={1}>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    className={classes.totalResults}
                  >
                    Overall Average:&nbsp;
                    <span className={classes.totalValue}>{totalStacked}%</span>
                  </Typography>
                </Grid>

                <StackedBarChart barSize={40} dataFill={bar} />
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.graphBox}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="left"
                    spacing={2}
                  >
                    <Grid item xs={"auto"}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        className={classes.titleLine}
                      >
                        <span className={classes.artLine}>// </span>Source URLs
                        with no records
                        <span className={classes.artLine}> //</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid
                    container
                    spacing={1.75}
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Grid item xs={"auto"}>
                      <MaterialUIButton
                        variant="outlined"
                        message="View All"
                        onClick={() =>
                          navigate(
                            "/dashboard-and-reports/source-URLs-with-no-records",
                            {
                              state: localStorage.getItem("role"),
                            }
                          )
                        }
                        startIcon={<DvrOutlinedIcon />}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} mt={1}>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    className={classes.totalResults}
                  >
                    Total Results:&nbsp;
                    <span className={classes.totalValue}>
                      {totalNorecords}
                    </span>{" "}
                    &nbsp;<span className={classes.textCaption}>Records</span>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.dataBox}>
                    {norecords ? (
                      norecords.map((e, index) => (
                        <>
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <Grid item xs={12} md={4}>
                              <Tooltip title={e.data} placement="top" arrow>
                                <Typography
                                  variant="caption"
                                  display="block"
                                  className={classes.progressBarLabel}
                                >
                                  {e.data}
                                </Typography>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                justifyContent="flex-end"
                              >
                                <Grid item xs>
                                  <LinearProgress
                                    value={
                                      (Number(e.no_Of_Days) / maxNorecords) *
                                      100
                                    }
                                    variant="determinate"
                                    className={
                                      classNames[index % classNames.length]
                                    }
                                  />
                                </Grid>
                                <Grid item xs={"auto"}>
                                  <span className={classes.progressResult}>
                                    {e.no_Of_Days} Days
                                  </span>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <div className={classes.dividerLine}></div>
                        </>
                      ))
                    ) : (
                      <Grid container spacing={2} minHeight={250}>
                        {" "}
                        <Grid
                          xs
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {" "}
                          <Typography variant="body2" gutterBottom>
                            No data to display{" "}
                          </Typography>{" "}
                        </Grid>{" "}
                      </Grid>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className={classes.pageActionArea}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item xs={12} md={6}>
            <div className={classes.graphBox}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="left"
                    spacing={2}
                  >
                    <Grid item xs={"auto"}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        className={classes.titleLine}
                      >
                        <span className={classes.artLine}>// </span>Scope vs
                        Scraped Models
                        <span className={classes.artLine}> //</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid
                    container
                    spacing={1.75}
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Grid item xs={"auto"}>
                      <MaterialUIButton
                        variant="outlined"
                        message="View All"
                        onClick={() =>
                          navigate("/manage-model-scope", {
                            state: localStorage.getItem("role"),
                          })
                        }
                        startIcon={<DvrOutlinedIcon />}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                className={classes.pageActionArea}
                alignItems="center"
                justifyContent="flex-start"
                sx={{ mt: 2 }}
              >
                <Grid item xs={6} md={6} lg={6} xl={6}>
                  <PieChartCom />
                  {/* <img src={pieChart} alt="pieChart" /> */}
                </Grid>
                <Grid item xs={6} md={6} lg={6} xl={6}>
                  <div className={classes.scrapedDetails}>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Grid item xs={"auto"} justifyContent="flex-start">
                        <img
                          src={scopeIcon}
                          alt="scopeIcon"
                          style={{ marginTop: "5px" }}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <p className={classes.scopeData}>Scope Models</p>
                        <p className={classes.scopeData}>
                          <span className={classes.scopeDataNum}>
                            {data.map((e) => e.scope_Models)}
                          </span>
                          &nbsp;
                          <span className={classes.textCaption}>Files</span>
                        </p>
                      </Grid>
                      <Grid xs={12} className={classes.dividerLine}></Grid>
                      <Grid item xs={"auto"} justifyContent="flex-start">
                        <img
                          src={scrapedIcon}
                          alt="scrapedIcon"
                          style={{ marginTop: "5px" }}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <p className={classes.scopeData}>
                          Scraped Models - Success
                        </p>
                        <p className={classes.scopeData}>
                          <span className={classes.scopeDataNum}>
                            {data.map((e) => e.scraped_Models_Success)}
                          </span>
                          &nbsp;
                          <span className={classes.textCaption}>Files</span>
                        </p>
                      </Grid>
                      <Grid xs={12} className={classes.dividerLine}></Grid>
                      <Grid item xs={"auto"} justifyContent="flex-start">
                        <img
                          src={scraperfailedIcon}
                          alt="scrapedIcon"
                          style={{ marginTop: "5px" }}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <p className={classes.scopeData}>
                          Scraped Models - Failed
                        </p>
                        <p className={classes.scopeData}>
                          <span className={classes.scopeDataNum}>
                            {data.map((e) => e.unScraped_Models_Failed)}
                          </span>
                          &nbsp;
                          <span className={classes.textCaption}>Files</span>
                        </p>
                      </Grid>
                      <Grid xs={12} className={classes.dividerLine}></Grid>
                      <Grid item xs={"auto"} justifyContent="flex-start">
                        <img
                          src={unscrapedIcon}
                          alt="unscrapedIcon"
                          style={{ marginTop: "5px" }}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <p className={classes.scopeData}>Unscraped Models</p>
                        <p className={classes.scopeData}>
                          <span className={classes.scopeDataNum}>
                            {" "}
                            {data.map((e) => e.unScraped_Models)}
                          </span>
                          &nbsp;
                          <span className={classes.textCaption}>Files</span>
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.graphBox}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="left"
                    spacing={2}
                  >
                    <Grid item xs={"auto"}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        className={classes.titleLine}
                      >
                        <span className={classes.artLine}>// </span>Scraper Wise
                        Details
                        <span className={classes.artLine}> //</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid
                    container
                    spacing={1.75}
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Grid item xs={"auto"}>
                      <MaterialUIButton
                        variant="outlined"
                        message="View All"
                        onClick={() =>
                          navigate("/manage-scraper", {
                            state: localStorage.getItem("role"),
                          })
                        }
                        startIcon={<DvrOutlinedIcon />}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} mt={1}>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    className={classes.totalResults}
                  >
                    Total Results:&nbsp; {totalBar}&nbsp;
                    <span className={classes.totalValue}>
                      <span className={classes.textCaption}>Records</span>
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <BarChartCom />
                  {/* <img src={barChart} alt="barChart" /> */}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          className={classes.pageActionArea}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item xs={12} md={6}>
            <div className={classes.graphBox}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="left"
                    spacing={2}
                  >
                    <Grid item xs={"auto"}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        className={classes.titleLine}
                      >
                        <span className={classes.artLine}>// </span>Source URLs
                        with price point changes
                        <span className={classes.artLine}> //</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid
                    container
                    spacing={1.75}
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Grid item xs={"auto"}>
                      <MaterialUIButton
                        variant="outlined"
                        message="View All"
                        onClick={() =>
                          navigate(
                            "/dashboard-and-reports/source-URLs-with-price-point-changes",
                            {
                              state: localStorage.getItem("role"),
                            }
                          )
                        }
                        startIcon={<DvrOutlinedIcon />}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} mt={1}>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    className={classes.totalResults}
                  >
                    Total Results:&nbsp;
                    <span className={classes.totalValue}>{total} Records</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.dataBox}>
                    {pricepoint ? (
                      pricepoint.map((e, index) => (
                        <>
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <Grid item xs={12} md={4}>
                              <Tooltip title={e.data} placement="top" arrow>
                                <Typography
                                  variant="caption"
                                  display="block"
                                  className={classes.progressBarLabel}
                                >
                                  {e.data}
                                </Typography>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                justifyContent="flex-end"
                              >
                                <Grid item xs={9}>
                                  <LinearProgress
                                    value={(Number(e.noOfModels) / max) * 100}
                                    variant="determinate"
                                    className={
                                      classNames[index % classNames.length]
                                    }
                                  />
                                </Grid>
                                <Grid item xs={"auto"}>
                                  <span className={classes.progressResult}>
                                    {e.noOfModels}
                                  </span>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <div className={classes.dividerLine}></div>
                        </>
                      ))
                    ) : (
                      <Grid container spacing={2} minHeight={250}>
                        {" "}
                        <Grid
                          xs
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {" "}
                          <Typography variant="body2" gutterBottom>
                            No data to display{" "}
                          </Typography>{" "}
                        </Grid>{" "}
                      </Grid>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <div className={classes.graphBox}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="left"
                    spacing={2}
                  >
                    <Grid item xs={"auto"}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        className={classes.titleLine}
                      >
                        <span className={classes.artLine}>// </span>Delivered
                        File and Data Count
                        <span className={classes.artLine}> //</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid
                    container
                    spacing={1.75}
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Grid item xs={"auto"}>
                      <MaterialUIButton
                        variant="outlined"
                        message="View All"
                        onClick={() =>
                          navigate(
                            "/dashboard-and-reports/delivered-file-and-data-count",
                            {
                              state: localStorage.getItem("role"),
                            }
                          )
                        }
                        startIcon={<DvrOutlinedIcon />}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} mt={1}>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    className={classes.totalResults}
                  >
                    Total Results:&nbsp;
                    <span className={classes.totalValue}>
                      {totalD ?? 0}
                    </span>{" "}
                    &nbsp;
                    <span className={classes.textCaption}>Records</span>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.dataBox}>
                    {totalD > 0 && deliveryData ? (
                      deliveryData?.map((e, index) => (
                        <>
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <Grid item xs={12} md={4}>
                              <Tooltip title={e.key} placement="top" arrow>
                                <Typography
                                  variant="caption"
                                  display="block"
                                  className={classes.progressBarLabel}
                                >
                                  {e.key}
                                </Typography>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                justifyContent="flex-end"
                              >
                                <Grid item xs>
                                  <LinearProgress
                                    value={(Number(e.val) / totalD) * 100}
                                    variant="determinate"
                                    className={
                                      classNames[index % classNames.length]
                                    }
                                  />
                                </Grid>
                                <Grid item xs={"auto"}>
                                  <span className={classes.progressResult}>
                                    {e.val}
                                  </span>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <div className={classes.dividerLine}></div>
                        </>
                      ))
                    ) : (
                      <Grid container spacing={2} minHeight={250}>
                        {" "}
                        <Grid
                          xs
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {" "}
                          <Typography variant="body2" gutterBottom>
                            No data to display{" "}
                          </Typography>{" "}
                        </Grid>{" "}
                      </Grid>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid> */}
        </Grid>
      </Box>

      <MaterialUIModal
        open={advFilterOpen}
        anchor="right"
        body={<AdvanceFilters close={() => setAdvFilterOpen(false)} />}
      />
    </div>
  );
}

export default DashboardAndReports;
