// Default React, React Components, Router and Services Components
import React from "react";
import { Box, Drawer } from "@mui/material";

function MaterialUIModal(props) {
  // Declare drawerWidth
  let drawerWidth = 0;

  if (props.drawerWidth === "md") {
    drawerWidth = 1020;
  } else if (props.drawerWidth === "role") {
    drawerWidth = 540;
  } else {
    drawerWidth = 350;
  }

  return (
    <Drawer anchor={props.anchor} open={props.open} onClose={props.onClose}>
      <Box sx={{ maxWidth: drawerWidth, p: 2.5 }}>{props.body}</Box>
    </Drawer>
  );
}

export default MaterialUIModal;
