import MaterialUIButton from "components/commonComponents/button/button";
import Textbox from "components/commonComponents/textbox/textbox";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { Required, onlySting } from "utils/validation helper";
import MultipleAutocomplete from "components/commonComponents/autocomplete/multipleAutocomplete";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import AgGrid from "components/commonComponents/grid/agGrid";
import { headers } from "./PermissionData";
import AlertDialog from "components/commonComponents/alertDialog";
import useStyles from "./roleManagement.styles";
import DoneIcon from "@mui/icons-material/Done";
import { getMenu } from "redux/rolemanagement/action";
import { menuName } from "redux/app/actions";
import { Chip } from "@material-ui/core";
function EditRole(props) {
  const data = useSelector((state) => state.manageIssue);
  const select = useSelector((state) => state.roleManagement.rolesdataid);
  const name = localStorage.getItem("firstName");
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const [configdata, setconfigData] = useState([]);
  const [load, setLoading] = useState(true);
  const gridApi = useRef(null);
  const classes = useStyles();
  const id=useSelector((state) => state.project.role)

  const muifilter = createFilterOptions();
  const handleToggleOption = (selectedOptions) => {
    formik.setFieldValue("selectedProjectIds", selectedOptions);
  };
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const optionRenderer = (props, option, { selected }) => {
    console.log(option);
    const selectAllProps =
      option.projectName === "Select All" // To control the state of 'select-all' checkbox
        ? {
            checked:
              data.project.length === formik.values.selectedProjectIds.length,
          }
        : {};
    return (
      <li {...props}>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {option.projectName}
      </li>
    );
  };

  /******************************************************************************
  Function: validate
  Argument: values
  Return: errors
  Usage:
  1.To validate the form
  *******************************************************************************/

  const validate = (values) => {
    const errors = {};

    if (!Required(values.roleDescription)) {
      errors.roleDescription = true;
    } else if (values.roleDescription.length > 50) {
      errors.roleDescription = "Exceeds 50 characters";
    }
    if (values.selectedProjectIds.length === 0) {
      errors.selectedProjectIds = true;
    }
    if (!Required(values.roleName)) {
      errors.roleName = true;
    } else if (!onlySting(values.roleName)) {
      errors.roleName = "Alphabets and underscore are allowed";
      // toast.error("only alphabets and under score  are allowed")
    } else if (values.roleName.length > 20) {
      errors.roleName = "Exceeds 20 characters";
    }
    return errors;
  };
  useEffect(() => {
    dispatch(getMenu(id)).then((res) => {
      if (res.data.result.statusCode === 200) {
        dispatch(menuName(res.data.result.result));
      } else {
        dispatch(menuName([]));
      }
    });
  }, []);
  /******************************************************************************
  Function: reset
  Argument: 
  Return: 
  Usage:
  1.To reset the form
  *******************************************************************************/

  const reset = () => {
    if (formik && formik.values) {
      formik.resetForm();
      formik.values.roleName = select.roleName;
      formik.valuesisActive = select.isActive;
      formik.values.roleDescription = select.roleDescription;
      formik.values.CreatedBy = name;
      formik.values.roleConfigurations = select.roleConfigurations;
    }
  };

  const onGridReadyadd = (params) => {
    gridApi.current = params.api;

    gridApi.current.setRowData(formik.values.roleConfigurations);
  };
  console.log(select);
  const formik = useFormik({
    initialValues: {
      roleName: select.roleName,
      isActive: select.isActive,
      selectedProjectIds: select.projectDetails ? select.projectDetails : [],
      roleDescription: select.roleDescription,
      CreatedBy: name,
      roleConfigurations: select.roleConfigurations,
    },
    validate,
    onSubmit: (values) => {
      props.onChangeSave(true);
      formik.values.roleConfigurations = select.roleConfigurations;
      formik.values.selectedProjectIds = values.selectedProjectIds.map(
        (e) => e.projectId
      );
      props.EditRolesdata(select.roleId, values);
    },
  });
  /******************************************************************************
  Function: handleSelectadd
  Argument: row
  Return:
  Usage:
  1.To select rows to export
  *******************************************************************************/

  const handleSelectadd = (value, name, row) => {
    row[name] = value;
    if (configdata.indexOf(row) !== -1) {
      configdata.length > 0 &&
        configdata.map((e) => {
          if (e.menuID === row.menuID) {
            if (name === "isView") {
              e[name] = value;
              e["isAdd"] = false;
              e["isEdit"] = false;
              e["isDelete"] = false;
            } else {
              e[name] = value;
            }
          }
          return e;
        });
    } else {
      configdata.push(row);
    }

    configdata.length === 0 ? configdata.push(row) : console.log();

    gridApi.current.setRowData(select.roleConfigurations);

    // formik.setFieldValue("roleConfigurations",formik.values.roleConfigurations)
    //gridApi.current.refreshCells(configdata);
  };

  return (
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                Edit Role
              </Typography>
            </Grid>
            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                {/* <Grid item xs="auto">
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => reset()}
                    message="RESET"
                    startIcon={<RestartAltOutlinedIcon />}
                  />
                </Grid> */}
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                          JSON.stringify(formik.values) &&
                        data.files.length === 0
                          ? props.close()
                          : setShow(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}
        <Grid item xs={12}>
          <Textbox
            label="Role Name"
            name="roleName"
            readOnly={
              formik.values.roleName === "Merit QC" ||
              formik.values.roleName === "JATO Admin" ||
              formik.values.roleName === "Merit Admin" ||
              formik.values.roleName === "Merit Developer"
            }
            value={formik.values.roleName}
            onChange={formik.handleChange}
            error={formik.touched.roleName && Boolean(formik.errors.roleName)}
            helperText={formik.touched.roleName && formik.errors.roleName}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            id="project"
            required
            label="Project Name"
            labelId="project"
            name="selectedProjectIds"
            value={formik.values.selectedProjectIds}
            filterOptions={(options, params) => {
              const filtered = muifilter(options, params);
              return [{ projectId: 0, projectName: "Select All" }, ...filtered];
            }}
            onChange={(_, e, value) => {
              console.log(e);
              if (value === "selectOption" || value === "removeOption") {
                if (e.find((option) => option.project === "Select All")) {
                  let result = [];
                  result = data.project.filter(
                    (el) => el.projectName !== "Select All"
                  );
                  formik.setFieldValue("selectedProjectIds", result);
                } else {
                  handleToggleOption && handleToggleOption(e);

                  formik.setFieldValue("selectedProjectIds", e);
                }
              } else if (value === "clear") {
                formik.setFieldValue("selectedProjectIds", []);
              }
              if (e.find((option) => option.projectName === "Select All")) {
                data.project.length === formik.values.selectedProjectIds.length
                  ? formik.setFieldValue("selectedProjectIds", [])
                  : formik.setFieldValue("selectedProjectIds", data.project);
              }
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.projectName}
                  //{...getTagProps({ index })}
                />
              ))
            }
            //  isOptionEqualToValue={(option, value) =>  option.value === value.value }
            isOptionEqualToValue={(option, value) => {
              return option.projectId === value.projectId;
            }}
            // getOptionSelected={(option, value) => option.value === value.value}
            error={
              formik.touched.selectedProjectIds &&
              Boolean(formik.errors.selectedProjectIds)
            }
            helperText={
              formik.touched.selectedProjectIds &&
              formik.errors.selectedProjectIds
            }
            placeholder="project"
            limitTags={4}
            options={data.project}
            getOptionLabel={(e) => e}
            renderOption={optionRenderer}
          />
        </Grid>
        <Grid item xs={12}>
          <Textbox
            required
            multiline
            rows={3}
            label="Role Description"
            fullWidth
            name="roleDescription"
            value={formik.values.roleDescription}
            onChange={formik.handleChange}
            error={
              formik.touched.roleDescription &&
              Boolean(formik.errors.roleDescription)
            }
            helperText={
              formik.touched.roleDescription && formik.errors.roleDescription
            }
          />
        </Grid>
        <Grid item xs={12}>
          <AgGrid
            onGridReady={onGridReadyadd}
            // data={select.manageIssuedata}
            data={formik.values.roleConfigurations}
            header={headers}
            handleSelect={handleSelectadd}
            // actionsHandler={handleGridAction}
            // selectAll={handleSelect}
            // showEdit={() => setShowEditIssue(true)}
            // showView={() => SetViewIssue(true)}
            // showDelete={() => setShowDeleteIssue(true)}
            // edit={editBody}
            // delete={deleteBody}
            // view={viewBody}
            width={100}
          />
        </Grid>
        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                disabled={props.save}
                message="SAVE"
                startIcon={<SaveOutlinedIcon />}
                onClick={() => formik.handleSubmit()}
              />
            </Grid>
            {/* <Grid item xs="auto">
              <MaterialUIButton
                size="small"
                variant="outlined"
                onClick={() => reset()}
                message="RESET"
                startIcon={<RestartAltOutlinedIcon />}
              />
            </Grid> */}
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="CANCEL"
                startIcon={<EventBusyOutlinedIcon />}
                onClick={() => {
                  JSON.stringify(formik.initialValues) ===
                    JSON.stringify(formik.values) && data.files.length === 0
                    ? props.close()
                    : setShow(true);
                }}
              />
            </Grid>
          </Grid>
          <AlertDialog
            open={show}
            onClose={() => setShow(false)}
            // open={isDeleted}
            title={"Confirmation"}
            // maxWidth={"sm"}
            description={`Do you want to discard the changes ?`}
            action={
              <Grid
                container
                alignItems="center"
                justifyContent="right"
                spacing={2}
                className={classes.alertDialogbtn}
              >
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      reset();

                      props.close();
                      setShow(false);
                    }}
                    message="Yes"
                    startIcon={<DoneIcon />}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      setShow(false);
                    }}
                    message="No"
                    startIcon={<CloseIcon />}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
        {/* Action Area End Here */}
      </Grid>
    </Box>
  );
}

export default EditRole;
