// Default React, React Components, Router and Services Components
import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  createFilterOptions,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { formatBytes, getMonth } from "utils/helper";

// Importing Custom Components
import Textbox from "components/commonComponents/textbox/textbox";
import Dropdown from "components/commonComponents/dropdown/dropdown";
import MaterialUIButton from "components/commonComponents/button/button";
import Datepicker from "components/commonComponents/datepicker/datepicker";
import Radiobutton from "components/commonComponents/radiobutton/radiobutton";
import MultipleAutocomplete from "components/commonComponents/autocomplete/multipleAutocomplete";

// Importing useStyles
import useStyles from "./manageScraper.styles";

// Import Material Icons
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  ScraperReason,
  getCountry,
  getMake,
  getScraperCountry,
  getScraperMake,
  getStatus,
  getWebsite,
  nextRunStatus,
  scraperFilter,
  scraperStatus,
  sourceUrl,
} from "redux/app/actions";
import {
  countryDropdown,
  makeDropdown,
  makeString,
  statusDropdown,
  websiteDropdown,
} from "redux/manageissues/action";
import EditPicker from "components/commonComponents/datepicker/editpicker";
import Autocompletedropdown from "components/commonComponents/autocomplete/autocompletedropdown";
import AlertDialog from "components/commonComponents/alertDialog";
import DoneIcon from "@mui/icons-material/Done";
import {
  nextRunstatusScraperDropdown,
  scraperReasonFailure,
  sourceUrlDropdown,
  statusScraperDropdown,
} from "redux/managescraper/action";
import { makeDependency } from "redux/managesources/action";
function AdvanceFilters(props) {
  // Declaration of States and Component Variables Start Here
  const months = getMonth();
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const data = useSelector((state) => state.manageScarper);
  const data1 = useSelector((state) => state.manageIssue);
  const muifilter = createFilterOptions();
  const select = useSelector((state) => state.manageScarper.filter);
  const dispatch = useDispatch();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const id = useSelector((state) => state.project.managescraper);
  const Pname = useSelector((state) => state.project.managescraperprojectname);

  // Declaration of States and Component Variables End Here
  useEffect(() => {
    getDataFormAPIForDropdown();
    // getSearchIssue(page, limit, "");
  }, []);
  /******************************************************************************
  Function: getDataFormAPIForDropdown
  Argument:
  Return:
  Usage:
  1.To Call dropdown API 
  *******************************************************************************/
  const getDataFormAPIForDropdown = () => {
    dispatch(countryDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getCountry([]));
        } else {
          dispatch(getCountry(res.data.result));
        }
      } else {
        dispatch(getCountry([]));
      }
    });
    dispatch(makeString([], id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getMake([]));
        } else {
          dispatch(getMake(res.data.result));
        }
      } else {
        dispatch(getMake([]));
      }
    });

    dispatch(statusScraperDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(scraperStatus([]));
        } else {
          dispatch(scraperStatus(res.data.result));
        }
      } else {
        dispatch(scraperStatus([]));
      }
    });
    dispatch(nextRunstatusScraperDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(nextRunStatus([]));
        } else {
          dispatch(nextRunStatus(res.data.result));
        }
      } else {
        dispatch(nextRunStatus([]));
      }
    });
    dispatch(websiteDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getWebsite([]));
        } else {
          dispatch(getWebsite(res.data.result));
        }
      } else {
        dispatch(getWebsite([]));
      }
    });
    dispatch(scraperReasonFailure(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(ScraperReason([]));
        } else {
          dispatch(ScraperReason(res.data.result));
        }
      } else {
        dispatch(ScraperReason([]));
      }
    });
    dispatch(sourceUrlDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(sourceUrl([]));
        } else {
          dispatch(sourceUrl(res.data.result));
        }
      } else {
        dispatch(sourceUrl([]));
      }
    });
  };

  // Function to Set Default Values
  const formik = useFormik({
    initialValues: {
      timeline: select.timeline,
      quater: select.quater,
      startdate: select.startdate,
      enddate: select.enddate,
      timeLineStart: select.timeLineStart,
      timeLineEnd: select.timeLineEnd,
      country: select.country,
      make: select.make,
      sourceurl: select.sourceurl,
      active: select.active,
      lastrun: select.lastrun,
      website: select.website,
      nextrun: select.nextrun,
      nextrunstatus: select.nextrunstatus,
      reasonForFailure: select.reasonForFailure,
      status: select.status,
      exportId: select.exportId,
    },

    onSubmit: (values) => {
      dispatch(scraperFilter(values));
      props.filter({
        timeLineStart: values.timeLineStart,
        timeLineEnd: values.timeLineEnd,
        country: values.country.map((e) => e.countryCode),
        make: values.make.map((e) => e.make1),
        website: values.website.map((e) => e.website1),

        sourceUrl: values.sourceurl,
        active: values.active,
        status: values.status,
        lastRun: values.lastrun,
        nextRun: values.nextrun,
        reasonForFailure: values.reasonForFailure,
        nextRunStatus: values.nextrunstatus,
        exportId: [],
      });
    },
  });

  /******************************************************************************
  Function: reset
  Argument:
  Return:
  Usage:
  1.To reset the form values
  *******************************************************************************/

  // Function for Reset Values
  const reset = () => {
    if (formik && formik.values) {
      //formik.resetForm();
      formik.values.timeline = "Year";
      formik.values.quater = "";
      formik.values.startdate = null;
      formik.values.enddate = null;
      formik.values.timeLineStart = null;
      formik.values.timeLineEnd = null;
      formik.values.country = [];
      formik.values.make = [];
      formik.values.website = [];

      formik.values.status = [];
      formik.values.exportId = [];
      formik.values.sourceurl = [];
      formik.values.active = "All";
      formik.values.lastrun = null;
      formik.values.nextrun = null;
      formik.values.nextrunstatus = [];
      formik.values.reasonForFailure = [];
      formik.values.exportId = [];
      props.filter(
        {
          timeLineStart: null,
          timeLineEnd: null,
          country: [],
          make: [],
          sourceurl: [],
          website: [],
          active: "All",
          status: [],
          lastrun: null,
          nextrun: null,
          nextrunstatus: [],
          reasonForFailure: [],
          exportId: [],
        },
        true
      );
      dispatch(
        scraperFilter({
          timeline: "Year",
          quater: "",
          startdate: null,
          enddate: null,
          timeLineStart: null,
          timeLineEnd: null,
          country: [],
          make: [],
          website: [],
          sourceurl: [],
          active: "All",
          status: [],
          lastrun: null,
          nextrun: null,
          nextrunstatus: [],
          reasonForFailure: [],
          exportId: [],
        })
      );
    }
  };

  let fmt = "[Q]Q-Y";
  let quarters = [
    moment().format(fmt),
    moment().subtract(1, "Q").format(fmt),
    moment().subtract(2, "Q").format(fmt),
    moment().subtract(3, "Q").format(fmt),
  ];

  return (
    // Advance Filter Option Start Here
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                Advance Filter
              </Typography>
            </Grid>

            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs="auto">
                  <MaterialUIButton
                    color="secondary"
                    message="RESET"
                    onClick={() => {
                      reset();
                    }}
                    startIcon={<RestartAltOutlinedIcon />}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                        JSON.stringify(formik.values)
                          ? props.close()
                          : setShow(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}
        <Grid item xs={12} sx={{ mt: -1, mb: -1 }}>
          <Typography variant="subtitle2" gutterBottom>
            Timeline
          </Typography>

          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Year"
                name="timeline"
                itemvalue="Year"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);

                  formik.setFieldValue(
                    "timeLineStart",
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Quarter"
                // checked={formik.values.timeline === "Quater"}
                name="timeline"
                value={formik.values.timeline}
                itemvalue="Quater"
                onChange={(e) =>
                  formik.setFieldValue("timeline", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Month"
                name="timeline"
                value={formik.values.timeline}
                itemvalue="Month"
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().startOf("month").format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().endOf("month").format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Week"
                // checked={formik.values.timeline === "Week"}
                name="timeline"
                itemvalue="Week"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue("startdate", moment().startOf("week"));
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().startOf("week").format("YYYY-MM-DD")
                  );
                  formik.setFieldValue("enddate", moment().endOf("week"));
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().endOf("week").format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Today"
                // checked={formik.values.timeline === "Today"}
                name="timeline"
                itemvalue="Today"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Custom"
                value={formik.values.timeline}
                // checked={formik.values.timeline === "Custom"}
                name="timeline"
                itemvalue="Custom"
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue("startdate", null);
                  formik.setFieldValue("enddate", null);
                  formik.setFieldValue("timeLineStart", null);
                  formik.setFieldValue("timeLineEnd", null);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {formik.values.timeline === "Week" && (
            <Textbox
              fullWidth
              disabled
              id="week"
              name="week"
              value={
                moment().startOf("week").format("ll") +
                " to " +
                moment().endOf("week").format("ll")
              }
            />
          )}
          {formik.values.timeline === "Month" && (
            <Textbox
              fullWidth
              disabled
              id="year"
              name="year"
              value={months[0]}
            />
          )}
          {formik.values.timeline === "Year" && (
            <Textbox
              fullWidth
              disabled
              id="year"
              name="year"
              value={months[months.length - 1] + " to " + months[0]}
            />
          )}
          {formik.values.timeline === "Today" && (
            <Textbox
              fullWidth
              disabled
              id="today"
              name="today"
              value={moment().format("LL")}
            />
          )}
          {formik.values.timeline === "Quater" && (
            <Dropdown
              fullWidth
              labelId="quater"
              id="quater"
              name="quater"
              label="Quarter"
              value={formik.values.quater}
              onChange={(e) => {
                const value = e.target.value.split("-");
                formik.setFieldValue("quater", e.target.value);

                if (value[0] === "Q1") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("1/1/" + value[1]),
                        moment("3/31/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("3/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("1/1/" + value[1]),
                      moment("3/31/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("1/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("1/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("3/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q2") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("4/1/" + value[1]),
                        moment("6/30/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("6/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("4/1/" + value[1]),
                      moment("6/30/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("4/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("4/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("6/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q3") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("7/1/" + value[1]),
                        moment("9/30/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("9/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("4/1/" + value[1]),
                      moment("6/30/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("7/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("7/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("9/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q4") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("10/1/" + value[1]),
                        moment("12/31/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("12/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("10/1/" + value[1]),
                      moment("12/31/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("10/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("10/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("12/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
              }}
              menuItems={quarters.map((e) => (
                <MenuItem value={e}>{e}</MenuItem>
              ))}
            />
          )}
          {formik.values.timeline === "Custom" && (
            <>
              <EditPicker
                max={moment().format("YYYY-MM-DD")}
                min={moment().subtract(1, "year").format("YYYY-MM-DD")}
                name="timeLineStart"
                value={formik.values.startdate}
                onChange={(e) => {
                  if (e === null) {
                    formik.setFieldValue("startdate", e);
                    // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
                  } else {
                    formik.setFieldValue("startdate", e);

                    formik.setFieldValue(
                      "timeLineStart",
                      e.format("YYYY-MM-DD")
                    );
                  }
                }}
                error={
                  formik.touched.startdate && Boolean(formik.errors.startdate)
                }
                helperText={formik.touched.startdate && formik.errors.startdate}
                size="small"
                fullWidth
                label="Start Date"
              />
              <br />
              <EditPicker
                max={moment().format("YYYY-MM-DD")}
                min={formik.values.startdate}
                name="timeLineEnd"
                value={formik.values.enddate}
                onChange={(e) => {
                  if (e === null) {
                    formik.setFieldValue("enddate", e);
                    // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
                  } else {
                    formik.setFieldValue("enddate", e);
                    formik.setFieldValue("timeLineEnd", e.format("YYYY-MM-DD"));
                  }
                }}
                error={formik.touched.enddate && Boolean(formik.errors.enddate)}
                helperText={formik.touched.enddate && formik.errors.enddate}
                size="small"
                fullWidth
                label="End Date"
              />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="Country"
            id="Country"
            name="country"
            label="Region"
            onChange={(_, e, value) => {
              formik.setFieldValue("country", e);
              if (value !== "clear") {
                dispatch(makeString(e.map((i) => i.countryId))).then((res) => {
                  if (res.data.statusCode === 200) {
                    if (res.data.result === "No records found") {
                      dispatch(getMake([]));
                    } else {
                      dispatch(getMake(res.data.result));
                    }
                  } else {
                    dispatch(getMake([]));
                  }
                });
              } else {
                dispatch(makeString([])).then((res) => {
                  if (res.data.statusCode === 200) {
                    if (res.data.result === "No records found") {
                      dispatch(getMake([]));
                    } else {
                      dispatch(getMake(res.data.result));
                    }
                  } else {
                    dispatch(getMake([]));
                  }
                });
              }
            }}
            value={formik.values.country}
            placeholder="Country"
            limitTags={2}
            options={data1.country}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.countryCode + " - " + option.region}
                  //{...getTagProps({ index })}
                />
              ))
            }
            isOptionEqualToValue={(option, value) => {
              return option.countryId === value.countryId;
            }}
            getOptionLabel={(e) => e.countryCode + " - " + e.region}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.countryCode + " - " + e.region}
              </li>
            )}
          />
        </Grid>
        {Pname === "TRANSACTIONAL ANALYSIS" ? (
          <Grid item xs={12}>
            <MultipleAutocomplete
              labelId="website"
              // disabled={formik.values.country.length === 0 ? true : false}
              id="website"
              name="website"
              label="Website"
              onChange={(_, e) => formik.setFieldValue("website", e)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={option.website1}
                    //{...getTagProps({ index })}
                  />
                ))
              }
              value={formik.values.website}
              placeholder="website"
              limitTags={4}
              options={data1.website}
              getOptionLabel={(e) => e.website1}
              isOptionEqualToValue={(option, value) => {
                return option.website1 === value.website1;
              }}
              renderOption={(props, e, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    size="small"
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {e.website1}
                </li>
              )}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <MultipleAutocomplete
              labelId="Make"
              id="Make"
              name="make"
              label="Make"
              onChange={(_, e) => {
                formik.setFieldValue("make", e);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={option.make1}
                    //{...getTagProps({ index })}
                  />
                ))
              }
              value={formik.values.make}
              placeholder="Make"
              limitTags={3}
              options={data1.make}
              isOptionEqualToValue={(option, value) => {
                return option.make1 === value.make1;
              }}
              getOptionLabel={(e) => e.make1}
              renderOption={(props, e, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    size="small"
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {e.make1}
                </li>
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="sourceurl"
            id="sourceurl"
            name="sourceurl"
            label="Source Url"
            onChange={(_, e) => {
              formik.setFieldValue("sourceurl", e);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option}
                  //{...getTagProps({ index })}
                />
              ))
            }
            value={formik.values.sourceurl}
            placeholder="Source Url"
            limitTags={1}
            options={data.sourceurl}
            getOptionLabel={(e) => e}
            isOptionEqualToValue={(option, value) => {
              return option === value;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  size="small"
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e}
              </li>
            )}
          />
        </Grid>

        {/* <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Active
          </Typography>

          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={6} md={4}>
              <Radiobutton
                name="active"
                itemvalue="All"
                value={formik.values.active}
                onChange={(e) => formik.setFieldValue("active", e.target.value)}
                label="All"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                name="active"
                value={formik.values.active}
                onChange={(e) => formik.setFieldValue("active", e.target.value)}
                itemvalue="Yes"
                label="Yes"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                name="active"
                value={formik.values.active}
                onChange={(e) => formik.setFieldValue("active", e.target.value)}
                itemvalue="No"
                label="No"
              />
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="status"
            id="status"
            label="Scraper Running Status"
            name="status"
            placeholder="status"
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("status", e)}
            value={formik.values.status}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option}
                  //{...getTagProps({ index })}
                />
              ))
            }
            options={data.status}
            getOptionLabel={(e) => e}
            isOptionEqualToValue={(option, value) => {
              return option === value;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="status"
            id="status"
            label="Reason For Failure"
            name="reasonForFailure"
            placeholder="reasonForFailure"
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("reasonForFailure", e)}
            value={formik.values.reasonForFailure}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option}
                  //{...getTagProps({ index })}
                />
              ))
            }
            options={data.reasonForFailure}
            getOptionLabel={(e) => e}
            isOptionEqualToValue={(option, value) => {
              return option === value;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <EditPicker
            name="lastrun"
            value={formik.values.lastrun}
            onChange={(e) => {
              if (e === null) {
                formik.setFieldValue("lastrun", e);
                // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
              } else {
                formik.setFieldValue("lastrun", e);
                formik.setFieldValue("lastrun", e.format("YYYY-MM-DD"));
              }
            }}
            error={formik.touched.lastrun && Boolean(formik.errors.lastrun)}
            helperText={formik.touched.lastrun && formik.errors.lastrun}
            fullWidth
            label="Last Run"
          />
        </Grid>
        <Grid item xs={12}>
          <EditPicker
            name="nextrun"
            value={formik.values.nextrun}
            onChange={(e) => {
              if (e === null) {
                formik.setFieldValue("nextrun", e);
                // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
              } else {
                formik.setFieldValue("nextrun", e);
                formik.setFieldValue("nextrun", e.format("YYYY-MM-DD"));
              }
            }}
            error={formik.touched.nextrun && Boolean(formik.errors.nextrun)}
            helperText={formik.touched.nextrun && formik.errors.nextrun}
            fullWidth
            label="Next Run"
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="Next Run Status"
            id="Issue Field"
            label="Next Run Status"
            name="nextrunstatus"
            placeholder="Next Run Status"
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("nextrunstatus", e)}
            value={formik.values.nextrunstatus}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option}
                  //{...getTagProps({ index })}
                />
              ))
            }
            options={data.nextrunstatus}
            getOptionLabel={(e) => e}
            isOptionEqualToValue={(option, value) => {
              return option === value;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e}
              </li>
            )}
          />
        </Grid>

        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="FILTER"
                startIcon={<FilterListOutlinedIcon />}
                onClick={() => formik.handleSubmit()}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                onClick={() => {
                  reset();
                }}
                message="RESET"
                startIcon={<RestartAltOutlinedIcon />}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="CANCEL"
                startIcon={<EventBusyOutlinedIcon />}
                onClick={() => {
                  JSON.stringify(formik.initialValues) ===
                  JSON.stringify(formik.values)
                    ? props.close()
                    : setShow(true);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <AlertDialog
          open={show}
          onClose={() => setShow(false)}
          // open={isDeleted}
          title={"Confirmation"}
          // maxWidth={"sm"}
          description={`Do you want to discard the changes ?`}
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setShow(false);
                    props.close();
                    reset();
                  }}
                  message="Yes"
                  startIcon={<DoneIcon />}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setShow(false);
                  }}
                  message="No"
                  startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
        {/* Action Area End Here */}
      </Grid>
    </Box>
    // Advance Filter Option End Here
  );
}

export default AdvanceFilters;
