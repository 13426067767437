import {
  ADD_ROLE,
  BASE_URL_ROLE,
  MENU,
  ROLE_MANAGEMENT_DELETE_URL,
  ROLE_MANAGEMENT_UPDATE_URL,
  ROLE_MANAGEMENT_URL,
  UPDATE_ROLE,
  VIEW,
} from "apiConfig/rolemanagement";
import {
  deleteData,
  getData,
  postData,
  putData,
} from "services/basicApiService";
import { checkTokenExpiry } from "utils/helper";
/******************************************************************************
  Function: getRoles
  Argument: pagenumber, pagesize, search,data
  Return: response
  Usage:
  1.Get Role Data
  *******************************************************************************/
const token = localStorage.getItem("token");
export const getRoles = (pagenumber, pagesize, search, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(
      ROLE_MANAGEMENT_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,

      {
        headers: {
          "X-Project-ID": header,
        },
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: addRoles
  Argument: data
  Return: response
  Usage:
  1.Add Role data
  *******************************************************************************/
export const addRoles = (data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(ADD_ROLE, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: updateRoles
  Argument: data
  Return: response
  Usage:
  1.Used to update the roles data
*******************************************************************************/
export const updateRoles = (id, data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await putData(UPDATE_ROLE + `?id=${id}`, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: viewRoles
  Argument: id
  Return: response
  Usage:
  1.Get View Role Data
  *******************************************************************************/
export const viewRoles = (id, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(VIEW + `?id=${id}`, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: deleteRoles
  Argument: Id
  Return: response
  Usage:
  1.Used for deleting the role based on the role Id.
  *******************************************************************************/
export const deleteRoles = (id, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await deleteData(
      ROLE_MANAGEMENT_DELETE_URL + `?id=${id}`,
      {
        headers: { "X-Project-ID": header },
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: getMenu
  Argument: 
  Return: response
  Usage:
  1.Get Menu Data
  *******************************************************************************/
export const getMenu = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(
      MENU + `?PageNumber=${1}&PageSize=${20}&Search=${""}`,

      {
        headers: {
          "X-Project-ID": header,
        },
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
