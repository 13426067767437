import MaterialUIButton from "components/commonComponents/button/button";
import Datepicker from "components/commonComponents/datepicker/datepicker";
import Dropdown from "components/commonComponents/dropdown/dropdown";
import Textbox from "components/commonComponents/textbox/textbox";
import React, { useEffect, useState } from "react";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import DoneIcon from "@mui/icons-material/Done";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";

import Radiobutton from "components/commonComponents/radiobutton/radiobutton";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import MultipleAutocomplete from "components/commonComponents/autocomplete/multipleAutocomplete";
import {
  detailwiseFilter,
  filterDashboard,
  getCountry,
  getMake,
  getModel,
  noFilter,
  saveFilters,
} from "redux/app/actions";
import { getMonth } from "utils/helper";
import {
  countryDropdown,
  makeString,
  modelDropdown,
} from "redux/manageissues/action";
import AlertDialog from "components/commonComponents/alertDialog";
import useStyles from "../dashboardAndReports.styles";
import EditPicker from "components/commonComponents/datepicker/editpicker";
function AdvanceFiltersNo(props) {
  const months = getMonth();
  const select = useSelector((state) => state.dashboardandreports.noRecFilter);
  const data = useSelector((state) => state.manageIssue);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const classes = useStyles();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const id=useSelector((state) => state.project.dashboard)

  useEffect(() => {
    getDataFormAPIForDropdown();
  }, []);
  /******************************************************************************
Function: getDataFormAPIForDropdown
Argument: 
Usage:
1. Calling API for Dropdown.
*******************************************************************************/
  const getDataFormAPIForDropdown = () => {
    dispatch(countryDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getCountry([]));
        } else {
          dispatch(getCountry(res.data.result));
        }
      } else {
        dispatch(getCountry([]));
      }
    });
    dispatch(makeString([],id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getMake([]));
        } else {
          dispatch(getMake(res.data.result));
        }
      } else {
        dispatch(getMake([]));
      }
    });
  };
  /******************************************************************************
Function: formik
Argument: 
Usage:
1. To submit the data.
*******************************************************************************/

  const formik = useFormik({
    initialValues: {
      timeline: select.timeline,
      quater: select.quater,
      startdate: select.startdate,
      enddate: select.enddate,
      timeLineStart: select.timeLineStart
        ? select.timeLineStart
        : moment().format("YYYY-MM-DD"),
      timeLineEnd: select.timeLineEnd
        ? select.timeLineEnd
        : moment().format("YYYY-MM-DD"),
      country: select.country,
      make: select.make,
      model: select.model,
      noOfDays: select.noOfDays,
      exportId: select.exportId,
    },

    onSubmit: (values) => {
      dispatch(noFilter(values));

      props.filter({
        timeLineStart: values.timeLineStart,
        timeLineEnd: values.timeLineEnd,
        country: values.country.map((e) => e.countryId),
        make: values.make.map((e) => e.make1),
        model: values.model.map((e) => e.model1),
        noOfDays: values.noOfDays,
        exportId: values.exportId,
      });
    },
  });
  /******************************************************************************
Function: cancel
Argument: 
Usage:
1. To reset the fileds.
*******************************************************************************/

  const reset = () => {
    if (formik && formik.values) {
      formik.resetForm();
      formik.values.timeline = "Today";
      formik.values.quater = "";
      formik.values.startdate = null;
      formik.values.enddate = null;
      formik.values.timeLineStart = moment().format("YYYY-MM-DD");
      formik.values.timeLineEnd = moment().format("YYYY-MM-DD");
      formik.values.country = [];
      formik.values.make = [];
      formik.values.model = [];
      formik.values.noOfDays = "";
      formik.values.exportId = [];

      props.filter(
        {
          timeLineStart: moment().format("YYYY-MM-DD"),
          timeLineEnd: moment().format("YYYY-MM-DD"),
          country: [],
          make: [],
          model: [],
          noOfDays: "",
          exportId: [],
        },
        true
      );
      dispatch(
        noFilter({
          timeline: "Today",
          quater: "",
          startdate: null,
          enddate: null,
          timeLineStart: moment().format("YYYY-MM-DD"),
          timeLineEnd: moment().format("YYYY-MM-DD"),
          country: [],
          make: [],
          model: [],
          noOfDays: "",
          exportId: [],
        })
      );
    }
  };

  let fmt = "[Q]Q-Y";

  let quarters = [
    moment().format(fmt),
    moment().subtract(1, "Q").format(fmt),
    moment().subtract(2, "Q").format(fmt),
    moment().subtract(3, "Q").format(fmt),
    moment().subtract(4, "Q").format(fmt),
  ];

  return (
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                Advance Filter
              </Typography>
            </Grid>

            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs="auto">
                  <MaterialUIButton
                    color="secondary"
                    message="RESET"
                    onClick={() => {
                      reset();
                    }}
                    startIcon={<RestartAltOutlinedIcon />}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                        JSON.stringify(formik.values)
                          ? props.close()
                          : setShow(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}
        <Grid item xs={12} sx={{ mt: -1, mb: -1 }}>
          <Typography variant="subtitle2" gutterBottom>
            Timeline
          </Typography>

          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Year"
                name="timeline"
                itemvalue="Year"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);

                  formik.setFieldValue(
                    "timeLineStart",
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Quarter"
                // checked={formik.values.timeline === "Quater"}
                name="timeline"
                value={formik.values.timeline}
                itemvalue="Quater"
                onChange={(e) =>
                  formik.setFieldValue("timeline", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Month"
                name="timeline"
                value={formik.values.timeline}
                itemvalue="Month"
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().startOf("month").format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().endOf("month").format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Week"
                // checked={formik.values.timeline === "Week"}
                name="timeline"
                itemvalue="Week"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue("startdate", moment().startOf("week"));
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().startOf("week").format("YYYY-MM-DD")
                  );
                  formik.setFieldValue("enddate", moment().endOf("week"));
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().endOf("week").format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Today"
                // checked={formik.values.timeline === "Today"}
                name="timeline"
                itemvalue="Today"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Custom"
                value={formik.values.timeline}
                // checked={formik.values.timeline === "Custom"}
                name="timeline"
                itemvalue="Custom"
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue("startdate", null);
                  formik.setFieldValue("enddate", null);
                  formik.setFieldValue("timeLineStart", null);
                  formik.setFieldValue("timeLineEnd", null);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {formik.values.timeline === "Week" && (
            <Textbox
              fullWidth
              disabled
              id="week"
              name="week"
              value={
                moment().startOf("week").format("ll") +
                " to " +
                moment().endOf("week").format("ll")
              }
            />
          )}
          {formik.values.timeline === "Month" && (
            <Textbox
              fullWidth
              disabled
              id="year"
              name="year"
              value={months[0]}
            />
          )}
          {formik.values.timeline === "Year" && (
            <Textbox
              fullWidth
              disabled
              id="year"
              name="year"
              value={months[months.length - 1] + " to " + months[0]}
            />
          )}
          {formik.values.timeline === "Today" && (
            <Textbox
              fullWidth
              disabled
              id="today"
              name="today"
              value={moment().format("LL")}
            />
          )}
          {formik.values.timeline === "Quater" && (
            <Dropdown
              fullWidth
              labelId="quater"
              id="quater"
              name="quater"
              label="Quarter"
              value={formik.values.quater}
              onChange={(e) => {
                const value = e.target.value.split("-");
                formik.setFieldValue("quater", e.target.value);

                if (value[0] === "Q1") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("1/1/" + value[1]),
                        moment("3/31/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("3/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("1/1/" + value[1]),
                      moment("3/31/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("1/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("1/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("3/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q2") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("4/1/" + value[1]),
                        moment("6/30/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("6/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("4/1/" + value[1]),
                      moment("6/30/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("4/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("4/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("6/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q3") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("7/1/" + value[1]),
                        moment("9/30/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("9/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("4/1/" + value[1]),
                      moment("6/30/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("7/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("7/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("9/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q4") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("10/1/" + value[1]),
                        moment("12/31/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("12/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("10/1/" + value[1]),
                      moment("12/31/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("10/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("10/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("12/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
              }}
              menuItems={quarters.map((e) => (
                <MenuItem value={e}>{e}</MenuItem>
              ))}
            />
          )}
          {formik.values.timeline === "Custom" && (
            <>
              <EditPicker
                max={moment().format("YYYY-MM-DD")}
                min={moment().subtract(1, "year").format("YYYY-MM-DD")}
                name="timeLineStart"
                value={formik.values.startdate}
                onChange={(e) => {
                  if (e === null) {
                    formik.setFieldValue("startdate", e);
                    // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
                  } else {
                    formik.setFieldValue("startdate", e);

                    formik.setFieldValue(
                      "timeLineStart",
                      e.format("YYYY-MM-DD")
                    );
                  }
                }}
                error={
                  formik.touched.startdate && Boolean(formik.errors.startdate)
                }
                helperText={formik.touched.startdate && formik.errors.startdate}
                size="small"
                fullWidth
                label="Start Date"
              />
              <br />
              <EditPicker
                max={moment().format("YYYY-MM-DD")}
                min={formik.values.startdate}
                name="timeLineEnd"
                value={formik.values.enddate}
                onChange={(e) => {
                  if (e === null) {
                    formik.setFieldValue("enddate", e);
                    // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
                  } else {
                    formik.setFieldValue("enddate", e);
                    formik.setFieldValue("timeLineEnd", e.format("YYYY-MM-DD"));
                  }
                }}
                error={formik.touched.enddate && Boolean(formik.errors.enddate)}
                helperText={formik.touched.enddate && formik.errors.enddate}
                size="small"
                fullWidth
                label="End Date"
              />
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="Country"
            id="Country"
            name="country"
            label="Country"
            value={formik.values.country}
            onChange={(_, e, value) => {
            
              formik.setFieldValue("country", e);
              if (value !== "clear") {
                dispatch(makeString(e.map((i) => i.countryId),id)).then((res) => {
                  if (res.data.statusCode === 200) {
                    if (res.data.result === "No records found") {
                      dispatch(getMake([]));
                    } else {
                      dispatch(getMake(res.data.result));
                    }
                  } else {
                    dispatch(getMake([]));
                  }
                });
              } 
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.countryCode + " - " + option.region}
                  // //{...getTagProps({ index })}
                />
              ))
            }
            placeholder="Country"
            limitTags={4}
            options={data.country}
            isOptionEqualToValue={(option, value) => {
              return option.countryId === value.countryId;
            }}
            getOptionLabel={(e) => e.countryCode + " - " + e.region}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.countryCode + " - " + e.region}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="Make"
         
            id="Make"
            name="make"
            label="Make"
            onChange={(_, e, value) => {
             
              formik.setFieldValue("make", e);
              if (value !== "clear") {
                dispatch(modelDropdown(e.map((i) => i.make1),id)).then((res) => {
                  if (res.data.statusCode === 200) {
                    if (res.data.result === "No records found") {
                      dispatch(getModel([]));
                    } else {
                      dispatch(getModel(res.data.result));
                    }
                  } else {
                    dispatch(getModel([]));
                  }
                });
              } 
              else if(value==="removeOption"&&e.length===0){
                formik.setFieldValue("model", []);

              }
              else {
                formik.setFieldValue("model", []);
              }
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.make1}
                  //{...getTagProps({ index })}
                />
              ))
            }
            value={formik.values.make}
            placeholder="Make"
            limitTags={4}
            options={data.make}
            getOptionLabel={(e) => e.make1}
            isOptionEqualToValue={(option, value) => {
              return option.make1 === value.make1;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  size="small"
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.make1}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            id="Model"
            label="Model"
            labelId="Model"
            disabled={formik.values.make.length === 0 ? true : false}
            name="model"
            value={formik.values.model}
            onChange={(_, e) => formik.setFieldValue("model", e)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.model1}
                  //{...getTagProps({ index })}
                />
              ))
            }
            placeholder="Model"
            limitTags={4}
            options={data.model}
            getOptionLabel={(e) => e.model1}
            isOptionEqualToValue={(option, value) => {
              return option.model1 === value.model1;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.model1}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Textbox
            rows={3}
            length={400}
            label="No Of Days"
            name="noOfDays"
            value={formik.values.noOfDays}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="FILTER"
                startIcon={<FilterListOutlinedIcon />}
                onClick={() => formik.handleSubmit()}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                onClick={() => {
                  reset();
                }}
                message="RESET"
                startIcon={<RestartAltOutlinedIcon />}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="CANCEL"
                startIcon={<EventBusyOutlinedIcon />}
                onClick={() => {
                  JSON.stringify(formik.initialValues) ===
                  JSON.stringify(formik.values)
                    ? props.close()
                    : setShow(true);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <AlertDialog
          open={show}
          onClose={() => setShow(false)}
          // open={isDeleted}
          title={"Confirmation"}
          // maxWidth={"sm"}
          description={`Do you want to discard the changes ?`}
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    reset();
                    props.close();
                    setShow(false);
                  }}
                  message="Yes"
                  startIcon={<DoneIcon />}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setShow(false);
                  }}
                  message="No"
                  startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
        {/* Action Area End Here */}
      </Grid>
    </Box>
  );
}

export default AdvanceFiltersNo;
