import {
  deleteData,
  getData,
  postData,
  postFormData,
  putData,
} from "services/basicApiService";
import {
  BASE_COUNTRY_URL,
  DASHBOARD_COUNTRY_WISE_EXPORT_URL,
  DASHBOARD_COUNTRY_WISE_URL,
  DASHBOARD_DETAILED_COUNTRY_WISE_EXPORT_URL,
  DASHBOARD_DETAILED_COUNTRY_WISE_URL,
} from "apiConfig/dashboardandreports/countrywisestatus";
import {
  PRICE_POINT_CHANGE_EXPORT_URL,
  DASHBOARD_PRICE_POINT_CHANGE_URL,
} from "apiConfig/dashboardandreports/pricepointchanges";
import {
  SOURCE_URL_NO_RECORDS_EXPORT_URL,
  SOURCE_URL_NO_RECORDS_URL,
} from "apiConfig/dashboardandreports/sourceurlwithnorecords";
import {
  PIE_DETAILS,
  PRICE_POINT_CHANGE_URL,
  SCRAPER_DETAILS,
  SOURCE_URL_WITH_NO_RECORD,
} from "apiConfig/dashboardandreports/pricepointchanges";
import { checkTokenExpiry } from "utils/helper";
import {
  CHART_DEVIVERY,
  DASHBOARD_DELIVERY_FILES,
  EXPORT_DELIVERY,
} from "apiConfig/dashboardandreports/deliveryfiles";
const token = localStorage.getItem("token");
/******************************************************************************
  Function: getCountryWise
  Argument: pagenumber, pagesize, search, data
  Return: response
  Usage:
  1.get Country wise status data
  *******************************************************************************/
export const getCountryWise = (pagenumber, pagesize, search, data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(
      DASHBOARD_COUNTRY_WISE_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
      // { authorization: `Bearer ${token}` }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const exportDeliveryData = (
  pagenumber,
  pagesize,
  search,
  data,
  header
) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(
      EXPORT_DELIVERY +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
      // { authorization: `Bearer ${token}` }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const getDeliveryData = (pagenumber, pagesize, search, data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(
      EXPORT_DELIVERY +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
      // { authorization: `Bearer ${token}` }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
Function: ExportCountrywise
Argument: pagenumber, pagesize, search, data
Return: response
Usage:
1.Export Country Wise Status data
*******************************************************************************/
export const ExportCountryWise = (
  pagenumber,
  pagesize,
  search,
  data,
  header
) => {
  return async (dispatch) => {
    //checkTokenExpiry()
    const response = await postData(
      DASHBOARD_COUNTRY_WISE_EXPORT_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }

      //  { authorization: `Bearer ${token}` }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: getDetailCountryWise
  Argument: pagenumber, pagesize, search, data
  Return: response
  Usage:
  1.get Detailed Country wise status data
  *******************************************************************************/
export const getDetailCountryWise = (
  pagenumber,
  pagesize,
  search,
  data,
  header
) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(
      DASHBOARD_DETAILED_COUNTRY_WISE_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
      // ,
      //  { authorization: `Bearer ${token}` }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
Function: ExportDetailCountryWise
Argument: pagenumber, pagesize, search, data
Return: response
Usage:
1.Export Country Wise Status data
*******************************************************************************/
export const ExportDetailCountryWise = (
  pagenumber,
  pagesize,
  search,
  data,
  header
) => {
  return async (dispatch) => {
    //checkTokenExpiry()
    const response = await postData(
      DASHBOARD_DETAILED_COUNTRY_WISE_EXPORT_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
      // ,{ authorization: `Bearer ${token}` }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: getPricePontChanges
  Argument: pagenumber, pagesize, search, data
  Return: response
  Usage:
  1.get Detailed Price Ponit Changes data
  *******************************************************************************/
export const getPricePontChanges = (
  pagenumber,
  pagesize,
  search,
  data,
  header
) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(
      DASHBOARD_PRICE_POINT_CHANGE_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
      // , { authorization: `Bearer ${token}` }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
Function: ExportPricePoint
Argument: pagenumber, pagesize, search, data
Return: response
Usage:
1.Export Price Point Changes data
*******************************************************************************/
export const ExportPricePoint = (
  pagenumber,
  pagesize,
  search,
  data,
  header
) => {
  return async (dispatch) => {
    // checkTokenExpiry()
    const response = await postData(
      PRICE_POINT_CHANGE_EXPORT_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
      // ,   { authorization: `Bearer ${token}` }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: getSourceUrlNoRecords
  Argument: pagenumber, pagesize, search, data
  Return: response
  Usage:
  1.get Source Url No Records data
  *******************************************************************************/
export const getSourceUrlNoRecords = (
  pagenumber,
  pagesize,
  search,
  data,
  header
) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(
      SOURCE_URL_NO_RECORDS_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
      // ,     { authorization: `Bearer ${token}` }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
Function: ExportSourceUrlNoRecords
Argument: pagenumber, pagesize, search, data
Return: response
Usage:
1.Export Source Url No Records data
*******************************************************************************/
export const ExportSourceUrlNoRecords = (
  pagenumber,
  pagesize,
  search,
  data,
  header
) => {
  return async (dispatch) => {
    //checkTokenExpiry()
    const response = await postData(
      SOURCE_URL_NO_RECORDS_EXPORT_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
      // ,    { authorization: `Bearer ${token}` }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: chartUrlCountry
  Argument: data
  Return: response
  Usage:
  1.get Country Wise Status chart data
  *******************************************************************************/
export const chartUrlCountry = (data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(BASE_COUNTRY_URL, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: chartUrlNoRecords
  Argument: 
  Return: response
  Usage:
  1.get Source URL with no Records chart data
  *******************************************************************************/
export const chartUrlNoRecords = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(SOURCE_URL_WITH_NO_RECORD, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: chartUrlNoRecords
  Argument: 
  Return: response
  Usage:
  1.get Source URL with no Records chart data
  *******************************************************************************/
export const chartDeliverdFiles = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(CHART_DEVIVERY, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: chartUrlNoRecords
  Argument: 
  Return: response
  Usage:
  1.get Scraper chart data
  *******************************************************************************/
export const chartUrlScarper = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(SCRAPER_DETAILS, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: chartUrlPie
  Argument: 
  Return: response
  Usage:
  1.get Source chart data
  *******************************************************************************/
export const chartUrlPie = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(PIE_DETAILS, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: chartUrlPricepointChange
  Argument: 
  Return: response
  Usage:
  1.get price point changes chart data
  *******************************************************************************/
export const chartUrlPricepointChange = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(PRICE_POINT_CHANGE_URL, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
