import { AppActionsTypes } from "./actions-types";

const INITIAL_STATE = {
  usersdata: [],
  usersdataid: {},
  firstName:localStorage.getItem("firstName")?localStorage.getItem("firstName"):"",
  lastName:localStorage.getItem("lastName")?localStorage.getItem("lastName"):"",
  role:localStorage.getItem("role")?localStorage.getItem("role"):"",
  roleId:localStorage.getItem("roleId")?localStorage.getItem("roleId"):0,
  roleData:localStorage.getItem("roleData")?JSON.parse(localStorage.getItem("roleData")):{},
  error: null,
  projectDetails:[],
  roles:[],
  isLoading: false,
};
const userMangementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppActionsTypes.FETCH_FAILURE_SUCCESS:
      return {
        ...state,
        error: action.payload,
      };
      case AppActionsTypes.ROLES:
        return {
          ...state,
          roles: action.payload,
        };
    case AppActionsTypes.FETCH_USER_MANAGEMENT:
      return {
        ...state,
        usersdata: action.payload,
      };
      case AppActionsTypes.PROJECT_DETAILS:
        return {
          ...state,
          projectDetails: action.payload,
        };
      
      case AppActionsTypes.IS_LOADING:
        return {
          ...state,
          isLoading: action.payload,
        };
    case AppActionsTypes.FETCH_USER_MANAGEMENT_ID:
      return {
        ...state,
        usersdataid: action.payload,
      };
      case AppActionsTypes.ROLE_DATA:
        return {
          ...state,
          roleData: action.payload,
        };
        case AppActionsTypes.ROLE_ID:
          return {
            ...state,
            roleId: action.payload,
          };
          case AppActionsTypes.ROLE_NAME:
          return {
            ...state,
            roleName: action.payload,
          };
          case AppActionsTypes.FIRST_NAME:
            return {
              ...state,
              firstName: action.payload,
            };
            case AppActionsTypes.LAST_NAME:
              return {
                ...state,
                lastName: action.payload,
              };

    default:
      return state;
  }
};

export default userMangementReducer;
