import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AlertDialog = (props) => {
  const { action, description, id, maxWidth, open, onClose, title } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={maxWidth ? maxWidth : "xs"}
        aria-labelledby={id + "-title"}
        aria-describedby={id + "-description"}
        TransitionComponent={Transition}
        fullWidth
        keepMounted
      >
        <DialogTitle
          id={id + "-title"}
          style={{
            color: "#7947F6",
            padding: "15px 15px 10px 15px",
            fontWeight: "600",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent style={{ padding: "0 15px 0 15px", fontWeight: "600" }}>
          <DialogContentText
            id={id + "-description"}
            style={{ color: "#333333" }}
          >
            {description}
          </DialogContentText>
        </DialogContent>
        {action && <DialogActions>{action}</DialogActions>}
      </Dialog>
    </>
  );
};
export default AlertDialog;
