import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { chartUrlPie } from "redux/dashboard&reports/action";
import "../stackeBarChart/style.scss";
import { Grid, Typography } from "@mui/material";
const COLORS = ["#79D559", "#e75858", "#FF823C"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  value,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {value > 0 ? `${value}%` : ""}
    </text>
  );
};

export default function PieChartCom() {
  const dispatch = useDispatch();
  const [data, setdata] = useState([]);
  const [value, setvalue] = useState({});
  const [tooltip, setTooltip] = useState({});
  const id = useSelector((state) => state.project.dashboard);

  /******************************************************************************
Function: CustomTooltip
Argument:  active, payload, label
Usage:
1. To display the custom tooltip for the barchart.
*******************************************************************************/
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="chartTitle">
            {`${payload[0].payload.caption}`}:{" "}
            <span className="chartValue">{`${payload[0].payload.data}`}</span>
          </p>
          {/* <p className="intro">{`${payload[0].payload.caption}:${payload[0].payload.data}`}</p> */}
        </div>
      );
    }

    return null;
  };
  useEffect(() => {
    dispatch(chartUrlPie(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          setdata([]);
        } else {
          setTooltip(res.data.result[0]);
          setvalue(res.data.result[0]);
          setdata([
            {
              name: "Scraped Percentage",
              // eslint-disable-next-line no-dupe-keys
              value: res.data.result[0].success_Scraped_Percentage,
              caption: "Scraped Models - Success",
              data: res.data.result[0].scraped_Models_Success,
            },
            {
              name: "Failed Percentage",
              // eslint-disable-next-line no-dupe-keys
              value: res.data.result[0].failed_Scraped_Percentage,
              caption: "Scraped Models - Failed",
              data: res.data.result[0].unScraped_Models_Failed,
            },

            {
              name: "Unscraped Percentage",
              // eslint-disable-next-line no-dupe-keys
              value: res.data.result[0].unScraped_Percentage,
              caption: "Unscraped Models",
              data: res.data.result[0].unScraped_Models,
            },
          ]);
        }
      } else {
        setdata([]);
      }
    });
  }, [id]);

  return (
    <>
      <ResponsiveContainer width="100%" height={220}>
        {value.unScraped_Percentage !== 0 ||
        value.success_Scraped_Percentage !== 0 ||
        value.failed_Scraped_Percentage !== 0 ? (
          <PieChart width={220} height={220}>
            <Tooltip
              cursor={{ fill: "none" }}
              content={<CustomTooltip />}
              wrapperStyle={{ outline: "none" }}
            />

            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={110}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  // dataKey="unScraped_Percentage"
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        ) : (
          <Grid container spacing={2} minHeight={250}>
            {" "}
            <Grid xs display="flex" justifyContent="center" alignItems="center">
              {" "}
              <Typography variant="body2" gutterBottom>
                No data to display{" "}
              </Typography>{" "}
            </Grid>{" "}
          </Grid>
        )}
      </ResponsiveContainer>
    </>
  );
}
