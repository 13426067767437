import React from "react";
import { FormControl, InputLabel, Select } from "@mui/material";

import useStyles from "./dropdown.styles";
import { IconButton } from "@material-ui/core";
import ClearIcon from "@mui/icons-material/Clear";
function Dropdown(props) {
  const classes = useStyles();

  return (
    <FormControl
      required={props.required}
      sx={{ minWidth: props.width }}
      error={props.error}
      size="small"
      fullWidth={props.fullWidth}
      className={classes.textField}
    >
      <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
      <Select
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        multiple={props.multiple}
        labelId="demo-simple-select-label"
        id={props.id}
        value={props.value}
        label={props.label}
        onChange={props.onChange}
        renderValue={props.renderValue}
      >
        {props.menuItems}
      </Select>
    </FormControl>
  );
}

export default Dropdown;
