// Default React, React Components, Router and Services Components
import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";

// Dummy Data
import { headers } from "./Data";

// Importing Custom Components
import AdvanceFilters from "./advanceFilters";
import BaseLayout from "components/commonComponents/baseLayout";
import Gridnav from "components/commonComponents/gridnav/gridnav";
import MaterialUIModal from "components/commonComponents/modal/modal";
import MaterialUIButton from "components/commonComponents/button/button";
import AgGrid from "components/commonComponents/grid/agGrid";
import UsePagination from "components/commonComponents/pagination/pagination";

// Importing useStyles
import { styled } from "@mui/material/styles";
import useStyles from "./manageSources.styles";

// Import Material Icons
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import AddSource from "./addSource";
import BulkUpload from "./bulkUpload";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { utils, writeFile } from "xlsx";
import {
  addBulk,
  deletesource,
  ExportManagesource,
  getManagesource,
  updateManagesource,
  viewSource,
} from "redux/managesources/action";
import {
  errorMessage,
  fetchmanageSource,
  fetchmanageSourceid,
  saveFiltersSource,
} from "redux/app/actions";
import Toasts from "components/commonComponents/toasts/toasts";
import AlertDialog from "components/commonComponents/alertDialog";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import EditSource from "./editSource";
import ViewSource from "./viewSource";
import { addSource } from "redux/managesources/action";
import { toast } from "react-toastify";
import Spinner from "components/commonComponents/spinner/spinner";
import { useLocation } from "react-router-dom";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { debounce } from "lodash";
function ManageSources() {
  const dispatch = useDispatch();
  const gridApi = useRef(null);
  const id = useSelector((state) => state.project.managesource);

  const select = useSelector((state) => state.manageSource);
  const [saveDisabled, setsaveDisabled] = useState(false);
  const [load, setLoad] = useState(true);
  const [save, setsave] = useState(false);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [msg, setMsg] = useState("");
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [total, settotal] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  const [totalRecords, settotalRecords] = useState(0);
  const [selectExport, setselectExport] = useState([]);
  const [rows, setRows] = useState();
  const [error, setError] = useState({});
  const [advFilterOpen, setAdvFilterOpen] = useState(false);
  const classes = useStyles();
  const [addIssuseOpen, setAddIssuseOpen] = useState(false);
  const [bulkupload, setBulkupload] = useState(false);
  const [viewsource, SetViewsource] = useState(false);
  const [showEditSource, setShowEditSource] = useState(false);
  const [Deletedata, setDeletedata] = useState({});
  const [showDelete, setshowDelete] = useState(false);
  const [disableedit, setdisableedit] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const role = localStorage.getItem("role");
  const name = localStorage.getItem("firstName");
  const location = useLocation();
  const [add, setAdd] = useState([]);
  const selector = JSON.parse(localStorage.getItem("roleData"));
  const filterData = useSelector((state) => state.manageSource.filter);
  const filterBody = {
    timeLineStart: filterData.timeLineStart,
    timeLineEnd: filterData.timeLineEnd,
    country: filterData.country.map((e) => e.countryId),
    make: filterData.make.map((e) => e.make1),
    model: filterData.model.map((e) => e.model1),
    sourceUrl: filterData.sourceurl.map((e) => e.sourceUrl),
    urlStatus: filterData.urlstatus.map((e) => e.urlstatusId),
    receivedDate: filterData.receiveddate,
    priorityLevel: filterData.priorityLevel,
    newSourceStatus: filterData.newsourcestatus,
    complexityLevel: filterData.complexityLevel,
    exportId: [],
  };
  useEffect(() => {
    if (search.length > 0) {
      const timerId = setTimeout(() => {
        performSearch(search);
      }, 3000);
      return () => clearTimeout(timerId);
    } else {
      setPage(1);

      getSource(1, limit, search, filterBody);
    }
  }, [search]);

  const performSearch = (value) => {
    setPage(1);
    let encodedValue = encodeURIComponent(value);

    getSource(1, limit, encodedValue, filterBody);
    // Update the results state with the fetched data
    // setResults(responseData);
  };

  /******************************************************************************
  Function: getSource
  Argument: page,limit,search,body
  Return: 
  Usage:
  1.To call grid API
  *******************************************************************************/

  const getSource = (
    page,
    limit,
    search,
    body = {
      timeLineStart: null,
      timeLineEnd: null,
      country: [],
      make: [],
      model: [],
      sourceUrl: [],
      priorityLevel: [],

      urlStatus: [],
      receivedDate: null,
      newSourceStatus: [],
      complexityLevel: [],
      exportId: [],
    }
  ) => {
    dispatch(getManagesource(page, limit, search, body, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            settotalRecords(0);
            dispatch(fetchmanageSource([]));
          } else {
            dispatch(fetchmanageSource(res.data.result));
          }
          setLoad(false);

          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        }
        else{
          setLoad(false);
          settotalCount(0);
          settotalRecords(0);
          dispatch(fetchmanageSource([]));
        }
      }
    });
  };
  const exportdata = selectExport.toString();
  const exportIds = exportdata.split(",");

  /******************************************************************************
  Function: handleExport
  Argument: body
  Return: 
  Usage:
  1.To Export data from grid
  *******************************************************************************/

  const handleExport = (body) => {
    setLoad(true);
    dispatch(
      ExportManagesource(
        page,
        limit,
        search,
        (body = {
          timeLineStart: filterData.timeLineStart,
          timeLineEnd: filterData.timeLineEnd,
          country: filterData.country.map((e) => e.countryId),
          make: filterData.make.map((e) => e.make1),
          model: filterData.model.map((e) => e.model1),
          sourceUrl: filterData.sourceurl.map((e) => e.sourceUrl),
          urlStatus: filterData.urlstatus.map((e) => e.urlstatusId),
          receivedDate: filterData.receiveddate,
          complexityLevel: filterData.complexityLevel,
          newSourceStatus: filterData.newsourcestatus,
          priorityLevel: filterData.priorityLevel,

          exportId: rows ? ["All"] : exportIds,
        }),
        id
      )
    ).then((res) => {
      setLoad(false);
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, [
        [
          "Country",
          "Make",
          "Model",
          "Business Priority",
          "Source URL",
          "Previous Source URL",
          "Scrapper Complexity",
          "URL Status",
          "Source Received date",
          "Comments",
        ],
      ]);
      const rows = res.data.result.map((row) => [
        row.country,
        row.make,
        row.model,
        row.priority_Level ? row.priority_Level : "",
        row.sourceURL,
        row.previousSourceURL,
        row.complexity_Level ? row.complexity_Level : "",
        row.urlStatus ? row.urlStatus : "",
        row.receivedDate ? moment(row.receivedDate).format("DD/MM/YYYY") : "",

        row.comments,
      ]);
      utils.sheet_add_json(ws, rows, {
        origin: "A2",
        skipHeader: true,
      });

      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, `managesource_${moment().format("DMMMyy")}.csv`);
    });
    getSource(page, limit, search, filterBody);
  };
  /******************************************************************************
  Function: editBody
  Argument: data
  Return: 
  Usage:
  1.To set values in edit screen
  *******************************************************************************/

  const editBody = (data) => {
    setShowEditSource(true);
  };

  /******************************************************************************
  Function: viewBody
  Argument: data
  Return: 
  Usage:
  1.To set values in view screen
  *******************************************************************************/

  const viewBody = (data) => {
    SetViewsource(true);
  };

  /******************************************************************************
  Function: EditSourcedata
  Argument: data
  Return: 
  Usage:
  1.To edit the existing data 
  *******************************************************************************/

  const EditSourcedata = (data) => {
    setLoad(true);
    dispatch(updateManagesource(data, id)).then(async (res) => {
      if (res.data.statusCode === 200) {
        toast.success("Source saved successfully");
        await getSource(page, limit, search);
        setShowEditSource(false);
        SetViewsource(false);
        setdisableedit(false);
      } else {
        setShowEditSource(false);
        SetViewsource(false);
        setdisableedit(false);
        toast.error("Source save failed");
      }

      await gridApi.current.redrawRows(select.manageSourcedata);
    });
  };
  /******************************************************************************
  Function: filterSource
  Argument: data, reset 
  Return: 
  Usage:
  1.To Filter the grid data
  *******************************************************************************/

  const filterSource = async (data, reset = false) => {
    setLoad(true);
    setPage(1);
    dispatch(getManagesource(1, limit, search, data, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            //setState([]);
            settotalRecords(0);
            dispatch(fetchmanageSource([]));
          } else {
            dispatch(fetchmanageSource(res.data.result));
          }
          setLoad(false);
          //setState(res.data.result);
          if (!reset) {
            setAdvFilterOpen(false);
          }

          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        }
      }
    });
    gridApi.current.redrawRows(select.manageIssuedata);
    //
  };
  /******************************************************************************
  Function: deleteBody
  Argument: data, reset 
  Return: 
  Usage:
  1.To set id to delete
  *******************************************************************************/

  const deleteBody = (event) => {
    setDeletedata(event.sourceID);
    //setRolename(event.);
  };

  /******************************************************************************
  Function: handleDelete
  Argument: sourceId
  Return: 
  Usage:
  1.To handle delete in grid
  *******************************************************************************/

  const handleDelete = (sourceId) => {
    setLoad(true);
    dispatch(deletesource(sourceId, id)).then(async (res) => {
      if (res.data.statusCode === 200) {
        toast.success("Source deleted successfully");
        await getSource(page, limit, search);
      } else {
        toast.error("Failed to delete");
        await getSource(page, limit, search);
      }
      await gridApi.current.redrawRows(select.manageSourcedata);
    });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  // useEffect for fetching results on initial load
  // useEffect(() => {
  //   performSearch(search);
  // }, []);

  useEffect(() => {
    getSource(page, limit, "");
    dispatch(
      saveFiltersSource({
        timeline: "Year",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: null,
        timeLineEnd: null,
        country: [],
        make: [],
        priorityLevel: [],

        model: [],
        sourceurl: [],
        urlstatus: [],
        receiveddate: null,
        complexityLevel: [],
        newsourcestatus: [],
        exportId: [],
      })
    );
    setAdd(
      selector &&
        selector.roleConfigurations.filter((e) =>
          e.menuName === "Manage Issue/Request"
            ? location.pathname === "/manage-issue-/-request" &&
              e.isAdd === true
            : e.menuName.replace("&", "and").toLowerCase() ===
                location.pathname
                  .replace("/", "")
                  .replace("-", " ")
                  .replace("-", " ")
                  .replace("/", "#")
                  .split("#")[0] && e.isAdd === true
        )
    );
  }, [id]);

  /******************************************************************************
  Function: handleGridAction
  Argument: action, selectedRow
  Return: 
  Usage:
  1.To handle grid actions 
  *******************************************************************************/

  const handleGridAction = (action, selectedRow) => {
    switch (action) {
      case "Delete":
        handleDelete(selectedRow);
        break;
      default:
        break;
    }
  };
  /******************************************************************************
  Function: handleSelect
  Argument: row
  Return:
  Usage:
  1.To select rows to export
  *******************************************************************************/

  const handleSelect = async (row) => {
    setselectExport(row.map((e) => e.sourceID));
    setRows(
      row.length === 10 ||
        row.length === 15 ||
        row.length === 25 ||
        row.length === 50 ||
        row.length === 100
    );
    if (row.length === 0) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  /******************************************************************************
  Function: upload
  Argument: data
  Return:
  Usage:
  1.To Upload excel data to grid
  *******************************************************************************/

  const upload = (data) => {
    setMsg("Uploading....");
    dispatch(addBulk(data, name, id)).then(async (res) => {
      setMsg("");
      if (res.data.statusCode === 200) {
        toast.success("Successfully saved");
        await getSource(page, limit, search);
        setBulkupload(false);
        setsave(false);
      } else if (res.data.statusCode === 400) {
        dispatch(
          errorMessage(res.data.responseException.exceptionMessage.result)
        );
        setsave(false);
      } else {
        setBulkupload(false);
        toast.error("Save failed");
        setsave(false);
      }

      await gridApi.current.redrawRows(select.manageSourcedata);
    });
  };
  /******************************************************************************
  Function: handlePageClick
  Argument: event, data
  Return:
  Usage:
  1.To handle page navigation
  *******************************************************************************/

  const handlePageClick = async (event, data) => {
    setLoad(true);
    setPage(data);
    await getSource(data, limit, search, filterBody);
    gridApi.current.redrawRows(select.manageSourcedata);
  };
  /******************************************************************************
  Function: addNewsource
  Argument:  data
  Return:
  Usage:
  1.To add new source in grid
  *******************************************************************************/

  const addNewsource = (data) => {
    setLoad(true);
    setPage(1);
    dispatch(addSource(data, id)).then(async (res) => {
      if (res.data.statusCode === 201) {
        toast.success("Source saved successfully");
        await getSource(page, limit, search);
        setAddIssuseOpen(false);
        setLoad(false);
        setsaveDisabled(false);
      } else {
        setAddIssuseOpen(false);
        setLoad(false);
        toast.error("Source save failed");
        setsaveDisabled(false);
      }

      await gridApi.current.redrawRows(select.manageSourcedata);
    });
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    boxShadow: "0px 0px 4px 0px rgb(195 0 5 / 10%)",
    position: "relative",
    borderRadius: "14px",
  }));
  const [gridKey, setGridKey] = useState(0);
  const resetAll = async () => {
    dispatch(
      saveFiltersSource({
        timeline: "Year",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: null,
        timeLineEnd: null,
        country: [],
        make: [],
        model: [],
        sourceurl: [],
        urlstatus: [],
        priorityLevel: [],

        receiveddate: null,
        complexityLevel: [],
        newsourcestatus: [],
        exportId: [],
      })
    );
    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
    if (search !== "") {
      setPage(1);
      setSearch("");
    }
    getSource(1, limit, "", {
      timeLineStart: null,
      timeLineEnd: null,
      country: [],
      make: [],
      model: [],
      sourceUrl: [],
      urlStatus: [],
      receivedDate: null,
      complexityLevel: [],
      priorityLevel: [],

      newSourceStatus: [],
      exportId: [],
    });

    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
  };
  return (
    <div>
      {/* Manage Issues Page Start Here */}
      <Box>
        <Spinner open={load} />
        {/* BaseLayout Start Here */}
        <BaseLayout>
          {/* Action Area - Search by Keyword, Total Record Count, Add Issue, Adv. Filter and Export */}
          <Grid
            container
            spacing={2}
            className={classes.pageActionArea}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={12} md={5}>
              <Grid
                container
                alignItems="center"
                justifyContent="left"
                spacing={2}
              >
                <Grid item xs={"auto"}>
                  <Gridnav onChange={handleInputChange} data={search} />
                </Grid>
                <Grid item xs={"auto"}>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    className={classes.totalResults}
                  >
                    Total Results:{" "}
                    <span className={classes.totalValue}>
                      {totalRecords ?? 0}
                    </span>
                    &nbsp;
                    <span className={classes.recordText}>Records</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid
                container
                spacing={1.75}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    message="Clear Filters"
                    startIcon={<RestartAltIcon />}
                    onClick={() => resetAll()}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    disabled={add.length === 0}
                    variant="outlined"
                    onClick={() => setAddIssuseOpen(true)}
                    message="Add Source"
                    startIcon={<AddBoxOutlinedIcon />}
                  />
                </Grid>

                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    disabled={add.length === 0}
                    onClick={() => setBulkupload(true)}
                    message="Bulk Upload"
                    startIcon={<DifferenceOutlinedIcon />}
                  />
                </Grid>

                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => setAdvFilterOpen(true)}
                    message="Advance Filter"
                    startIcon={<FilterListOutlinedIcon />}
                  />
                </Grid>

                {show === true ? (
                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      message="Export"
                      onClick={() => {
                        handleExport();
                        setShow(false);
                      }}
                      startIcon={<FileDownloadOutlinedIcon />}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      message="Export"
                      onClick={() => handleExport()}
                      disabled
                      startIcon={<FileDownloadOutlinedIcon />}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* Grid Start here */}
          <Grid
            container
            spacing={2}
            className={classes.pageActionArea}
            alignItems="center"
            justifyContent="flex-start"
            sx={{ p: 0 }}
          >
            <Grid item xs={12} className={classes.fullHeight}>
              <AgGrid
                // onGridReady={onGridReadyManageSource}
                data={select.manageSourcedata}
                header={headers}
                // actionsHandler={handleGridAction}
                //sorting={handleSort}
                selectAll={handleSelect}
                showEdit={() => setShowEditSource(true)}
                showView={() => SetViewsource(true)}
                showDelete={() => setshowDelete(true)}
                edit={editBody}
                delete={deleteBody}
                view={viewBody}
                key={gridKey}
              />
              <UsePagination
                pageCount={pageCount}
                count={total}
                t={totalRecords}
                total={totalCount}
                currentPage={page}
                limit={limit}
                value={limit}
                handlePageClick={handlePageClick}
                onChange={(e) => {
                  setLimit(e.target.value);
                  setPage(1);
                  getSource(1, e.target.value, search, filterBody);
                  // gridApi.current.redrawRows(select.manageSourcedata);
                }}
              />
            </Grid>
          </Grid>
        </BaseLayout>

        {/* Advance Filter Model */}
        <MaterialUIModal
          open={advFilterOpen}
          anchor="right"
          body={
            <AdvanceFilters
              filter={filterSource}
              close={() => setAdvFilterOpen(false)}
            />
          }
        />

        <MaterialUIModal
          open={addIssuseOpen}
          anchor="right"
          body={
            <AddSource
              save={saveDisabled}
              onChangeSave={() => setsaveDisabled(true)}
              close={() => setAddIssuseOpen(false)}
              addsource={addNewsource}
            />
          }
        />
        <MaterialUIModal
          open={bulkupload}
          // onClose={() => setBulkupload(false)}
          anchor="right"
          body={
            <BulkUpload
              save={save}
              error={error}
              msg={msg}
              set={() => setMsg("")}
              seterror={() => setError({})}
              onChangeSave={() => setsave(true)}
              close={() => setBulkupload(false)}
              upload={upload}
            />
          }
        />
        <MaterialUIModal
          drawerWidth="md"
          open={showEditSource}
          //onClose={() => setShowEditSource(false)}
          anchor="right"
          body={
            <EditSource
              save={disableedit}
              onChangeSave={() => setdisableedit(true)}
              close={() => setShowEditSource(false)}
              EditSourcedata={EditSourcedata}
            />
          }
        />
        <MaterialUIModal
          drawerWidth="md"
          open={viewsource}
          //onClose={() => SetViewsource(false)}
          anchor="right"
          body={
            <ViewSource
              //data={data}
              EditSourcedata={EditSourcedata}
              save={disableedit}
              onChangeSave={() => setdisableedit(true)}
              close={() => SetViewsource(false)}
            />
          }
        />
        <Toasts open={open} onClose={() => setOpen(false)} message={message} />
        <AlertDialog
          open={showDelete}
          onClose={() => setshowDelete(false)}
          // open={isDeleted}
          title={"Confirmation"}
          // maxWidth={"sm"}
          description={`Do you want to delete Source? `} /* - ${Deletedata} */
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    handleGridAction("Delete", Deletedata);
                    setshowDelete(false);
                  }}
                  message="Delete"
                  startIcon={<DoneIcon />}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setshowDelete(false);
                  }}
                  message="CANCEL"
                  startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
      </Box>
    </div>
  );
}

export default ManageSources;
