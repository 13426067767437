// Default React, React Components, Router and Services Components
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
// Importing Custom Components
import Dropzone from "components/commonComponents/dragDropField";

import MaterialUIButton from "components/commonComponents/button/button";
import DownloadIcon from "@mui/icons-material/Download";
// Importing useStyles

// Import Material Icons
import CloseIcon from "@mui/icons-material/Close";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import { useFormik } from "formik";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

// Import Image Icons
import { pdfIcon, remove } from "../../../../assets/index";
import newStyles from "./manageModelScope.styles";
import { errorMessage, saveFiles } from "redux/app/actions";
import AlertDialog from "components/commonComponents/alertDialog";
import { addBulk } from "redux/managesources/action";
import { toast } from "react-toastify";
import { writeFile } from "xlsx";
import moment from "moment";
import { utils } from "xlsx/xlsx";
import * as XLSX from "xlsx";
import Radiobutton from "components/commonComponents/radiobutton/radiobutton";

function bulkUpload(props) {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useSelector((state) => state.manageIssue);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const id=useSelector((state) => state.project.managescope)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const error = useSelector((state) => state.manageSource.error);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [show, setShow] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const name = localStorage.getItem("firstName").split("-")[1];
  const classes = newStyles();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    window.addEventListener("load", dispatch(errorMessage({})), false);
  }, []);
  /******************************************************************************
Function: formik
Argument: 
Usage:
1. To submit the data.
*******************************************************************************/

  const formData = new FormData();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formik = useFormik({
    initialValues: {
      scope: "current",
      month: moment().format("MMMM"),

      files: [],
    },
    onSubmit: (values) => {
     
      if (data.files.length === 0) {
        toast.error("Please upload a file");
      } else {
        props.onChangeSave(true);
        for (let i = 0; i < data.files.length; i++) {
          formData.append("files", data.files[i]);
        }
        for (var pair of formData.entries()) {
        }

        props.upload(
          formData,
          name,
          values.scope + "-" + values.month.toLowerCase()
        );
      }
    },
  });
  /******************************************************************************
Function: reset
Argument: 
Usage:
1. To reset the data.
*******************************************************************************/

  const reset = () => {
    if (formik && formik.values) {
      formik.resetForm();

      dispatch(saveFiles([]));
      dispatch(errorMessage({}));
    }
  };
  /******************************************************************************
Function: handleDownload
Argument: 
Usage:
1. To download the template file.
*******************************************************************************/

  const handleDownload = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);
    const header = ["Country", "Make", "Model","Business Priority", "Month"];

    // Add header row to worksheet
    XLSX.utils.sheet_add_aoa(ws, [header]);

    // Set bold font for header row
    const headerStyle = {
      font: { bold: true },
    };
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let c = range.s.c; c <= range.e.c; ++c) {
      const cell = XLSX.utils.encode_cell({ r: 0, c: c });
      ws[cell].s = headerStyle;
    }

    XLSX.utils.book_append_sheet(wb, ws, "manage_model_scope");
    XLSX.writeFile(wb, `ManageModelScopeTemplate.xlsx`);
  };
  /******************************************************************************
Function: closeError
Argument: 
Usage:
1. To close the errormessage.
*******************************************************************************/

  const closeError = () => {
    dispatch(errorMessage({}));
    props.set();
    props.close();
  };
  return (
    // Add Source Form Start Here
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Bulk Upload Start Here */}

        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                Bulk Upload
              </Typography>
            </Grid>
            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs="auto">
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => reset()}
                    message="RESET"
                    startIcon={<RestartAltOutlinedIcon />}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                          JSON.stringify(formik.values) &&
                        data.files.length === 0
                          ? closeError()
                          : setShow(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}

        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              className={classes.uploadTitle}
              gutterBottom
            >
              Scope For
            </Typography>

            <Grid
              container
              spacing={0}
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid item xs={8} md={6}>
                <Radiobutton
                  name="scope"
                  itemvalue="current"
                  value={formik.values.scope}
                  onChange={(e) => {
                    formik.setFieldValue("scope", e.target.value);

                    formik.setFieldValue("month", moment().format("MMMM"));
                  }}
                  label="Current Month"
                />
              </Grid>
              <Grid item xs={8} md={6}>
                <Radiobutton
                  name="scope"
                  value={formik.values.scope}
                  onChange={(e) => {
                    formik.setFieldValue("scope", e.target.value);
                    formik.setFieldValue(
                      "month",
                      moment().add(1, "month").format("MMMM")
                    );
                  }}
                  itemvalue="next"
                  label="Next Month"
                />
              </Grid>
            </Grid>
          </Grid>
          <Dropzone
            margin="normal"
            showSelectedFile={true}
            // isFileCleared={isFileCleared}
            onFilesAdded={(e, fileUpload) =>
              // handleFileEvent(fileUpload)
              fileUpload
            }
            scope={true}
            onBulk={true}
          />
        </Grid>
        <Grid item xs={12}>
          {" "}
          <Grid
            container
            spacing={0}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            {" "}
            <Grid item xs="auto" sx={{ mt: -1 }}>
              {" "}
              <div style={{ padding: "9px 5px 0 0" }}>
                <DownloadIcon color="primary" size="small" />{" "}
              </div>{" "}
            </Grid>{" "}
            <Grid item xs="auto">
              {" "}
              <Typography
                variant="caption"
                align="center"
                style={{
                  color: "#000000",
                  fontsize: "11px",
                  fontWeight: "bold",
                  marginBottom: 0,
                  marginTop: "10px",
                }}
              >
                <div
                  onClick={() => handleDownload()}
                  style={{ cursor: "pointer" }}
                >
                  Download-Manage-Model-Scope-Template.xlsx{" "}
                </div>
              </Typography>{" "}
            </Grid>{" "}
          </Grid>{" "}
        </Grid>

        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="UPLOAD"
                disabled={props.save}
                startIcon={<SaveOutlinedIcon />}
                onClick={() => formik.handleSubmit()}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                size="small"
                variant="outlined"
                onClick={() => reset()}
                message="RESET"
                startIcon={<RestartAltOutlinedIcon />}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="CANCEL"
                startIcon={<EventBusyOutlinedIcon />}
                onClick={() => {
                  JSON.stringify(formik.initialValues) ===
                    JSON.stringify(formik.values) && data.files.length === 0
                    ? closeError()
                    : setShow(true);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={{ color: "#2C0957" }}> {props.msg}</div>
        </Grid>
        <Grid item xs={12}>
          {Object.keys(error).length > 0 && (
            <>
              <Typography
                variant="body"
                className={classes.errorMessagesTitle}
                gutterBottom
              >
                Error Message(s)
              </Typography>
              <ul className={classes.errorMessages}>
                {Object.keys(error).map((key, index) => (
                  <li className={classes.messages}>
                    {key} {error[key]}
                  </li>
                ))}
              </ul>
            </>
          )}
        </Grid>
        <AlertDialog
          open={show}
          onClose={() => setShow(false)}
          // open={isDeleted}
          title={"Confirmation"}
          // maxWidth={"sm"}
          description={`Do you want to discard the changes ?`}
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    reset();
                    closeError();
                    setShow(false);
                  }}
                  message="Yes"
                  startIcon={<DoneIcon />}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setShow(false);
                  }}
                  message="No"
                  startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
        {/* Action Area End Here */}
      </Grid>
    </Box>
  );
}

export default bulkUpload;
