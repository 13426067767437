import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import "./_spinner.scss"
function Spinner(props) {
  return (
    // <div>
    //   {loading && (
    //     <div
    //       className={"loader-container " + (inline ? "position-absolute" : "")}
    //     >
    //       <Spinner
    //         animation="border"
    //         className="spinner-height loader"
    //         role="status"
    //       />
    //     </div>
    //   )}
    // </div>
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.open}
      //   onClick={props.onClick}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default Spinner;
