import {
  ADD_HOLIDAY,
  DELETE_HOLIDAY,
  GET_HOLIDAY,
  GETALL_HOLIDAY,
  HOLIDAY_LIST,
  UPDATE_HOLIDAY,
} from "apiConfig/holidays";
import {
  ADD_SLA,
  DELETE_SLA,
  GET_SLA,
  GETALL_SLA,
  UPDATE_SLA,
} from "apiConfig/sla";
import {
  deleteData,
  getData,
  postData,
  putData,
} from "services/basicApiService";

export const getAllSla = (pagenumber, pagesize, search, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(
      GETALL_SLA +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,

      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const getSla = (id,header) => {
  //checkTokenExpiry()
  return async () => {
    const response = await getData(GET_SLA+`?slaId=${id}`, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const addSla = (data,header) => {
  //checkTokenExpiry()
  return async () => {
    const response = await postData(ADD_SLA,data, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const deleteSla = (id,header) => {
  //checkTokenExpiry()
  return async () => {
    const response = await deleteData(DELETE_SLA+`?slaId=${id}`, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const updateSla = (data,header) => {
  //checkTokenExpiry()
  return async () => {
    const response = await putData(UPDATE_SLA,data, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const getAllHoliday = (pagenumber, pagesize, search, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(
      GETALL_HOLIDAY +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,

      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const getHoliday = (data,header) => {
  //checkTokenExpiry()
  return async () => {
    const response = await getData(GET_HOLIDAY+`?Id=${data}`, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const getHolidayList = (data,header) => {
  //checkTokenExpiry()
  return async () => {
    const response = await getData(HOLIDAY_LIST+`?year=${data}`, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
}
export const addHoliday = (data,header) => {
  //checkTokenExpiry()
  return async () => {
    const response = await postData(ADD_HOLIDAY, data,{
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const deleteHoliday = (id,header) => {
  //checkTokenExpiry()
  return async () => {
    const response = await deleteData(DELETE_HOLIDAY+`?id=${id}`, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const updateHoliday = (data,header) => {
  //checkTokenExpiry()
  return async () => {
    const response = await putData(UPDATE_HOLIDAY,data,{
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
