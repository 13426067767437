import { AppActionsTypes } from "./actions-types";

export const getTicket = (data) => {
  return {
    type: AppActionsTypes.TICKET,

    payload: data,
  };
};

export const getCountry = (data) => {
  return {
    type: AppActionsTypes.COUNTRY,

    payload: data,
  };
};
export const getProvider = (data) => {
  return {
    type: AppActionsTypes.PROVIDER,

    payload: data,
  };
};
export const getProject = (data) => {
  return {
    type: AppActionsTypes.PROJECT,

    payload: data,
  };
};
export const getopenstatus = (data) => {
  return {
    type: AppActionsTypes.OPENSTATUS,

    payload: data,
  };
};
export const getMake = (data) => {
  return {
    type: AppActionsTypes.MAKE,

    payload: data,
  };
};
export const getModel = (data) => {
  return {
    type: AppActionsTypes.MODEL,

    payload: data,
  };
};
export const getcomplexity = (data) => {
  return {
    type: AppActionsTypes.COMPLEXITY,

    payload: data,
  };
};

export const getCategory = (data) => {
  return {
    type: AppActionsTypes.CATEGORY,

    payload: data,
  };
};
export const getType = (data) => {
  return {
    type: AppActionsTypes.TYPE,

    payload: data,
  };
};
export const getPriority = (data) => {
  return {
    type: AppActionsTypes.PRIORITY,

    payload: data,
  };
};
export const getField = (data) => {
  return {
    type: AppActionsTypes.FIELD,

    payload: data,
  };
};
export const getRerun = (data) => {
  return {
    type: AppActionsTypes.RERUN,

    payload: data,
  };
};
export const getWorktype = (data) => {
  return {
    type: AppActionsTypes.WORKTYPE,

    payload: data,
  };
};
export const getStatus = (data) => {
  return {
    type: AppActionsTypes.STATUS,

    payload: data,
  };
};
export const getDevcomplexity = (data) => {
  return {
    type: AppActionsTypes.DEVELOPER_COMPLEXITY,

    payload: data,
  };
};

export const fetchManageissue = (data) => {
  return {
    type: AppActionsTypes.FETCH_MANAGE_ISSUE,
    payload: data,
  };
};
export const fetchManageissueid = (data) => {
  return {
    type: AppActionsTypes.FETCH_MANAGE_ISSUE_ID,
    payload: data,
  };
};
export const fetchAttachments = (data) => {
  return {
    type: AppActionsTypes.ATTACHMENTS,
    payload: data,
  };
};
export const fetchDevAttachments = (data) => {
  return {
    type: AppActionsTypes.DEVATTACHMENTS,
    payload: data,
  };
};
export const fetchusers = (data) => {
  return {
    type: AppActionsTypes.USERS,
    payload: data,
  };
};
export const fetchProject = (data) => {
  return {
    type: AppActionsTypes.PROJECT_DETAILS,
    payload: data,
  };
};

export const fetchResolutionstatus = (data) => {
  return {
    type: AppActionsTypes.RESOLUTION_STATUS,
    payload: data,
  };
};
export const fetchTicketlist = (data) => {
  return {
    type: AppActionsTypes.TICKET_LIST,
    payload: data,
  };
};
export const saveFilters = (data) => {
  return {
    type: AppActionsTypes.FILTER,
    payload: data,
  };
};
export const saveFiles = (data) => {
  return {
    type: AppActionsTypes.FILES,
    payload: data,
  };
};
export const saveDevFiles = (data) => {
  return {
    type: AppActionsTypes.DEVFILES,
    payload: data,
  };
};
export const scraperStatus = (data) => {
  return {
    type: AppActionsTypes.SCRAPER_STATUS,
    payload: data,
  };
};
export const sourceUrl = (data) => {
  return {
    type: AppActionsTypes.SOURCEURL,
    payload: data,
  };
};
export const ScraperReason = (data) => {
  return {
    type: AppActionsTypes.SCRAPER_REASON,
    payload: data,
  };
};
export const nextRunStatus = (data) => {
  return {
    type: AppActionsTypes.NEXT_RUN_STATUS,
    payload: data,
  };
};
export const scraperFilter = (data) => {
  return {
    type: AppActionsTypes.FILTER_SCRAPER,
    payload: data,
  };
};
export const getScraperCountry = (data) => {
  return {
    type: AppActionsTypes.SCRAPER_COUNTRY,

    payload: data,
  };
};
export const getScraperMake = (data) => {
  return {
    type: AppActionsTypes.SCRAPER_MAKE,

    payload: data,
  };
};
export const fetchmanageScraper = (data) => {
  return {
    type: AppActionsTypes.FETCH_MANAGE_SCRAPER,
    payload: data,
  };
};
export const fetchmanageScraperCount = (data) => {
  return {
    type: AppActionsTypes.FETCH_MANAGE_SCRAPER_COUNT,
    payload: data,
  };
};
export const fetchmanageSource = (data) => {
  return {
    type: AppActionsTypes.FETCH_MANAGE_SOURCE,
    payload: data,
  };
};
export const fetchmanageSourceid = (data) => {
  return {
    type: AppActionsTypes.FETCH_MANAGE_SOURCE_ID,
    payload: data,
  };
};
export const getNewSourceStatus = (data) => {
  return {
    type: AppActionsTypes.NEW_SOURCE_STATUS,

    payload: data,
  };
};
export const getCountrySource = (data) => {
  return {
    type: AppActionsTypes.SOURCE_COUNTRY,

    payload: data,
  };
};
export const getMakeSource = (data) => {
  return {
    type: AppActionsTypes.SOURCE_MAKE,

    payload: data,
  };
};
export const getModelSource = (data) => {
  return {
    type: AppActionsTypes.SOURCE_MODEL,

    payload: data,
  };
};
export const getUrlStatusSource = (data) => {
  return {
    type: AppActionsTypes.SOURCE_URL_STATUS,

    payload: data,
  };
};
export const getPrioritySource = (data) => {
  return {
    type: AppActionsTypes.SOURCE_PRIORITY,

    payload: data,
  };
};
export const getSourceurlSource = (data) => {
  return {
    type: AppActionsTypes.SOURCEURL_SOURCE,

    payload: data,
  };
};
export const saveFiltersSource = (data) => {
  return {
    type: AppActionsTypes.FILTER_SOURCE,

    payload: data,
  };
};

export const reportedBy = (data) => {
  return {
    type: AppActionsTypes.REPORTEDBY,

    payload: data,
  };
};
export const errorMessage = (data) => {
  return {
    type: AppActionsTypes.ERROR,

    payload: data,
  };
};
export const filterScope = (data) => {
  return {
    type: AppActionsTypes.FILTER_SCOPE,

    payload: data,
  };
};
export const fetchmanageScope = (data) => {
  return {
    type: AppActionsTypes.FETCH_MANAGE_SCOPE,
    payload: data,
  };
};
export const fetchmanageScopeid = (data) => {
  return {
    type: AppActionsTypes.FETCH_MANAGE_SCOPE_ID,
    payload: data,
  };
};
export const addPrefiled = (data) => {
  return {
    type: AppActionsTypes.PREFILED,
    payload: data,
  };
};
export const reasonAction = (data) => {
  return {
    type: AppActionsTypes.REASON,
    payload: data,
  };
};
export const fetchUsers = (data) => {
  return {
    type: AppActionsTypes.FETCH_USER_MANAGEMENT,
    payload: data,
  };
};
export const fetchUsersid = (data) => {
  return {
    type: AppActionsTypes.FETCH_USER_MANAGEMENT_ID,
    payload: data,
  };
};
export const fetchRoles = (data) => {
  return {
    type: AppActionsTypes.FETCH_ROLE_MANAGEMENT,
    payload: data,
  };
};
export const fetchRolesid = (data) => {
  return {
    type: AppActionsTypes.FETCH_ROLE_MANAGEMENT_ID,
    payload: data,
  };
};
export const fetchCountrywise = (data) => {
  return {
    type: AppActionsTypes.FETCH_COUNTRY_WISE_STATUS,
    payload: data,
  };
};
export const fetchDetailCountrywise = (data) => {
  return {
    type: AppActionsTypes.FETCH_DETAILED_COUNTRY_WISE_STATUS,
    payload: data,
  };
};

export const filterDashboard = (data) => {
  return {
    type: AppActionsTypes.FILTER_DASHBOARD,
    payload: data,
  };
};
export const fetchPricepointchanges = (data) => {
  return {
    type: AppActionsTypes.FETCH_PRICE_POINT_CHANGES,
    payload: data,
  };
};
export const fetchsourceurlwithnorecords = (data) => {
  return {
    type: AppActionsTypes.FETCH_SOURCE_URL_WITH_NO_RECORDS,
    payload: data,
  };
};
export const countrywiseFilter = (data) => {
  return {
    type: AppActionsTypes.COUNTRY_FILTER_DASHBOARD,
    payload: data,
  };
};
export const detailwiseFilter = (data) => {
  return {
    type: AppActionsTypes.DETAIL_FILTER_DASHBOARD,
    payload: data,
  };
};
export const priceFilter = (data) => {
  return {
    type: AppActionsTypes.PRICEPOINT_FILTER_DASHBOARD,
    payload: data,
  };
};
export const noFilter = (data) => {
  return {
    type: AppActionsTypes.NO_FILTER_DASHBOARD,
    payload: data,
  };
};
export const fetchFailureSuccess = (data) => {
  return {
    type: AppActionsTypes.FETCH_FAILURE_SUCCESS,
    payload: data,
  };
};
export const isLoading = (data) => {
  return {
    type: AppActionsTypes.IS_LOADING,
    payload: data,
  };
};
export const isSwitchOn = (data) => {
  return {
    type: AppActionsTypes.IS_SWITCH_ON,
    payload: data,
  };
};
export const menuName = (data) => {
  return {
    type: AppActionsTypes.MENU,
    payload: data,
  };
};
export const fetchRole = (data) => {
  return {
    type: AppActionsTypes.ROLE_DATA,
    payload: data,
  };
};

export const fetchRoleId = (data) => {
  return {
    type: AppActionsTypes.ROLE_ID,
    payload: data,
  };
};
export const fetchRoleName = (data) => {
  return {
    type: AppActionsTypes.ROLE_NAME,
    payload: data,
  };
};
export const fetchFirstName = (data) => {
  return {
    type: AppActionsTypes.FIRST_NAME,
    payload: data,
  };
};
export const fetchLastName = (data) => {
  return {
    type: AppActionsTypes.LAST_NAME,
    payload: data,
  };
};
export const fetchAll = (data) => {
  return {
    type: AppActionsTypes.ALL,
    payload: data,
  };
};

export const fetchIssue = (data) => {
  return {
    type: AppActionsTypes.ISSUE,
    payload: data,
  };
};
export const fetchScope = (data) => {
  return {
    type: AppActionsTypes.SCOPE,
    payload: data,
  };
};
export const fetchsource = (data) => {
  return {
    type: AppActionsTypes.SOURCE,
    payload: data,
  };
};
export const fetchscraper = (data) => {
  return {
    type: AppActionsTypes.SCRAPER,
    payload: data,
  };
};
export const fetchComments = (data) => {
  return {
    type: AppActionsTypes.COMMENTS,
    payload: data,
  };
};
export const fetchdash = (data) => {
  return {
    type: AppActionsTypes.DASHBOARD,
    payload: data,
  };
};
export const fetchuser = (data) => {
  return {
    type: AppActionsTypes.USER,
    payload: data,
  };
};
export const fetchrole = (data) => {
  return {
    type: AppActionsTypes.ROLE,
    payload: data,
  };
};
export const fetchquality = (data) => {
  return {
    type: AppActionsTypes.QUALITY,
    payload: data,
  };
};
export const getmanagequality = (data) => {
  return {
    type: AppActionsTypes.MANAGE_QUALITY,
    payload: data,
  };
};
export const saveqcFilter = (data) => {
  return {
    type: AppActionsTypes.MANAGE_QUALITY_FILTER,
    payload: data,
  };
};
export const issueProjectName = (data) => {
  return {
    type: AppActionsTypes.PROJECT_NAME,
    payload: data,
  };
};
export const qualityProjectName = (data) => {
  return {
    type: AppActionsTypes.PROJECT_NAME_QUALITY,
    payload: data,
  };
};

export const deliveryFilter = (data) => {
  return {
    type: AppActionsTypes.FILTER_DELIVERY,
    payload: data,
  };
};
export const deliveryDetailData = (data) => {
  return {
    type: AppActionsTypes.FILTER_DELIVERY_DETAIL,
    payload: data,
  };
};
export const deliveryData = (data) => {
  return {
    type: AppActionsTypes.FILTER_DELIVERY_DATA,
    payload: data,
  };
};
export const dashboardCountry = (data) => {
  return {
    type: AppActionsTypes.DASHBOARD_COUNTRY,
    payload: data,
  };
};
export const dashboardMake = (data) => {
  return {
    type: AppActionsTypes.DASHBOARD_MAKE,
    payload: data,
  };
};
export const dashboardModel = (data) => {
  return {
    type: AppActionsTypes.DASHBOARD_MODEL,
    payload: data,
  };
};
export const fetchRolesDropdown = (data) => {
  return {
    type: AppActionsTypes.ROLES,
    payload: data,
  };
};
export const getScraperName = (data) => {
  return {
    type: AppActionsTypes.PROJECT_NAME_SCRAPER,

    payload: data,
  };
};
export const getWebsite = (data) => {
  return {
    type: AppActionsTypes.WEBSITE,

    payload: data,
  };
};
export const getBAU = (data) => {
  return {
    type: AppActionsTypes.BAU_STATUS,

    payload: data,
  };
};
export const getReports = (data) => {
  return {
    type: AppActionsTypes.REPORTS,

    payload: data,
  };
};
export const getReportsData = (data) => {
  return {
    type: AppActionsTypes.GRID_DATA_BAU,

    payload: data,
  };
};
export const filterBAU = (data) => {
  return {
    type: AppActionsTypes.BAU_FILTER,

    payload: data,
  };
};
export const filledData = (data) => {
  return {
    type: AppActionsTypes.DATA_BAU,

    payload: data,
  };
};
export const requestType = (data) => {
  return {
    type: AppActionsTypes.REQUEST_TYPE_DROPDOWN,

    payload: data,
  };
};
export const setMeritUser = (data) => {
  return {
    type: AppActionsTypes.MUSER,

    payload: data,
  };
};
export const setprojectSla = (data) => {
  return {
    type: AppActionsTypes.PROJECT_NAME_SLA,

    payload: data,
  };
};