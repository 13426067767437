import React, { useEffect, useRef, useState } from "react";
import { Box, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import useStyles from "../dashboardAndReports.styles";
import MaterialUIButton from "components/commonComponents/button/button";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BaseLayout from "components/commonComponents/baseLayout";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import StackedBarChart from "./stackedBarChart";
import AgGrid from "components/commonComponents/grid/agGrid";
import UsePagination from "components/commonComponents/pagination/pagination";
import Gridnav from "components/commonComponents/gridnav/gridnav";
import { Detailedheaders, headers } from "./Data";
import { useDispatch, useSelector } from "react-redux";
import { utils, writeFile } from "xlsx";
import moment from "moment";
import {
  ExportCountryWise,
  getCountryWise,
  getDetailCountryWise,
  ExportDetailCountryWise,
  chartUrlCountry,
} from "redux/dashboard&reports/action";
import {
  countrywiseFilter,
  detailwiseFilter,
  fetchCountrywise,
  fetchDetailCountrywise,
  getCountry,
} from "redux/app/actions";
import AdvanceFilters from "../advanceFilters";
import MaterialUIModal from "components/commonComponents/modal/modal";
import AdvanceFiltersCountryWise from "./advanceFiltersCountryWise";
import AdvanceFiltersDetailView from "./advanceFilterDetailView";
import { countryDropdown } from "redux/manageissues/action";
import Spinner from "components/commonComponents/spinner/spinner";
import { useNavigate } from "react-router";
function CountryWiseStatus(props) {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [advFilterOpen, setAdvFilterOpen] = useState(false);
  const dispatch = useDispatch();
  const gridApi = useRef(null);
  const select = useSelector((state) => state.dashboardandreports);
  const id = useSelector((state) => state.project.dashboard);

  const [saveDisabled, setsaveDisabled] = useState(false);
  const [save, setsave] = useState(false);
  const [search, setSearch] = useState("");
  const [msg, setMsg] = useState("");
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [total, settotal] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  const [totalRecords, settotalRecords] = useState(0);
  const [limitdetail, setLimitDetail] = useState(25);
  const [pagedetail, setPageDetail] = useState(1);
  const [pageCountdetail, setpageCountDetail] = useState(0);
  const [totaldetail, settotalDetail] = useState(0);
  const [totalCountdetail, settotalCountDetail] = useState(0);
  const [totalRecordsdetail, settotalRecordsDetail] = useState(0);

  const [selectExport, setselectExport] = useState([]);
  const [selectExportDetail, setselectExportDetail] = useState([]);
  const filterData = useSelector(
    (state) => state.dashboardandreports.detailFilter
  );
  const filterDataCountry = useSelector(
    (state) => state.dashboardandreports.countryFilter
  );
  const [rows, setRows] = useState();
  const [advFilter, setAdvFilter] = useState(false);
  const [load, setLoad] = useState(true);
  const [data, setData] = useState(false);
  /******************************************************************************
Function: filterBody
Argument: 
Usage:
1. Send filter body to get all the issues with the filters.
*******************************************************************************/

  const filterBody = {
    timeLineStart: filterDataCountry.timeLineStart
      ? filterDataCountry.timeLineStart
      : moment().format("YYYY-MM-DD"),
    timeLineEnd: filterDataCountry.timeLineEnd
      ? filterDataCountry.timeLineEnd
      : moment().format("YYYY-MM-DD"),
    country: filterData.country.map((e) => e.countryId),
    make: filterData.make.map((e) => e.make1),
    model: filterData.model.map((e) => e.model1),
    exportId: filterData.exportId,
  };
  const filterBodyCountry = {
    timeLineStart: filterDataCountry.timeLineStart
      ? filterDataCountry.timeLineStart
      : moment().format("YYYY-MM-DD"),
    timeLineEnd: filterDataCountry.timeLineEnd
      ? filterDataCountry.timeLineEnd
      : moment().format("YYYY-MM-DD"),
    country: filterDataCountry.country.map((e) => e.countryId),
    make: filterDataCountry.make.map((e) => e.make1),
    model: filterDataCountry.model.map((e) => e.model1),
    exportId: filterDataCountry.exportId,
  };
  const [gridKey, setGridKey] = useState(0);
  const [gridKey1, setGridKey1] = useState(0);

  const resetAll = async () => {
    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
  };
  const navigate = useNavigate();
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  useEffect(() => {
    dispatch(
      detailwiseFilter({
        timeline: "Today",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: moment().format("YYYY-MM-DD"),
        timeLineEnd: moment().format("YYYY-MM-DD"),
        country: [],
        make: [],
        model: [],
        exportId: [],
      })
    );
    dispatch(
      countrywiseFilter({
        timeline: "Today",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: moment().format("YYYY-MM-DD"),
        timeLineEnd: moment().format("YYYY-MM-DD"),
        country: [],
        make: [],
        model: [],
        exportId: [],
      })
    );
    dispatch(
      chartUrlCountry({
        timeLineStart: moment().format("YYYY-MM-DD"),
        timeLineEnd: moment().format("YYYY-MM-DD"),
        country: [],
        make: [],
        model: [],
        exportId: [],
        id,
      })
    ).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result !== "No records found") {
          setData(res.data.result);
        }
      }
    });
    getCountryWiseStatus(page, limit, "", {
      timeLineStart: moment().format("YYYY-MM-DD"),
      timeLineEnd: moment().format("YYYY-MM-DD"),
      country: [],
      make: [],
      model: [],
      exportId: [],
      id,
    });
    getDataFormAPIForDropdown();
    getDetailedCountryWiseStatus(page, limit, "", {
      timeLineStart: moment().format("YYYY-MM-DD"),
      timeLineEnd: moment().format("YYYY-MM-DD"),
      country: [],
      make: [],
      model: [],
      exportId: [],
      id,
    });
  }, [id]);
  /******************************************************************************
Function: getDataFormAPIForDropdown
Argument: 
Usage:
1. Calling API for Dropdown.
*******************************************************************************/

  const getDataFormAPIForDropdown = () => {
    dispatch(countryDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getCountry([]));
        } else {
          dispatch(getCountry(res.data.result));
        }
      } else {
        dispatch(getCountry([]));
      }
    });
    // dispatch(chartUrlCountry(filterBodyCountry)).then((res) => {
    //   if (res.data.statusCode === 200) {
    //     if (res.data.result !== "No records found") {
    //       setData(res.data.result);
    //     }
    //   }
    // });
  };
  /**************************************
Function: getCountryWiseStatus
Argument:page,limit,search,body
Usage:
1. To generate getCountryWiseStatus data
*******************************************/
  const getCountryWiseStatus = (
    page,
    limit,
    search,
    body = {
      timeLineStart: filterDataCountry.timeLineStart
        ? filterDataCountry.timeLineStart
        : moment().format("YYYY-MM-DD"),
      timeLineEnd: filterDataCountry.timeLineEnd
        ? filterDataCountry.timeLineEnd
        : moment().format("YYYY-MM-DD"),
      country: [],
      make: [],
      model: [],
      exportId: [],
      noOfDays: "",
    }
  ) => {
    dispatch(getCountryWise(page, limit, search, body, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            settotalRecords(0);
            //setState([]);
            dispatch(fetchCountrywise([]));
          } else {
            dispatch(fetchCountrywise(res.data.result));
          }
          setLoad(false);
          //setState(res.data.result);

          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        }
        else{
          settotalCount(0);
          settotalRecords(0);
          //setState([]);
          dispatch(fetchCountrywise([]));
        }
      }
    });
  };
  /**************************************
Function: handleSelect
Argument: row
Usage:
1. To select the data to export
*******************************************/
  const handleSelect = async (row) => {
    setselectExport(row.map((e) => e.id));
    setRows(
      row.length === 10 ||
        row.length === 15 ||
        row.length === 25 ||
        row.length === 50 ||
        row.length === 100
    );
    if (row.length === 0) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  /**************************************
Function: handleExport
Argument:
Usage:
1. To export data
*******************************************/
  const exportdata = selectExport.toString();
  const exportIds = exportdata.split(",");

  const handleExport = (body) => {
    setLoad(true);
    dispatch(
      ExportCountryWise(
        page,
        limit,
        search,
        (body = {
          timeLineStart: filterDataCountry.timeLineStart
            ? filterDataCountry.timeLineStart
            : moment().format("YYYY-MM-DD"),
          timeLineEnd: filterDataCountry.timeLineEnd
            ? filterDataCountry.timeLineEnd
            : moment().format("YYYY-MM-DD"),
          country: filterDataCountry.country.map((e) => e.countryId),
          make: filterDataCountry.make.map((e) => e.make1),
          model: filterDataCountry.model.map((e) => e.model1),
          exportId: rows ? ["All"] : exportIds,
          noOfDays: "",
        }),
        id
      )
    ).then((res) => {
      setLoad(false);
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);

      utils.sheet_add_aoa(ws, [
        [
          "Date",
          "Country",
          "Make",
          "Total Scrapers",
          "Total Scrapers Successful",
          "Total Scrapers Failed",
          "Proxy Issue Failure",
          "Site Down Failure",
        ],
      ]);
      const rows = res.data.result.map((row) => [
        row.date.split("-").length > 1
          ? moment(row.date.split("-")[0]).format("DD/MM/YYYY") +
            "-" +
            moment(row.date.split("-")[1]).format("DD/MM/YYYY")
          : moment(row.date).format("DD/MM/YYYY"),
        row.country,
        row.make,
        row.total_Scrapers,
        row.total_Scrapers_Successful,
        row.total_Scrapers_Failed,
        row.proxy_Issue,
        row.site_Down,
      ]);
      utils.sheet_add_json(ws, rows, {
        origin: "A2",
        skipHeader: true,
      });

      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, `country_wise_status_${moment().format("DMMMyy")}.csv`);
    });
    getCountryWiseStatus(page, limit, search, filterBodyCountry);
  };
  /******************************************************************************
Function: handlePageClick
Argument: data
Usage:
1. Pagination change action.
*******************************************************************************/

  const handlePageClick = async (event, data) => {
    setLoad(true);
    setPage(data);
    await getCountryWiseStatus(data, limit, search, filterBodyCountry);
    gridApi.current.redrawRows(select.countrywisedata);
  };
  /**************************************
Function: getDetailedCountryWiseStatus
Argument:page,limit,search,body
Usage:
1. To generate getDetailedCountryWiseStatus data
*******************************************/
  const getDetailedCountryWiseStatus = (
    page,
    limit,
    search,
    body = {
      timeLineStart: filterDataCountry.timeLineStart
        ? filterDataCountry.timeLineStart
        : moment().format("YYYY-MM-DD"),
      timeLineEnd: filterDataCountry.timeLineEnd
        ? filterDataCountry.timeLineEnd
        : moment().format("YYYY-MM-DD"),
      country: [],
      make: [],
      model: [],
      exportId: [],
      noOfDays: "",
    }
  ) => {
    dispatch(getDetailCountryWise(page, limit, search, body, id)).then(
      (res) => {
        if (res) {
          setLoad(false);
          if (res.data.statusCode === 200) {
            if (res.data.result === "No records found") {
              settotalCountDetail(0);
              settotalRecordsDetail(0);
              dispatch(fetchDetailCountrywise([]));
            } else {
              dispatch(fetchDetailCountrywise(res.data.result));
            }
            setLoad(false);

            settotalDetail(res.data.result.length);
            setpageCountDetail(Math.ceil(total / limit));
            settotalCountDetail(res.data.total);
            settotalRecordsDetail(res.data.totalRecords);
          }
          else{
            settotalCountDetail(0);
              settotalRecordsDetail(0);
              dispatch(fetchDetailCountrywise([]));
          }
        }
      }
    );
  };
  /**************************************
Function: handleSelect
Argument: row
Usage:
1. To select the data to export
*******************************************/
  const handleSelectDetail = async (row) => {
    setselectExportDetail(row.map((e) => e.id));

    setRows(
      row.length === 10 ||
        row.length === 15 ||
        row.length === 25 ||
        row.length === 50 ||
        row.length === 100
    );
    if (row.length === 0) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  const exportdetaildata = selectExportDetail.toString();
  const exportdetailIds = exportdetaildata.split(",");

  /**************************************
Function: handleExportDetail
Argument:
Usage:
1. To export data
*******************************************/
  const handleExportDetail = (body) => {
    setLoad(true);
    dispatch(
      ExportDetailCountryWise(
        page,
        limit,
        search,
        (body = {
          timeLineStart: filterData.timeLineStart
            ? filterData.timeLineStart
            : moment().format("YYYY-MM-DD"),
          timeLineEnd: filterData.timeLineEnd
            ? filterData.timeLineEnd
            : moment().format("YYYY-MM-DD"),
          country: filterData.country.map((e) => e.countryId),
          make: filterData.make.map((e) => e.make1),
          model: filterData.model.map((e) => e.model1),
          exportId: rows ? ["All"] : exportdetailIds,
          noOfDays: "",
        }),
        id
      )
    ).then((res) => {
      setLoad(false);
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);

      utils.sheet_add_aoa(ws, [
        [
          "Date",
          "Country",
          "Total Makes",
          "Total Models",
          "Total Makes Successful",
          "Total Models Successful",
          "Total Makes Failed",
          "Total Models Failed",
          "Successful Make Percentage(%)",
          "Successful Model Percentage(%)",
          "Failed Make Percentage(%)",
          "Failed Model Percentage(%)",
        ],
      ]);
      const rows = res.data.result.map((row) => [
        row.date.split("-").length > 1
          ? moment(row.date.split("-")[0]).format("DD/MM/YYYY") +
            "-" +
            moment(row.date.split("-")[1]).format("DD/MM/YYYY")
          : moment(row.date).format("DD/MM/YYYY"),
        row.country,
        row.total_Makes,
        row.total_Models,
        row.total_Makes_SuccessFul,
        row.total_Models_SuccessFul,
        row.total_Makes_Failed,
        row.total_Models_Failed,
        row.successful_Make_Percentage,
        row.successful_Model_Percentage,
        row.failed_Make_Percentage,
        row.failed_Model_Percentage,
      ]);
      utils.sheet_add_json(ws, rows, {
        origin: "A2",
        skipHeader: true,
      });

      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, `country_wise_status_${moment().format("DMMMyy")}.csv`);
    });
    getDetailedCountryWiseStatus(pagedetail, limit, search, filterBody);
  };
  /******************************************************************************
Function: handlePageClickDetail
Argument: data
Usage:
1. Pagination change action.
*******************************************************************************/

  const handlePageClickDetail = async (event, data) => {
    setLoad(true);
    setPageDetail(data);
    await getDetailedCountryWiseStatus(data, limit, search, filterBody);
    gridApi.current.redrawRows(select.detailcountrywisedata);
  };
  /**************************************
Function: filterCountryView
Argument:data, reset = false
Usage:
1. To filter the data
*******************************************/
  const filterCountryView = async (data, reset = false) => {
    setLoad(true);
    setPage(1);
    dispatch(chartUrlCountry(data, id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result !== "No records found") {
          setData(res.data.result);
        }
      }
    });

    dispatch(getCountryWise(1, limit, search, data, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            settotalRecords(0);
            //setState([]);
            dispatch(fetchCountrywise([]));
          } else {
            dispatch(fetchCountrywise(res.data.result));
          }
          setLoad(false);
          //setState(res.data.result);
          if (!reset) {
            setAdvFilter(false);
          }
          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        }
      }
    });
    gridApi.current.redrawRows(select.manageIssuedata);
    //
  };
  /**************************************
Function: filterDetailsView
Argument:data, reset = false
Usage:
1. To filter the data
*******************************************/
  const filterDetailsView = async (data, reset = false) => {
    setLoad(true);
    setPageDetail(1);
    dispatch(getDetailCountryWise(1, limit, search, data, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCountDetail(0);
            settotalRecordsDetail(0);
            dispatch(fetchDetailCountrywise([]));
          } else {
            dispatch(fetchDetailCountrywise(res.data.result));
          }
          setLoad(false);
          if (!reset) {
            setAdvFilterOpen(false);
          }
          settotalDetail(res.data.result.length);
          setpageCountDetail(Math.ceil(total / limit));
          settotalCountDetail(res.data.total);
          settotalRecordsDetail(res.data.totalRecords);
        }
      }
    });
    gridApi.current.redrawRows(select.manageIssuedata);
    //
  };
  return (
    <div>
      <Spinner open={load} />
      {isSwitchOn ? (
        <Box>
          {/* BaseLayout Start Here */}
          <BaseLayout>
            {/* Action Area - Search by Keyword, Total Record Count, Add Issue, Adv. Filter and Export */}
            <Grid
              container
              spacing={2}
              className={classes.pageActionArea}
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid item xs={12} md={5}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="left"
                  spacing={2}
                >
                  <Grid item xs={"auto"}>
                    <Gridnav
                      onChange={async (e) => {
                        if (e.target.value === "") {
                          setPageDetail(1);
                          setSearch("");
                          await getDetailedCountryWiseStatus(
                            1,
                            limit,
                            "",
                            filterBody
                          );
                          gridApi.current.redrawRows(
                            select.detailcountrywisedata
                          );
                        } else {
                          setPageDetail(1);
                          setSearch(e.target.value);
                          await getDetailedCountryWiseStatus(
                            1,
                            limit,
                            e.target.value,
                            filterBody
                          );
                          gridApi.current.redrawRows(
                            select.detailcountrywisedata
                          );
                        }
                      }}
                      data={search}
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      className={classes.totalResults}
                    >
                      Total Results:{" "}
                      <span className={classes.totalValue}>
                        {totalRecordsdetail ?? 0}
                      </span>
                      &nbsp;
                      <span className={classes.recordText}>Records</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={7}>
                <Grid
                  container
                  spacing={1.75}
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      message="Clear Filters"
                      startIcon={<RestartAltOutlinedIcon />}
                      onClick={() => {
                        setGridKey1(gridKey1 + 1);

                        dispatch(
                          detailwiseFilter({
                            timeline: "Today",
                            quater: "",
                            startdate: null,
                            enddate: null,
                            timeLineStart: moment().format("YYYY-MM-DD"),
                            timeLineEnd: moment().format("YYYY-MM-DD"),
                            country: [],
                            make: [],
                            model: [],
                            exportId: [],
                          })
                        );
                        if (search !== "") {
                          setPageDetail(1);
                          setSearch("");
                        }
                        getDetailedCountryWiseStatus(1, limit, "", {
                          timeLineStart: moment().format("YYYY-MM-DD"),
                          timeLineEnd: moment().format("YYYY-MM-DD"),
                          country: [],
                          make: [],
                          model: [],
                          exportId: [],
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      onClick={() => setIsSwitchOn(!isSwitchOn)}
                      message="Back"
                      startIcon={<ArrowBackIcon />}
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <FormControlLabel
                      sx={{ mb: -0.5, mr: 0 }}
                      control={
                        <Switch
                          checked={isSwitchOn}
                          onChange={() => {
                            setIsSwitchOn(!isSwitchOn);
                          }}
                        />
                      }
                      label={
                        <Typography variant="body1" fontWeight="bold">
                          Detail View
                        </Typography>
                      }
                    />
                  </Grid>
                  {/*  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      message="Clear Filters"
                      startIcon={<RestartAltOutlinedIcon />}
                      onClick={() => resetAll()}
                    />
                  </Grid> */}
                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      onClick={() => setAdvFilterOpen(true)}
                      message="Advance Filter"
                      startIcon={<FilterListOutlinedIcon />}
                    />
                  </Grid>
                  {show === true ? (
                    <Grid item xs={"auto"}>
                      <MaterialUIButton
                        variant="outlined"
                        message="Export"
                        onClick={() => {
                          handleExportDetail();
                          setShow(false);
                        }}
                        startIcon={<FileDownloadOutlinedIcon />}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={"auto"}>
                      <MaterialUIButton
                        variant="outlined"
                        message="Export"
                        onClick={() => handleExportDetail()}
                        disabled
                        startIcon={<FileDownloadOutlinedIcon />}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/* Grid Start here */}
            <Grid
              container
              spacing={2}
              className={classes.pageActionArea}
              alignItems="center"
              justifyContent="flex-start"
              sx={{ p: 0 }}
            >
              <Grid item xs={12} className={classes.fullHeight}>
                <AgGrid
                  // onGridReady={onGridReadyManageSource}
                  data={select.detailcountrywisedata}
                  header={Detailedheaders}
                  // actionsHandler={handleGridAction}
                  //sorting={handleSort}
                  selectAll={handleSelectDetail}
                  key={gridKey1}
                />
                <UsePagination
                  pageCount={pageCountdetail}
                  count={totaldetail}
                  t={totalRecordsdetail}
                  total={totalCountdetail}
                  currentPage={page}
                  limit={limitdetail}
                  value={limitdetail}
                  handlePageClick={handlePageClickDetail}
                  onChange={(e) => {
                    setLimitDetail(e.target.value);
                    setPageDetail(1);
                    let encodedValue = encodeURIComponent(e.target.value);

                    getDetailedCountryWiseStatus(
                      1,
                      encodedValue,
                      search,
                      filterBody
                    );
                    // gridApi.current.redrawRows(select.detailcountrywisedata);
                  }}
                />
              </Grid>
            </Grid>
          </BaseLayout>

          {/* Advance Filter Model */}
          <MaterialUIModal
            open={advFilterOpen}
            anchor="right"
            body={
              <AdvanceFiltersDetailView
                close={() => setAdvFilterOpen(false)}
                filter={filterDetailsView}
              />
            }
          />
        </Box>
      ) : (
        <Box>
          {/* BaseLayout Start Here */}
          <BaseLayout>
            {/* Action Area - Search by Keyword, Total Record Count, Add Issue, Adv. Filter and Export */}
            <Grid
              container
              spacing={2}
              className={classes.pageActionArea}
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid item xs={12} lg={3}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  className={classes.titleLine}
                >
                  <span className={classes.artLine}>// </span>Chart View
                  <span className={classes.artLine}> //</span>
                </Typography>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Grid
                  container
                  spacing={1.75}
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      message="Clear Filters"
                      startIcon={<RestartAltOutlinedIcon />}
                      onClick={() => {
                        setGridKey(gridKey + 1);

                        dispatch(
                          countrywiseFilter({
                            timeline: "Today",
                            quater: "",
                            startdate: null,
                            enddate: null,
                            timeLineStart: moment().format("YYYY-MM-DD"),
                            timeLineEnd: moment().format("YYYY-MM-DD"),
                            country: [],
                            make: [],
                            model: [],
                            exportId: [],
                          })
                        );
                        if (search !== "") {
                          setPage(1);
                          setSearch("");
                        }
                        getCountryWiseStatus(1, limit, "", {
                          timeLineStart: moment().format("YYYY-MM-DD"),
                          timeLineEnd: moment().format("YYYY-MM-DD"),
                          country: [],
                          make: [],
                          model: [],
                          exportId: [],
                        });
                        dispatch(
                          chartUrlCountry({
                            timeLineStart: moment().format("YYYY-MM-DD"),
                            timeLineEnd: moment().format("YYYY-MM-DD"),
                            country: [],
                            make: [],
                            model: [],
                            exportId: [],
                            id,
                          })
                        ).then((res) => {
                          if (res.data.statusCode === 200) {
                            if (res.data.result !== "No records found") {
                              setData(res.data.result);
                            }
                          }
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      message="Back"
                      startIcon={<ArrowBackIcon />}
                    />
                  </Grid>

                  <Grid item xs={"auto"}>
                    <FormControlLabel
                      sx={{ mb: -0.5, mr: 0 }}
                      control={
                        <Switch
                          checked={isSwitchOn}
                          onChange={() => {
                            setIsSwitchOn(!isSwitchOn);
                          }}
                        />
                      }
                      label={
                        <Typography variant="body1" fontWeight="bold">
                          Detail View
                        </Typography>
                      }
                    />
                  </Grid>
                  {/*  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      message="Clear Filters"
                      startIcon={<RestartAltOutlinedIcon />}
                      onClick={() => resetAll()}
                    />
                  </Grid> */}
                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      onClick={() => setAdvFilter(true)}
                      message="Advance Filter"
                      startIcon={<FilterListOutlinedIcon />}
                    />
                  </Grid>

                  {show === true ? (
                    <Grid item xs={"auto"}>
                      <MaterialUIButton
                        variant="outlined"
                        message="Export"
                        onClick={() => {
                          handleExport();
                          setShow(false);
                        }}
                        startIcon={<FileDownloadOutlinedIcon />}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={"auto"}>
                      <MaterialUIButton
                        variant="outlined"
                        message="Export"
                        onClick={() => handleExport()}
                        disabled
                        startIcon={<FileDownloadOutlinedIcon />}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className={classes.boxWithBorder}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <StackedBarChart
                        data={filterBodyCountry}
                        dataFill={data}
                        barSize={60}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item xs={12} md={12} mt={2}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  className={classes.titleLine}
                >
                  <span className={classes.artLine}>// </span>Table View
                  <span className={classes.artLine}> //</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="left"
                  spacing={2}
                >
                  <Grid item xs={"auto"}>
                    <Gridnav
                      onChange={async (e) => {
                        if (e.target.value === "") {
                          setPage(1);
                          setSearch("");
                          await getCountryWiseStatus(
                            1,
                            limit,
                            "",
                            filterBodyCountry
                          );
                          gridApi.current.redrawRows(select.countrywisedata);
                        } else {
                          setPage(1);
                          setSearch(e.target.value);
                          await getCountryWiseStatus(
                            1,
                            limit,
                            e.target.value,
                            filterBodyCountry
                          );
                          gridApi.current.redrawRows(select.countrywisedata);
                        }
                      }}
                      data={search}
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      className={classes.totalResults}
                    >
                      Total Results:{" "}
                      <span className={classes.totalValue}>
                        {totalRecords ?? 0}
                      </span>
                      &nbsp;
                      <span className={classes.recordText}>Records</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Grid Start here */}
            <Grid
              container
              spacing={2}
              className={classes.pageActionArea}
              alignItems="center"
              justifyContent="flex-start"
              sx={{ p: 0 }}
            >
              <Grid item xs={12} className={classes.innerPage}>
                {" "}
                <AgGrid // onGridReady={onGridReadyManageSource}
                  data={select.countrywisedata}
                  header={headers} // actionsHandler={handleGridAction} //sorting={handleSort}
                  height="280px"
                  selectAll={handleSelect}
                  key={gridKey}
                />
                <UsePagination
                  pageCount={pageCount}
                  count={total}
                  t={totalRecords}
                  total={totalCount}
                  currentPage={page}
                  limit={limit}
                  value={limit}
                  handlePageClick={handlePageClick}
                  onChange={(e) => {
                    setLimit(e.target.value);
                    setPage(1);
                    getCountryWiseStatus(
                      1,
                      e.target.value,
                      search,
                      filterBodyCountry
                    );
                    // gridApi.current.redrawRows(select.countrywisedata);
                  }}
                />
              </Grid>
            </Grid>
          </BaseLayout>

          {/* Advance Filter Model */}

          <MaterialUIModal
            open={advFilter}
            anchor="right"
            body={
              <AdvanceFiltersCountryWise
                close={() => setAdvFilter(false)}
                filter={filterCountryView}
              />
            }
          />
        </Box>
      )}
      {/* Manage Issues Page Start Here */}
    </div>
  );
}

export default CountryWiseStatus;
