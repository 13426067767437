import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
  Dropzone: {
    // height: "80px",
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    border: `1px dashed ${alpha(theme.palette.common.black, 0.35)}`,
    display: "flex",
    flexDirection: "column",
    margin: "0",
    padding: "0",
    "&:hover": {
      border: `1px dashed ${alpha(theme.palette.common.black, 0.85)}`,
    },
  },
  uploadFiles: {
    width: "100%",
    border: "1px solid #afafaf !important",
    padding: theme.spacing(0) + "!important",
    borderRadius: "5px",
    background: "#F8F5FF",
    overflow: "auto",
  },
  Highlight: {
    borderColor: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
  },
  DropzoneInnerWrap: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  DropzoneInnerWrapHidden: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#F1F1F1",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    width: 1,
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
  },
  helperText: {
    fontSize: 12,
    marginTop: theme.spacing(2),
  },
  barColorPrimary: {
    background: "green",
  },
  barColorSecondary: {
    background: "green",
  },
  listItemStyle: {
    marginTop: 0.1,
    marginBottom: 0.1,
    borderBottom: "1px solid #afafaf",
  },
  listItemLastStyle: {
    marginTop: 0.1,
    marginBottom: 0.1,
    borderBottom: "1px solid #afafaf",
  },
  uploadTitle: {
    marginTop: "0px",
    marginBottom: "5px",
  },
  newAttachments: {
    height: "110px",
    overflowX: "hidden",
    overflowY: "auto",
    marginRight: "2px",
    border: "1px solid #afafaf",
    padding: "10px",
    borderRadius: "4px",
  },
}));

export default useStyles;
