import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles(theme => ({

    rowPerPage: {
        color: "#666666",
        fontFamily: "'Inter', sans-serif",
        fontSize: "11px !important",
        padding: theme.spacing(0, 0, 0, 1),
        fontWeight: 700,
    },
    recountCountDetails: {
        color: "#333333",
        fontFamily: "'Inter', sans-serif",
        fontSize: "11px !important",
        fontWeight: 600,
    },
    color:{
        color:'#7947f6'
    },
    btnStyle: {
        margin: theme.spacing(0, 0.625, 0, 0.625) + " !important",
        textAlign: "center",
        fontWeight: 700,
        fontSize: 20,
    },
}));

export default useStyles;
