import { REASON } from "apiConfig/manageissue";
import {
  BASE_URL_SCOPE,
  BULK_DELETE,
  BULK_UPLOAD_SCRAPER,
  MANAGE_SCOPE_DELETE_URL,
  MANAGE_SCOPE_EXPORT_URL,
  MANAGE_SCOPE_UPDATE_URL,
  MANAGE_SCOPE_URL,
} from "apiConfig/managescope";
import {
  deleteData,
  getData,
  postData,
  postFormData,
  putData,
} from "services/basicApiService";
import { checkTokenExpiry } from "utils/helper";
const token = localStorage.getItem("token");
/******************************************************************************
  Function: getManagescope
  Argument: pagenumber, pagesize, search,data
  Return: response
  Usage:
  1.Get Manage Scope Data
  *******************************************************************************/
export const getManagescope = (pagenumber, pagesize, search, data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(
      MANAGE_SCOPE_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: ExportManagescope
  Argument: pagenumber, pagesize, search, data
  Return: response
  Usage:
  1.Export Manage Scope data 
  *******************************************************************************/
export const ExportManagescope = (
  pagenumber,
  pagesize,
  search,
  data,
  header
) => {
  return async (dispatch) => {
    //checkTokenExpiry()
    const response = await postData(
      MANAGE_SCOPE_EXPORT_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const HandleBulkDelete = (
  pagenumber,
  pagesize,
  search,
  data,
  header
) => {
  return async (dispatch) => {
    //checkTokenExpiry()
    const response = await postData(
      BULK_DELETE +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};

/******************************************************************************
  Function: updateManagescope
  Argument: data
  Return: response
  Usage:
  1.Used to update the manage scope data
  *******************************************************************************/
export const updateManagescope = (data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await putData(MANAGE_SCOPE_UPDATE_URL, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: viewScope
  Argument: id
  Return: response
  Usage:
  1.Get Scope by Id Data
  *******************************************************************************/
export const viewScope = (id, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(BASE_URL_SCOPE + `/${id}`, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: deletescope
  Argument: Id
  Return: response
  Usage:
  1.Used for deleting the scope based on the scope Id.
  *******************************************************************************/
export const deletescope = (id, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await deleteData(MANAGE_SCOPE_DELETE_URL + `?id=${id}`, {
      headers: { "X-Project-ID": header },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: addBulkScope
  Argument: data,name,month
  Return: response
  Usage:
  1.Upload Bulk data 
  *******************************************************************************/
export const addBulkScope = (data, name, month, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postFormData(
      BULK_UPLOAD_SCRAPER + `?uploadedBy=${name}&givenMonth=${month}`,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: reasonDropdown
  Argument: 
  Return: response
  Usage:
  1.Get Reason data
  *******************************************************************************/
export const reasonDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(REASON, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
