// Default React, React Components, Router and Services Components
import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, Paper, Tooltip, Typography } from "@mui/material";

// Dummy Data
import { headers, headers1 } from "./Data";

// Importing Custom Components
import AdvanceFilters from "./advanceFilters";
import BaseLayout from "components/commonComponents/baseLayout";
import Gridnav from "components/commonComponents/gridnav/gridnav";
import MaterialUIModal from "components/commonComponents/modal/modal";
import MaterialUIButton from "components/commonComponents/button/button";
import AgGrid from "components/commonComponents/grid/agGrid";
import UsePagination from "components/commonComponents/pagination/pagination";

// Importing useStyles
import { styled } from "@mui/material/styles";
import useStyles from "./manageScraper.styles";

// Import Material Icons
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

// Import SVG Images
import {
  totalIcon,
  inactiveIcon,
  successIcon,
  failedIcon,
  viewArrow,
} from "../../../../assets/index";
import {
  ExportManagescraper,
  getManagescraper,
  getManagescraperCount,
} from "redux/managescraper/action";
import { useDispatch, useSelector } from "react-redux";
import { utils, writeFile } from "xlsx";
import moment from "moment";
import {
  fetchmanageScraper,
  fetchmanageScraperCount,
  scraperFilter,
} from "redux/app/actions";
import Spinner from "components/commonComponents/spinner/spinner";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { debounce } from "lodash";
function ManageScraper() {
  const dispatch = useDispatch();
  const gridApi = useRef(null);
  const select = useSelector((state) => state.manageScarper);
  const [advFilterOpen, setAdvFilterOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [searchc, setSearchc] = useState("");
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [total, settotal] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  const [totalRecords, settotalRecords] = useState(0);
  const [load, setLoad] = useState(true);
  const [selectExport, setselectExport] = useState([]);
  const id = useSelector((state) => state.project.managescraper);
  const Pname = useSelector((state) => state.project.managescraperprojectname);

  const [rows, setRows] = useState();
  const filterData = useSelector((state) => state.manageScarper.filter);
  const [status, setStatus] = useState("All");
  const filterBody = {
    timeLineStart: filterData.timeLineStart,
    timeLineEnd: filterData.timeLineEnd,
    country: filterData.country.map((e) => e.countryCode),
    make: filterData.make.map((e) => e.make1),
    // model: filterData.model.map((e) => e.model1),
    sourceUrl: filterData.sourceurl,
    website: filterData.website.map((e) => e.website1),
    active: filterData.active,
    status: filterData.status,
    lastRun: filterData.lastrun,
    nextRun: filterData.nextrun,
    reasonForFailure: filterData.reasonForFailure,
    nextRunStatus: filterData.nextrunstatus,
    exportId: [],
  };

  const [totalSourceUrl, setTotalSourceUrl] = useState();
  const [totalInActiveUrl, setTotalInActiveUrl] = useState();
  const [totalFailedUrl, setTotalFailedUrls] = useState();
  const [totalSuccessUrl, setTotalSuccessUrls] = useState();
  // const handleChangeWithLib = debounce(async (e) => {

  //   setSearch(e.target.value);
  //   setSearchc(await getScraper(page, limit, e.target.value, status, filterBody));

  // }, 500);
  // const handleChangeWithLib = debounce((e) => {

  //   setSearch(e.target.value);
  //   setSearchc(getScraper(page, limit, e.target.value, status, filterBody));

  // }, 100);

  useEffect(() => {
    if (search) {
      const timerId = setTimeout(() => {
        performSearch(search);
      }, 3000);
      return () => clearTimeout(timerId);
    } else {
      setPage(1);
      getScraper(1, limit, search, status, filterBody);
    }

    // Cleanup function to cancel the timeout if the component is unmounted before the timeout is reached
  }, [search]);

  const performSearch = (value) => {
    setPage(1);
    let encodedValue = encodeURIComponent(value);

    getScraper(1, limit, encodedValue, status, filterBody);
    // Update the results state with the fetched data
    // setResults(responseData);
  };
  useEffect(() => {
    // getSearchIssue(page, limit, "");

    getScraper(page, limit, "", "");
    dispatch(
      scraperFilter({
        timeline: "Year",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: null,
        timeLineEnd: null,
        country: [],
        make: [],
        // model:[],
        sourceurl: [],
        active: "All",
        status: [],
        website: [],
        lastrun: null,
        nextrun: null,
        nextrunstatus: [],
        reasonForFailure: [],
        exportId: [],
      })
    );
    //getScraperCount();
  }, [id]);
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  // // useEffect for fetching results on initial load
  // useEffect(() => {
  //   performSearch(search);
  // }, []);
  /******************************************************************************
  Function: getScraper
  Argument: page,limit,search,status,body
  Return:
  Usage:
  1.To call the grid API 
  *******************************************************************************/
  const getScraper = (
    page,
    limit,
    search,
    status,
    body = {
      timeLineStart: null,
      timeLineEnd: null,
      country: [],
      make: [],
      // model:[],
      sourceUrl: [],
      active: "",
      status: [],
      lastRun: null,
      nextRun: null,
      nextRunStatus: [],
      website: [],
      reasonForFailure: [],
      exportId: [],
    }
  ) => {
    dispatch(getManagescraper(page, limit, search, status, body, id)).then(
      (res) => {
        if (res) {
          setLoad(false);
          if (res.data.statusCode === 200) {
            if (res.data.result === "No records found") {
              settotalCount(0);
              settotalRecords(0);
              dispatch(fetchmanageScraper([]));
            } else {
              dispatch(fetchmanageScraper(res.data.result.result));
            }
            setLoad(false);
            //setState(res.data.result);

            settotal(res.data.result.length);
            setpageCount(Math.ceil(total / limit));
            settotalCount(res.data.total);
            settotalRecords(res.data.result.totalRecords);
            setTotalSourceUrl(res.data.total);
            setTotalInActiveUrl(res.data.totalInActiveUrls);
            setTotalFailedUrls(res.data.totalFailedUrls);
            setTotalSuccessUrls(res.data.totalSuccessUrls);
          }
          else{
            settotalCount(0);
              settotalRecords(0);
              dispatch(fetchmanageScraper([]));
              setTotalSourceUrl(0);
              setTotalInActiveUrl(0);
              setTotalFailedUrls(0);
              setTotalSuccessUrls(0);
          }
        }
      }
    );
  };
  /******************************************************************************
  Function: filterScraper
  Argument: data, reset
  Return:
  Usage:
  1.To filter grid data
  *******************************************************************************/
  const filterScraper = async (data, reset = false) => {
    setLoad(true);
    setPage(1);
    dispatch(getManagescraper(1, limit, search, status, data, id)).then(
      (res) => {
        if (res) {
          setLoad(false);
          if (res.status === 200) {
            if (res.data.result === "No records found") {
              settotalCount(0);
              settotalRecords(0);
              dispatch(fetchmanageScraper([]));
            } else {
              dispatch(fetchmanageScraper(res.data.result.result));
            }
            setLoad(false);
            //setState(res.data.result);
            if (!reset) {
              setAdvFilterOpen(false);
            }
            settotal(res.data.result.length);
            setpageCount(Math.ceil(total / limit));
            settotalCount(res.data.total);
            settotalRecords(res.data.result.totalRecords);
            setTotalSourceUrl(res.data.total);
            setTotalInActiveUrl(res.data.totalInActiveUrls);
            setTotalFailedUrls(res.data.totalFailedUrls);
            setTotalSuccessUrls(res.data.totalSuccessUrls);
          }
        }
      }
    );
    gridApi.current.redrawRows(select.manageScraperdata);
    //
  };
  const exportdata = selectExport.toString();
  const exportIds = exportdata.split(",");
  /******************************************************************************
  Function: handleExport
  Argument: body
  Return:
  Usage:
  1.To Export grid data 
  *******************************************************************************/
  const handleExport = (body) => {
    setLoad(true);
    dispatch(
      ExportManagescraper(
        page,
        limit,
        search,
        status,
        (body = {
          timeLineStart: filterData.timeLineStart,
          timeLineEnd: filterData.timeLineEnd,
          country: filterData.country.map((e) => e.countryCode),
          make: filterData.make.map((e) => e.make1),
          // model:filterData.model.map((e)=>e.model1),
          sourceUrl: filterData.sourceurl,
          website: filterData.website.map((e) => e.website1),
          active: filterData.active,
          status: filterData.status,
          lastRun: filterData.lastrun,
          nextRun: filterData.nextrun,
          reasonForFailure: filterData.reasonForFailure,
          nextRunStatus: filterData.nextrunstatus,
          exportId: rows ? ["All"] : exportIds,
        }),
        id
      )
    ).then((res) => {
      setLoad(false);
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);

      utils.sheet_add_aoa(ws, [
        [
          "Country",
          "Make",
          // "Model",
          "Source URL",
          "Scraper Running Status",
          "Reason For Failure",
          "Last Run",
          "Next Run",
          "Next Run Status",
        ],
      ]);
      const rows = res.data.result.map((row) => [
        row.regionCode,
        row.manufacturer,
        // row.model,
        row.oemUrl,
        row.runningStatus,

        row.failureReason,
        row.lastRuntime ? moment(row.lastRuntime).format("DD/MM/YYYY") : "",
        row.nextRuntime ? moment(row.nextRuntime).format("DD/MM/YYYY") : "",
        row.nextRunStatus,
      ]);
      utils.sheet_add_json(ws, rows, {
        origin: "A2",
        skipHeader: true,
      });

      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, `managescraper_${moment().format("DMMMyy")}.csv`);
    });
    getScraper(page, limit, search, status, filterBody);
  };
  const handleExportTA = (body) => {
    setLoad(true);
    dispatch(
      ExportManagescraper(
        page,
        limit,
        search,
        status,
        (body = {
          timeLineStart: filterData.timeLineStart,
          timeLineEnd: filterData.timeLineEnd,
          country: filterData.country.map((e) => e.countryCode),
          make: filterData.make.map((e) => e.make1),
          // model:filterData.model.map((e)=>e.model1),
          website: filterData.website.map((e) => e.website1),

          sourceUrl: filterData.sourceurl,
          active: filterData.active,
          status: filterData.status,
          lastRun: filterData.lastrun,
          nextRun: filterData.nextrun,
          reasonForFailure: filterData.reasonForFailure,
          nextRunStatus: filterData.nextrunstatus,
          exportId: rows ? ["All"] : exportIds,
        }),
        id
      )
    ).then((res) => {
      setLoad(false);
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);

      utils.sheet_add_aoa(ws, [
        [
          "Region",
          "Website",
          "Source URL",
          "Scraper Running Status",
          "Reason For Failure",
          "Last Run",
          "Next Run",
          "Next Run Status",
        ],
      ]);
      const rows = res.data.result.map((row) => [
        row.regionCode,
        row.manufacturer,
        row.oemUrl,
        row.runningStatus,

        row.failureReason,
        row.lastRuntime ? moment(row.lastRuntime).format("DD/MM/YYYY") : "",
        row.nextRuntime ? moment(row.nextRuntime).format("DD/MM/YYYY") : "",
        row.nextRunStatus,
      ]);
      utils.sheet_add_json(ws, rows, {
        origin: "A2",
        skipHeader: true,
      });

      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, `managescraper_${moment().format("DMMMyy")}.csv`);
    });
    getScraper(page, limit, search, status, filterBody);
  };
  /******************************************************************************
  Function: handleSelect
  Argument: row
  Return:
  Usage:
  1.To select rows to export
  *******************************************************************************/
  const handleSelect = async (row) => {
    setselectExport(row.map((e) => e.oemId));
    setRows(
      row.length === 10 ||
        row.length === 15 ||
        row.length === 25 ||
        row.length === 50 ||
        row.length === 100
    );
    if (row.length === 0) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  /******************************************************************************
  Function: handlePageClick
  Argument: event, data
  Return:
  Usage:
  1.To handle page navigation
  *******************************************************************************/
  const handlePageClick = async (event, data) => {
    setLoad(true);
    setPage(data);
    console.log(search);
    await getScraper(data, limit, search, status, filterBody);
    gridApi.current.redrawRows(select.manageScraperdata);
  };
  const classes = useStyles();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    boxShadow: "0px 0px 4px 0px rgb(195 0 5 / 10%)",
    position: "relative",
    borderRadius: "14px",
  }));
  const [gridKey, setGridKey] = useState(0);
  const resetAll = async () => {
    dispatch(
      scraperFilter({
        timeline: "Year",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: null,
        timeLineEnd: null,
        country: [],
        make: [],
        // model:[],
        sourceurl: [],
        active: "All",
        status: [],
        lastrun: null,
        nextrun: null,
        nextrunstatus: [],
        reasonForFailure: [],
        website: [],
        exportId: [],
      })
    );
    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
    if (search !== "") {
      setPage(1);
      setSearch("");
      setSearchc("");
    }
    getScraper(1, limit, "", {
      timeLineStart: null,
      timeLineEnd: null,
      country: [],
      make: [],
      // model:[],
      sourceurl: [],
      active: "All",
      status: [],
      lastrun: null,
      nextrun: null,
      nextrunstatus: [],
      reasonForFailure: [],
      website: [],
      exportId: [],
    });

    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
  };

  return (
    <div>
      {/* Manage Issues Page Start Here */}
      <Box>
        <Spinner open={load} />
        {/* Short Hints Start Here */}
        <Grid
          container
          spacing={2}
          className={classes.statusArea}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item xs={12} md={6} lg={4}>
            <Item className={classes.totalBg}>
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs={"auto"}>
                  <div className={classes.statusIcon}>
                    <img src={totalIcon} width="50" alt="Total URLs" />
                  </div>
                </Grid>
                <Grid item xs={"auto"}>
                  <div className={classes.countArea}>
                    <div
                      variant="subtitle2"
                      gutterBottom
                      className={classes.cationText}
                    >
                      Total URLs
                    </div>
                    <div gutterBottom className={classes.totalUrl}>
                      {totalSourceUrl ?? 0}
                    </div>
                    <div className={classes.viewText}>
                      <Button
                        variant="text"
                        size="small"
                        className={classes.viewButton}
                        onClick={async () => {
                          setStatus("All");
                          setLoad(true);
                          await getScraper(1, limit, search, "All", filterBody);
                          await gridApi.current.redrawRows(
                            select.manageScraperdata
                          );
                        }}
                      >
                        View&nbsp; <img src={viewArrow} width="7" alt="View" />
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Item>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3}>
            <Item className={classes.inacBg}>
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs={"auto"}>
                  <div className={classes.statusIcon}>
                    <img src={inactiveIcon} width="50" alt="Inactive URLs" />
                  </div>
                </Grid>
                <Grid item xs={"auto"}>
                  <div className={classes.countArea}>
                    <div
                      variant="subtitle2"
                      gutterBottom
                      className={classes.cationText}
                    >
                      Inactive URLs
                    </div>
                    <div gutterBottom className={classes.inactiveUrl}>
                      {totalInActiveUrl ?? 0}
                    </div>
                    <div className={classes.viewText}>
                      {totalInActiveUrl == 0 ? (
                        <Button
                          disabled
                          variant="contained"
                          size="small"
                          className={classes.viewButton}
                          onClick={async () => {
                            setStatus("inactiveUrls");
                            setLoad(true);
                            await getScraper(
                              1,
                              limit,
                              search,
                              "inactiveUrls",
                              filterBody
                            );
                            await gridApi.current.redrawRows(
                              select.manageScraperdata
                            );
                          }}
                        >
                          View&nbsp;{" "}
                          <img src={viewArrow} width="7" alt="View" />
                        </Button>
                      ) : (
                        <Button
                          variant="text"
                          size="small"
                          className={classes.viewButton}
                          onClick={async () => {
                            setStatus("inactiveUrls");
                            setLoad(true);
                            await getScraper(
                              1,
                              limit,
                              search,
                              "inactiveUrls",
                              filterBody
                            );
                            await gridApi.current.redrawRows(
                              select.manageScraperdata
                            );
                          }}
                        >
                          View&nbsp;{" "}
                          <img src={viewArrow} width="7" alt="View" />
                        </Button>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Item>
          </Grid> */}
          <Grid item xs={12} md={6} lg={4}>
            <Item className={classes.succBg}>
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs={"auto"}>
                  <div className={classes.statusIcon}>
                    <img src={successIcon} width="50" alt="Successful URLs" />
                  </div>
                </Grid>
                <Grid item xs={"auto"}>
                  <div className={classes.countArea}>
                    <div
                      variant="subtitle2"
                      gutterBottom
                      className={classes.cationText}
                    >
                      Successful URLs
                    </div>
                    <div gutterBottom className={classes.successfulUrl}>
                      {totalSuccessUrl ?? 0}
                    </div>

                    <div className={classes.viewText}>
                      {totalSuccessUrl == 0 ? (
                        <Button
                          disabled
                          variant="contained"
                          size="small"
                          className={classes.viewButton}
                          onClick={async () => {
                            setStatus("successUrls");
                            setLoad(true);
                            await getScraper(
                              1,
                              limit,
                              search,
                              "successUrls",
                              filterBody
                            );
                            await gridApi.current.redrawRows(
                              select.manageScraperdata
                            );
                          }}
                        >
                          View&nbsp;{" "}
                          <img src={viewArrow} width="7" alt="View" />
                        </Button>
                      ) : (
                        <Button
                          variant="text"
                          size="small"
                          className={classes.viewButton}
                          onClick={async () => {
                            setStatus("successUrls");
                            setLoad(true);
                            await getScraper(
                              1,
                              limit,
                              search,
                              "successUrls",
                              filterBody
                            );
                            await gridApi.current.redrawRows(
                              select.manageScraperdata
                            );
                          }}
                        >
                          View&nbsp;{" "}
                          <img src={viewArrow} width="7" alt="View" />
                        </Button>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Item>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Item className={classes.failBg}>
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs={"auto"}>
                  <div className={classes.statusIcon}>
                    <img src={failedIcon} width="50" alt="Failed URLs" />
                  </div>
                </Grid>
                <Grid item xs={"auto"}>
                  <div className={classes.countArea}>
                    <div
                      variant="subtitle2"
                      gutterBottom
                      className={classes.cationText}
                    >
                      Failed URLs
                    </div>
                    <div gutterBottom className={classes.failedUrl}>
                      {totalFailedUrl ?? 0}
                    </div>
                    <div className={classes.viewText}>
                      {totalFailedUrl == 0 ? (
                        <Button
                          disabled
                          variant="contained"
                          size="small"
                          className={classes.viewButton}
                          onClick={async () => {
                            setStatus("failedUrls");
                            setLoad(true);
                            await getScraper(
                              1,
                              limit,
                              search,
                              "failedUrls",
                              filterBody
                            );
                          }}
                        >
                          View&nbsp;{" "}
                          <img src={viewArrow} width="7" alt="View" />
                        </Button>
                      ) : (
                        <Button
                          variant="text"
                          size="small"
                          className={classes.viewButton}
                          onClick={async () => {
                            setStatus("failedUrls");
                            setLoad(true);
                            await getScraper(
                              1,
                              limit,
                              search,
                              "failedUrls",
                              filterBody
                            );
                          }}
                        >
                          View&nbsp;{" "}
                          <img src={viewArrow} width="7" alt="View" />
                        </Button>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
        {/* Short Hints End Here */}

        {/* BaseLayout Start Here */}
        <BaseLayout>
          {/* Action Area - Search by Keyword, Total Record Count, Add Issue, Adv. Filter and Export */}
          <Grid
            container
            spacing={2}
            className={classes.pageActionArea}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={12} md={6}>
              <Grid
                container
                alignItems="center"
                justifyContent="left"
                spacing={2}
              >
                <Grid item xs={"auto"}>
                  <Gridnav
                    onChange={handleInputChange}
                    // onChange={async (e) => {

                    //     setPage(1);
                    //     setSearch(e.target.value);
                    //     await getScraper(
                    //       1,
                    //       limit,
                    //       e.target.value,
                    //       status,
                    //       filterBody
                    //     );
                    //     // gridApi.current.redrawRows(select.manageScraperdata);

                    // }}
                    data={search}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    className={classes.totalResults}
                  >
                    Total Results:{" "}
                    <span className={classes.totalValue}>
                      {totalRecords ?? 0}
                    </span>
                    &nbsp;
                    <span className={classes.recordText}>Records</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={1.75}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    message="Clear Filters"
                    startIcon={<RestartAltIcon />}
                    onClick={() => resetAll()}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => setAdvFilterOpen(true)}
                    message="Advance Filter"
                    startIcon={<FilterListOutlinedIcon />}
                  />
                </Grid>

                {show === true ? (
                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      message="Export"
                      onClick={() => {
                        Pname === "TRANSACTIONAL ANALYSIS"
                          ? handleExportTA()
                          : handleExport();
                        setShow(false);
                      }}
                      startIcon={<FileDownloadOutlinedIcon />}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      message="Export"
                      onClick={() => handleExport()}
                      disabled
                      startIcon={<FileDownloadOutlinedIcon />}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* Grid Start here */}
          <Grid
            container
            spacing={2}
            className={classes.pageActionArea}
            alignItems="center"
            justifyContent="flex-start"
            sx={{ p: 0 }}
          >
            <Grid item xs={12} className={classes.fullHeight}>
              <AgGrid
                // onGridReady={onGridReadyManageScraper}
                height={"calc(100vh - 335px)"}
                data={select.manageScraperdata}
                header={Pname === "TRANSACTIONAL ANALYSIS" ? headers1 : headers}
                selectAll={handleSelect}
                key={gridKey}
              />
              <UsePagination
                pageCount={pageCount}
                count={total}
                t={totalRecords}
                total={totalCount}
                currentPage={page}
                limit={limit}
                value={limit}
                handlePageClick={handlePageClick}
                onChange={(e) => {
                  setLimit(e.target.value);
                  setPage(1);
                  getScraper(1, e.target.value, search, status, filterBody);
                  // gridApi.current.redrawRows(select.manageScraperdata);
                }}
              />
            </Grid>
          </Grid>
        </BaseLayout>

        {/* Advance Filter Model */}

        <MaterialUIModal
          open={advFilterOpen}
          anchor="right"
          body={
            <AdvanceFilters
              filter={filterScraper}
              close={() => setAdvFilterOpen(false)}
            />
          }
        />
      </Box>
    </div>
  );
}

export default ManageScraper;
