import { color } from "@mui/system";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { chartUrlCountry } from "redux/dashboard&reports/action";
import "./style.scss";
import { Grid, Typography } from "@mui/material";

function StackedBarChart(props) {
  const dispatch = useDispatch();
  const [data, setdata] = useState([]);
  const id = useSelector((state) => state.project.dashboard);

  const d = {
    timeLineStart: moment().format("YYYY-MM-DD"),
    timeLineEnd: moment().format("YYYY-MM-DD"),
    country: [],
    make: [],
    model: [],
    exportId: [],
    noOfDays: "",
  };
  useEffect(() => {
    dispatch(chartUrlCountry(props.data ? props.data : d, id)).then((res) => {
      if (res.data.statusCode === 200 && res.data.total > 0) {
        setdata(res.data.result);
      } else {
        setdata([]);
      }
    });
  }, [props.data, id]);

  const colors = ["#4D79EA", "#F8B40D", "#35B277", "#D2388B", "#8053EE"];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="chartTitle">
            Region:{" "}
            <span className="chartValue">{`${payload[0].payload.region}`}</span>
          </p>
          <p className="chartTitle">
            Success:{" "}
            <span className="chartValue">
              {`${payload[0].payload.successResult}`}%
            </span>
          </p>
          <p className="chartTitle">
            Failed:{" "}
            <span className="chartValue">
              {`${payload[0].payload.errorResult}`}%
            </span>
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {props.dataFill ? (
        props.dataFill.length > 0 ? (
          <ResponsiveContainer width="100%" height={263}>
            <BarChart data={props.dataFill ? props.dataFill : data}>
              <CartesianGrid />
              <XAxis
                dataKey="country"
                stroke="#000000"
                strokeOpacity="0"
                strokeWidth={1}
                style={{
                  fontFamily: "'Inter', sans-serif",
                  fontSize: "10px",
                  fontWeight: "700",
                  color: "black",
                }}
              />
              <YAxis
                tickFormatter={(tick) => {
                  return `${tick}%`;
                }}
                stroke="#000000"
                strokeOpacity="0"
                strokeWidth={1}
                style={{
                  fontFamily: "'Inter', sans-serif",
                  fontSize: "10px",
                  fontWeight: "700",
                  color: "black",
                }}
              />
              <Tooltip
                cursor={{ fill: "none" }}
                content={<CustomTooltip />}
                wrapperStyle={{ outline: "none" }}
              />
              <Bar dataKey="successResult" stackId="a" barSize={props.barSize}>
                {data.map((entry, index) => (
                  <Cell
                    key={`successResult-${index}`}
                    fill={colors[index % colors.length]}
                  />
                ))}
              </Bar>
              <Bar
                dataKey="errorResult"
                stackId="a"
                barSize={props.barSize}
                fill="#ff823c"
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Grid container spacing={2} minHeight={278}>
            <Grid xs display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body2" gutterBottom>
                No data to display
              </Typography>
            </Grid>
          </Grid>
        )
      ) : !data ? (
        <ResponsiveContainer width="100%" height={263}>
          <BarChart data={props.dataFill ? props.dataFill : data}>
            <CartesianGrid />
            <XAxis
              dataKey="country"
              style={{ fontFamily: "arial", fontSize: "10px", color: "black" }}
            />
            <YAxis
              tickFormatter={(tick) => {
                return `${tick}%`;
              }}
              style={{ fontFamily: "arial", fontSize: "10px", color: "black" }}
            />
            <Tooltip
              cursor={{ fill: "none" }}
              content={<CustomTooltip />}
              wrapperStyle={{ outline: "none" }}
            />
            <Bar dataKey="successResult" stackId="a" barSize={props.barSize}>
              {data.map((entry, index) => (
                <Cell
                  key={`successResult-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Bar>
            <Bar
              dataKey="errorResult"
              stackId="a"
              barSize={props.barSize}
              fill="#ff823c"
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Grid container spacing={2} minHeight={278}>
          <Grid xs display="flex" justifyContent="center" alignItems="center">
            <Typography variant="body2" gutterBottom>
              No data to display
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default StackedBarChart;
