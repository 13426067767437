import { createTheme } from '@mui/material/styles';
import { grey, deepPurple } from '@mui/material/colors';

const iconWidth = 40;

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette: {
    type: 'light',
    background: {
      default: '#F8F5FF',
      paper: grey[50],
    },
    text: {
      primary: grey[900],
      secondary: grey[800],
    },
    primary: {
      main: deepPurple[600],
      light: deepPurple[600],
      dark: deepPurple[600],
    },
    secondary: {
      light: grey[200],
      main: grey[800],
      dark: grey[800],
    }
  }
});

// A custom theme for this app
const theme = createTheme({
  // Break Points
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },

  // Palette Style 
  palette: {
    type: 'light',
    primary: {
      main: defaultTheme.palette.primary.main,
      light: defaultTheme.palette.primary.light,
      dark: defaultTheme.palette.primary.dark,
    },
    secondary: {
      main: defaultTheme.palette.secondary.main,
      light: defaultTheme.palette.secondary.light,
      dark: defaultTheme.palette.secondary.dark,
    },
    background: {
      default: defaultTheme.palette.background.default,
      paper: defaultTheme.palette.background.paper,
    },
    text: {
      primary: defaultTheme.palette.text.primary,
      secondary: defaultTheme.palette.text.secondary,
    }
  },

  // Typography Style 
  typography: {
    fontFamily: "'Inter', sans-serif",
    h1: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600,
      color: defaultTheme.palette.text.primary,
    },
    h2: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600,
      color: defaultTheme.palette.text.primary,
    },
    h3: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600,
      color: defaultTheme.palette.text.primary,
    },
    h4: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600,
      color: defaultTheme.palette.text.primary,
    },
    h5: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 700,
      fontSize: 24,
      color: defaultTheme.palette.text.primary,
    },
    h6: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600,
      fontSize: 20,
      color: defaultTheme.palette.primary.main,
    },
    subtitle1: {
      fontWeight: 500,
      lineHeight: 1.5
    },
  },

  // Components Style Overrides
  components: {
    // Autocomplete
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          // background: "#20b2aa",
          "& hover": {
            background: "#20b2aa",
            border: "red"
          }
        },
        paper: {
          fontSize: 12,
        }
      },
    },

    // Text Field
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
        readOnly:{
          background: 'green'
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root:{
          "&.MuiInputBase-readOnly": {
            background: "#F1F1F1",
            color: "black"
          }
        },
        input: {
          fontSize: 12,
          // border: "1px solid #BDBDBD",
          // ":hover":{
          //   border: "1px solid red",
          // }
        }
      }
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "transparent",
        }
      }
    },

    MuiList: {
      styleOverrides: {
        root: {
          // minHeight: 200,
          maxHeight: "30vh"
        },
      }
    },

    /* MuiTypography: {
      styleOverrides: {
        colorTextSecondary: {
          color: "#333333 !important"
        },
      }
    }, MuiSelect: {
      styleOverrides: {
        select: {
          maxHeight: 100
        },
        menuPaper: {
          maxHeight: 100
        }

      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 20,
        }
      }
    }, */

    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 12,
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 5,
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
        asterisk: {
          color: "#C40005",
          fontWeight: 600,
        },
        
      }
    },

  },

});

export default theme;
