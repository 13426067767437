import moment from "moment";
import MultiFloatFilter from "./MultiFloatFilter";

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = moment(cellValue).format("DD-MM-YYYY");
    const dateParts = dateAsString.split("-");
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  inRangeInclusive: true,
  browserDatePicker: true,
};

export const headers = [
  {
    headerName: "Date",
    field: "date",
    filter: MultiFloatFilter,
    filterParams: "date",
    lockPosition: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatDate",
    },
    sortable: true,
    width: 200,
    minWidth: 200,
  },

  {
    headerName: "Country",
    field: "country",
    filter: MultiFloatFilter,
    filterParams: "country",
    lockPosition: "left",
    width: 180,
    minWidth: 180,
    sortable: true,
  },
  {
    headerName: "Make",
    field: "make",
    filter: MultiFloatFilter,
    filterParams: "make",
    lockPosition: "left",
    width: 200,
    minWidth: 200,
    sortable: true,
  },
  {
    headerName: "Model",
    field: "model",
    filter: MultiFloatFilter,
    filterParams: "model",
    lockPosition: "left",
    wrapText: true,
    autoHeight: true,
    width: 200,
    minWidth: 200,
    sortable: true,
    cellClass: "trimText",
  },

  {
    headerName: "Price Point Change",
    field: "price_Point_Change",
    filter: MultiFloatFilter,
    filterParams: "price_Point_Change",
    lockPosition: "left",
    width: 200,
    minWidth: 200,
    sortable: true,
  },
];
