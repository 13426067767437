import { getRefreshToken } from "redux/usermanagement/action";
import Cookies from 'js-cookie'
export const pagename = (name) => {
  if(name==="/manage-issue-/-request"){
    return "Manage Issue/Request"
  }
  const n = name.replace("/", "");

  if (n.split("/").length > 1) {
    const split = n.split("/")[1].split("-");

    if (split.length === 6) {
      return (
        split[0].charAt(0).toUpperCase() +
        split[0].slice(1) +
        "    " +
        split[1].charAt(0).toUpperCase() +
        split[1].slice(1) +
        "    " +
        split[2].charAt(0).toUpperCase() +
        split[2].slice(1) +
        "    " +
        split[3].charAt(0).toUpperCase() +
        split[3].slice(1) +
        "    " +
        split[4].charAt(0).toUpperCase() +
        split[4].slice(1) +
        "    " +
        split[5].charAt(0).toUpperCase() +
        split[5].slice(1)
      );
    } else if (split.length === 5) {
      return (
        split[0].charAt(0).toUpperCase() +
        split[0].slice(1) +
        "    " +
        split[1].charAt(0).toUpperCase() +
        split[1].slice(1) +
        "    " +
        split[2].charAt(0).toUpperCase() +
        split[2].slice(1) +
        "    " +
        split[3].charAt(0).toUpperCase() +
        split[3].slice(1) +
        "    " +
        split[4].charAt(0).toUpperCase() +
        split[4].slice(1)
      );
    } else if (split.length === 3) {
      return (
        split[0].charAt(0).toUpperCase() +
        split[0].slice(1) +
        "    " +
        split[1].charAt(0).toUpperCase() +
        split[1].slice(1) +
        "    " +
        split[2].charAt(0).toUpperCase() +
        split[2].slice(1)
      );
    } else {
      return split[0].charAt(0).toUpperCase() + split[0].slice(1);
    }
  } else {
    const split = n.split("-");

    if (split.length > 2) {
      return (
        split[0].charAt(0).toUpperCase() +
        split[0].slice(1) +
        "    " +
        split[1].charAt(0).toUpperCase() +
        split[1].slice(1) +
        "    " +
        split[2].charAt(0).toUpperCase() +
        split[2].slice(1)
      );
    } else if (split.length > 1) {
      return (
        split[0].charAt(0).toUpperCase() +
        split[0].slice(1) +
        "    " +
        split[1].charAt(0).toUpperCase() +
        split[1].slice(1)
      );
    } else {
      return split[0].charAt(0).toUpperCase() + split[0].slice(1);
    }
  }
};
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getMonth = () => {
  var monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  var months = [];
  var d = new Date();
  var i;
  d.setDate(1);
  for (i = 0; i <= 12; i++) {
       months.push(monthName[d.getMonth()] + " " + d.getFullYear());

    d.setMonth(d.getMonth() - 1);
  }

  return months;
};
export const getQuater = () => {
  var monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  var Q1 = [];
  var Q2 = [];
  var Q3 = [];
  var Q4 = [];
  var d = new Date();
  var i;
  d.setDate(1);
  for (i = 0; i <= 12; i++) {
    if (monthName[d.getMonth()] === "Jan" || "Feb" || "Mar") {
      Q1.push(monthName[d.getMonth()] + " " + d.getFullYear());
    }
   
    d.setMonth(d.getMonth() - 1);
  }
 
  // return months;
};


export const fileValidation = (file) => {
  var fileInput = file.name;

 
};
const timer=()=>{
  setTimeout(() => {
    removeToken()
    
  }, 1200000);
}

export const setCookie = (name, value, minutes) => {


  var date = new Date(new Date().getTime() + 20 * 60 * 1000);

  Cookies.set(name, value)
  };

export const getCookie = (name) => {
  return Cookies.get(name)
};
export const removeToken=async()=>{
  Cookies.remove('token', { path: '' })
 

}
export const getSelectedProject = (projects, projectChecked) => {
  console.log(projectChecked,projects);
  return projects?.filter(item => item.ProjectId === projectChecked);
}

export const getSelectedProjectString = (projectSelected) => {
  console.log(projectSelected);
  let string = projectSelected?.projectName;
  if(string?.length > 30) {
    return string.substring(0,27) + "...";
  }
  else {
    return string
  }
}

// export const checkTokenExpiry =() => {
   
//         if (getCookie("token") === ''||getCookie("token") === "null"||getCookie("token") === null||getCookie("token") === undefined||getCookie("token") === "string"||(typeof getCookie("token") === "string"&&getCookie("token").length<10)) {
         
//           const refreshToken = localStorage.getItem("refreshToken");
//           getRefreshToken(refreshToken).then((res)=>{
           
//           localStorage.setItem("refreshToken", res.data.result.refreshToken);
//           localStorage.setItem("token", res.data.result.jwtToken);
          
//           setCookie("token", res.data.result.jwtToken, 20);
//         // timer()
        
//           });
          
          
//         }
      
       
//     };
  
  