import MaterialUIButton from "components/commonComponents/button/button";
import Datepicker from "components/commonComponents/datepicker/datepicker";
import Textbox from "components/commonComponents/textbox/textbox";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useState } from "react";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import useStyles from "./userManagement.styles";
import Autocompletedropdown from "components/commonComponents/autocomplete/autocompletedropdown";
import {
  Required,
  isValidEmailAddress,
  onlySting,
} from "utils/validation helper";
import { useDispatch, usedataor, useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import { rerunDropdown, rolesDropdown } from "redux/manageissues/action";
import { fetchRoles, fetchRolesDropdown, getNewSourceStatus, getRerun } from "redux/app/actions";
import { useLocation } from "react-router-dom";
import AlertDialog from "components/commonComponents/alertDialog";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import EditIcon from "@mui/icons-material/Edit";
import { calendar } from "../../../../assets/index";
import moment from "moment";
import { newsourceStatus } from "redux/managesources/action";
import { getRoles } from "redux/rolemanagement/action";
import { forgotPassword } from "redux/usermanagement/action";
import { toast } from "react-toastify";
import Spinner from "components/commonComponents/spinner/spinner";
function ViewUser(props) {
 
  const classes = useStyles();
  const [view, Setview] = useState(true);
  const [viewedit, setViewedit] = useState(false);
  const selectdrop = useSelector((state) => state.userManagement);
  const select = useSelector((state) => state.userManagement);
  const id=useSelector((state) => state.project.user)

  const data = useSelector((state) => state.userManagement.usersdataid);
  
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const location = useLocation();
  const [load, setLoad] = useState(false);


  useEffect(() => {
    dispatch(rolesDropdown(id)).then((res) => {
      if (res) {
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            dispatch(fetchRolesDropdown([]));
          } else {
            dispatch(fetchRolesDropdown(res.data.result));
          }
        }
      }
    });
  }, []);
  const [edit, setEdit] = useState([]);
  const selector = JSON.parse(localStorage.getItem("roleData"));

  useEffect(() => {
    setEdit(
      selector &&
      selector.roleConfigurations.filter((e) =>
        e.menuName === "Manage Issue/Request"
          ? location.pathname === "/manage-issue-/-request" &&
            e.isEdit === true
          : e.menuName.replace("&", "and").toLowerCase() ===
              location.pathname
                .replace("/", "")
                .replace("-", " ")
                .replace("-", " ")
                .replace("/", "#")
                .split("#")[0] && e.isEdit === true
      )
    );
    return () => {
      setEdit(
        selector.roleConfigurations.filter(
          (e) =>
            e.menuName.replace("&", "and").toLowerCase() ===
              location.pathname
                .replace("/", "")
                .replace("-", " ")
                .replace("-", " ") && e.isEdit === true
        )
      );
    };
  }, [location.pathname]);
  const rolecheck = localStorage.getItem("role");
  const [showalert, setShowalert] = useState(false);

  /******************************************************************************
  Function: validate
  Argument: values
  Return: errors
  Usage:
  1.To validate the form
  *******************************************************************************/

  const validate = (values) => {
    const errors = {};

    if (!Required(values.lastName)) {
      errors.lastName = true;
    } else if (!onlySting(values.lastName)) {
      errors.lastName = "Alphabets and underscore are allowed";
      // toast.error("only alphabets and under score  are allowed")
    } else if (values.lastName.length > 15) {
      errors.lastName = "Exceeds 15 characters";
    }
    if (!Required(values.firstName)) {
      errors.firstName = true;
    } else if (!onlySting(values.firstName)) {
      errors.firstName = "Alphabets and underscore are allowed";
      // toast.error("only alphabets and under score  are allowed")
    } else if (values.firstName.length > 15) {
      errors.firstName = "Exceeds 15 characters";
    }

    if (!Required(values.roleId)) {
      errors.roleId = true;
    }
    if (!Required(values.email)) {
      errors.email = true;
    } else if (!isValidEmailAddress(values.email)) {
      errors.email = "Invalid email ID";
      // toast.error("Invalid email address")
    } else if (values.email.length > 50) {
      errors.email = "Exceeds 50 characters";
    }

    return errors;
  };
  const Role = data.roleName && {
    id: data.roleId,
    label: data.roleName,
  };
  const name = localStorage.getItem("firstName");
  const formik = useFormik({
    initialValues: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      roleId: Role,
      createdBy: data.createdBy,
    },
    enableReinitialize: true,
    validate,
    onSubmit: (values) => {
      props.onChangeSave(true);
     
      props.EditUsersdata(
        {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          roleId: values.roleId.id,
          createdBy: name,
        },
        data.userId,
        values.roleId.id
      );
    },
  });
  /******************************************************************************
  Function: cancel
  Argument: 
  Return: 
  Usage:
  1.To close the model
  *******************************************************************************/

  const cancel = () => {
    props.close();
  };
  const formiklinkreset = useFormik({
    initialValues: {
      email: data.email,
    },
    onSubmit: async (values) => {
    
      setLoad(true);
      await dispatch(forgotPassword(values)).then((res) => {
       
        if (res.data.statusCode === 201) {
          //setError(false);
          toast.success(res.data.result);
          setLoad(false);
          cancel();
        } else if (res.data.statusCode === 404) {
          //setError(true);
          toast.error(res.data.responseException.exceptionMessage);
          setLoad(false);
          cancel();
        } else if (res.data.statusCode === 400) {
          //setError(true);
          toast.error(res.data.responseException.exceptionMessage);
          setLoad(false);
          cancel();
        }
      });
    },
  });
  return (
    <Box>
      <Spinner open={load} />
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              {view === true ? (
                <Typography variant="h6" gutterBottom>
                  View User
                </Typography>
              ) : (
                <Typography variant="h6" gutterBottom>
                  Edit User
                </Typography>
              )}
            </Grid>
            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                {view === true ? (
                  <Grid item xs="auto">
                    <MaterialUIButton
                      variant="outlined"
                      message="EDIT"
                      onClick={() => {
                        setViewedit(true);
                        Setview(false);
                      }}
                      disabled={edit.length === 0}
                      startIcon={<EditIcon />}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                        JSON.stringify(formik.values)
                          ? cancel()
                          : setShowalert(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <AlertDialog
                  open={showalert}
                  onClose={() => setShowalert(false)}
                  // open={isDeleted}
                  title={"Confirmation"}
                  // maxWidth={"sm"}
                  description={`Do you want to discard the changes ?`}
                  action={
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="right"
                      spacing={2}
                      className={classes.alertDialogbtn}
                    >
                      <Grid item xs={"auto"}>
                        <MaterialUIButton
                          variant="outlined"
                          onClick={props.close}
                          message="Yes"
                          startIcon={<DoneIcon />}
                        />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <MaterialUIButton
                          variant="outlined"
                          onClick={() => {
                            setShowalert(false);
                          }}
                          message="No"
                          startIcon={<CloseIcon />}
                        />
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}

        <Grid item xs={12}>
          {view === true ? (
            <>
              {" "}
              <Typography variant="subtitle2" display="block" gutterBottom>
                First Name
              </Typography>
              <Typography variant="body2" gutterBottom>
                {data.firstName ? data.firstName : ""}
              </Typography>
            </>
          ) : (
            <>
              <Textbox
                required
                size="small"
                label="First Name"
                fullWidth
                name="firstName"
                value={formik.values.firstName}
                onChange={(data) => {
                  formik.setFieldValue("firstName", data.target.value);
                }}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {view === true ? (
            <>
              {" "}
              <Typography variant="subtitle2" display="block" gutterBottom>
                Last Name
              </Typography>
              <Typography variant="body2" gutterBottom>
                {data.lastName ? data.lastName : ""}
              </Typography>
            </>
          ) : (
            <>
              {" "}
              <Textbox
                required
                size="small"
                label="Last Name"
                fullWidth
                name="lastName"
                value={formik.values.lastName}
                onChange={(data) => {
                  formik.setFieldValue("lastName", data.target.value);
                }}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {view === true ? (
            <>
              {" "}
              <Typography variant="subtitle2" display="block" gutterBottom>
                Email ID
              </Typography>
              <Typography variant="body2" gutterBottom>
                {data.email ? data.email : ""}
              </Typography>
            </>
          ) : (
            <>
              {" "}
              <Textbox
                required
                size="small"
                label="Email ID"
                fullWidth
                name="email"
                value={formik.values.email}
                onChange={(data) => {
                  formik.setFieldValue("email", data.target.value);
                }}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {view === true ? (
            <>
              {" "}
              <Typography variant="subtitle2" display="block" gutterBottom>
                Role
              </Typography>
              <Typography variant="body2" gutterBottom>
                {data.roleName ? data.roleName : ""}
              </Typography>
            </>
          ) : (
            <>
              {" "}
              <Autocompletedropdown
                required
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                id="Role"
                name="roleId"
                label="Role"
                placeholder="roleId"
                value={formik.values.roleId}
                limitTags={4}
                onChange={(_, e) => formik.setFieldValue("roleId", e)}
                getOptionLabel={(e) => e.roleName}
                error={formik.touched.roleId && Boolean(formik.errors.roleId)}
                helperText={formik.touched.roleId && formik.errors.roleId}
                options={selectdrop.roles.map((e) => {
                  return {
                    label: e.roleName,
                    id: e.roleId,
                  };
                })}
              />
            </>
          )}
        </Grid>

        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            {rolecheck === "JATO Admin" && view === true ? (
              <>
                {/* <Grid item xs="auto">
                  <MaterialUIButton
                    variant="outlined"
                    message="GENERATE RESET LINK"
                    //disabled={props.save}
                    onClick={() => formiklinkreset.handleSubmit()}
                    startIcon={<InsertLinkIcon />}
                  />
                </Grid> */}
              </>
            ) : (
              <></>
            )}

            {view === true ? (
              <></>
            ) : (
              <>
                <Grid item xs="auto">
                  <MaterialUIButton
                    variant="outlined"
                    message="SAVE"
                    disabled={props.save}
                    onClick={() => formik.handleSubmit()}
                    startIcon={<SaveOutlinedIcon />}
                  />
                </Grid>
                <Grid item xs="auto">
                  <MaterialUIButton
                    variant="outlined"
                    message="CANCEL"
                    onClick={() => {
                      JSON.stringify(formik.initialValues) ===
                      JSON.stringify(formik.values)
                        ? cancel()
                        : setShowalert(true);
                    }}
                    startIcon={<EventBusyOutlinedIcon />}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        {/* Action Area End Here */}
      </Grid>
    </Box>
  );
}

export default ViewUser;
