import * as React from 'react';

import { Snackbar } from '@mui/material';

export default function Toasts(props) {
  return (
    <Snackbar
    open={props.open}
    autoHideDuration={3000}
    onClose={props.onClose}
    message={props.message}
    
  />
  );
}
