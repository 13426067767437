
export const AppActionsTypes = {
  TICKET: "TICKET",
  COUNTRY: "COUNTRY",
  MAKE: "MAKE",
  MODEL: "MODEL",
  CATEGORY: "CATEGORY",
  TYPE: "TYPE",
  PRIORITY: "PRIORITY",
  FIELD: "FIELD",
  RERUN: "RERUN",
  WORKTYPE: "WORKTYPE",
  STATUS: "STATUS",
  DEVELOPER_COMPLEXITY: "DEVELOPER_COMPLEXITY",
  RESOLUTION_STATUS: "RESOLUTION_STATUS",
  TICKET_LIST: "TICKET_LIST",
  USERS: "USERS",
  FETCH_MANAGE_ISSUE: "FETCH_MANAGE_ISSUE",
  FETCH_MANAGE_ISSUE_ID: "FETCH_MANAGE_ISSUE_ID",
  VIEW: "VIEW",
  FILTER: "FILTER",
  FILES: "FILES",
  DEVFILES: "DEVFILES",
  COMPLEXITY: "COMPLEXITY",
  OPENSTATUS: "OPENSTATUS",
  SOURCEURL: "SOURCEURL",
  SCRAPER_STATUS: "SCRAPER_STATUS",
  NEXT_RUN_STATUS: "NEXT_RUN_STATUS",
  FILTER_SCRAPER: "FILTER_SCRAPER",
  SCRAPER_COUNTRY: "SCRAPER_COUNTRY",
  SCRAPER_MAKE: "SCRAPER_MAKE",
  FETCH_MANAGE_SCRAPER: "FETCH_MANAGE_SCRAPER",
  FETCH_MANAGE_SCRAPER_COUNT: "FETCH_MANAGE_SCRAPER_COUNT",
  FETCH_MANAGE_SOURCE: "FETCH_MANAGE_SOURCE",
  FETCH_MANAGE_SOURCE_ID: "FETCH_MANAGE_SOURCE_ID",
  SOURCE_COUNTRY: "SOURCE_COUNTRY",
  SOURCE_MAKE: "SOURCE_MAKE",
  SOURCE_MODEL: "SOURCE_MODEL",
  SOURCE_PRIORITY: "SOURCE_PRIORITY",
  SOURCE_URL_STATUS: "SOURCE_URL_STATUS",
  FILTER_SOURCE: "FILTER_SOURCE",
  NEW_SOURCE_STATUS: "NEW_SOURCE_STATUS",
  SOURCEURL_SOURCE: "SOURCEURL_SOURCE",
  REPORTEDBY: "REPORTEDBY",
  ERROR: "ERROR",
  FILTER_SCOPE: "FILTER_SCOPE",
  PREFILED: "PREFILED",
  FETCH_MANAGE_SCOPE: "FETCH_MANAGE_SCOPE",
  FETCH_MANAGE_SCOPE_ID: "FETCH_MANAGE_SCOPE_ID",
  REASON: "REASON",
  ATTACHMENTS: "ATTACHMENTS",
  DEVATTACHMENTS: "DEVATTACHMENTS",
  FETCH_USER_MANAGEMENT: "FETCH_USER_MANAGEMENT",
  FETCH_USER_MANAGEMENT_ID: "FETCH_USER_MANAGEMENT_ID",
  FETCH_ROLE_MANAGEMENT: "FETCH_ROLE_MANAGEMENT",
  FETCH_ROLE_MANAGEMENT_ID: "FETCH_ROLE_MANAGEMENT_ID",
  FETCH_COUNTRY_WISE_STATUS: "FETCH_COUNTRY_WISE_STATUS",
  FETCH_DETAILED_COUNTRY_WISE_STATUS: "FETCH_DETAILED_COUNTRY_WISE_STATUS",
  FILTER_DASHBOARD: "FILTER_DASHBOARD",
  FETCH_PRICE_POINT_CHANGES: "FETCH_PRICE_POINT_CHANGES",
  FETCH_SOURCE_URL_WITH_NO_RECORDS: "FETCH_SOURCE_URL_WITH_NO_RECORDS",
  COUNTRY_FILTER_DASHBOARD: "COUNTRY_FILTER_DASHBOARD",
  DETAIL_FILTER_DASHBOARD: "DETAIL_FILTER_DASHBOARD",
  PRICEPOINT_FILTER_DASHBOARD: "PRICEPOINT_FILTER_DASHBOARD",
  NO_FILTER_DASHBOARD: "NO_FILTER_DASHBOARD",
  FETCH_FAILURE_SUCCESS: "FETCH_FAILURE_SUCCESS",
  IS_LOADING: "IS_LOADING",
  IS_SWITCH_ON: "IS_SWITCH_ON",
  MENU: "MENU",
  ROLE_DATA: "ROLE_DATA",
  ROLE_ID: "ROLE_ID",
  ROLE_NAME: "ROLE_NAME",
  FIRST_NAME: "FIRST_NAME",
  LAST_NAME: "LAST_NAME",
  COMMENTS: "COMMENTS",
  SCRAPER_REASON: "SCRAPER_REASON",
  PROJECT: "PROJECT",
  PROJECT_DETAILS: "PROJECT_DETAILS",
  ISSUE: "ISSUE",
  SCOPE: "SCOPE",
  SOURCE: "SOURCE",
  SCRAPER: "SCRAPER",
  ALL: "ALL",
  DASHBOARD: "DASHBOARD",
  USER: "USER",
  ROLE: "ROLE",
  QUALITY: "QUALITY",
  MANAGE_QUALITY: "MANAGE_QUALITY",
  MANAGE_QUALITY_FILTER: "MANAGE_QUALITY_FILTER",
  PROJECT_NAME: "PROJECT_NAME",
  PROVIDER: "PROVIDER",
  FILTER_DELIVERY: "FILTER_DELIVERY",
  FILTER_DELIVERY_DATA: "FILTER_DELIVERY_DATA",
  FILTER_DELIVERY_DETAIL: "FILTER_DELIVERY_DETAIL",
  DASHBOARD_COUNTRY: "DASHBOARD_COUNTRY",
  DASHBOARD_MAKE: "DASHBOARD_MAKE",
  DASHBOARD_MODEL: "DASHBOARD_MODEL",
  ROLES: "ROLES",
  PROJECT_NAME_QUALITY: "PROJECT_NAME_QUALITY",
  PROJECT_NAME_SCRAPER: "PROJECT_NAME_SCRAPER",
  WEBSITE: "WEBSITE",
  BAU_STATUS: "BAU_STATUS",
  REPORTS: "REPORTS",
  GRID_DATA_BAU: "GRID_DATA_BAU",
  BAU_FILTER: "BAU_FILTER",
  DATA_BAU: "DATA_BAU",
  REQUEST_TYPE_DROPDOWN:"REQUEST_TYPE_DROPDOWN",
  MUSER:"MUSER",
  PROJECT_NAME_SLA:"PROJECT_NAME_SLA"
};
