import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    totalResults: {
      fontSize: 12,
      fontFamily: "'Inter', sans-serif",
      fontWeight: "500 !important",
      color: "#333333",
    },
    uploadFiles: {
      width: "100%",
      border: "1px solid #afafaf !important",
      padding: theme.spacing(0) + "!important",
      borderRadius: "5px",
      background: "#F8F5FF",
      overflow: "auto",
    },
    moreAttachments: {
      maxHeight: "120px",
      overflowY: "auto",
      overflowX: "hidden",
      paddingRight: "2px",
    },
    totalValue: {
      fontSize: 12,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600,
      color: "#2C0957",
    },
    recordText: {
      fontSize: 12,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400,
      color: "#666666",
    },

    // Page Action Area
    pageActionArea: {
      padding: theme.spacing(0, 2, 2, 2),
    },

    borderLine: {
      border: "1px solid #afafaf !important",
      borderRadius: "14px",
      padding: theme.spacing(2, 3, 3, 3),
    },
    titleLine: {
      color: "#7947F6",
      fontWeight: 600 + "!important",
      fontSize: "16px !important",
    },
    artLine: {
      color: "#333333 !important",
    },
    artSubText: {
      color: "#333333 !important",
    },
    dataIcon: {
      margin: theme.spacing(0.3, 0, -0.3, 0),
    },
    alertDialogbtn: {
      margin: theme.spacing(0, 1, 1, 0),
      color: "#333333",
    },
    readOnly: {
      background: "green !important",
    },
    fullHeight: {
      height: "calc(100vh - 140px)",
    },
    commentBorderLine: {
      border: "1px solid #e3e3e3 !important",

      borderLeft: "5px solid #ed6c02 !important",

      borderRadius: "6px",

      color: "#000000",

      fontFamily: "'Inter', sans-serif",

      fontSize: "10px !important",

      fontWeight: 700,

      padding: theme.spacing(1, 1.5, 1, 1.5),

      margin: theme.spacing(0, 0, 1.5, 0),
    },

    commentedBy: {
      color: "#333",

      fontFamily: "'Inter', sans-serif",

      fontSize: "11px !important",

      fontWeight: 700,
    },
    autoDisable: {
      background: "#F1F1F1 !important",
      "& .MuiFormLabel-root": { color: "#424242 !important" },
      "&:hover fieldset": { border: "1px solid #afafaf" },
      "& .MuiAutocomplete-tag": { color: "#000000" },
    },
  })
);

export default useStyles;
