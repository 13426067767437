import React from "react";
import { styled } from "@mui/material/styles";
import {
  FormControlLabel,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
} from "@mui/material";

function Radiobutton(props) {
  return (
    <RadioGroup
      row
      onChange={props.onChange}
      value={props.value}
      name={props.name}
      disabled={props.disbaled}
    >
      <FormControlLabel
        disabled={props.disabled}
        label={props.label}
        value={props.itemvalue}
        control={<Radio size="small" />}
      />
    </RadioGroup>
  );
}
const JDListItemButton = styled(ListItemButton)(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  "&.Mui-selected": {
    borderColor: theme.palette.primary.main,
  },
  "& > .MuiListItemIcon-root": {
    minWidth: theme.spacing(5),
    "& > .MuiCheckbox-root": {
      marginLeft: `-${theme.spacing(1)}`,
    },
  },
  "& .MuiListItemText-root > .MuiListItemText-primary": {
    fontSize: 13,
    fontWeight: 500,
  },
}));

function ListButtonRadio(props) {
  const { checked, id, label, isListItem = false, onChange, ...rest } = props;

  const LIButtonRadio = () => {
    return (
      <JDListItemButton role={undefined} selected={checked} onClick={onChange}>
        <ListItemIcon>
          <Radio
            edge="start"
            tabIndex={-1}
            inputProps={{ "aria-labelledby": `${id}-label` }}
            disableRipple
            checked={checked}
            {...rest}
          />
        </ListItemIcon>
        <ListItemText id={`${id}-label`} primary={label} />
      </JDListItemButton>
    );
  };
  const LIButtonWrapper = (props) => {
    if (props.shouldWrap) {
      return <ListItem sx={{ px: 0, py: 0.5 }}>{props.children}</ListItem>;
    } else {
      return <>{props.children}</>;
    }
  };
  return (
    <LIButtonWrapper shouldWrap={isListItem}>
      <LIButtonRadio />
    </LIButtonWrapper>
  );
}
export default Radiobutton;
export { Radiobutton, ListButtonRadio };
