import { AppActionsTypes } from "./actions-types";

const INITIAL_STATE = {
 
 
  manageScopedata: [],
  manageScopedataid: {},
  reason:[],

  filter: {
    timeline: "Year",
    quater: "",
    startdate: null,
    enddate: null,
    timeLineStart: null,
    timeLineEnd: null,
    country: [],
    make: [],
    model: [],
    priority:[],
    sourceurl: [],
    availability:"All",
   reasonForFailure:[],
    exportId: [],
  },
};
const manageScopeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
   
    
    case AppActionsTypes.FETCH_MANAGE_SCOPE:
      return {
        ...state,
        manageScopedata: action.payload,
      };
    case AppActionsTypes.FETCH_MANAGE_SCOPE_ID:
      return {
        ...state,
        manageScopedataid: action.payload,
      };
   
    case AppActionsTypes.FILTER_SCOPE:
      return {
        ...state,
        filter: action.payload,
      };
       case AppActionsTypes.REASON:
      return {
        ...state,
        reason: action.payload,
      };

    default:
      return state;
  }
};

export default manageScopeReducer;
