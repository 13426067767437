import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import useStyles from "./autocompletedropdown.styles";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton } from "@material-ui/core";
function Autocompletedropdown(props) {
  const classes = useStyles();

  return (
    <Autocomplete
     

       disableClearable={props.disableClearable}
      className={props.readOnly ? classes.textFieldReadOnly : classes.textField}
      id={props.id}
      isOptionEqualToValue={props.isOptionEqualToValue}
      options={props.options}
      onChange={props.onChange}
      value={props.value}
      inputValue={props.inputValue}
      disabled={props.disabled}
      readOnly={props.readOnly}
      fullWidth
      renderInput={(params) => (
        <TextField
          required={props.required}
          {...params}
          error={props.error}
          // helperText={props.helperText}
          size="small"
          label={props.label}
        />
      )}
    />
  );
}

export default Autocompletedropdown;
