import React, {
  Fragment,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
} from "react";
import "./sourceUrlWithNoRecords.styles.css";
import ListItem from "@material-ui/core/ListItem";
import MaterialUIButton from "components/commonComponents/button/button";
import ListItemButton from "@mui/material/ListItemButton";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import List from "@material-ui/core/List";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { MenuProps, useStyles } from "./utils";
import Textbox from "components/commonComponents/textbox/textbox";
import { Grid } from "@mui/material";
import moment from "moment";
import { SettingsBackupRestore } from "@mui/icons-material";
export default forwardRef((props, ref) => {
  const [currentValue, setCurrentValue] = useState(null);
  var newArr = [];
  const [modifiedArr, setModifiedArr] = useState([]);
  const [selected, setSelected] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [resetData, setData] = useState("");
  const [noMatch, setNoMatch] = useState();
  const [formatForDate, setFormatForDate] = useState([]);
  const [dataValue, setDataValue] = useState();
  const column = ["id", "date", "country", "make", "model", "no_Of_Days"];

  const findKeys = (obj, val) => {
    var arr = [];
    var inner_Arr = [];
    if (val === "date") {
      const c = column.filter((key) => key === val);
      obj.map((e) => {
        if (c.length > 0) {
          if (e[c[0]]) {
            console.log(e[c[0]].split("T")[0]);
            arr.push(e[c[0]].split("T")[0]);
          }
        }
      });
      var filter = 0;
      obj.forEach((item) => {
        if (val in item) {
        } else {
          filter = filter + 1;
        }
      });
      if (filter > 0) {
        arr.splice(0, 0, "Blanks");
      }
      arr = new Set(arr);
      arr = Array.from(arr);
    } else {
      const c = column.filter((key) => key === val);
      obj.map((e) => {
        // console.log(e,c);
        if (c.length > 0) {
          if (e[c[0]]) {
            arr.push(e[c[0]]);
          }
        }
      });
      if (typeof arr[0] !== "object") {
        var filter = 0;
        obj.forEach((item) => {
          if (val in item) {
          } else {
            filter = filter + 1;
          }
        });
        if (filter > 0) {
          arr.splice(0, 0, "Blanks");
        }
      }
      if (typeof arr[0] === "object") {
        const innerObject = Object.keys(arr[0]).filter(
          (key) => key === props.colDef.field.split(".")[1]
        );
        arr.map((e) => {
          // console.log(e[innerObject[0]]);
          if (innerObject.length > 0) {
            if (e[innerObject[0]]) {
              inner_Arr.push(e[innerObject[0]]);
            }
          }
          var filter = 0;
          if (val in e) {
          } else {
            filter = filter + 1;
          }

          if (filter > 0) {
            inner_Arr.splice(0, 0, "Blanks");
          }
        });
        inner_Arr = new Set(inner_Arr);
        arr = Array.from(inner_Arr);
      } else {
        arr = new Set(arr);
        arr = Array.from(arr);
        if (typeof arr[0] === "number") {
          arr = Array.from(arr, (x) => `${x}`);
        }
      }
    }
    arr.sort((a, b) => {
      console.log(a, b);

      if (val === "no_Of_Days") {
        return a - b;
      } else {
        if (a === "Blanks") {
          return -1;
        }

        if (b === "Blanks") {
          return 1;
        }

        if (a.toLowerCase() < b.toLowerCase()) {
          return -1;
        }

        if (a.toLowerCase() > b.toLowerCase()) {
          return 1;
        }
      } // return 0;
    });
    /*  arr.sort((a, b) => {
      if (a === "Blanks") {
        return -1;
      }
      if (b === "Blanks") {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    }); */
    let modifiedVal = [];
    arr.map((value) => {
      modifiedVal.push({
        label: value,
        checked: false,
      });
    });
    var formatArray = [];
    setModifiedArr(modifiedVal);
    modifiedVal.map((item) => {
      formatArray.push(
        item.label.split("-").length > 1
          ? moment(item.label.split("-")[0]).format("DD/MM/YYYY") +
              "-" +
              moment(item.label.split("-")[1]).format("DD/MM/YYYY")
          : moment(item.label).format("DD/MM/YYYY")
      );
    });
    setFormatForDate(formatArray);

    console.log("arr", arr);

    console.log("ModifiedArray", modifiedArr);

    // console.log(typeof arr);
  };

  // expose AG Grid Filter Lfecycle callbacks
  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    var input = React.createRef();
    return {
      doesFilterPass(params) {
        if (
          selected.length === 0 &&
          props.context.componentParent.props.data.length > 0
        ) {
          console.log(
            findKeys(
              props.context.componentParent.props.data,
              props.colDef.filterParams
            )
          );
        }
        const { api, colDef, column, columnApi, context } = props;
        const { node } = params;

        let passed = true;
        //const datefiled = value.split("T")[0];
        // make sure each word passes separately, ie search for firstname, lastname
        if (selected.length > 0) {
          passed = false;
        }
        let value;
        selected.forEach((filterWord) => {
          value = props.valueGetter({
            api,
            colDef,
            column,
            columnApi,
            context,
            data: node.data,
            getValue: (field) => console.log(node.data[field]),

            node,
          });
          //console.log("values", value.split("T")[0]);

          console.log("v", value);
          console.log("f", filterWord);
          console.log(node);
          /*  if (props.column.colId === "model1") {
            node.data.fkIssueTracker.forEach((item) => {
              if (
                !passed &&
                item.model.model1.toString().indexOf(filterWord) < 0
              ) {
                passed = false;
              } else {
                passed = true;
              }
            });
          } */
          if (filterWord === "Blanks") {
            if (value === null || value === undefined) {
              passed = true;
            }
          }
          if (resetData === "reset" && selected === 0) {
            passed = true;
          } else if (value) {
            if (!passed && value.toString().indexOf(filterWord) < 0) {
              passed = false;
            } else {
              passed = true;
            }
          }
        });
        console.log("vlaue", value);
        console.log(node);
        console.log(selected);
        return passed;
      },

      isFilterActive() {
        return selected != null && selected !== "";
      },

      getModel() {
        if (!this.isFilterActive()) {
          return null;
        }

        return { value: selected };
      },

      setModel(model) {
        setSelected(model == null ? null : model.value);
      },
    };
  });
  // const onInputBoxChanged = (input) => {
  //   if (input.target.value === '') {
  //     // Remove the filter
  //     props.parentFilterInstance((instance) => {
  //       instance.onFloatingFilterChanged(null, null);
  //     });
  //     return;
  //   }

  //   setCurrentValue(Number(input.target.value));
  //   props.parentFilterInstance((instance) => {
  //     instance.onFloatingFilterChanged('greaterThan', input.target.value);
  //   });
  // };
  if (
    modifiedArr.length === 0 &&
    props.context.componentParent.props.data.length > 0
  ) {
    console.log(
      findKeys(
        props.context.componentParent.props.data,
        props.colDef.filterParams
      )
    );
  }
  useEffect(() => {
    if (
      (selected.length > 0 || resetData !== "") &&
      props.context.componentParent.props.data.length > 0
    ) {
      props.filterChangedCallback();
    }
    // findKeys(
    //   props.context.componentParent.props.data,
    //   props.colDef.filterParams
    // )
  }, [props, selected, resetData]);
  const style = {
    color: props.color,
    width: "30px",
  };
  const classes = useStyles();
  const handleFilterOptions = (e) => {
    let query = e.target.value;
    setDataValue(e.target.value);
    console.log(query.toLowerCase());
    let _filteredCities = [];
    let filterValues = [];
    let modifiedQuery;
    let modifiedInnerArray = [];
    if (query.includes("/")) {
      modifiedQuery = query.replace("/", "");

      formatForDate.map((item) => {
        modifiedInnerArray.push(item.replace("/", ""));
      });
      for (var i = 0; i < modifiedInnerArray.length; i++) {
        if (modifiedInnerArray[i].indexOf(modifiedQuery) !== -1) {
          _filteredCities.push(modifiedArr[i]);
        }
      }
    } else {
      for (let country of modifiedArr) {
        _filteredCities = modifiedArr.filter(
          (item) => item.label.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }
    }

    if (_filteredCities.length === 0) {
      setNoMatch(true);
    } else {
      setNoMatch(false);
    }
    setFilteredOptions(_filteredCities);
  };

  const handleSelectAll = (e) => {
    console.log(e);
    let tempArray = [];
    const newOptions = [...modifiedArr];
    if (e === "reset") {
      for (var i = 0; i < modifiedArr.length; i++) {
        newOptions[i].checked = false;
        setModifiedArr(newOptions);

        // handleSubmit("reset");
      }
    }
    else if(filteredOptions.length>0){
      const newOptions = [...filteredOptions];

      for (var s = 0; s < filteredOptions.length; s++) {
        if (e.target.checked === true) {
          newOptions[s].checked = true;
          setSelectAll(true);
          setFilteredOptions(newOptions);
        }
        if (e.target.checked === false) {
          newOptions[s].checked = false;
          setSelectAll(false);
          setFilteredOptions(newOptions);
        }
      }    }
    else {
      for (var s = 0; s < modifiedArr.length; s++) {
        if (e.target.checked === true) {
          newOptions[s].checked = true;
          setSelectAll(true);
          setModifiedArr(newOptions);
        }
        if (e.target.checked === false) {
          newOptions[s].checked = false;
          setSelectAll(false);
          setModifiedArr(newOptions);
        }
      }
    }
    // tempArray.length===newOptions.length?setSelectAll(true):setSelectAll(false);
  };

  const reset = (value) => {
    /*    setModifiedArr([]);
    setSelected([]);
    setFilteredOptions([]);
    setSelectAll(false);
    setData(props.context.componentParent.props.data);
    console.log(props.context.componentParent.props.data); */
    //setModifiedArr([]);
    setData("reset");
    setSelected([]);
    setDataValue("");
    handleSelectAll(value);
    setFilteredOptions([]);
    // var temparr=[];
    setSelectAll(false);
    // props.context.componentParent.props.data.forEach(item=>
    //   {
    //     temparr.push(item.make.make1);
    //   });
    //   setSelected(temparr);
    // props.filterChangedCallback();
  };

  const handleSubmit = (e) => {
    var tempArray = [];
    // if (filteredOptions.length > 0) {
    //   for (var j = 0; j < filteredOptions.length; j++) {
    //     if (filteredOptions[j].checked === true) {
    //       tempArray.push(filteredOptions[j].label);
    //     }
    //   }
    // }
    //  else if (e === "reset") {
    //   for (var k = 0; k < modifiedArr.length; k++) {
    //     console.log(modifiedArr[k].checked);
    //     if (modifiedArr[k].checked === false) {
    //       tempArray.push(modifiedArr[k].label);
    //     }
    //   }
    // }
    //  else {
    for (var k = 0; k < modifiedArr.length; k++) {
      if (modifiedArr[k].checked === true) {
        tempArray.push(modifiedArr[k].label);
      }
      // }
    }
    setSelected(tempArray);
  };
  function handlerefresh() {
    var myarray = [];
    modifiedArr.map((item) => {
      myarray.push(item.label);
    });
    setSelected(myarray);
  }

  const handleOptionCheck = (e, value) => {
    var count = 0;
    e.stopPropagation();
    console.log(e);
    var newOpt = [...modifiedArr];
    //

    for (var i = 0; i < modifiedArr.length; i++) {
      if (value === newOpt[i].label) {
        // if(e.target.checked===true){
        //   count=count+1
        // }
        newOpt[i].checked = !newOpt[i].checked;

        setModifiedArr(newOpt);
      }
    }

    console.log(count, selected.length);

    // JSON.stringify(newOpt)===JSON.stringify(modifiedArr)?setSelectAll(true):setSelectAll(false)
    //  count===modifiedArr.length?setSelectAll(true):setSelectAll(false)
    modifiedArr.forEach((item) => {
      if (item.checked === true) {
        count = count + 1;
      }
    });
    count === modifiedArr.length ? setSelectAll(true) : setSelectAll(false);
  };
  // const handleChange = (event) => {
  //   const value = event.target.value;
  //   if (value[value.length - 1] === "all") {
  //     setSelected(selected.length === options.length ? [] : options);
  //     return;
  //   }
  //   setSelected(value);
  // };
  const truncate = (str) => {
    return str ? (str.length > 20 ? str.substring(0, 20) + "..." : str) : "";
  };

  return modifiedArr.length > 0 ? (
    <div
      className={classes.urlArea}
      style={{
        padding: "12px 8px 8px 8px",
        background: "#ffffff",
        width: "230px",
      }}
    >
      <div className={classes.searchKeyword}>
        <Textbox
          fullWidth
          id="week"
          name="week"
          variant="outlined"
          value={dataValue}
          label="Search"
          onChange={(e) => handleFilterOptions(e)}
        />
      </div>
      {noMatch ? (
        <div style={{ margin: "15px", textAlign: "center", fontSize: "12px" }}>
          No Match Found
        </div>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              maxWidth: 360,
              minHeight: 210,
              maxHeight: 210,
              overflow: "auto",
              marginTop: "6px",
              marginBottom: "6px",
              padding: "7px 0 0 0",
            }}
          >
            <div disablePadding style={{ padding: "0px" }}>
              <div
                role={undefined}
                dense
                disablePadding
                style={{ padding: "0px" }}
              >
                {/* <label>
              <input
                type="checkbox"
                name="myCheckbox"
                id="checkboxid1"
                checked={selectAll}
                onChange={(e) => handleSelectAll(e)}
              />
              Select All
            </label> */}
                <label class="b-contain">
                  <span>Select All</span>
                  <input
                    type="checkbox"
                    name="myCheckbox"
                    id="checkboxid1"
                    checked={selectAll}
                    onChange={(e) => handleSelectAll(e)}
                  />
                  <div class="b-input"></div>
                </label>
              </div>
            </div>

            {filteredOptions.length > 0
              ? filteredOptions.map((value) => {
                  const labelId = `checkbox-list-label-${value}`;
                  return (
                    <div
                      key={value.label}
                      disablePadding
                      style={{ padding: "0px" }}
                    >
                      <div
                        role={undefined}
                        dense
                        disablePadding
                        style={{ padding: "0px" }}
                      >
                        <label class="b-contain">
                          <span>
                            {props.colDef.filterParams === "date"
                              ? value.label !== "Blanks"
                                ? value.label.split("-").length > 1
                                  ? moment(value.label.split("-")[0]).format(
                                      "DD/MM/YYYY"
                                    ) +
                                    "-" +
                                    moment(value.label.split("-")[1]).format(
                                      "DD/MM/YYYY"
                                    )
                                  : moment(value.label).format("DD/MM/YYYY")
                                : value.label
                              : props.colDef.filterParams === "feedback" ||
                                props.colDef.filterParams ===
                                  "developerComments" ||
                                props.colDef.filterParams === "field" ||
                                props.colDef.filterParams ===
                                  "meritJustification"
                              ? truncate(value.label)
                              : value.label}
                          </span>
                          <input
                            type="checkbox"
                            checked={value.checked}
                            onChange={(e) => handleOptionCheck(e, value.label)}
                          />
                          <div class="b-input"></div>
                        </label>
                      </div>
                    </div>
                  );
                })
              : modifiedArr.map((value) => {
                  return (
                    <div
                      key={value.label}
                      disablePadding
                      style={{ padding: "0px" }}
                    >
                      <div
                        role={undefined}
                        dense
                        disablePadding
                        style={{ padding: "0px" }}
                      >
                        <label class="b-contain">
                          <span>
                            {props.colDef.filterParams === "date"
                              ? value.label !== "Blanks"
                                ? value.label.split("-").length > 1
                                  ? moment(value.label.split("-")[0]).format(
                                      "DD/MM/YYYY"
                                    ) +
                                    "-" +
                                    moment(value.label.split("-")[1]).format(
                                      "DD/MM/YYYY"
                                    )
                                  : moment(value.label).format("DD/MM/YYYY")
                                : value.label
                              : props.colDef.filterParams === "feedback" ||
                                props.colDef.filterParams ===
                                  "developerComments" ||
                                props.colDef.filterParams === "field" ||
                                props.colDef.filterParams ===
                                  "meritJustification"
                              ? truncate(value.label)
                              : value.label}
                          </span>
                          <input
                            type="checkbox"
                            checked={value.checked}
                            onChange={(e) => handleOptionCheck(e, value.label)}
                          />
                          <div class="b-input"></div>
                        </label>
                      </div>
                    </div>
                  );
                })}
          </div>

          <Grid
            container
            spacing={1.5}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="APPLY"
                startIcon={<FilterListOutlinedIcon />}
                onClick={(e) => handleSubmit(e)}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="RESET"
                onClick={() => {
                  reset("reset");
                }}
                startIcon={<RestartAltOutlinedIcon />}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  ) : (
    <div
      className={classes.urlArea}
      style={{
        padding: "12px 8px 8px 8px",
        background: "#ffffff",
        width: "230px",
      }}
    >
      No Data
    </div>
  );
});
