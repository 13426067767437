import moment from "moment";
import MultiFloatFilter from "./MultiFloatFilter";

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = moment(cellValue).format("DD-MM-YYYY");
    const dateParts = dateAsString.split("-");
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  inRangeInclusive: true,
  browserDatePicker: true,
};
export const headers = [
  {
    headerName: "Project Name",
    field: "projectName",
    filter: MultiFloatFilter,
    filterParams: "projectName",
    lockPosition: "left",
    sortable: true,
    width: 205,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    minWidth: 205,
  },
  {
    headerName: "Country",
    field: "regionCode",
    sortable: true,
    filter: MultiFloatFilter,
    filterParams: "regionCode",
    lockPosition: "left",

    width: 180,
    minWidth: 180,
  },

  {
    headerName: "Make",
    field: "manufacturer",
    filter: MultiFloatFilter,
    filterParams: "manufacturer",
    lockPosition: "left",

    width: 200,
    minWidth: 200,
    sortable: true,
  },

  {
    headerName: "Source URL",
    field: "oemUrl",
    filter: MultiFloatFilter,
    filterParams: "oemUrl",
    cellClass: "trimText",
    wrapText: true,
    autoHeight: true,
    //tooltipField: "oemUrl",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "sourceurl",
    },
    lockPosition: "left",

    sortable: true,
    width: 250,
    minWidth: 250,
  },

  {
    headerName: "Scraper Running Status",
    field: "runningStatus",
    filter: MultiFloatFilter,
    filterParams: "runningStatus",
    lockPosition: "left",

    sortable: true,
    width: 200,
    minWidth: 200,
  },

  {
    headerName: "Reason for Failure",
    field: "failureReason",
    filter: MultiFloatFilter,
    filterParams: "failureReason",
    lockPosition: "left",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "reason",
    },
    sortable: true,
    width: 200,
    minWidth: 200,
  },

  {
    headerName: "Last Run",
    field: "lastRuntime",
    filter: MultiFloatFilter,
    filterParams: "lastRuntime",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatlastrun",
    },
    lockPosition: "left",

    width: 150,
    minWidth: 150,
    sortable: true,
  },

  {
    headerName: "Next Run",
    field: "nextRuntime",
    filter: MultiFloatFilter,
    filterParams: "nextRuntime",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatnextrun",
    },
    lockPosition: "left",

    sortable: true,
    width: 150,
    minWidth: 150,
  },

  {
    headerName: "Next Run Status",
    field: "nextRunStatus",
    filter: MultiFloatFilter,
    filterParams: "nextRunStatus",
    lockPosition: "left",

    sortable: true,
    width: 180,
    minWidth: 180,
  },
];
export const headers1 = [
  {
    headerName: "Project Name",
    field: "projectName",
    filter: MultiFloatFilter,
    filterParams: "projectName",
    lockPosition: "left",
    sortable: true,
    width: 205,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    minWidth: 205,
  },
  {
    headerName: "Region",
    field: "regionCode",
    sortable: true,
    filter: MultiFloatFilter,
    filterParams: "regionCode",
    lockPosition: "left",

    width: 180,
    minWidth: 180,
  },

  {
    headerName: "Website",
    field: "manufacturer",
    filter: MultiFloatFilter,
    filterParams: "manufacturer",
    lockPosition: "left",

    width: 200,
    minWidth: 200,
    sortable: true,
  },

  {
    headerName: "Source URL",
    field: "oemUrl",
    filter: MultiFloatFilter,
    filterParams: "oemUrl",
    cellClass: "trimText",
    wrapText: true,
    autoHeight: true,
    //tooltipField: "oemUrl",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "sourceurl",
    },
    lockPosition: "left",

    sortable: true,
    width: 250,
    minWidth: 250,
  },

  {
    headerName: "Scraper Running Status",
    field: "runningStatus",
    filter: MultiFloatFilter,
    filterParams: "runningStatus",
    lockPosition: "left",
    // cellRenderer: "runningStatus",
    // cellRendererParams: {
    //   actionType: "sourceurl",
    // },
    sortable: true,
    width: 200,
    minWidth: 200,
  },

  {
    headerName: "Reason for Failure",
    field: "failureReason",
    filter: MultiFloatFilter,
    filterParams: "failureReason",
    lockPosition: "left",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "reason",
    },
    sortable: true,
    width: 200,
    minWidth: 200,
  },

  {
    headerName: "Last Run",
    field: "lastRuntime",
    filter: MultiFloatFilter,
    filterParams: "lastRuntime",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatlastrun",
    },
    lockPosition: "left",

    width: 150,
    minWidth: 150,
    sortable: true,
  },

  {
    headerName: "Next Run",
    field: "nextRuntime",
    filter: MultiFloatFilter,
    filterParams: "nextRuntime",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatnextrun",
    },
    lockPosition: "left",

    sortable: true,
    width: 150,
    minWidth: 150,
  },

  {
    headerName: "Next Run Status",
    field: "nextRunStatus",
    filter: MultiFloatFilter,
    filterParams: "nextRunStatus",
    lockPosition: "left",

    sortable: true,
    width: 180,
    minWidth: 180,
  },
];
