import {
  BASE_URL_USER,
  LOGOUT,
  USER_LOGIN,
  USER_MANAGEMENT_DELETE_URL,
  USER_MANAGEMENT_EXPORT_URL,
  USER_MANAGEMENT_UPDATE_URL,
  USER_MANAGEMENT_URL,
} from "apiConfig/usermanagement";
import {
  fetchFailureSuccess,
  fetchProject,
  fetchusers,
  isLoading,
} from "redux/app/actions";
import {
  deleteData,
  getData,
  postData,
  putData,
} from "services/basicApiService";
import { ErrorResponseMessage } from "../../constants/constants";
// import { checkLocalStorage.getItem("token")Expiry, removeLocalStorage.getItem("token"), setCookie } from "utils/helper";

/******************************************************************************
  Function: getUsers
  Argument: pagenumber, pagesize, search,data
  Return: response
  Usage:
  1.Get Users Data
  *******************************************************************************/
export const getUsers = (pagenumber, pagesize, search, header) => {
  return async () => {
    console.log(localStorage.getItem("token"));
    ////checkLocalStorage.getItem("token")Expiry();
    const response = await getData(
      USER_MANAGEMENT_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      {
        headers: {
          "X-Project-ID": header,
        },
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: login
  Argument: data,name,month
  Return: response
  Usage:
  1.Calls API to login user. 
  *******************************************************************************/

export const login = (acc) => {
  return async (dispatch) => {
    const response = await getData(USER_LOGIN, {
      headers: { authorization: `Bearer ${acc}` },
    }).catch((error) => {
      throw error;
    });

    if (!response) {
      dispatch(fetchFailureSuccess(ErrorResponseMessage));
    } else if (response.data.statusCode === 201) {
      localStorage.setItem("lastName", response.data.result.lastName);
      localStorage.setItem("firstName", response.data.result.firstName);
      localStorage.setItem(
        "roleData",
        JSON.stringify(response.data.result.roleDeatils)
      );
      localStorage.setItem("role", response.data.result.roleName);
      localStorage.setItem("email", response.data.result.email);
      localStorage.setItem("roleId", response.data.result.roleId);
      dispatch(fetchFailureSuccess(null));
      dispatch(fetchusers(response.data.result));
      dispatch(fetchProject(response.data.result.projects));

      return response;
    } else if (response.data.statusCode === 500) {
      dispatch(isLoading(false));
      dispatch(fetchFailureSuccess(ErrorResponseMessage));
    } else {
      return response;
    }
  };
};

/******************************************************************************
  Function: addUsers
  Argument: data
  Return: response
  Usage:
  1.Add Users data
  *******************************************************************************/
export const addUsers = (data, header) => {
  return async () => {
    ////checkLocalStorage.getItem("token")Expiry();
    const response = await postData(USER_MANAGEMENT_URL, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: viewUser
  Argument: id
  Return: response
  Usage:
  1.Get View User Data
  *******************************************************************************/

export const viewUser = (id, header) => {
  return async () => {
    ////checkLocalStorage.getItem("token")Expiry();
    const response = await getData(BASE_URL_USER + `?id=${id}`, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};

export const logoutUser = () => {
  return async () => {
    ////checkLocalStorage.getItem("token")Expiry();
    const response = await getData(LOGOUT, {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: updateUsers
  Argument: data
  Return: response
  Usage:
  1.Used to update the Users data
  *******************************************************************************/

export const updateUsers = (data, id, header) => {
  return async () => {
    ////checkLocalStorage.getItem("token")Expiry();
    const response = await putData(
      USER_MANAGEMENT_UPDATE_URL + "/" + id,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: ExportUsers
  Argument: data
  Return: response
  Usage:
  1.Export Users data
  *******************************************************************************/
export const ExportUsers = (pagenumber, pagesize, search, data, header) => {
  return async (dispatch) => {
    ////checkLocalStorage.getItem("token")Expiry();
    const response = await postData(
      USER_MANAGEMENT_EXPORT_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: deleteUsers
  Argument: Id
  Return: response
  Usage:
  1.Used for deleting the user based on the user Id.
  *******************************************************************************/
export const deleteUsers = (id, header) => {
  ////checkLocalStorage.getItem("token")Expiry();
  return async () => {
    const response = await deleteData(
      USER_MANAGEMENT_DELETE_URL + `?id=${id}`,

      {
        headers: { "X-Project-ID": header },
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
Function: forgotpassword
Argument: email
Return: response
Usage:
1.Used for send the email to the user.
*******************************************************************************/
export const resetPassword = (data, header) => {
  return async () => {
    const response = await postData(
      USER_MANAGEMENT_URL + "/resetpassword",
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
Function: resetPassword
Argument: data
Return: response
Usage:
1.Used for reset password
*******************************************************************************/

export const forgotPassword = (data, header) => {
  return () => {
    const response = postData(USER_MANAGEMENT_URL + "/forgotpassword", data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
Function:resetknownPassword
Argument: data
Return: response
Usage:
1.Used for reset password
*******************************************************************************/

export const resetknownPassword = (data, header) => {
  return () => {
    ////checkLocalStorage.getItem("token")Expiry();
    const response = postData(
      USER_MANAGEMENT_URL + "/resetknownpassword",
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
