// Default React, React Components, Router and Services Components
import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { useFormik } from "formik";
import { Required, urlValidation } from "utils/validation helper";
import { useDispatch, useSelector } from "react-redux";
import {
  countryDropdown,
  devcomplexityDropdown,
  modelDropdown,
  priorityDropdown} from "redux/manageissues/action";
import {
  getCountrySource,
  getMakeSource,
  getModelSource,
  getPrioritySource,
  getUrlStatusSource,
  getDevcomplexity
} from "redux/app/actions";

// Importing Custom Components

import Textbox from "components/commonComponents/textbox/textbox";
import Dropdown from "components/commonComponents/dropdown/dropdown";
import MaterialUIButton from "components/commonComponents/button/button";
import Autocompletedropdown from "components/commonComponents/autocomplete/autocompletedropdown";
import moment from "moment/moment";

// Importing useStyles
import useStyles from "./manageSources.styles";

// Import Material Icons
import CloseIcon from "@mui/icons-material/Close";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
// Import Image Icons

import Datepicker from "components/commonComponents/datepicker/datepicker";
import {
  makeDependency,
  sourceurlStatus,
  sourceurlDependency,
  modelDependency,
  dependency,
} from "redux/managesources/action";
import AlertDialog from "components/commonComponents/alertDialog";
import DoneIcon from "@mui/icons-material/Done";
import { useLocation } from "react-router-dom";
import MultipleAutocomplete from "components/commonComponents/autocomplete/multipleAutocomplete";

function AddSource(props) {
  // Declaration of States and Component Variables Start Here
  const role = localStorage.getItem("role");
  const [show, setShow] = useState(false);
  const [showWaring, setShowWarining] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.manageSource);
  const data1 = useSelector((state) => state.manageIssue);

  // Declaration of States and Component Variables End Here
  const classes = useStyles();
  const id = useSelector((state) => state.project.managesource);
  // Function for Use Effect
  useEffect(() => {
    getDataFormAPIForDropdown();
  }, []);
  const muifilter = createFilterOptions();
  const dataS = data.urlstatus.filter(
    (e) =>
      e.urlstatus1.toLowerCase() !== "DECOMMISSIONED - REPLACED".toLowerCase()
  );
  const dataReplaced = data.urlstatus.filter(
    (e) =>
      e.urlstatus1.toLowerCase() === "DECOMMISSIONED - REPLACED".toLowerCase()
  );
  /******************************************************************************
  Function: getDataFormAPIForDropdown
  Argument:
  Return:
  Usage:
  1.To call dropdown API
  *******************************************************************************/
  const getDataFormAPIForDropdown = () => {
    dispatch(countryDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getCountrySource([]));
        } else {
          dispatch(getCountrySource(res.data.result));
        }
      } else {
        dispatch(getCountrySource([]));
      }
    });
    dispatch(devcomplexityDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getDevcomplexity([]));
        } else dispatch(getDevcomplexity(res.data.result));
      } else {
        dispatch(getDevcomplexity([]));
      }
    });
    dispatch(priorityDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getPrioritySource([]));
        } else {
          dispatch(getPrioritySource(res.data.result));
        }
      } else {
        dispatch(getPrioritySource([]));
      }
    });

    dispatch(sourceurlStatus(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getUrlStatusSource([]));
        } else {
          dispatch(getUrlStatusSource(res.data.result));
        }
      } else {
        dispatch(getUrlStatusSource([]));
      }
    });
    dispatch(devcomplexityDropdown(id)).then((res) => { 
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getDevcomplexity([]));
        } else {
         
          dispatch(getDevcomplexity(res.data.result));
        }
      } else {
        dispatch(getDevcomplexity([]));
      }
    });
  };
  /******************************************************************************
  Function: validate
  Argument: values
  Return: errors
  Usage:
  1.To validate the form
  *******************************************************************************/

  const validate = (values) => {
    const errors = {};

    if (!Required(values.countryId)) {
      errors.countryId = true;
    }
    if (!Required(values.makeId)) {
      errors.makeId = true;
    }
    if (values.modelId.length === 0) {
      errors.modelId = true;
    }

    if (!Required(values.priorityId)) {
      errors.priorityId = true;
    }
    if (role !== "JATO Admin" && !Required(values.complexity)) {
      errors.complexity = true;
    }
    if (!Required(values.newSourceStatus)) {
      errors.newSourceStatus = true;
    } else if (!urlValidation(values.newSourceStatus)) {
      errors.newSourceStatus = "Invalid URL";
    } else if (values.newSourceStatus.length > 400) {
      errors.newSourceStatus = "Exceeds 200 characters";
    }
    if (role !== "JATO Admin" && values.sourceUrl === "") {
      if (role !== "JATO Admin" && !Required(values.urlstatusId)) {
        errors.urlstatusId = true;
      }
    }
    if (values.comments.length > 400) {
      errors.comments = "Exceeds 400 characters";
    }
    if (!Required(values.receivedDate)) {
      errors.receivedDate = true;
    }
    return errors;
  };

  /******************************************************************************
  Function: onKeyDown
  Argument: e
  Return: 
  Usage:
  1.To handle onKeyDown event
  *******************************************************************************/

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  /******************************************************************************
  Function: reset
  Argument: 
  Return: 
  Usage:
  1.To reset the form
  *******************************************************************************/

  const reset = () => {
    if (formik && formik.values) {
      formik.resetForm();
      formik.values.countryId = null;
      formik.values.makeId = null;
      formik.values.modelId = [];
      formik.values.sourceUrl = "";
      formik.values.priorityId = null;
      formik.values.complexity = null;
      formik.values.urlstatusId = null;
      formik.values.complexityString = null;
      formik.values.receivedDate = null;
      formik.values.newSourceStatus = "";
      formik.values.comments = "";
      formik.values.createdBy = localStorage.getItem("firstName");
    }
  };

  const formik = useFormik({
    initialValues: {
      countryId: null,
      makeId: null,
      modelId: [],
      sourceUrl: "",
      priorityId: null,
      complexity:null,
      urlstatusId: null,
      receivedDate: null,
      complexityString: null,
      newSourceStatus: "",
      comments: "",
      createdBy: localStorage.getItem("firstName"),
    },
    validate,
    onSubmit: (values) => {
      console.log(values);
      if (values.sourceUrl !== "" && values.newSourceStatus !== "") {
        setShowWarining(true);
      } else {
        props.onChangeSave(true);
        props.addsource({
          countryId: formik.values.countryId.id,
          makeId: formik.values.makeId.id,
          modelIds: formik.values.modelId.map((e) => e.modelId),
          sourceUrl: formik.values.newSourceStatus,
          complexity:formik.values.complexity ? formik.values.complexity :null ,
          priorityId: formik.values.priorityId
            ? formik.values.priorityId.id
            : null,
          urlstatusId:
            formik.values.sourceUrl !== ""
              ? data.urlstatus[1].urlstatusId
              : formik.values.urlstatusId
              ? formik.values.urlstatusId.id
              : null,
          complexityString: formik.values.complexityString
            ? formik.values.complexityString.label
            : null,
          receivedDate: formik.values.receivedDate,
          comments: formik.values.comments,
          createdBy: formik.values.createdBy,
        });
        reset();
      }
    },
  });
  /******************************************************************************
  Function: handleToggleOption
  Argument: selectedOptions
  Return: 
  Usage:
  1.To set value for modelId
  *******************************************************************************/

  const handleToggleOption = (selectedOptions) => {
    formik.setFieldValue("modelId", selectedOptions);
  };
  /******************************************************************************
  Function: optionRenderer
  Argument: props, option,selected 
  Return: Hmtl element
  Usage:
  1.To set value in  checkbox for model dropdown
  *******************************************************************************/
  const optionRenderer = (props, option, { selected }) => {
    const selectAllProps =
      option.model1 === "Select All" // To control the state of 'select-all' checkbox
        ? { checked: data.model.length === formik.values.modelId.length }
        : {};
    return (
      <li {...props}>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {option.model1}
      </li>
    );
  };
  return (
    // Add Source Form Start Here
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                Add Source
              </Typography>
            </Grid>
            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs="auto">
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => reset()}
                    message="RESET"
                    startIcon={<RestartAltOutlinedIcon />}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                          JSON.stringify(formik.values) &&
                        data.files.length === 0
                          ? props.close()
                          : setShow(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}

        <Grid item xs={12}>
          <Autocompletedropdown
            required
            onChange={(_, data) => {
              formik.setFieldValue("countryId", data);
              formik.setFieldValue("makeId", null);
              formik.setFieldValue("modelId", []);
              formik.setFieldValue("sourceUrl", "");
              dispatch(makeDependency([data.id], id)).then((res) => {
                if (res.data.statusCode === 200) {
                  if (res.data.result === "No records found") {
                    dispatch(getMakeSource([]));
                  } else {
                    dispatch(getMakeSource(res.data.result));
                  }
                } else {
                  dispatch(getMakeSource([]));
                }
              });
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            error={formik.touched.countryId && Boolean(formik.errors.countryId)}
            helperText={formik.touched.countryId && formik.errors.countryId}
            labelId="Country"
            id="Country"
            value={formik.values.countryId}
            name="countryId"
            label="Country"
            options={data.country.map((e) => {
              return {
                label: e.countryCode + " - " + e.region,
                id: e.countryId,
              };
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocompletedropdown
            required
            disabled={!formik.values.countryId ? true : false}
            onChange={(_, data) => {
              formik.setFieldValue("makeId", data);
              formik.setFieldValue("modelId", []);
              formik.setFieldValue("sourceUrl", "");
              dispatch(modelDependency([data.id], id)).then((res) => {
                if (res.data.statusCode === 200) {
                  if (res.data.result === "No records found") {
                    dispatch(getModelSource([]));
                  } else {
                    dispatch(getModelSource(res.data.result));
                  }
                } else {
                  dispatch(getModelSource([]));
                }
              });
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            labelId="Make"
            id="Make"
            name="makeId"
            label="Make"
            value={formik.values.makeId}
            error={formik.touched.makeId && Boolean(formik.errors.makeId)}
            helperText={formik.touched.makeId && formik.errors.makeId}
            options={data.make.map((e) => {
              return {
                label: e.make1,
                id: e.makeId,
              };
            })}
          />
        </Grid>
        
        <Grid item xs={12}>
          <MultipleAutocomplete
            id="Model"
            required
            label="Model"
            labelId="Model"
            disabled={!formik.values.makeId ? true : false}
            name="modelId"
            value={formik.values.modelId}
            filterOptions={(options, params) => {
              const filtered = muifilter(options, params);
              return [{ modelId: 0, model1: "Select All" }, ...filtered];
            }}
            onChange={(_, e, value) => {
              if (value === "selectOption" || value === "removeOption") {
                if (e.find((option) => option.model === "Select All")) {
                  let result = [];
                  result = data.model.filter(
                    (el) => el.model1 !== "Select All"
                  );
                  formik.setFieldValue("modelId", result);
                } else {
                  handleToggleOption && handleToggleOption(e);

                  formik.setFieldValue("modelId", e);
                  e.length === 1
                    ? dispatch(
                        dependency(
                          e.map((e) => e.modelId),
                          id
                        )
                      ).then((res) => {
                        if (res.status === 200) {
                          if (res.data.sourceUrl) {
                            formik.setFieldValue(
                              "sourceUrl",
                              res.data.sourceUrl
                            );
                          } else {
                            formik.setFieldValue("sourceUrl", "");
                          }
                        } else {
                          formik.setFieldValue("sourceUrl", "");
                        }
                      })
                    : formik.setFieldValue("sourceUrl", "");
                }
              } else if (value === "clear") {
                formik.setFieldValue("modelId", []);
                dispatch(dependency([], id)).then((res) => {
                  if (res.status === 200) {
                    if (res.data.sourceUrl) {
                      formik.setFieldValue("sourceUrl", res.data.sourceUrl);
                    } else {
                      formik.setFieldValue("sourceUrl", "");
                    }
                  } else {
                    formik.setFieldValue("sourceUrl", "");
                  }
                });
              }
              if (e.find((option) => option.model1 === "Select All")) {
                data.model.length === formik.values.modelId.length
                  ? formik.setFieldValue("modelId", [])
                  : formik.setFieldValue("modelId", data.model);
              }
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.model1}
                  //{...getTagProps({ index })}
                />
              ))
            }
            //  isOptionEqualToValue={(option, value) =>  option.value === value.value }
            isOptionEqualToValue={(option, value) => {
              return option.modelId === value.modelId;
            }}
            // getOptionSelected={(option, value) => option.value === value.value}
            error={formik.touched.modelId && Boolean(formik.errors.modelId)}
            helperText={formik.touched.modelId && formik.errors.modelId}
            placeholder="Model"
            limitTags={4}
            options={data.model}
            getOptionLabel={(e) => e.model1}
            renderOption={optionRenderer}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocompletedropdown
            fullWidth
            required
            name="priorityId"
            onChange={(_, e, value) => {
              formik.setFieldValue("priorityId", e);
            }}
            value={formik.values.priorityId}
            error={
              formik.touched.priorityId && Boolean(formik.errors.priorityId)
            }
            helperText={formik.touched.priorityId && formik.errors.priorityId}
            labelId="Business Priority"
            id="Business Priority"
            label="Business Priority"
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            getOptionLabel={(e) => e.e.issuePriority1}
            options={data.priority.map((e) => {
              return {
                label: e.issuePriority1,
                id: e.priorityId,
              };
            })}
          />
        </Grid>
        {formik.values.sourceUrl ? (
          <Grid item xs={12}>
            <Textbox
              multiline
              readOnly
              rows={3}
              // length={400}
              label="Source URL"
              fullWidth
              name="sourceUrl"
              value={formik.values.sourceUrl}
            />
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={12}>
          <Textbox
            required
            multiline
            rows={3}
            length={400}
            label="Add new source URL "
            fullWidth
            name="newSourceStatus"
            value={formik.values.newSourceStatus}
            onChange={(e) => {
              formik.setFieldValue("newSourceStatus", e.target.value);
              formik.values.sourceUrl !== "" ??
                formik.setFieldValue(
                  "urlstatusId",
                  data.urlstatus[2].urlstatus1
                );
            }}
            error={
              formik.touched.newSourceStatus &&
              Boolean(formik.errors.newSourceStatus)
            }
            helperText={
              formik.touched.newSourceStatus && formik.errors.newSourceStatus
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Autocompletedropdown
            required={role !== "JATO Admin"}
            value={formik.values.complexityString}
            onChange={(_, data) =>
              formik.setFieldValue("complexityString", data)
            }
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            labelId="Scrapper Complexity"
            id="Scrapper Complexity"
            name="complexityString"
            error={
              formik.touched.complexityString &&
              Boolean(formik.errors.complexityString)
            }
            helperText={
              formik.touched.complexityString && formik.errors.complexityString
            }
            label="Scrapper Complexity"
            options={data1.developercomplexity.map((e) => {
              return {
                label: e,
                id: e,
              };
            })}
          />
        </Grid>
      
        <Grid item xs={12}>
          <Autocompletedropdown
            required={role !== "JATO Admin"}
            value={formik.values.urlstatusId}
            onChange={(_, data) => {
              formik.setFieldValue("urlstatusId", data);
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            labelId="URL Status"
            id="URL Status"
            label="URL Status"
            name="urlstatusId"
            // filterOptions={() => data.urlstatus.filter(e=>e.urlstatus1==="Replaced")}
            error={
              formik.touched.urlstatusId && Boolean(formik.errors.urlstatusId)
            }
            helperText={formik.touched.urlstatusId && formik.errors.urlstatusId}
            options={dataS.map((e) => {
              return {
                label: e.urlstatus1,
                id: e.urlstatusId,
              };
            })}
          />
        </Grid>

        <Grid item xs={12}>
          <Datepicker
            onKeyDown={onKeyDown}
            required
            name="receivedDate"
            max={moment().format("YYYY-MM-DD")}
            value={formik.values.receivedDate}
            onChange={(e) => {
              formik.setFieldValue("receivedDate", e.format("YYYY-MM-DD"));
            }}
            error={
              formik.touched.receivedDate && Boolean(formik.errors.receivedDate)
            }
            helperText={
              formik.touched.receivedDate && formik.errors.receivedDate
            }
            fullWidth
            label="Source Received date"
          />
        </Grid>

        <Grid item xs={12}>
          <Textbox
            multiline
            rows={3}
            label="Comments"
            fullWidth
            name="comments"
            value={formik.values.comments}
            onChange={formik.handleChange}
            error={formik.touched.comments && Boolean(formik.errors.comments)}
            helperText={formik.touched.comments && formik.errors.comments}
          />
        </Grid>

        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="SAVE"
                disabled={props.save}
                startIcon={<SaveOutlinedIcon />}
                onClick={() => formik.handleSubmit()}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                size="small"
                variant="outlined"
                onClick={() => reset()}
                message="RESET"
                startIcon={<RestartAltOutlinedIcon />}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="CANCEL"
                startIcon={<EventBusyOutlinedIcon />}
                onClick={() => {
                  JSON.stringify(formik.initialValues) ===
                    JSON.stringify(formik.values) && data.files.length === 0
                    ? props.close()
                    : setShow(true);
                }}
              />
            </Grid>
          </Grid>
          <AlertDialog
            open={show}
            onClose={() => setShow(false)}
            // open={isDeleted}
            title={"Confirmation"}
            // maxWidth={"sm"}
            description={`Do you want to discard the changes ?`}
            action={
              <Grid
                container
                alignItems="center"
                justifyContent="right"
                spacing={2}
                className={classes.alertDialogbtn}
              >
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      reset();
                      props.close();
                      setShow(false);
                    }}
                    message="Yes"
                    startIcon={<DoneIcon />}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      setShow(false);
                    }}
                    message="No"
                    startIcon={<CloseIcon />}
                  />
                </Grid>
              </Grid>
            }
          />
          <AlertDialog
            open={showWaring}
            onClose={() => setShowWarining(false)}
            // open={isDeleted}
            title={"Warning"}
            // maxWidth={"sm"}
            description={`Do you want to replace the existing URL `}
            action={
              <Grid
                container
                alignItems="center"
                justifyContent="right"
                spacing={2}
                className={classes.alertDialogbtn}
              >
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      // console.log(data.urlstatus[1].urlstatusId,formik.values.urlstatusId,formik.values.urlstatusId.id);

                      props.onChangeSave(true);
                      props.addsource({
                        countryId: formik.values.countryId.id,
                        makeId: formik.values.makeId.id,
                        modelIds: formik.values.modelId.map((e) => e.modelId),
                        sourceUrl: formik.values.newSourceStatus,
                        priorityId: formik.values.priorityId
                          ? formik.values.priorityId.id
                          : null,
                        complexityString: formik.values.complexityString
                          ? formik.values.complexityString.label
                          : null,
                        urlstatusId:
                          formik.values.sourceUrl !== ""
                            ? data.urlstatus[1].urlstatusId
                            : formik.values.urlstatusId
                            ? formik.values.urlstatusId.id
                            : null,
                        receivedDate: formik.values.receivedDate,
                        comments: formik.values.comments,
                        createdBy: formik.values.createdBy,
                      });

                      reset();
                      setShowWarining(false);
                    }}
                    message="Yes"
                    startIcon={<DoneIcon />}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      setShowWarining(false);
                    }}
                    message="No"
                    startIcon={<CloseIcon />}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>

        {/* Action Area End Here */}
      </Grid>
    </Box>
    // Add Source Form End Here
  );
}

export default AddSource;
