import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FormDialog(props) {
  const { action, children, id, open, onClose, maxWidth = "xs", title, titleHelper } = props;
  let helperString;

  if (typeof (titleHelper) === "string" && titleHelper !== "") {
    helperString = <Typography variant="body2" component="p" sx={{ mt: 0.5 }}>{titleHelper}</Typography>
  }

  return (
    <Dialog
      aria-labelledby={id + "-title"}
      aria-describedby={id + "-description"}
      fullWidth={true}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <DialogTitle variant="inherit" component="div" sx={{ textAlign: "center" }}>
        <Typography variant="h6" component="h2">{title}</Typography>
        {helperString}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
        {action}
      </DialogActions>
    </Dialog>
  );
}

export { FormDialog }