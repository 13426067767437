import MaterialUIButton from "components/commonComponents/button/button";

import Dropdown from "components/commonComponents/dropdown/dropdown";
import Textbox from "components/commonComponents/textbox/textbox";
import React, { useEffect, useState } from "react";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import DoneIcon from "@mui/icons-material/Done";
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";

import Radiobutton from "components/commonComponents/radiobutton/radiobutton";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import MultipleAutocomplete from "components/commonComponents/autocomplete/multipleAutocomplete";
import {
  fetchResolutionstatus,
  fetchTicketlist,
  fetchusers,
  getCategory,
  getcomplexity,
  getCountry,
  getField,
  getMake,
  getModel,
  getopenstatus,
  getPriority,
  getProvider,
  getRerun,
  getStatus,
  getTicket,
  getType,
  getWebsite,
  getWorktype,
  reportedBy,
  requestType,
  saveFilters,
  setMeritUser,
} from "redux/app/actions";
import { getMonth } from "utils/helper";
import {
  categoryDropdown,
  complexityDropdown,
  countryDropdown,
  fieldDropdown,
  getTicketID,
  jiraIdDropdown,
  makeDropdown,
  makeString,
  meritUsers,
  modelDropdown,
  openstatusDropdown,
  priorityDropdown,
  providerDropdown,
  reportedByDropdown,
  reportedByDropdownNew,
  requestTypeDropdown,
  rerunDropdown,
  resolutionDropdown,
  statusDropdown,
  ticketDropdown,
  typeDropdown,
  userDropdown,
  websiteDropdown,
  worktypeDropdown,
} from "redux/manageissues/action";
import AlertDialog from "components/commonComponents/alertDialog";
import useStyles from "./manageIssues.styles";
import { toast } from "react-toastify";
import EditPicker from "components/commonComponents/datepicker/editpicker";
import { makeDependency, modelDependency } from "redux/managesources/action";
function AdvanceFilters(props) {
  const months = getMonth();
  const select = useSelector((state) => state.manageIssue.filter);
  const data = useSelector((state) => state.manageIssue);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const id = useSelector((state) => state.project.manageissue);
  const Pname = useSelector((state) => state.project.manageissueprojectname);
  const role = localStorage.getItem("role");
  const [jiarID, setjiarID] = useState([]);
  const classes = useStyles();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    getDataFormAPIForDropdown();
    // getSearchIssue(page, limit, "");
  }, []);

  /******************************************************************************
Function: callingDropdowndata
Argument: 
Usage:
1. Calling API for Dropdown.
*******************************************************************************/

  const getDataFormAPIForDropdown = () => {
    dispatch(getTicketID(id)).then((res) =>
      dispatch(getTicket(res.data.result))
    );
    dispatch(countryDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getCountry([]));
        } else {
          dispatch(getCountry(res.data.result));
        }
      } else {
        dispatch(getCountry([]));
      }
    });
    dispatch(providerDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getProvider([]));
        } else {
          dispatch(getProvider(res.data.result));
        }
      } else {
        dispatch(getProvider([]));
      }
    });
    dispatch(categoryDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getCategory([]));
        } else {
          dispatch(getCategory(res.data.result));
        }
      } else {
        dispatch(getCategory([]));
      }
    });
    dispatch(requestTypeDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(requestType([]));
        } else {
          dispatch(requestType(res.data.result));
        }
      } else {
        dispatch(requestType([]));
      }
    });
    dispatch(typeDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getType([]));
        } else {
          dispatch(getType(res.data.result));
        }
      } else {
        dispatch(getType([]));
      }
    });
    dispatch(priorityDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getPriority([]));
        } else {
          dispatch(getPriority(res.data.result));
        }
      } else {
        dispatch(getPriority([]));
      }
    });
    dispatch(jiraIdDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          setjiarID([]);
        } else {
          setjiarID(res.data.result);
        }
      } else {
        setjiarID([]);
      }
    });
    dispatch(fieldDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getField([]));
        } else {
          dispatch(getField(res.data.result));
        }
      } else {
        dispatch(getField([]));
      }
    });
    dispatch(makeString([], id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getMake([]));
        } else {
          dispatch(getMake(res.data.result));
        }
      } else {
        dispatch(getMake([]));
      }
    });
    dispatch(rerunDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getRerun([]));
        } else {
          dispatch(getRerun(res.data.result));
        }
      } else {
        dispatch(getRerun([]));
      }
    });
    // dispatch(reportedByDropdown(id)).then((res) => {
    //   if (res.data.statusCode === 200) {
    //     if (res.data.result === "No records found") {
    //       dispatch(reportedBy([]));
    //     } else {
    //       dispatch(reportedBy(res.data.result));
    //     }
    //   } else {
    //     dispatch(reportedBy([]));
    //   }
    // });
    dispatch(
      reportedByDropdownNew(
        id,
        role.toLowerCase().includes("merit") ? false : true
      )
    ).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(reportedBy([]));
        } else {
          dispatch(reportedBy(res.data.result));
        }
      } else {
        dispatch(reportedBy([]));
      }
    });
    dispatch(complexityDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getcomplexity([]));
        } else {
          dispatch(getcomplexity(res.data.result));
        }
      } else {
        dispatch(getcomplexity([]));
      }
    });

    dispatch(worktypeDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getWorktype([]));
        } else {
          dispatch(getWorktype(res.data.result));
        }
      } else {
        dispatch(getWorktype([]));
      }
    });
    dispatch(statusDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getStatus([]));
        } else {
          dispatch(getStatus(res.data.result));
        }
      } else {
        dispatch(getStatus([]));
      }
    });
    dispatch(ticketDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(fetchTicketlist([]));
        } else {
          dispatch(fetchTicketlist(res.data.result));
        }
      } else {
        dispatch(fetchTicketlist([]));
      }
    });
    dispatch(websiteDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getWebsite([]));
        } else {
          dispatch(getWebsite(res.data.result));
        }
      } else {
        dispatch(getWebsite([]));
      }
    });
    dispatch(resolutionDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(fetchResolutionstatus([]));
        } else dispatch(fetchResolutionstatus(res.data.result));
      } else {
        dispatch(fetchResolutionstatus([]));
      }
    });
    dispatch(openstatusDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getopenstatus([]));
        } else dispatch(getopenstatus(res.data.result));
      } else {
        dispatch(getopenstatus([]));
      }
    });

    dispatch(userDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(fetchusers([]));
        } else dispatch(fetchusers(res.data.result));
      } else {
        dispatch(fetchusers([]));
      }
    });
    dispatch(meritUsers(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(setMeritUser([]));
        } else dispatch(setMeritUser(res.data.result));
      } else {
        dispatch(setMeritUser([]));
      }
    });
  };
  /******************************************************************************
Function: validate
Argument: values
Usage:
1. To validate the fileds.
*******************************************************************************/

  const validate = (values) => {
    const errors = {};
    if (new Date(values.timeLineStart) > new Date(values.timeLineEnd)) {
      errors.timeLineEnd = "Enddate should be greater than  startdate";
    }
    if (values.plannedEndDate === "Invalid") {
      errors.plannedEndDate = "invalid date";
    }
    if (values.resolutionDate === "Invalid") {
      errors.resolutionDate = "invalid date";
    }
    if (values.startdate === "Invalid") {
      errors.startdate = "invalid date";
    }
    if (values.enddate === "Invalid") {
      errors.enddate = "invalid date";
    }
    return errors;
  };
  /******************************************************************************
Function: formik
Argument: 
Usage:
1. To submit the data .
*******************************************************************************/

  const formik = useFormik({
    initialValues: {
      timeline: select.timeline,
      quater: select.quater,
      startdate: select.startdate,
      enddate: select.enddate,
      timeLineStart: select.timeLineStart,
      timeLineEnd: select.timeLineEnd,
      ticketId: select.ticketId,
      country: select.country,
      website: select.website,
      make: select.make,
      model: select.model,
      issueCategory: select.issueCategory,
      issueType: select.issueType,
      priority: select.priority,
      attachments: select.attachments,
      rerun: select.rerun,
      workType: select.workType,
      status: select.status,
      reportedBy: select.reportedBy,
      addedBy: select.addedBy,
      planned: select.planned,
      issueField: select.issueField,
      issueComplexity: select.issueComplexity,
      resolution: select.resolution,
      requestTypeId: select.requestTypeId,
      plannedEndDate: select.plannedEndDate,
      resolutionStatus: select.resolutionStatus,
      resolutionDate: select.resolutionDate,
      resolvedBy: select.resolvedBy,
      leaseOperator: select.leaseOperator,
      jiraId:select.jiraId,
      exportticketIds: select.exportticketIds,
    },
    validate,
    onSubmit: (values) => {
      console.log(values);
      dispatch(saveFilters(values));

      props.filter({
        timeLineStart: values.timeLineStart,
        timeLineEnd: values.timeLineEnd,
        ticketId: values.ticketId,
        country: values.country.map((e) => e.countryId),
        make: values.make.map((e) => e.make1),
        website: values.website.map((e) => e.website1),
        model: values.model.map((e) => e.model1),
        issueCategory: values.issueCategory.map((e) => e.categoryId),
        issueType: values.issueType.map((e) => e.typeId),
        priority: values.priority.map((e) => e.priorityId),
        issueComplexity: values.issueComplexity,
        issueField: values.issueField.map((e) => e.fieldId),
        attachments: values.attachments,
        rerun: values.rerun,
        workType: values.workType.map((e) => e.workTypeId),
        requestTypeId: values.requestTypeId.map((e) => e.requestTypeId),
        status: values.status.map((e) => e.statusId),
        reportedBy: values.reportedBy.map((e) => e.reportedById),
        addedBy: values.addedBy.map((e) => e.name),
        plannedEndDate: values.plannedEndDate,
        resolutionStatus: values.resolutionStatus.map((e) => e.resolutionId),
        resolutionDate: values.resolutionDate,
        leaseOperator: values.leaseOperator.map((e) => e.providerId),
        resolvedBy: values.resolvedBy.map((e) => e.name),
        jiraId:values.jiraId,
        exportticketIds: [],
      });
    },
  });
  /******************************************************************************
Function: cancel
Argument: 
Usage:
1. To reset the fileds.
*******************************************************************************/

  const reset = () => {
    if (formik && formik.values) {
      //formik.resetForm();
      formik.values.timeline = "Year";
      formik.values.quater = "";
      formik.values.startdate = null;
      formik.values.enddate = null;
      formik.values.timeLineStart = null;
      formik.values.timeLineEnd = null;
      formik.values.ticketId = [];
      formik.values.country = [];
      formik.values.make = [];
      formik.values.website = [];
      formik.values.model = [];
      formik.values.issueCategory = [];
      formik.values.issueType = [];
      formik.values.priority = [];
      formik.values.addedBy = [];
      formik.values.attachments = "All";
      formik.values.requestTypeId = [];
      formik.values.rerun = 2;
      formik.values.workType = [];
      formik.values.issueField = [];
      formik.values.issueComplexity = [];
      formik.values.status = [];
      formik.values.reportedBy = [];
      formik.values.planned = null;
      formik.values.resolution = null;
      formik.values.plannedEndDate = null;
      formik.values.resolutionStatus = [];
      formik.values.resolutionDate = null;
      formik.values.leaseOperator = [];
      formik.values.resolvedBy = [];
      formik.values.exportticketIds = [];
      formik.values.jiraId = [];
      props.filter(
        {
          timeLineStart: null,
          timeLineEnd: null,
          ticketId: [],
          country: [],
          make: [],
          model: [],
          addedBy: [],
          website: [],
          issueCategory: [],
          issueType: [],
          priority: [],
          issueField: [],
          issueComplexity: [],
          attachments: "All",
          rerun: 2,
          workType: [],
          status: [],
          reportedBy: [],
          leaseOperator: [],
          plannedEndDate: null,
          resolutionStatus: [],
          resolutionDate: null,
          requestTypeId: [],
          resolvedBy: [],
          exportticketIds: [],
          jiraId:[]
        },
        true
      );
      dispatch(
        saveFilters({
          timeline: "Year",
          quater: "",
          startdate: null,
          enddate: null,
          timeLineStart: null,
          timeLineEnd: null,
          ticketId: [],
          country: [],
          make: [],
          website: [],
          model: [],
          addedBy: [],
          issueCategory: [],
          issueType: [],
          priority: [],
          issueField: [],
          issueComplexity: [],
          attachments: "All",
          rerun: 2,
          workType: [],
          status: [],
          reportedBy: [],
          requestTypeId: [],
          planned: null,
          resolution: null,
          plannedEndDate: null,
          resolutionStatus: [],
          resolutionDate: null,
          resolvedBy: [],
          leaseOperator: [],
          exportticketIds: [],
          jiraId:[]
        })
      );
    }
  };

  let fmt = "[Q]Q-Y";

  let quarters = [
    moment().format(fmt),
    moment().subtract(1, "Q").format(fmt),
    moment().subtract(2, "Q").format(fmt),
    moment().subtract(3, "Q").format(fmt),
    moment().subtract(4, "Q").format(fmt),
  ];

  return (
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                Advance Filter
              </Typography>
            </Grid>

            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs="auto">
                  <MaterialUIButton
                    color="secondary"
                    message="RESET"
                    onClick={() => {
                      reset();
                    }}
                    startIcon={<RestartAltOutlinedIcon />}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                        JSON.stringify(formik.values)
                          ? props.close()
                          : setShow(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}
        <Grid item xs={12} sx={{ mt: -1, mb: -1 }}>
          <Typography variant="subtitle2" gutterBottom>
            Timeline
          </Typography>

          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Year"
                name="timeline"
                itemvalue="Year"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);

                  formik.setFieldValue(
                    "timeLineStart",
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Quarter"
                // checked={formik.values.timeline === "Quater"}
                name="timeline"
                value={formik.values.timeline}
                itemvalue="Quater"
                onChange={(e) =>
                  formik.setFieldValue("timeline", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Month"
                name="timeline"
                value={formik.values.timeline}
                itemvalue="Month"
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().startOf("month").format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().endOf("month").format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Week"
                // checked={formik.values.timeline === "Week"}
                name="timeline"
                itemvalue="Week"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue("startdate", moment().startOf("week"));
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().startOf("week").format("YYYY-MM-DD")
                  );
                  formik.setFieldValue("enddate", moment().endOf("week"));
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().endOf("week").format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Today"
                // checked={formik.values.timeline === "Today"}
                name="timeline"
                itemvalue="Today"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Custom"
                value={formik.values.timeline}
                // checked={formik.values.timeline === "Custom"}
                name="timeline"
                itemvalue="Custom"
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue("startdate", null);
                  formik.setFieldValue("enddate", null);
                  formik.setFieldValue("timeLineStart", null);
                  formik.setFieldValue("timeLineEnd", null);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {formik.values.timeline === "Week" && (
            <Textbox
              fullWidth
              disabled
              id="week"
              name="week"
              value={
                moment().startOf("week").format("ll") +
                " to " +
                moment().endOf("week").format("ll")
              }
            />
          )}
          {formik.values.timeline === "Month" && (
            <Textbox
              fullWidth
              disabled
              id="year"
              name="year"
              value={months[0]}
            />
          )}
          {formik.values.timeline === "Year" && (
            <Textbox
              fullWidth
              disabled
              id="year"
              name="year"
              value={months[months.length - 1] + " to " + months[0]}
            />
          )}
          {formik.values.timeline === "Today" && (
            <Textbox
              fullWidth
              disabled
              id="today"
              name="today"
              value={moment().format("LL")}
            />
          )}
          {formik.values.timeline === "Quater" && (
            <Dropdown
              fullWidth
              labelId="quater"
              id="quater"
              name="quater"
              label="Quarter"
              value={formik.values.quater}
              onChange={(e) => {
                const value = e.target.value.split("-");
                formik.setFieldValue("quater", e.target.value);

                if (value[0] === "Q1") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("1/1/" + value[1]),
                        moment("3/31/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("3/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("1/1/" + value[1]),
                      moment("3/31/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("1/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("1/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("3/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q2") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("4/1/" + value[1]),
                        moment("6/30/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("6/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("4/1/" + value[1]),
                      moment("6/30/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("4/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("4/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("6/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q3") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("7/1/" + value[1]),
                        moment("9/30/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("9/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("4/1/" + value[1]),
                      moment("6/30/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("7/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("7/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("9/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q4") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("10/1/" + value[1]),
                        moment("12/31/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("12/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("10/1/" + value[1]),
                      moment("12/31/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("10/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("10/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("12/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
              }}
              menuItems={quarters.map((e) => (
                <MenuItem value={e}>{e}</MenuItem>
              ))}
            />
          )}
          {formik.values.timeline === "Custom" && (
            <>
              <EditPicker
                max={moment().format("YYYY-MM-DD")}
                min={moment().subtract(1, "year").format("YYYY-MM-DD")}
                name="timeLineStart"
                value={formik.values.startdate}
                onChange={(e) => {
                  if (e === null) {
                    formik.setFieldValue("startdate", e);
                    // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
                  } else {
                    formik.setFieldValue("startdate", e);

                    formik.setFieldValue(
                      "timeLineStart",
                      e.format("YYYY-MM-DD")
                    );
                  }
                }}
                error={
                  formik.touched.startdate && Boolean(formik.errors.startdate)
                }
                helperText={formik.touched.startdate && formik.errors.startdate}
                size="small"
                fullWidth
                label="Start Date"
              />
              <br />
              <EditPicker
                max={moment().format("YYYY-MM-DD")}
                min={formik.values.startdate}
                name="timeLineEnd"
                value={formik.values.enddate}
                onChange={(e) => {
                  if (e === null) {
                    formik.setFieldValue("enddate", e);
                    // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
                  } else {
                    formik.setFieldValue("enddate", e);
                    formik.setFieldValue("timeLineEnd", e.format("YYYY-MM-DD"));
                  }
                }}
                error={formik.touched.enddate && Boolean(formik.errors.enddate)}
                helperText={formik.touched.enddate && formik.errors.enddate}
                size="small"
                fullWidth
                label="End Date"
              />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            id="Ticket Id"
            name="ticketId"
            label="Ticket Id"
            placeholder="tickets"
            value={formik.values.ticketId}
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("ticketId", e)}
            options={data.ticketlist}
            isOptionEqualToValue={(option, value) => {
              return option === value;
            }}
            getOptionLabel={(e) => e}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option}
                  // //{...getTagProps({ index })}
                />
              ))
            }
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            id="Jira Id"
            name="jiraId"
            label="Jira Ticket Id"
            placeholder="jira"
            value={formik.values.jiraId}
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("jiraId", e)}
            options={jiarID}
            isOptionEqualToValue={(option, value) => {
              return option === value;
            }}
            getOptionLabel={(e) => e}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option}
                  // //{...getTagProps({ index })}
                />
              ))
            }
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="Country"
            id="Country"
            name="country"
            label="Country"
            value={formik.values.country}
            onChange={(_, e, value) => {
              formik.setFieldValue("country", e);
              if (value !== "clear") {
                dispatch(
                  makeString(
                    e.map((i) => i.countryId),
                    id
                  )
                ).then((res) => {
                  if (res.data.statusCode === 200) {
                    if (res.data.result === "No records found") {
                      dispatch(getMake([]));
                    } else {
                      dispatch(getMake(res.data.result));
                    }
                  } else {
                    dispatch(getMake([]));
                  }
                });
              } else {
                dispatch(makeString([], id)).then((res) => {
                  if (res.data.statusCode === 200) {
                    if (res.data.result === "No records found") {
                      dispatch(getMake([]));
                    } else {
                      dispatch(getMake(res.data.result));
                    }
                  } else {
                    dispatch(getMake([]));
                  }
                });
              }
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.countryCode + " - " + option.region}
                  // //{...getTagProps({ index })}
                />
              ))
            }
            placeholder="Country"
            limitTags={4}
            options={data.country}
            isOptionEqualToValue={(option, value) => {
              return option.countryId === value.countryId;
            }}
            getOptionLabel={(e) => e.countryCode + " - " + e.region}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.countryCode + " - " + e.region}
              </li>
            )}
          />
        </Grid>
        {Pname === "LEASE OPERATOR" ? (
          <Grid item xs={12}>
            <MultipleAutocomplete
              labelId="leaseOperator"
              // disabled={formik.values.country.length === 0 ? true : false}
              id="leaseOperator"
              name="leaseOperator"
              label="Leasing Provider"
              onChange={(_, e) => formik.setFieldValue("leaseOperator", e)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={option.leasingProviderName}
                    //{...getTagProps({ index })}
                  />
                ))
              }
              value={formik.values.leaseOperator}
              placeholder="Leasing Provider"
              limitTags={4}
              options={data.provider}
              getOptionLabel={(e) => e.leasingProviderName}
              isOptionEqualToValue={(option, value) => {
                return option.leasingProviderName === value.leasingProviderName;
              }}
              renderOption={(props, e, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    size="small"
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {e.leasingProviderName}
                </li>
              )}
            />
          </Grid>
        ) : (
          <></>
        )}
        {Pname === "TRANSACTIONAL ANALYSIS" ? (
          <Grid item xs={12}>
            <MultipleAutocomplete
              labelId="website"
              // disabled={formik.values.country.length === 0 ? true : false}
              id="website"
              name="website"
              label="Website"
              onChange={(_, e) => formik.setFieldValue("website", e)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={option.website1}
                    //{...getTagProps({ index })}
                  />
                ))
              }
              value={formik.values.website}
              placeholder="website"
              limitTags={4}
              options={data.website}
              getOptionLabel={(e) => e.website1}
              isOptionEqualToValue={(option, value) => {
                return option.website1 === value.website1;
              }}
              renderOption={(props, e, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    size="small"
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {e.website1}
                </li>
              )}
            />
          </Grid>
        ) : (
          <> </>
        )}
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="Make"
            // disabled={formik.values.country.length === 0 ? true : false}
            id="Make"
            name="make"
            label="Make"
            onChange={(_, e, value) => {
              console.log(e, value);
              formik.setFieldValue("make", e);
              if (value !== "clear") {
                dispatch(
                  modelDropdown(
                    e.map((i) => i.make1),
                    id
                  )
                ).then((res) => {
                  if (res.data.statusCode === 200) {
                    if (res.data.result === "No records found") {
                      dispatch(getModel([]));
                    } else {
                      dispatch(getModel(res.data.result));
                    }
                  } else {
                    dispatch(getModel([]));
                  }
                });
              } else if (value === "removeOption" && e.length === 0) {
                formik.setFieldValue("model", []);
              } else {
                formik.setFieldValue("model", []);
              }
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.make1}
                  //{...getTagProps({ index })}
                />
              ))
            }
            value={formik.values.make}
            placeholder="Make"
            limitTags={4}
            options={data.make}
            getOptionLabel={(e) => e.make1}
            isOptionEqualToValue={(option, value) => {
              return option.make1 === value.make1;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  size="small"
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.make1}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            id="Model"
            label="Model"
            labelId="Model"
            disabled={formik.values.make.length === 0 ? true : false}
            name="model"
            value={formik.values.model}
            onChange={(_, e) => formik.setFieldValue("model", e)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.model1}
                  //{...getTagProps({ index })}
                />
              ))
            }
            placeholder="Model"
            limitTags={4}
            options={data.model}
            getOptionLabel={(e) => e.model1}
            isOptionEqualToValue={(option, value) => {
              return option.model1 === value.model1;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.model1}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="Issue Category"
            id="Issue Category"
            name="issueCategory"
            label="Issue Category"
            value={formik.values.issueCategory}
            placeholder="Issue Category"
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("issueCategory", e)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.issueCategory1}
                  //{...getTagProps({ index })}
                />
              ))
            }
            options={data.category}
            getOptionLabel={(e) => e.issueCategory1}
            isOptionEqualToValue={(option, value) => {
              return option.categoryId === value.categoryId;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.issueCategory1}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="Issue Type"
            id="Issue Type"
            label="Issue Type"
            value={formik.values.issueType}
            name="issueType"
            placeholder="Issue type"
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("issueType", e)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.issueType1}
                  //{...getTagProps({ index })}
                />
              ))
            }
            options={data.type}
            getOptionLabel={(e) => e.issueType1}
            isOptionEqualToValue={(option, value) => {
              return option.typeId === value.typeId;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.issueType1}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            name="priority"
            labelId="Business Priority"
            id="Business Priority"
            label="Business Priority"
            placeholder="Business Priority"
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("priority", e)}
            value={formik.values.priority}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.issuePriority1}
                  //{...getTagProps({ index })}
                />
              ))
            }
            options={data.priority}
            getOptionLabel={(e) => e.issuePriority1}
            isOptionEqualToValue={(option, value) => {
              return option.priorityId === value.priorityId;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.issuePriority1}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            name="issueField"
            labelId="Issue Field"
            id="Issue Field"
            label="Issue Field"
            placeholder="Issue Field"
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("issueField", e)}
            value={formik.values.issueField}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.issueField1}
                  //{...getTagProps({ index })}
                />
              ))
            }
            options={data.field}
            getOptionLabel={(e) => e.issueField1}
            isOptionEqualToValue={(option, value) => {
              return option.fieldId === value.fieldId;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.issueField1}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            name="requestTypeId"
            labelId="Request Type"
            id="Request Type"
            label="Issue/Request  Type"
            placeholder="Request Type"
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("requestTypeId", e)}
            value={formik.values.requestTypeId}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.requestTypeName}
                  //{...getTagProps({ index })}
                />
              ))
            }
            options={data.requestType}
            getOptionLabel={(e) => e.requestTypeName}
            isOptionEqualToValue={(option, value) => {
              return option.requestTypeId === value.requestTypeId;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.requestTypeName}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            name="issueComplexity"
            labelId="Scrapper Complexity"
            id="Scrapper Complexity"
            label="Scrapper Complexity"
            placeholder="Scrapper Complexity"
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("issueComplexity", e)}
            value={formik.values.issueComplexity}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option}
                  //{...getTagProps({ index })}
                />
              ))
            }
            options={data.complexity}
            getOptionLabel={(e) => e}
            isOptionEqualToValue={(option, value) => {
              return option === value;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Attachments
          </Typography>

          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={6} md={4}>
              <Radiobutton
                name="attachments"
                itemvalue="All"
                value={formik.values.attachments}
                onChange={(e) =>
                  formik.setFieldValue("attachments", e.target.value)
                }
                label="All"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                name="attachments"
                value={formik.values.attachments}
                onChange={(e) =>
                  formik.setFieldValue("attachments", e.target.value)
                }
                itemvalue="Yes"
                label="Yes"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                name="attachments"
                value={formik.values.attachments}
                onChange={(e) =>
                  formik.setFieldValue("attachments", e.target.value)
                }
                itemvalue="No"
                label="No"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: -1, mb: -1 }}>
          <Typography variant="subtitle2" gutterBottom>
            Rerun
          </Typography>

          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={6} md={4}>
              <Radiobutton
                name="rerun"
                value={formik.values.rerun}
                onChange={(e) => formik.setFieldValue("rerun", 2)}
                itemvalue={2}
                size="small"
                label="All"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                value={formik.values.rerun}
                onChange={(e) => formik.setFieldValue("rerun", 1)}
                itemvalue={1}
                label="Yes"
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <Radiobutton
                value={formik.values.rerun}
                onChange={(e) => formik.setFieldValue("rerun", 0)}
                itemvalue={0}
                label="No"
              />
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="Work Type"
            id="Work Type"
            name="workType"
            label="Work Type"
            value={formik.values.workType}
            placeholder="Work Type"
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("workType", e)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.workType1}
                  //{...getTagProps({ index })}
                />
              ))
            }
            options={data.worktype}
            getOptionLabel={(e) => e.workType1}
            isOptionEqualToValue={(option, value) => {
              return option.workTypeId === value.workTypeId;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.workType1}
              </li>
            )}
          />
        </Grid> */}
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="status"
            id="status"
            label="Status"
            name="status"
            placeholder="status"
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("status", e)}
            value={formik.values.status}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.status1}
                  //{...getTagProps({ index })}
                />
              ))
            }
            options={data.status}
            getOptionLabel={(e) => e.status1}
            isOptionEqualToValue={(option, value) => {
              return option.statusId === value.statusId;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.status1}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            id="Reported By"
            name="reportedBy"
            label="Reported By"
            placeholder="Reported By"
            value={formik.values.reportedBy}
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("reportedBy", e)}
            options={data.reportedBy}
            getOptionLabel={(e) => e.reportedBy1}
            isOptionEqualToValue={(option, value) => {
              return option.reportedById === value.reportedById;
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.reportedBy1}
                  // //{...getTagProps({ index })}
                />
              ))
            }
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.reportedBy1}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="addedby"
            id="addedby"
            label="Added By"
            name="addedBy"
            placeholder="Added By"
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("addedBy", e)}
            value={formik.values.addedBy}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.name}
                  //{...getTagProps({ index })}
                />
              ))
            }
            options={data.users}
            getOptionLabel={(e) => e.name}
            isOptionEqualToValue={(option, value) => {
              return option.name === value.name;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.name}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <EditPicker
            name="plannedEndDate"
            value={formik.values.planned}
            onChange={(e) => {
              if (e === null) {
                formik.setFieldValue("planned", e);
                // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
              } else {
                formik.setFieldValue("planned", e);
                formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"));
              }
            }}
            error={formik.touched.planned && Boolean(formik.errors.planned)}
            helperText={formik.touched.planned && formik.errors.planned}
            fullWidth
            label="Response Date"
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="Resolution Status"
            id="Resolution Status"
            label="Resolution Status"
            name="resolutionStatus"
            placeholder="Resolution Status"
            value={formik.values.resolutionStatus}
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("resolutionStatus", e)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.resolutionStatus1}
                  //{...getTagProps({ index })}
                />
              ))
            }
            options={data.resolutionstatus}
            getOptionLabel={(e) => e.resolutionStatus1}
            isOptionEqualToValue={(option, value) => {
              return option.resolutionId === value.resolutionId;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.resolutionStatus1}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <EditPicker
            name="resolutionDate"
            value={formik.values.resolution}
            onChange={(e) => {
              if (e === null) {
                formik.setFieldValue("resolution", e);
                // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
              } else {
                formik.setFieldValue("resolution", e);
                formik.setFieldValue("resolutionDate", e.format("YYYY-MM-DD"));
              }
            }}
            error={
              formik.touched.resolutionDate &&
              Boolean(formik.errors.resolutionDate)
            }
            helperText={
              formik.touched.resolutionDate && formik.errors.resolutionDate
            }
            fullWidth
            label="Resolution Date"
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="Resolved By"
            id="Resolved By"
            label="Assigned To"
            name="resolvedBy"
            placeholder="Assigned To"
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("resolvedBy", e)}
            value={formik.values.resolvedBy}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.name}
                  //{...getTagProps({ index })}
                />
              ))
            }
            options={
              role.toLowerCase().includes("merit")
                ? data.meritusers
                : data.users
            }
            getOptionLabel={(e) => e.name}
            isOptionEqualToValue={(option, value) => {
              return option.name === value.name;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.name}
              </li>
            )}
          />
        </Grid>
        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="FILTER"
                startIcon={<FilterListOutlinedIcon />}
                onClick={() => formik.handleSubmit()}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                onClick={() => {
                  reset();
                }}
                message="RESET"
                startIcon={<RestartAltOutlinedIcon />}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="CANCEL"
                startIcon={<EventBusyOutlinedIcon />}
                onClick={() => {
                  JSON.stringify(formik.initialValues) ===
                  JSON.stringify(formik.values)
                    ? props.close()
                    : setShow(true);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <AlertDialog
          open={show}
          onClose={() => setShow(false)}
          // open={isDeleted}
          title={"Confirmation"}
          // maxWidth={"sm"}
          description={`Do you want to discard the changes ?`}
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    reset();
                    props.close();
                    setShow(false);
                  }}
                  message="Yes"
                  startIcon={<DoneIcon />}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setShow(false);
                  }}
                  message="No"
                  startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
        {/* Action Area End Here */}
      </Grid>
    </Box>
  );
}

export default AdvanceFilters;
