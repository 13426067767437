import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    extremeDot: {
      background: "#700707",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 0 2px 0",
    },
    criticalDot: {
      background: "#ed5249",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 0 2px 0",
    },
    highDot: {
      background: "#F86800",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 0 2px 0",
    },
    mediumDot: {
      background: "#BABE00",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 0 2px 0",
    },
    lowDot: {
      background: "#73DAA8",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 0 2px 0",
    },
    passDot: {
      background: "#63DB5F",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 0 2px 0",
    },
    failDot: {
      background: "#FE6E51",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 0 2px 0",
    },
    blueDot: {
      background: "#B4C6E7 ",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 0 2px 0",
    },
    greenDot: {
      background: "#92D050 ",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 0 2px 0",
    },
    whiteDot: {
      background: "#FFFFFF ",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 0 2px 0",
    },
    yellowDot: {
      background: "#FFFF00 ",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 0 2px 0",
    },
    greyDot: {
      background: "#AEAAAA ",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 0 2px 0",
    },
  })
);

export default useStyles;
