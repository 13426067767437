import MaterialUIButton from "components/commonComponents/button/button";
import Textbox from "components/commonComponents/textbox/textbox";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  isValidEmailAddress,
  onlySting,
  Required,
} from "utils/validation helper";
import MultipleAutocomplete from "components/commonComponents/autocomplete/multipleAutocomplete";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocompletedropdown from "components/commonComponents/autocomplete/autocompletedropdown";
import DoneIcon from "@mui/icons-material/Done";
import AlertDialog from "components/commonComponents/alertDialog";
import useStyles from "./slaHolidays.styles";
import { useEffect } from "react";
import { getRoles } from "redux/rolemanagement/action";
import { fetchRoles, fetchRolesDropdown, getDevcomplexity, getPriority } from "redux/app/actions";
import { devcomplexityDropdown, priorityDropdown, rolesDropdown } from "redux/manageissues/action";

function AddSla(props) {
  const data = useSelector((state) => state.manageIssue);
  const select = useSelector((state) => state.userManagement);
  const name = localStorage.getItem("firstName");
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const id = useSelector((state) => state.project.sla);

  /******************************************************************************
  Function: validate
  Argument: values
  Return: errors
  Usage:
  1.To validate the form
  *******************************************************************************/

  const validate = (values) => {
    const errors = {};
    if (!Required(values.priorityId)) {
      errors.priorityId = true;
    }
    if (!Required(values.complexity)) {
      errors.complexity = true;
    }
    if (!Required(values.days)) {
      errors.days = true;
    } else if (values.days<0) {
      errors.days = "Invalid number";
    } 
    return errors;
  };
  useEffect(() => {
    dispatch(priorityDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getPriority([]));
        } else {
          dispatch(getPriority(res.data.result));
        }
      } else {
        dispatch(getPriority([]));
      }
    });
    dispatch(devcomplexityDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getDevcomplexity([]));
        } else dispatch(getDevcomplexity(res.data.result));
      } else {
        dispatch(getDevcomplexity([]));
      }
    });
  }, []);
  /******************************************************************************
  Function: reset
  Argument: 
  Return: 
  Usage:
  1.To reset the form
  *******************************************************************************/

  const reset = () => {
    if (formik) {
      formik.resetForm();
    }
  };
  const precomplexity = props.editSla && {
    id: props.editSla.complexityName
    ,
    label: props.editSla.complexityName
    ,
  };
  const prepriority = props.editSla && {
    id: props.editSla.priorityId,
    label: props.editSla.priorityName,
  };
  const formik = useFormik({
    initialValues: {
      id:props.action==="Edit"?props.editSla.id: 0,
      complexity:props.action==="Edit"?precomplexity: null,
      priorityId:props.action==="Edit"?prepriority: null,
      days:props.action==="Edit"?props.editSla.days: 0
    },
    validate,
    onSubmit: (values) => {
      console.log(values);
      // props.onChangeSave(true);
      props.addNewSla({
        id: values.id,
        complexityId:0,
        complexityName: values.complexity.label,
        priorityId: values.priorityId.id,
        priorityName:values.priorityId.label,
        days: values.days,
        
      });
    },
  });

  return (
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
              {props.action} SLA
              </Typography>
            </Grid>
            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                {props.action==="Edit"?<></>:
                <Grid item xs="auto">
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => reset()}
                    message="RESET"
                    startIcon={<RestartAltOutlinedIcon />}
                  />
                </Grid>}
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                          JSON.stringify(formik.values) &&
                        data.files.length === 0
                          ? props.close()
                          : setShow(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}
        <Grid item xs={12}>
          <Autocompletedropdown
            required
            onChange={(_, e, value) => {
              formik.setFieldValue("complexity", e);
            }}
            value={formik.values.complexity}
            id="complexity"
            name="complexity"
            label="Scrapper Complexity"
            error={
              formik.touched.complexity && Boolean(formik.errors.complexity)
            }
            helperText={formik.touched.complexity && formik.errors.complexity}
            options={data.developercomplexity.map((e) => {
              return {
                label: e,
                id: e,
              };
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocompletedropdown
            required
            value={formik.values.priorityId}
            onChange={(_, data) => formik.setFieldValue("priorityId", data)}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            labelId="Complexity Level"
            id="Complexity Level"
            name="priorityId"
            error={
              formik.touched.priorityId && Boolean(formik.errors.priorityId)
            }
            helperText={formik.touched.priorityId && formik.errors.priorityId}
            label="Business Priority"
            options={data.priority.map((e) => {
              return {
                label: e.issuePriority1,
                id: e.priorityId,
              };
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Textbox
            required
            size="small"
            label="Days"
            fullWidth
            name="days"
            type="number"
            value={formik.values.days}
            onChange={(data) => {
              formik.setFieldValue("days", data.target.value);
            }}
            error={formik.touched.days && Boolean(formik.errors.days)}
            helperText={formik.touched.days && formik.errors.days}
          />
        </Grid>

        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="SAVE"
                disabled={props.save}
                startIcon={<SaveOutlinedIcon />}
                onClick={() => formik.handleSubmit()}
              />
            </Grid>
            {props.action==="Edit"?<></>:
                <Grid item xs="auto">
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => reset()}
                    message="RESET"
                    startIcon={<RestartAltOutlinedIcon />}
                  />
                </Grid>}
           
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="CANCEL"
                startIcon={<EventBusyOutlinedIcon />}
                onClick={() => {
                  JSON.stringify(formik.initialValues) ===
                  JSON.stringify(formik.values)
                    ? props.close()
                    : setShow(true);
                }}
              />
            </Grid>
          </Grid>
          <AlertDialog
            open={show}
            onClose={() => setShow(false)}
            // open={isDeleted}
            title={"Confirmation"}
            // maxWidth={"sm"}
            description={`Do you want to discard the changes ?`}
            action={
              <Grid
                container
                alignItems="center"
                justifyContent="right"
                spacing={2}
                className={classes.alertDialogbtn}
              >
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      reset();

                      props.close();
                      setShow(false);
                    }}
                    message="Yes"
                    startIcon={<DoneIcon />}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      setShow(false);
                    }}
                    message="No"
                    startIcon={<CloseIcon />}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
        {/* Action Area End Here */}
      </Grid>
    </Box>
  );
}

export default AddSla;
