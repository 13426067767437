import moment from "moment";
import MultiFloatFilter from "./MultiFloatFilter";
import MultiFloatFilterDetail from "./MultiFloatFilterDetail";

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = moment(cellValue).format("DD-MM-YYYY");
    const dateParts = dateAsString.split("-");
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  inRangeInclusive: true,
  browserDatePicker: true,
};

export const headers = [
  {
    headerName: "Date",
    field: "date",
    filter: MultiFloatFilter,
    filterParams: "date",
    lockPosition: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    sortable: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatDate",
    },
    width: 200,
    minWidth: 200,
  },

  {
    headerName: "Country",
    field: "country",
    filter: MultiFloatFilter,
    filterParams: "country",
    lockPosition: "left",
    width: 150,
    minWidth: 150,
    sortable: true,
  },
  {
    headerName: "Make",
    field: "make",
    filter: MultiFloatFilter,
    filterParams: "make",
    lockPosition: "left",
    width: 110,
    minWidth: 110,
    sortable: true,
  },
  {
    headerName: "Total Scrapers",
    field: "total_Scrapers",
    filter: MultiFloatFilter,
    filterParams: "total_Scrapers",
    lockPosition: "left",
    wrapText: true,
    autoHeight: true,
    width: 170,
    minWidth: 170,
    sortable: true,
    cellClass: "trimText",
  },

  {
    headerName: "Total Scrapers Successful",
    field: "total_Scrapers_Successful",
    filter: MultiFloatFilter,
    filterParams: "total_Scrapers_Successful",
    lockPosition: "left",
    width: 240,
    minWidth: 240,
    sortable: true,
  },

  {
    headerName: "Total Scrapers Failed",
    field: "total_Scrapers_Failed",
    filter: MultiFloatFilter,
    filterParams: "total_Scrapers_Failed",
    lockPosition: "left",

    width: 220,
    minWidth: 220,
    lockPosition: "left",
    sortable: true,
  },
  {
    headerName: "Proxy Issue Failure",
    field: "proxy_Issue",
    width: 200,
    minWidth: 200,
    filter: MultiFloatFilter,
    filterParams: "proxy_Issue",
    lockPosition: "left",
    sortable: true,
  },
  {
    headerName: "Site Down Failure",
    field: "site_Down",
    filter: MultiFloatFilter,
    filterParams: "site_Down",
    lockPosition: "left",
    width: 180,
    minWidth: 180,
    sortable: true,
  },
];
export const Detailedheaders = [
  {
    headerName: "Date",
    field: "date",
    filter: MultiFloatFilterDetail,
    filterParams: "date",
    lockPosition: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatDate",
    },
    width: 200,
    minWidth: 200,
    sortable: true,
  },

  {
    headerName: "Country",
    field: "country",
    filter: MultiFloatFilterDetail,
    filterParams: "country",
    lockPosition: "left",
    width: 150,
    minWidth: 150,
    sortable: true,
  },
  {
    headerName: "Total Makes",
    field: "total_Makes",
    filter: MultiFloatFilterDetail,
    filterParams: "total_Makes",
    lockPosition: "left",
    width: 180,
    minWidth: 180,
    sortable: true,
  },
  {
    headerName: "Total Models",
    field: "total_Models",
    filter: MultiFloatFilterDetail,
    filterParams: "total_Models",
    lockPosition: "left",
    width: 180,
    minWidth: 180,
    sortable: true,
  },

  {
    headerName: "Total  Makes Successful",
    field: "total_Makes_SuccessFul",
    filter: MultiFloatFilterDetail,
    filterParams: "total_Makes_SuccessFul",
    lockPosition: "left",
    width: 220,
    minWidth: 220,
    sortable: true,
  },
  {
    headerName: "Total Models Successful",
    field: "total_Models_SuccessFul",
    filter: MultiFloatFilterDetail,
    filterParams: "total_Models_SuccessFul",
    lockPosition: "left",
    width: 240,
    minWidth: 240,
    sortable: true,
  },
  {
    headerName: "Total Makes Failed",
    field: "total_Makes_Failed",
    filter: MultiFloatFilterDetail,
    filterParams: "total_Makes_Failed",
    lockPosition: "left",
    width: 190,
    minWidth: 190,
    sortable: true,
  },
  {
    headerName: "Total Models Failed",
    field: "total_Models_Failed",
    filter: MultiFloatFilterDetail,
    filterParams: "total_Models_Failed",
    lockPosition: "left",
    width: 190,
    minWidth: 190,
    sortable: true,
  },
  {
    headerName: "Successful Make Percentage(%) ",
    field: "successful_Make_Percentage",
    filter: MultiFloatFilterDetail,
    filterParams: "successful_Make_Percentage",
    lockPosition: "left",
    width: 280,
    minWidth: 280,
    sortable: true,
  },
  {
    headerName: "Successful Model Percentage(%)",
    field: "successful_Model_Percentage",
    filter: MultiFloatFilterDetail,
    filterParams: "successful_Model_Percentage",
    lockPosition: "left",
    width: 280,
    minWidth: 280,
    sortable: true,
  },
  {
    headerName: "Failed Makes Percentage(%)",
    field: "failed_Make_Percentage",
    filter: MultiFloatFilterDetail,
    filterParams: "failed_Make_Percentage",
    lockPosition: "left",
    width: 260,
    minWidth: 260,
    sortable: true,
  },
  {
    headerName: "Failed Model Percentage(%)",
    field: "failed_Model_Percentage",
    filter: MultiFloatFilterDetail,
    filterParams: "failed_Model_Percentage",
    lockPosition: "left",
    width: 260,
    minWidth: 260,
    sortable: true,
  },
];
