var baseApiUrl = process.env.REACT_APP_MANAGE_ISSUE_BASE_URL;
const baseurl = baseApiUrl + "api";
const baseurlforpricepoint = baseurl + "/v1/dashboard";
export const BASE_PRICE_POINT_URL = baseurlforpricepoint;
export const DASHBOARD_PRICE_POINT_CHANGE_URL =
  baseurlforpricepoint + "/getsourceurlwithpricepointchangeviewmodel";
export const PRICE_POINT_CHANGE_EXPORT_URL =
  baseurlforpricepoint + "/exportgetsourceurlwithpricepointchange";
export const PRICE_POINT_CHANGE_URL =
  baseurlforpricepoint + "/getpricepointchange";
export const SOURCE_URL_WITH_NO_RECORD =
  baseurlforpricepoint + "/getsourceurlwithnorecord";
export const SCRAPER_DETAILS = baseurlforpricepoint + "/getscraperdetails";
export const PIE_DETAILS = baseurlforpricepoint + "/getscopescrapedmodels";
