import { Tooltip } from "@mui/material";
import MultiFloatFilter from "./MultiFloatFilter";
// Custom cell renderer function to display projectName inside roleDescription
function customProjectNameCellRenderer(params) {
  const projectDetails = params.data.projectDetails;
  if (projectDetails && projectDetails.length > 0) {
    // Assuming that each role has only one project, you can customize this logic as needed
    const projectName = projectDetails.map((e) => e.projectName);
    return projectName.toString();
  }
  // if (params.data) {
  //   return params.data

  // }
  else {
    return "";
  }
}
const projectname = (str) => {
  console.log();
  return str?.length > 0
    ? str.length > 1
      ? str.toString().substring(0, 60) + "..."
      : str.toString()
    : "";
};
function customProjectNameCellRendererd(params) {
  const projectDetails = params.data.projectDetails;
  if (projectDetails && projectDetails.length > 0) {
    // Assuming that each role has only one project, you can customize this logic as needed
    const projectName = projectDetails.map((e) => e.projectName);
    return (
      <Tooltip title={projectName.toString()} placement="top" arrow>
        <span>{projectname(projectName)}</span>
      </Tooltip>
    );
  }
  // if (params.data) {
  //   return params.data

  // }
  else {
    return "";
  }
}
// Define the column configuration with the custom cell renderer and sorting function
const projectNameColumn = {
  headerName: "Project Name",
  field: "projectDetails",
  filter: MultiFloatFilter,
  filterParams: "projectDetails",
  lockPosition: "left",
  sortable: true,
  valueGetter: (params) => {
    const projectDetails = params.data.projectDetails;
    if (projectDetails && projectDetails.length > 0) {
      const projectName = projectDetails.map((e) => e.projectName);
      return projectName.toString();
    }
    return ""; // Handle empty data
  },

  cellRenderer: customProjectNameCellRendererd, // Use the custom cell renderer
  cellRendererParams: {
    actionType: "formatprojectname",
  },
  minWidth:500,
  // maxWidth: 500,
  //   comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
  //     console.log(isInverted);
  //     // log
  //     // console.log(valueA,valueB,nodeA,nodeB,isInverted);
  //     // const b=nodeB.data.projectDetails.map((e) => e.projectName).toString()
  // // const a =nodeA.data.projectDetails.map((e) => e.projectName).toString()
  //     // Custom comparator function to sort based on projectName
  //     const projectNameA =
  //      customProjectNameCellRenderer({
  //       data: nodeA.data,
  //     });
  //     const projectNameB = customProjectNameCellRenderer({
  //       data: nodeB.data,
  //     });
  //     const comparisonResult = projectNameA.localeCompare(projectNameB);

  //     return isInverted ? -comparisonResult : comparisonResult;
  //   },
};

// Include the projectNameColumn in your headers array
const headers1 = [
  // ... other columns
];

export const headers = [
  {
    headerName: "Role Name",
    field: "roleName",
    filter: MultiFloatFilter,
    filterParams: "roleName",
    lockPosition: "left",
    sortable: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatrolename",
    },
    minWidth: 150,
    // maxWidth: 220,
  },
  projectNameColumn,

  {
    headerName: "Description",
    field: "roleDescription",
    filter: MultiFloatFilter,
    filterParams: "roleDescription",
    lockPosition: "left",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatdescp",
    },
    minWidth: 600,
    // maxWidth: 800,
    sortable: true,
  },

  {
    headerName: "Action",
    lockPosition: "right",
    field: "",
    cellRenderer: "iconCellRenderer",
    cellRendererParams: {
      actionType: "role",
    },
    minWidth: 100,
    maxWidth: 100,
    cellClass: ["ag-cell-align-center", "locked-col"],
    // cellStyle: { color: 'red', 'background-color': 'green' }
    width: 100,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        "  </div>" +
        "</div>",
    },
  },
];
