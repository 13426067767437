import { AppActionsTypes } from "./actions-types";

const INITIAL_STATE = {
  all: 0,
  manageissue: 0,
  managescope: 0,
  managescraper: 0,
  managesource: 0,
  dashboard: 0,
  quality: 0,
  reports: 0,
  user: 0,
  role: 0,
  sla:0,
  manageissueprojectname: "",
  managequalityprojectname: "",
  managescraperprojectname: "",
};
const projectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppActionsTypes.PROJECT_NAME:
      return {
        ...state,
        manageissueprojectname: action.payload,
      };
      case AppActionsTypes.PROJECT_NAME_SLA:
      return {
        ...state,
        sla: action.payload,
      };
    case AppActionsTypes.PROJECT_NAME_SCRAPER:
      return {
        ...state,
        managescraperprojectname: action.payload,
      };
    case AppActionsTypes.PROJECT_NAME_QUALITY:
      return {
        ...state,
        managequalityprojectname: action.payload,
      };
    case AppActionsTypes.ISSUE:
      return {
        ...state,
        manageissue: action.payload,
      };
    case AppActionsTypes.SCOPE:
      return {
        ...state,
        managescope: action.payload,
      };
    case AppActionsTypes.SOURCE:
      return {
        ...state,
        managesource: action.payload,
      };
    case AppActionsTypes.SCRAPER:
      return {
        ...state,
        managescraper: action.payload,
      };
    case AppActionsTypes.ALL:
      return {
        ...state,
        all: action.payload,
      };
    case AppActionsTypes.DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
    case AppActionsTypes.REPORTS:
      return {
        ...state,
        reports: action.payload,
      };
    case AppActionsTypes.USER:
      return {
        ...state,
        user: action.payload,
      };
    case AppActionsTypes.ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case AppActionsTypes.QUALITY:
      return {
        ...state,
        quality: action.payload,
      };
    default:
      return state;
  }
};

export default projectReducer;
