import * as React from "react";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import { BsSearch } from "react-icons/bs";
import InputAdornment from "@mui/material/InputAdornment";
import Textbox from "../textbox/textbox";
import useStyles from "./gridnav.styles";

function Gridnav(props) {
  const classes = useStyles();
  console.log(props.data);
  return (
    <>
      <Textbox
        className={classes.searchBy}
        placeholder="Search By Keyword"
        label={props.label}
        InputProps={props.InputProps}
        value={props.data}
        onChange={props.onChange}
        error={props.error}
        helperText={props.helperText}
        id="search"
        name="search"
        start={<InputAdornment position="start">
           
              <BsSearch />
            
          </InputAdornment>}
      />
    </>
  );
}

export default Gridnav;
