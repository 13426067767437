import React, { useState, useRef, useEffect } from "react";
import useStyles from "./dragDrop.style";
import { pdfIcon, remove } from "../../../assets/index";
// import { BulkUploadIcon } from "@fluentui/react-icons-mdl2";
import { Box, Chip, FormControl, Typography } from "@material-ui/core";
import { upload } from "../../../assets/index";
import DoneIcon from "@mui/icons-material/Done";
import * as XLSX from "xlsx/xlsx";
import CloseIcon from "@mui/icons-material/Close";
import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { MAX_COUNT } from "constants/constants";
import { formatBytes } from "utils/helper";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { errorMessage, saveFiles } from "redux/app/actions";
import AlertDialog from "../alertDialog";
import MaterialUIButton from "../button/button";

const Dropzone = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const filesSelected = useSelector((state) => state.manageIssue.files);
  const [message, setMessage] = useState("");
  const [property, setProperty] = useState(false);
  const [items, setItems] = useState([]);
  const [messageDesc, setMessageDesc] = useState("");
  const [showRestrictAdding, setShowRestrictAdding] = useState(false);
  const [hightlight, setHightlight] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [filesArray, setFileArray] = useState(false);
  const [error, setError] = useState(props.isFileCleared && false);
  const [MissingPopUp, setMissingPopUp] = useState(false);
  const Invalid = [];
  const validationErrors = [];
  const fileInputRef = useRef();
  const valid = (value) => {
    return value / 1200;
  };
  const validMB = (value) => {
    return value / 1000000;
  };

  useEffect(() => {
    dispatch(saveFiles([]));
    if (props.isFileCleared) {
      dispatch(saveFiles([]));

      setError(false);
    }
  }, [props.isFileCleared]);

  const classes = useStyles();
  const openFileDialog = () => {
    if (props.onBulk) {
      if (filesSelected < 1) {
        if (isLoading) return;

        fileInputRef.current.click();
        setLoading(true);
        window.addEventListener("mousemove", handleProgess);
      }
    } else {
      if (isLoading) return;

      fileInputRef.current.click();
      setLoading(true);
      window.addEventListener("mousemove", handleProgess);
    }
  };

  const handleProgess = () => {
    if (filesSelected.length > 0) {
      setLoading(false);
    } else {
      setLoading(false);
    }
    window.removeEventListener("mousemove", handleProgess);
  };
  var missingHeaders = [];
  const HEADERS_MAP = new Map();
  HEADERS_MAP.set(0, "Country");
  HEADERS_MAP.set(1, "Make");
  HEADERS_MAP.set(2, "Model");
  HEADERS_MAP.set(3, "Source URL");
  HEADERS_MAP.set(4, "Business Priority");
  HEADERS_MAP.set(5, "URL Status");
  HEADERS_MAP.set(6, "Source Received date");
  HEADERS_MAP.set(7, "Comments");
  HEADERS_MAP.set(8, "Scrapper Complexity");

  const HEADERS = new Map();
  HEADERS.set(0, "country");
  HEADERS.set(1, "make");
  HEADERS.set(2, "model");
  HEADERS.set(3, "business priority");
  HEADERS.set(4, "month");
  
  const validateMissingHeaders = (
    sheet,
    sheetname,
    fileName,
    file,
    evt,
    callback
  ) => {
    var missingHeaders = [];
    var MissingRow;

    if (props.scope) {
      HEADERS.forEach((value, index) => {
        var cell = sheet[XLSX.utils.encode_cell({ c: index, r: 0 })];

        var header = "";
        if (cell && cell.h) {
          header = cell.h;

          if (value !== header.toLowerCase()) {
            missingHeaders.push(value);
          }
        } else {
          missingHeaders.push(value);
        }
        if (sheet.A2 === undefined || sheet.A2 === null) {
          MissingRow = true;
        }
      });
    } else {
      HEADERS_MAP.forEach((value, index) => {
        var cell = sheet[XLSX.utils.encode_cell({ c: index, r: 0 })];

        var header = "";
        if (cell && cell.h) {
          header = cell.h;
          if (value !== header) {
            missingHeaders.push(value);
          }
        } else {
          missingHeaders.push(value);
        }
        if (sheet.A2 === undefined || sheet.A2 === null) {
          MissingRow = true;
        }
      });
    }

    if (missingHeaders.length > 0) {
      setMessageDesc(
        "Missing Headers in   " +
          fileName +
          "  are  " +
          missingHeaders.toString()
      );

      setShowValidation(true);
    } else if (MissingRow) {
      setMissingPopUp(true);
    } else {
      const array = fileListToArray(file);
      dispatch(saveFiles(array));
      props.onFilesAdded(evt, [filesSelected, array]);
      setLoading(false);
      setShowValidation(false);
    }
  };
  const onFilesAdded = async (evt) => {
    const files = evt.target.files;
    const file = files[0];
    if (
      props.onFilesAdded &&
      props.onBulk &&
      (file.name.split(".")[file.name.split(".").length - 1].toLowerCase() ===
        "xlsx" ||
        file.name.split(".")[file.name.split(".").length - 1].toLowerCase() ===
          "xls")
    ) {
      if (filesArray === true) {
        setShowRestrictAdding(true);
      } else {
        //for Multi Upload
        for (var i = 0; i < files.length; i++) {
          // eslint-disable-next-line no-loop-func
          (function (file) {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            const fileNameValue = file.name;
            fileReader.onload = function (e) {
              const bufferArray = e.target.result;
              const wb = XLSX.read(bufferArray, { type: "buffer" });
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];

              validateMissingHeaders(
                ws,
                wsname,
                fileNameValue,
                file,
                evt,
                function (res) {
                  if (!res) {
                  }
                }
              );
            };
          })(files[i]);
        }
      }
      // if(response){
      //   const array = fileListToArray(files);
      //   dispatch(saveFiles(array));
      //   props.onFilesAdded(evt, [filesSelected, array]);
      //   setLoading(false);
      //   }
    } else if (props.onFilesAdded) {
      const array = fileListToArray(files);
      dispatch(saveFiles(array));
      props.onFilesAdded(evt, [filesSelected, array]);
      setLoading(false);
    }

    // window.removeEventListener("mousemove", handleProgess);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setHightlight(true);
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    setHightlight(false);
  };

  const onDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;

    const file = files[0];
    if (
      props.onFilesAdded &&
      props.onBulk &&
      (file.name.split(".")[file.name.split(".").length - 1].toLowerCase() ===
        "xlsx" ||
        file.name.split(".")[file.name.split(".").length - 1].toLowerCase() ===
          "xls")
    ) {
      if (filesArray === true) {
        setShowRestrictAdding(true);
      } else {
        //for Multi Upload
        for (var i = 0; i < files.length; i++) {
          // eslint-disable-next-line no-loop-func
          (function (file) {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            const fileNameValue = file.name;
            fileReader.onload = function (e) {
              const bufferArray = e.target.result;
              const wb = XLSX.read(bufferArray, { type: "buffer" });
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];

              validateMissingHeaders(
                ws,
                wsname,
                fileNameValue,
                file,
                event,
                function (res) {
                  if (!res) {
                  }
                }
              );
            };
          })(files[i]);
        }
      }
      // if(response){
      //   const array = fileListToArray(files);
      //   dispatch(saveFiles(array));
      //   props.onFilesAdded(evt, [filesSelected, array]);
      //   setLoading(false);
      //   }
    } else if (props.onFilesAdded) {
      const array = fileListToArray(files);
      dispatch(saveFiles(array));
      props.onFilesAdded(event, [filesSelected, array]);
      setLoading(false);
    }
    setHightlight(false);
  };

  const fileListToArray = (files) => {
    const uploaded = filesSelected ? filesSelected : [];

    var count = 0;
    var errors = 0;
    var sizeVal = 0;
    if (props.onBulk) {
      const file = files[0] !== undefined ? files[0] : files;

      if (
        file.name.split(".")[file.name.split(".").length - 1].toLowerCase() ===
          ("xlsx" || "XLSX") ||
        file.name.split(".")[file.name.split(".").length - 1].toLowerCase() ===
          ("xls" || "XLS")
      ) {
        if (file.size > 524288) {
          // setShow(true)

          Invalid.push(file.name);
          // toast.error(`${files[i].name} is more than 512 KB`);
        } else {
          if (uploaded.findIndex((f) => f.name === file.name) === -1) {
            if (filesSelected.length === 0) {
              uploaded.push(file);
            } else {
              toast.error("Only one file can be uploaded at a time");
            }
          } else {
            toast.error(`${file.name} is already uploaded`);
          }
        }
      } else {
        errors = errors + 1;
      }
      for (let index = 0; index < uploaded.length; index++) {
        sizeVal = sizeVal + uploaded[index].size;
      }
      if (uploaded.length > MAX_COUNT) {
        toast.error("Maximum 10 files with maximum 5 MB as file size");
        return [];
      }
      if (Invalid.length > 0) {
        setMessage(Invalid.join());

        setShow(true);
      }

      if (errors > 0) {
        toast.error(
          "Invalid file format, Please upload the file in this format (XLSX)"
        );
        return uploaded;
      } else {
        return uploaded;
      }
    } else {
      for (let i = 0; i < files.length; i++) {
        if (
          files[i].name
            .split(".")
            [files[i].name.split(".").length - 1].toLowerCase() === "pdf" ||
          files[i].name
            .split(".")
            [files[i].name.split(".").length - 1].toLowerCase() === "png" ||
          files[i].name
            .split(".")
            [files[i].name.split(".").length - 1].toLowerCase() === "jpg" ||
          files[i].name
            .split(".")
            [files[i].name.split(".").length - 1].toLowerCase() === "jpeg" ||
          files[i].name
            .split(".")
            [files[i].name.split(".").length - 1].toLowerCase() === "ppt" ||
          files[i].name
            .split(".")
            [files[i].name.split(".").length - 1].toLowerCase() === "pptx" ||
          files[i].name
            .split(".")
            [files[i].name.split(".").length - 1].toLowerCase() === "doc" ||
          files[i].name
            .split(".")
            [files[i].name.split(".").length - 1].toLowerCase() === "docx" ||
          files[i].name
            .split(".")
            [files[i].name.split(".").length - 1].toLowerCase() === "xls" ||
          files[i].name
            .split(".")
            [files[i].name.split(".").length - 1].toLowerCase() === "xlsx"
        ) {
          if (files.length > MAX_COUNT) {
            count = count + 1;
          } else if (files[i].size > 1048576) {
            setShow(true);

            Invalid.push(files[i].name);
            // toast.error(`${files[i].name} is more than 512 KB`);
          } else {
            if (uploaded.length < MAX_COUNT) {
              if (uploaded.findIndex((f) => f.name === files[i].name) === -1) {
                uploaded.push(files.item(i));
              } else {
                toast.error(`${files[i].name} is already uploaded`);
              }
            } else {
              count = count + 1;
            }
          }
        } else {
          errors = errors + 1;
        }
      }
      for (let index = 0; index < uploaded.length; index++) {
        sizeVal = sizeVal + uploaded[index].size;
      }
      if (count > 0) {
        toast.error("Maximum 10 files with maximum 5 MB as file size");
      }

      if (Invalid.length > 0) {
        setMessage(Invalid.join());

        setShow(true);
      }

      if (errors > 0) {
        toast.error(
          "Invalid file format, Please upload the file in this format (JPEG, JPG, PNG, PDF,DOC,DOCX,XLSX,XLS,PPT,PPTX)"
        );
        return uploaded;
      } else {
        return uploaded;
      }
    }
  };

  const handleDelete = (event) => {
    setFileArray(false);
    dispatch(errorMessage({}));
    dispatch(saveFiles(filesSelected.filter((e) => e.name !== event)));

    if (props.onFilesAdded) {
      props.onFilesAdded(event, filesSelected);
    }
  };

  const truncateFileName = (text) => {
    if (text && text.length <= 20) return text;
    if (text) {
      var string,
        filename = "",
        splitArr = text && text.split("."),
        extension = "." + splitArr[splitArr.length - 1];
      for (var i = 0; i < splitArr.length - 1; i++) {
        filename += splitArr[i];
      }

      string =
        filename.substring(0, 7) +
        "..." +
        filename.substring(filename.length - 7, filename.length) +
        extension;
      return string;
    }
  };

  return (
    <>
      <FormControl
        fullWidth
        // margin={props.margin}
      >
        <div
          className={`${classes.Dropzone} ${
            hightlight ? classes.Highlight : ""
          }`}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          onClick={openFileDialog}
          style={{ cursor: props.disabled ? "default" : "pointer" }}
        >
          <Box className={classes.DropzoneInnerWrap}>
            {props.onBulk ? (
              <input
                value={""}
                ref={fileInputRef}
                className={classes.visuallyHidden}
                type="file"
                accept=".xlsx,.xls,"
                onChange={(event) => onFilesAdded(event)}
              />
            ) : (
              <input
                value={""}
                ref={fileInputRef}
                className={classes.visuallyHidden}
                type="file"
                accept="
               .pdf, 
               .png,
               .jpg,
               .jpeg,
               .ppt,
               .pptx,
               .xsl,
               .xslx,
               .doc,.docx
               "
                multiple
                onChange={(event) => onFilesAdded(event)}
              />
            )}

            <img src={upload} width="32" alt="JATO Dynamics" />
            {props.onBulk ? (
              <Typography
                variant="caption"
                component="p"
                align="center"
                style={{ fontsize: "11px", marginBottom: 0, marginTop: "10px" }}
              >
                Drag and Drop or <span>browse</span> files to upload attachments
                in this format XLSX
              </Typography>
            ) : (
              <Typography
                variant="caption"
                component="p"
                align="center"
                style={{ fontsize: "11px", marginBottom: 0, marginTop: "10px" }}
              >
                Drag and Drop or <span>browse</span> files to upload attachments
                in this format (JPEG, JPG, PNG, PDF,DOC,DOCX,XLSX,XLS,PPT,PPTX)
              </Typography>
            )}
          </Box>
          {/* {isLoading && <LinearProgress />} */}
        </div>
        {!error &&
          filesSelected &&
          filesSelected.length > 0 &&
          props.showSelectedFile && (
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                gutterBottom
                style={{ marginTop: "12px", marginBottom: "5px" }}
              >
                Uploaded File(s)
              </Typography>

              <List className={classes.uploadFiles}>
                {!error &&
                  !showValidation &&
                  filesSelected.length > 0 &&
                  props.showSelectedFile &&
                  filesSelected.map((e) => (
                    <ListItem
                      className={
                        filesSelected.length > 0 &&
                        filesSelected[filesSelected.length - 1]
                          ? classes.listItemLastStyle
                          : classes.listItemStyle
                      }
                      //
                      secondaryAction={
                        <IconButton
                          onClick={() => handleDelete(e.name)}
                          edge="end"
                          aria-label="delete"
                        >
                          <img src={remove} width="20" alt="pdf" />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <img src={pdfIcon} width="24" alt="pdf" />
                      </ListItemAvatar>
                      <ListItemText primary={`${truncateFileName(e.name)}`} />
                    </ListItem>
                  ))}
              </List>
            </Grid>
          )}

        <AlertDialog
          open={show}
          onClose={() => setShow(false)}
          title={"Warning"}
          description={message + " " +props.onBulk?"are more than 512 KB" :"are more than 1 MB"}
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setShow(false);
                  }}
                  message="Ok"
                  // startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
        <AlertDialog
          open={showValidation}
          onClose={() => setShowValidation(false)}
          title={"Warning"}
          description={messageDesc}
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setShowValidation(false);
                  }}
                  message="Ok"
                  // startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
        <AlertDialog
          open={showRestrictAdding}
          onClose={() => setShowRestrictAdding(false)}
          title={"Warning"}
          description={"Only one file can be uploaded at a time"}
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setShowRestrictAdding(false);
                  }}
                  message="Ok"
                  // startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
        <AlertDialog
          open={MissingPopUp}
          onClose={() => setMissingPopUp(false)}
          title={"Warning"}
          description={
            "Data not available, Please upload a file with correct data"
          }
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="ojutlined"
                  onClick={() => {
                    setMissingPopUp(false);
                  }}
                  message="Ok"
                  // startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
      </FormControl>
    </>
  );
};

export default Dropzone;
