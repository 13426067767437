import Navbar from "components/commonComponents/navbar/navbar";
import Sidebar from "components/commonComponents/navbar/sidebar";

import Login from "components/pages/auth/login/login";
import ForgotPassword from "components/pages/auth/forgotpassword/forgotPassword";

import DashboardAndReports from "components/pages/main/dashboardReports/dashboardAndReports";
import PricePointChanges from "components/pages/main/dashboardReports/pricePointChanges/pricePointChanges";
import SourceUrlNoRecords from "components/pages/main/dashboardReports/sourceUrlWithNoRecords/sourceUrlWithNoRecords";
import CountryWiseStatus from "components/pages/main/dashboardReports/stackeBarChart/countryWiseStatus";
import ManageIssues from "components/pages/main/manageissues/manageIssues";
import ManageModelScope from "components/pages/main/managemodelscope/manageModelScope";
import ManageScraper from "components/pages/main/managescraper/manageScraper";
import ManageSources from "components/pages/main/managesources/manageSources";
import ManageQuality from "components/pages/main/managequality/manageQuality";
import RoleManagement from "components/pages/main/rolemanagement/roleManagement";
import UserManagement from "components/pages/main/usermanagement/userManagement";

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./layout.scss";
import ProtectedRoute from "./protectedRoutes";
import ResetPassword from "components/pages/auth/resetpassword/resetPassword";
import ChangePassword from "components/pages/auth/changePassword/changePassword";
import LoginRoute from "./loginRoutes";
import { useDispatch } from "react-redux";
import { login } from "redux/usermanagement/action";
import Unauthorized from "components/pages/auth/unauthorized/unauthorized";
import Delivered from "components/pages/main/dashboardReports/deliveredfileanddatacount/delivered";
import Reports from "components/pages/main/reports/reports";
import SLAHolidays from "components/pages/main/slaholidays/slaHolidays";

function AppRoutes() {
  const user = localStorage.getItem("token");
  const dispatch = useDispatch();
  return (
    <section
      className="d-flex h-100"
      style={{ background: "#F8F5FF", height: "100vh", overflow: "auto" }}
    >
      <Sidebar />
      <main className="mainContainer">
        <Navbar />
        <Routes>
          <Route path="/" element={<></>} />
          <Route path="/error" element={<Login />} />

          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/projectselection" element={<Unauthorized />} />

          {/* <Route path="/" element={<Login />} /> */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />

          <Route
            path="/dashboard-and-reports"
            element={
              <ProtectedRoute>
                <DashboardAndReports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard-and-reports/country-wise-status"
            element={
              <ProtectedRoute>
                <CountryWiseStatus />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard-and-reports/region-wise-status"
            element={
              <ProtectedRoute>
                <CountryWiseStatus />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <ProtectedRoute>
                <Reports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard-and-reports/source-URLs-with-price-point-changes"
            element={
              <ProtectedRoute>
                <PricePointChanges />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard-and-reports/source-URLs-with-no-records"
            element={
              <ProtectedRoute>
                <SourceUrlNoRecords />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard-and-reports/delivered-file-and-data-count"
            element={
              <ProtectedRoute>
                <Delivered />
              </ProtectedRoute>
            }
          />

          <Route
            path="/manage-issue-/-request"
            element={
              <ProtectedRoute>
                <ManageIssues />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-model-scope"
            element={
              <ProtectedRoute>
                <ManageModelScope />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-scraper"
            element={
              <ProtectedRoute>
                <ManageScraper />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-sources"
            element={
              <ProtectedRoute>
                <ManageSources />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-quality"
            element={
              <ProtectedRoute>
                <ManageQuality />
              </ProtectedRoute>
            }
          />
          <Route
            path="/role-management"
            element={
              <ProtectedRoute>
                <RoleManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-management"
            element={
              <ProtectedRoute>
                <UserManagement />
              </ProtectedRoute>
            }
          />
          <Route
             path="/sla-and-holidays"
            element={
              <ProtectedRoute>
              <SLAHolidays />
              </ProtectedRoute>
            }
          />
        </Routes>
      </main>
    </section>
  );
}

export default AppRoutes;
