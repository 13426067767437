import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  DialogActions,
} from "@mui/material";
import {
  Close,
  Done,
  FilterListOutlined,
  FileDownloadOutlined,
  RestartAltOutlined,
  SlowMotionVideoOutlined,
} from "@mui/icons-material";
import { toast } from "react-toastify";

import BaseLayout from "components/commonComponents/baseLayout/index";
import Spinner from "components/commonComponents/spinner/spinner";
import Gridnav from "components/commonComponents/gridnav/gridnav";
import MaterialUIButton from "components/commonComponents/button/button";
import AgGrid, {
  scrambleAndRefreshAll,
} from "components/commonComponents/grid/agGrid";
import UsePagination from "components/commonComponents/pagination/pagination";
import AlertDialog from "components/commonComponents/alertDialog";
import { deleteIssue, getManageIssue } from "redux/manageissues/action";

import useStyles from "./manageQuality.styles";

import { rowData, columnDefs, headers, headers1 } from "./Data";
import MaterialUIModal from "components/commonComponents/modal/modal";
import AdvanceFilters from "./advanceFilters";
import {
  deleteQuality,
  getAllManageQuality,
  getAllManageQualityexport,
} from "redux/managequality/action";
import { getmanagequality, saveqcFilter } from "redux/app/actions";
import moment from "moment";
import { utils } from "xlsx/xlsx";
import { writeFile } from "xlsx";
import axios from "axios";
import { MANAGE_DOWNLOAD } from "apiConfig/managequality";

const ManageQuality = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const gridApi = useRef(null);
  const filterData = useSelector((state) => state.managequality.filter);
  const select = useSelector((state) => state.managequality.manageQuaitydata);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [total, settotal] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  const [totalRecords, settotalRecords] = useState(0);
  const [load, setLoad] = useState(true);
  const [rows, setRows] = useState();
  const [viewIssue, SetViewIssue] = useState(false);
  const [showDeleteIssue, setShowDeleteIssue] = useState(false);
  const [Deletedata, setDeletedata] = useState({});
  const [advFilterOpen, setAdvFilterOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [showBatchActions, setShowBatchActions] = useState(false);
  const [selectExport, setSelectExport] = useState([]);
  const [selectExecute, setSelectExecute] = useState([]);
  const id = useSelector((state) => state.project.quality);
  const Pname = useSelector((state) => state.project.managequalityprojectname);

  const filterBody = {
    timeLineStart: filterData.timeLineStart,
    timeLineEnd: filterData.timeLineEnd,
    country: filterData.country.map((e) => e.countryId),
    make: filterData.make.map((e) => e.make1),
    ftpUploadStatus: filterData.ftpUploadStatus,
    qcStatus: filterData.qcStatus,
    exportId: filterData.exportId,
  };
  useEffect(() => {
    if (search.length > 0) {
      const timerId = setTimeout(() => {
        performSearch(search);
      }, 3000);
      return () => clearTimeout(timerId);
    } else {
      setPage(1);

      getQuality(1, limit, search, filterBody); // Update the results state with the fetched data
    }
  }, [search]);

  const performSearch = (value) => {
    setPage(1);
    let encodedValue = encodeURIComponent(value);

    getQuality(1, limit, encodedValue, filterBody); // Update the results state with the fetched data
    // setResults(responseData);
  };

  useEffect(() => {
    getQuality(page, limit, "");
    dispatch(
      saveqcFilter({
        timeline: "Year",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: null,
        timeLineEnd: null,
        country: [],
        make: [],
        // qcStatus: "",
        // ftpUploadStatus: "",
        qcStatus: "All",
        ftpUploadStatus: "All",
        exportId: [],
      })
    );
  }, [id]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };
  const getQuality = (
    page,
    limit,
    search,
    body = {
      timeLineStart: null,
      timeLineEnd: null,
      country: [],
      make: [],
      // qcStatus: "",
      // ftpUploadStatus: "",
      qcStatus: "All",
      ftpUploadStatus: "All",
      exportId: [],
    }
  ) => {
    dispatch(getAllManageQuality(page, limit, search, body, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            settotalRecords(0);
            //setState([]);
            dispatch(getmanagequality([]));
          } else {
            dispatch(getmanagequality(res.data.result));
          }
          setLoad(false);
          //setState(res.data.result);

          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        } else {
          settotalCount(0);
          settotalRecords(0);
          //setState([]);
          dispatch(getmanagequality([]));
        }
      }
    });
  };
  /**************************************
    Function: handleExport
    Argument:
    Usage:
    1. To export data
    *******************************************/
  /*   const format1 = "DD/MM/YYYY | hh:mm A";
    moment(props.data.dateRaised).format(format1); */
  const exportdata = selectExport.toString();
  const exportIds = exportdata.split(",");

  const handleExport = (body) => {
    setLoad(true);
    dispatch(
      getAllManageQualityexport(
        page,
        limit,
        search,
        (body = {
          timeLineStart: null,
          timeLineEnd: null,
          country: [],
          make: [],
          ftpUploadStatus: "All",
          qcStatus: "All",
          exportId: rows ? ["All"] : exportIds,
        }),
        id
      )
    ).then((res) => {
      setLoad(false);
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);

      utils.sheet_add_aoa(ws, [
        [
          "ID",
          "Project Name",
          "Country",
          "Make",
          "Date Of File",
          "QC Input File",
          "QC Output File",
          "QC Start Date",
          "QC End Date",
          "QC Status",
          "FTP Upload Status",
        ],
      ]);
      const rows = res.data.result.map((row) => [
        row.qualityID,
        row.projectName,
        row.country,
        row.make,
        row.dateOfFile ? moment(row.dateOfFile).format("DD/MM/YYYY") : "",
        row.inputFile,
        row.outputFile,
        row.startDate
          ? moment(row.startDate).format("DD/MM/YYYY HH:mm:ss")
          : "",
        row.endDate ? moment(row.endDate).format("DD/MM/YYYY HH:mm:ss") : "",
        row.status,
        row.uploadStatus,
      ]);
      utils.sheet_add_json(ws, rows, {
        origin: "A2",
        skipHeader: true,
      });

      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, `Manage_Quality_${moment().format("DMMMyy")}.csv`);
    });
    getQuality(page, limit, search, filterBody);
  };
  const handleExportLease = (body) => {
    setLoad(true);
    dispatch(
      getAllManageQualityexport(
        page,
        limit,
        search,
        (body = {
          timeLineStart: null,
          timeLineEnd: null,
          country: [],
          make: [],
          ftpUploadStatus: "All",
          qcStatus: "All",
          exportId: rows ? ["All"] : exportIds,
        }),
        id
      )
    ).then((res) => {
      setLoad(false);
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);

      utils.sheet_add_aoa(ws, [
        [
          "ID",
          "Project Name",
          "Country",
          "Leasing Provider",
          "Date Of File",
          "QC Input File",
          "QC Output File",
          "QC Start Date",
          "QC End Date",
          "QC Status",
          "FTP Upload Status",
        ],
      ]);
      const rows = res.data.result.map((row) => [
        row.qualityID,
        row.projectName,
        row.country,
        row.leasingProviderName,
        row.dateOfFile ? moment(row.dateOfFile).format("DD/MM/YYYY") : "",
        row.inputFile,
        row.outputFile,
        row.startDate
          ? moment(row.startDate).format("DD/MM/YYYY HH:mm:ss")
          : "",
        row.endDate ? moment(row.endDate).format("DD/MM/YYYY HH:mm:ss") : "",
        row.status,
        row.uploadStatus,
      ]);
      utils.sheet_add_json(ws, rows, {
        origin: "A2",
        skipHeader: true,
      });

      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, `Manage_Quality_${moment().format("DMMMyy")}.csv`);
    });
    getQuality(page, limit, search, filterBody);
  };
  /******************************************************************************
    Function: viewBody
    Argument: data
    Usage:
    1. Will get grid data in the view.
    *******************************************************************************/
  const viewBody = async (data) => {
    setLoad(true);
  };
  /******************************************************************************
    Function: editBody
    Argument: data
    Usage:
    1. Will edit grid data in the page.
    *******************************************************************************/
  const editBody = async (data) => {
    setLoad(true);
  };
  const downloadExcelFile = async (link, name) => {
    try {
      setLoad(true);
      const url1=encodeURIComponent(link)

      // Replace 'your-api-url' with the actual URL of the API that serves the Excel file.
      const response = await axios.get(
        MANAGE_DOWNLOAD + `?projectId=${id}&Filepath=${url1}`,
        {
          responseType: "arraybuffer",
        }
      );

      // Create a Blob from the response data
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element
      const a = document.createElement("a");
      a.href = url;
      a.download = name; // Specify the desired file name

      // Trigger a click event on the anchor element to start the download
      a.click();

      // Clean up resources
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading Excel file", error);
    } finally {
      setLoad(false);
    }
  };
  /******************************************************************************
      Function: cloneBody
      Argument: data
      Usage:
      1. Will get grid data in the view.
      *******************************************************************************/
  const cloneBody = (data) => {
    //setAddIssuseOpen(true);
  };
  /******************************************************************************
      Function: deleteBody
      Argument: event
      Usage:
      1. get the selected record and delete
      *******************************************************************************/
  const deleteBody = (event) => {
    setDeletedata(event.qualityID);
  };
  /**************************************
Function: handleSelect
Argument: row
Usage:
1. To select the data to export
*******************************************/
  const handleSelect = async (row) => {
    setSelectExport(row.map((e) => e.qualityID));
    setSelectExecute(row.map((e) => e.qualityID));
    setRows(
      row.length === 10 ||
        row.length === 15 ||
        row.length === 25 ||
        row.length === 50 ||
        row.length === 100
    );
    if (row.length === 0) {
      setShowBatchActions(false);
    } else {
      setShowBatchActions(true);
    }
  };
  console.log(showBatchActions);
  /******************************************************************************
    Function: handlePageClick
    Argument: data
    Usage:
    1. Pagination change action.
    *******************************************************************************/
  const handlePageClick = async (event, data) => {
    setLoad(true);
    setPage(data);
    await getQuality(data, limit, search, filterBody);
  };
  /******************************************************************************
    Function: handleDelete
    Argument: ticketId
    Usage:
    1. To delete the selected records
    *******************************************************************************/
  const handleDelete = (ticketId) => {
    console.log(ticketId);
    setLoad(true);
    dispatch(deleteQuality(ticketId, id)).then(async (res) => {
      if (res.data.statusCode === 200) {
        toast.success("Record deleted successfully");
        await getQuality(page, limit, search);
      } else {
        toast.error("Failed to delete");
        await getQuality(page, limit, search);
      }
      await gridApi.current.redrawRows(select.manageSourcedata);
    });
  };
  /******************************************************************************
    Function: handleGridAction
    Argument: action, selectedRow
    Usage:
     1. handleGridAction -  Performs the actions inside grid (delete).
    *******************************************************************************/
  const handleGridAction = (action, selectedRow) => {
    switch (action) {
      case "Delete":
        handleDelete(selectedRow);
        break;
      default:
        break;
    }
  };
  const filterIssue = async (data, reset = false) => {
    setLoad(true);
    setPage(1);
    dispatch(getAllManageQuality(page, limit, search, data, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            settotalRecords(0);
            //setState([]);
            dispatch(getmanagequality([]));
          } else {
            dispatch(getmanagequality(res.data.result));
          }
          setLoad(false);
          //setState(res.data.result);
          if (!reset) {
            setAdvFilterOpen(false);
          }
          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        } else {
          settotalCount(0);
          settotalRecords(0);
          //setState([]);
          dispatch(getmanagequality([]));
        }
      }
    });
    //
  };

  const [gridKey, setGridKey] = useState(0);
  const resetAll = async () => {
    dispatch(
      saveqcFilter({
        timeline: "Year",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: null,
        timeLineEnd: null,
        country: [],
        make: [],
        qcStatus: "All",
        ftpUploadStatus: "All",
        exportId: [],
      })
    );
    setGridKey(gridKey + 1);
    if (search !== "") {
      setPage(1);
      setSearch("");
      // gridApi.current.api.redrawRows(select.manageIssuedata);
    }
    await getQuality(1, limit, "", {
      timeLineStart: null,
      timeLineEnd: null,
      country: [],
      make: [],
      qcStatus: "All",
      ftpUploadStatus: "All",
      exportId: [],
    });
  };

  return (
    <>
      <Spinner open={load} />
      <BaseLayout>
        <Grid
          container
          spacing={2}
          className={classes.pageActionArea}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item xs={12} md={6}>
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={2}
            >
              <Grid item xs={"auto"}>
                <Gridnav onChange={handleInputChange} data={search} />
              </Grid>
              <Grid item xs={"auto"}>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className={classes.totalResults}
                >
                  Total Results:{" "}
                  <span className={classes.totalValue}>
                    {totalRecords ?? 0}
                  </span>
                  &nbsp;
                  <span className={classes.recordText}>Records</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              spacing={1.75}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  message="Clear Filters"
                  startIcon={<RestartAltOutlined />}
                  onClick={() => resetAll()}
                />
              </Grid>
              {/* <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  message="Batch Execute"
                  disabled
                  // disabled={!showBatchActions}
                  startIcon={<SlowMotionVideoOutlined />}
                  onClick={() => {}}
                />
              </Grid> */}

              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  startIcon={<FilterListOutlined />}
                  message="Advance Filter"
                  onClick={() => setAdvFilterOpen(true)}
                />
              </Grid>
              {showBatchActions === true ? (
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    startIcon={<FileDownloadOutlined />}
                    message="Export"
                    onClick={() => {
                      Pname === "LEASE OPERATOR"
                        ? handleExportLease()
                        : handleExport();
                      setShowBatchActions(false);
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    message="Export"
                    onClick={() => handleExport()}
                    disabled
                    startIcon={<FileDownloadOutlined />}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className={classes.pageActionArea}
          alignItems="center"
          justifyContent="flex-start"
          sx={{ p: 0 }}
        >
          <Grid item xs={12} className={classes.fullHeight}>
            <AgGrid
              // onGridReady={onGridReadyManageIssue}
              data={select}
              header={Pname === "LEASE OPERATOR" ? headers1 : headers}
              actionsHandler={handleGridAction}
              selectAll={handleSelect}
              showDelete={() => setShowDeleteIssue(true)}
              edit={editBody}
              sideBar={"filters"}
              download={downloadExcelFile}
              delete={deleteBody}
              view={viewBody}
              clone={cloneBody}
              key={gridKey}
            />
            <UsePagination
              pageCount={pageCount}
              count={total}
              t={totalRecords}
              total={totalCount}
              currentPage={page}
              limit={limit}
              value={limit}
              handlePageClick={handlePageClick}
              onChange={(e) => {
                setLimit(e.target.value);
                setPage(1);
                getQuality(1, e.target.value, search, filterBody);
              }}
            />
          </Grid>
        </Grid>
      </BaseLayout>
      <AlertDialog
        open={showDeleteIssue}
        onClose={() => setShowDeleteIssue(false)}
        // open={isDeleted}
        title={"Confirmation"}
        // maxWidth={"sm"}
        description={`Do you want to delete record id - ${Deletedata}`}
        action={
          <Grid
            container
            alignItems="center"
            justifyContent="right"
            spacing={2}
            className={classes.alertDialogbtn}
          >
            <Grid item xs={"auto"}>
              <MaterialUIButton
                variant="outlined"
                onClick={() => {
                  handleGridAction("Delete", Deletedata);
                  setShowDeleteIssue(false);
                }}
                message="DELETE"
                startIcon={<Done />}
              />
            </Grid>
            <Grid item xs={"auto"}>
              <MaterialUIButton
                variant="outlined"
                onClick={() => {
                  setShowDeleteIssue(false);
                }}
                message="CANCEL"
                startIcon={<Close />}
              />
            </Grid>
          </Grid>
        }
      />
      <MaterialUIModal
        open={advFilterOpen}
        anchor="right"
        body={
          <AdvanceFilters
            filter={filterIssue}
            close={() => setAdvFilterOpen(false)}
          />
        }
      />
    </>
  );
};
export default ManageQuality;
