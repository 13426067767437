import MaterialUIButton from "components/commonComponents/button/button";
import Datepicker from "components/commonComponents/datepicker/datepicker";

import Textbox from "components/commonComponents/textbox/textbox";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useState } from "react";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";

import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  MenuItem,
} from "@mui/material";

import useStyles from "./manageModelScope.styles";
import Autocompletedropdown from "components/commonComponents/autocomplete/autocompletedropdown";
import { Required, urlValidation } from "utils/validation helper";
import { useDispatch, usedataor, useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import { rerunDropdown } from "redux/manageissues/action";
import { getNewSourceStatus, getRerun } from "redux/app/actions";
import { useLocation } from "react-router-dom";
import AlertDialog from "components/commonComponents/alertDialog";
import EditIcon from "@mui/icons-material/Edit";
import { data_availability } from "./Data";
import Dropdown from "components/commonComponents/dropdown/dropdown";
import { calendar } from "../../../../assets/index";
import moment from "moment";
import { newsourceStatus } from "redux/managesources/action";
function ViewScope(props) {
  const classes = useStyles();
  const [viewsource, Setviewsource] = useState(true);
  const [viewedit, setViewedit] = useState(false);
  const select = useSelector((state) => state.manageScope);

  const data = useSelector((state) => state.manageScope.manageScopedataid);
  const dispatch = useDispatch();

  const location = useLocation();
  const rolename = location.state.split("-")[1];
  const id=useSelector((state) => state.project.managescope)
  const role = localStorage.getItem("role");
  const [edit, setEdit] = useState([]);
  const selector = JSON.parse(localStorage.getItem("roleData"));

  useEffect(() => {
    callingDropdowndata();
    setEdit(
      selector &&
          selector.roleConfigurations.filter((e) =>
            e.menuName === "Manage Issue/Request"
              ? location.pathname === "/manage-issue-/-request" &&
                e.isEdit === true
              : e.menuName.replace("&", "and").toLowerCase() ===
                  location.pathname
                    .replace("/", "")
                    .replace("-", " ")
                    .replace("-", " ")
                    .replace("/", "#")
                    .split("#")[0] && e.isEdit === true
          )
    );
    return () => {
      setEdit(
        selector.roleConfigurations.filter(
          (e) =>
            e.menuName.replace("&", "and").toLowerCase() ===
              location.pathname
                .replace("/", "")
                .replace("-", " ")
                .replace("-", " ") && e.isEdit === true
        )
      );
    };
  }, [location.pathname]);

  const callingDropdowndata = () => {
    /*  dispatch(newsourceStatus()).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getNewSourceStatus([]));
        } else {
          dispatch(getNewSourceStatus(res.data.result));
        }
      } else {
        dispatch(getNewSourceStatus([]));
      }
    }); */
  };

  const [showalert, setShowalert] = useState(false);
  /******************************************************************************
Function: validate
Argument: values
Usage:
1. To validate the fileds.
*******************************************************************************/

  const validate = (values) => {
    const comments = values.comments ? values.comments.length : "";

    const errors = {};
    if (values.sourceUrl && !urlValidation(values.sourceUrl)) {
      errors.sourceUrl = "Invalid URL";
    } else if (values.sourceUrl && values.sourceUrl.length > 200) {
      errors.sourceUrl = "Exceeds 200 characters";
    }
    if (
      !Required(values.reasonforFailure) &&
      values.dataAvailability === false
    ) {
      errors.reasonforFailure = true;
    } else if (
      values.reasonforFailure &&
      values.reasonforFailure.length > 400
    ) {
      errors.reasonforFailure = "Exceeds 400 characters";
    }
    return errors;
  };
  /******************************************************************************
Function: formik
Argument: 
Usage:
1. To submit the data.
*******************************************************************************/

const formik = useFormik({
  initialValues: {
    countryId: data.countryId,
    makeId: data.makeId,
    modelId: data.modelId,
    month: data.month,
    priority: data.make?.issuePriority?.priorityId,
    dataAvailability: data.dataAvailability
      ? data.dataAvailability === false
        ? "NO"
        : "YES"
      : data.dataAvailability === false
      ? "NO"
      : null,
    sourceUrl: data.sourceUrl,
    reasonforFailure: data.reasonforFailure,
    isupdate: false,
  },
  enableReinitialize: true,
  validate,
  onSubmit: (values) => {
    props.onChangeSave(true);
    props.EditScopedata({
      scopeId: data.scopeId,
      countryId: data.countryId,
      makeId: data.makeId,
      priority:data.make?.issuePriority?.priorityId,
      modelId: data.modelId,
      month: data.month,
      dataAvailability: values.dataAvailability
        ? values.dataAvailability === "YES"
          ? true
          : false
        : values.dataAvailability === "YES"
        ? true
        : null,
      sourceUrl: values.sourceUrl ? values.sourceUrl.trim() : "",
      reasonforFailure: values.reasonforFailure
        ? values.reasonforFailure.trim()
        : "",
      updatedBy: role,
    });
  },
});

  return (
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              {viewsource === true ? (
                <Typography variant="h6" gutterBottom>
                  Manage Model Scope Details
                  <span className={classes.artSubText}>(View)</span>
                </Typography>
              ) : (
                <Typography variant="h6" gutterBottom>
                  Manage Model Scope Details
                  <span className={classes.artSubText}>(Edit)</span>
                </Typography>
              )}
            </Grid>
            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs="auto">
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    {role === "MeritQC" || role === "MeritDeveloper" ? (
                      <></>
                    ) : (
                      <>
                        {viewsource === true ? (
                          <Grid item xs="auto">
                            <MaterialUIButton
                              variant="outlined"
                              message="EDIT"
                              onClick={() => {
                                setViewedit(true);
                                Setviewsource(false);
                              }}
                              disabled={edit.length === 0}
                              startIcon={<EditIcon />}
                            />
                          </Grid>
                        ) : (
                          <>
                            <Grid item xs="auto">
                              <MaterialUIButton
                                variant="outlined"
                                message="SAVE"
                                disabled={props.save}
                                onClick={() => formik.handleSubmit()}
                                startIcon={<SaveOutlinedIcon />}
                              />
                            </Grid>
                            <Grid item xs="auto">
                              <MaterialUIButton
                                variant="outlined"
                                message="CANCEL"
                                onClick={() => {
                                  formik.values.isupdate
                                    ? setShowalert(true)
                                    : Setviewsource(true);
                                }}
                                startIcon={<EventBusyOutlinedIcon />}
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton color="secondary" aria-label="Close">
                      <CloseIcon
                        onClick={() => {
                          formik.values.isupdate
                            ? setShowalert(true)
                            : props.close();
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <AlertDialog
                  open={showalert}
                  onClose={() => setShowalert(false)}
                  // open={isDeleted}
                  title={"Confirmation"}
                  // maxWidth={"sm"}
                  description={`Do you want to discard the changes ?`}
                  action={
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="right"
                      spacing={2}
                      className={classes.alertDialogbtn}
                    >
                      <Grid item xs={"auto"}>
                        <MaterialUIButton
                          variant="outlined"
                          onClick={props.close}
                          message="Yes"
                          startIcon={<DoneIcon />}
                        />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <MaterialUIButton
                          variant="outlined"
                          onClick={() => {
                            setShowalert(false);
                          }}
                          message="No"
                          startIcon={<CloseIcon />}
                        />
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}

        <Grid item xs={12}>
          <Box className={classes.borderLine}>
            <Grid
              container
              spacing={2.5}
              alignItems="top"
              justifyContent="flex-start"
            >
              <Grid item xs={12}>
                {/* <Typography
                  variant="subtitle2"
                  gutterBottom
                  className={classes.titleLine}
                >
                  <span className={classes.artLine}>// </span> Details
                  <span className={classes.artLine}>//</span>
                </Typography> */}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Country
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {data.country ? data.country.countryCode : ""}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Textbox
                      size="small"
                      readOnly
                      label="Country"
                      name="countryCode"
                      value={data.country ? data.country.countryCode : ""}
                      fullWidth
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Make
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      {data.make ? data.make.make1 : ""}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Textbox
                      size="small"
                      readOnly
                      label="Make"
                      name="make1"
                      value={data.make ? data.make.make1 : ""}
                      fullWidth
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                     Business Priority
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      {data.make
                      ? data.make.issuePriority
                        ? data.make.issuePriority.issuePriority1
                        : ""
                      : ""}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Textbox
                      size="small"
                      readOnly
                      label="Business Priority"
                      name="priority"
                      value={data.make
                        ? data.make.issuePriority
                          ? data.make.issuePriority.issuePriority1
                          : ""
                        : ""}
                      fullWidth
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Model
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      {data.model ? data.model.model1 : ""}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Textbox
                      size="small"
                      readOnly
                      label="Model"
                      name="model1"
                      value={data.model ? data.model.model1 : ""}
                      fullWidth
                    />
                  </>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Month
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {data.month ? data.month : ""}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Textbox
                      size="small"
                      readOnly
                      label="Month"
                      name="month"
                      value={data.month ? data.month : ""}
                      fullWidth
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={9}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Source URL
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      {data.sourceUrl ? data.sourceUrl : ""}
                    </Typography>
                  </>
                ) : (
                  <>
                    <div>
                      <Textbox
                        rows={3}
                        label="Source URL"
                        name="sourceUrl"
                        fullWidth
                        value={formik.values.sourceUrl}
                        onChange={(e) => {
                          formik.setFieldValue("sourceUrl", e.target.value);
                          formik.setFieldValue("isupdate", true);
                        }}
                        error={
                          formik.touched.sourceUrl &&
                          Boolean(formik.errors.sourceUrl)
                        }
                        helperText={
                          formik.touched.sourceUrl && formik.errors.sourceUrl
                        }
                      />
                    </div>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Data Availability
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {data.dataAvailability ? "YES" : "NO"}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Dropdown
                      fullWidth
                      name="dataAvailability"
                      value={formik.values.dataAvailability}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "dataAvailability",
                          e.target.value
                        );
                        formik.setFieldValue("isupdate", true);
                      }}
                      error={
                        formik.touched.dataAvailability &&
                        Boolean(formik.errors.dataAvailability)
                      }
                      helperText={
                        formik.touched.dataAvailability &&
                        formik.errors.dataAvailability
                      }
                      labelId="Data Availability"
                      id="Data Availability"
                      label="Data Availability"
                      menuItems={data_availability.map((e) => (
                        <MenuItem value={e.value}>{e.value}</MenuItem>
                      ))}
                    />
                  </>
                )}
              </Grid>

              {/*  */}
              <Grid item xs={12} sm={6} md={4} lg={6}>
                {viewsource === true ? (
                  <>
                    <Textbox
                      multiline
                      rows={3}
                      readOnly
                      label="Reason For Failure"
                      fullWidth
                      name="reasonforFailure"
                      value={formik.values.reasonforFailure}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "reasonforFailure",
                          e.target.value
                        );
                        formik.setFieldValue("isupdate", true);
                      }}
                      helperText={
                        formik.touched.reasonforFailure &&
                        formik.errors.reasonforFailure
                      }
                    />
                  </>
                ) : (
                  <>
                    <Textbox
                      multiline
                      rows={3}
                      readOnly={
                        formik.values.dataAvailability === "YES" ||
                        formik.values.dataAvailability === null
                      }
                      required={formik.values.dataAvailability === "NO"}
                      label="Reason For Failure"
                      fullWidth
                      name="reasonforFailure"
                      value={formik.values.reasonforFailure}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "reasonforFailure",
                          e.target.value
                        );
                        formik.setFieldValue("isupdate", true);
                      }}
                      error={
                        formik.touched.reasonforFailure &&
                        Boolean(formik.errors.reasonforFailure)
                      }
                      helperText={
                        formik.touched.reasonforFailure &&
                        formik.errors.reasonforFailure
                      }
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ViewScope;
