var baseApiUrl = process.env.REACT_APP_MANAGE_ISSUE_BASE_URL;
const baseurl = baseApiUrl + "api";
const baseurlforreports = baseurl + "/baureport";
export const BAU_REPORTS = baseurlforreports + "/getallbaustatusreport";
export const BULK_UPDATE = baseurlforreports + "/bulkupdatebaustatusreport";
export const BAU_EXPORT = baseurlforreports + "/exportallbaustatusreport";
export const BAU_EXPORT_FAILURE =
  baseurlforreports + "/getbaufailurecountreport";

export const BAU_BASE = baseurlforreports;
