var baseApiUrl = process.env.REACT_APP_MANAGE_ISSUE_BASE_URL;
const baseurl = baseApiUrl + "api";
const baseurlformanagescope = baseurl + "/v1/managemodelscope";
export const BASE_URL_SCOPE = baseurlformanagescope;
export const MANAGE_SCOPE_URL = baseurlformanagescope + "/getallmodelscope";
export const MANAGE_SCOPE_EXPORT_URL =
  baseurlformanagescope + "/exportallmodelscope";
export const MANAGE_SCOPE_DELETE_URL = baseurlformanagescope + "/";
export const MANAGE_SCOPE_UPDATE_URL = baseurlformanagescope;
export const BULK_UPLOAD_SCRAPER =
  baseurlformanagescope + "/bulkuploadmodelscope";
  export const BULK_DELETE=baseurlformanagescope+"/multipledeletemodelscope"
  
