import React, { useEffect, useState } from "react";
import { BsPencil, BsTrash } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { VscEye } from "react-icons/vsc";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SlowMotionVideoOutlinedIcon from "@mui/icons-material/SlowMotionVideoOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { viewAticketData } from "redux/manageissues/action";
import {
  addPrefiled,
  fetchAttachments,
  fetchDevAttachments,
  fetchManageissueid,
  fetchmanageScopeid,
  fetchmanageSourceid,
  filledData,
} from "redux/app/actions";
import { useDispatch, useSelector } from "react-redux";
import { viewScope } from "redux/managemodelscope/action";
import { viewSource } from "redux/managesources/action";
import TooltipIconButton from "components/commonComponents/iconbutton/buttonicon";
import { SingleBAU, updateSingleBAU } from "redux/reports/actions";
import moment from "moment";

function IconCellRender(props) {
  const dispatch = useDispatch();
  const [view, setView] = useState([]);
  const [Delete, setDelete] = useState([]);
  const [add, setAdd] = useState([]);
  const [edit, setEdit] = useState([]);
  const location = useLocation();
  const role = localStorage.getItem("role");
  const userid = localStorage.getItem("userId");
  const selector = JSON.parse(localStorage.getItem("roleData"));
  const id = useSelector((state) => state.project.manageissue);
  const idscource = useSelector((state) => state.project.managesource);
  const idscope = useSelector((state) => state.project.managescope);
  const idreports = useSelector((state) => state.project.reports);
  // Convert your date string into a Date object
  let current_date = new Date(props.data.date);

  // Get start and end of current week
  let startOfWeek = new Date();
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

  let endOfWeek = new Date();
  endOfWeek.setDate(endOfWeek.getDate() + (6 - endOfWeek.getDay()));
  console.log(current_date >= startOfWeek && current_date <= endOfWeek);

  useEffect(() => {
    setView(
      selector &&
        selector.roleConfigurations.filter((e) =>
          e.menuName === "Manage Issue/Request"
            ? location.pathname === "/manage-issue-/-request" &&
              e.isView === true
            : e.menuName.replace("&", "and").toLowerCase() ===
                location.pathname
                  .replace("/", "")
                  .replace("-", " ")
                  .replace("-", " ")
                  .replace("/", "#")
                  .split("#")[0] && e.isView === true
        )
    );
    setEdit(
      selector &&
        selector.roleConfigurations.filter((e) =>
          e.menuName === "Manage Issue/Request"
            ? location.pathname === "/manage-issue-/-request" &&
              e.isEdit === true
            : e.menuName.replace("&", "and").toLowerCase() ===
                location.pathname
                  .replace("/", "")
                  .replace("-", " ")
                  .replace("-", " ")
                  .replace("/", "#")
                  .split("#")[0] && e.isEdit === true
        )
    );
    setAdd(
      selector &&
        selector.roleConfigurations.filter((e) =>
          e.menuName === "Manage Issue/Request"
            ? location.pathname === "/manage-issue-/-request" &&
              e.isAdd === true
            : e.menuName.replace("&", "and").toLowerCase() ===
                location.pathname
                  .replace("/", "")
                  .replace("-", " ")
                  .replace("-", " ")
                  .replace("/", "#")
                  .split("#")[0] && e.isAdd === true
        )
    );
    setDelete(
      selector &&
        selector.roleConfigurations.filter((e) =>
          e.menuName === "Manage Issue/Request"
            ? location.pathname === "/manage-issue-/-request" &&
              e.isDelete === true
            : e.menuName.replace("&", "and").toLowerCase() ===
                location.pathname
                  .replace("/", "")
                  .replace("-", " ")
                  .replace("-", " ")
                  .replace("/", "#")
                  .split("#")[0] && e.isDelete === true
        )
    );
    return () => {
      setView(
        selector &&
          selector.roleConfigurations.filter((e) =>
            e.menuName === "Manage Issue/Request"
              ? location.pathname === "/manage-issue-/-request" &&
                e.isView === true
              : e.menuName.replace("&", "and").toLowerCase() ===
                  location.pathname
                    .replace("/", "")
                    .replace("-", " ")
                    .replace("-", " ")
                    .replace("/", "#")
                    .split("#")[0] && e.isView === true
          )
      );
      setEdit(
        selector &&
          selector.roleConfigurations.filter((e) =>
            e.menuName === "Manage Issue/Request"
              ? location.pathname === "/manage-issue-/-request" &&
                e.isEdit === true
              : e.menuName.replace("&", "and").toLowerCase() ===
                  location.pathname
                    .replace("/", "")
                    .replace("-", " ")
                    .replace("-", " ")
                    .replace("/", "#")
                    .split("#")[0] && e.isEdit === true
          )
      );
      setAdd(
        selector &&
          selector.roleConfigurations.filter((e) =>
            e.menuName === "Manage Issue/Request"
              ? location.pathname === "/manage-issue-/-request" &&
                e.isAdd === true
              : e.menuName.replace("&", "and").toLowerCase() ===
                  location.pathname
                    .replace("/", "")
                    .replace("-", " ")
                    .replace("-", " ")
                    .replace("/", "#")
                    .split("#")[0] && e.isAdd === true
          )
      );
      setDelete(
        selector &&
          selector.roleConfigurations.filter((e) =>
            e.menuName === "Manage Issue/Request"
              ? location.pathname === "/manage-issue-/-request" &&
                e.isDelete === true
              : e.menuName.replace("&", "and").toLowerCase() ===
                  location.pathname
                    .replace("/", "")
                    .replace("-", " ")
                    .replace("-", " ")
                    .replace("/", "#")
                    .split("#")[0] && e.isDelete === true
          )
      );
    };
  }, [location.pathname, id, idscope, idscource]);
  switch (props.actionType) {
    case "qcaction":
      return (
        <Grid container alignItems="center" justifyContent="left" spacing={0.5}>
          <Grid item xs={"auto"}>
            <Tooltip
              title="View"
              placement="top"
              arrow
              disabled={view.length === 0}
              color={view.length === 0 ? "primary" : ""}
            >
              <IconButton aria-label="View" size="small">
                <RemoveRedEyeIcon
                  color={view.length > 0 ? "secondary" : ""}
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    console.log(props.data.ticketId);
                    dispatch(viewAticketData(props.data.ticketId, id)).then(
                      async (res) => {
                        if (res.status === 200) {
                          await dispatch(fetchManageissueid(res.data));
                          await dispatch(
                            fetchAttachments(
                              res.data.attachments ? res.data.attachments : ""
                            )
                          );
                          await dispatch(
                            fetchDevAttachments(
                              res.data.devAttachments
                                ? res.data.devAttachments
                                : ""
                            )
                          );
                          props.context.componentParent.handleView(props.data);
                        }
                      }
                    );

                    // props.context.componentParent.setView();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={"auto"}>
            {props.data.actualClosedDate ||
            (props.data.assignedTo && role === "Merit QC") /* ||
            (role === "Merit Developer" &&
              userid !== props.data.assignedUserId) */ ? (
              <Tooltip title="Edit" placement="top" arrow>
                <IconButton aria-label="Edit" disabled color="primary">
                  <EditOutlinedIcon
                    color=""
                    style={{ fontSize: "22px" }}
                    onClick={() => {
                      dispatch(viewAticketData(props.data.ticketId, id)).then(
                        async (res) => {
                          if (res.status === 200) {
                            await dispatch(fetchManageissueid(res.data));
                            await dispatch(
                              fetchAttachments(
                                res.data.attachments ? res.data.attachments : ""
                              )
                            );
                            await dispatch(
                              fetchDevAttachments(
                                res.data.devAttachments
                                  ? res.data.devAttachments
                                  : ""
                              )
                            );
                            props.context.componentParent.handleEdit(
                              props.data
                            );

                            props.context.componentParent.setEdit();
                          }
                        }
                      );
                    }}
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Edit" placement="top" arrow>
                <IconButton
                  aria-label="Edit"
                  disabled={edit.length === 0}
                  color={edit.length === 0 ? "primary" : ""}
                >
                  <EditOutlinedIcon
                    color={edit.length > 0 ? "secondary" : ""}
                    style={{ fontSize: "22px" }}
                    onClick={() => {
                      dispatch(viewAticketData(props.data.ticketId, id)).then(
                        async (res) => {
                          if (res.status === 200) {
                            await dispatch(fetchManageissueid(res.data));
                            await dispatch(
                              fetchAttachments(
                                res.data.attachments ? res.data.attachments : ""
                              )
                            );
                            await dispatch(
                              fetchDevAttachments(
                                res.data.devAttachments
                                  ? res.data.devAttachments
                                  : ""
                              )
                            );
                            props.context.componentParent.handleEdit(
                              props.data
                            );

                            props.context.componentParent.setEdit();
                          }
                        }
                      );
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={"auto"}>
            {props.data.assignedTo ? (
              <Tooltip title="Delete" placement="top" arrow>
                <IconButton
                  aria-label="Delete"
                  disabled
                  size="small"
                  color="primary"
                >
                  <DeleteOutlineOutlinedIcon
                    color=""
                    style={{ fontSize: "22px" }}
                    onClick={() => {
                      props.context.componentParent.handleDelete(props.data);

                      props.context.componentParent.setDelete();
                    }}
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Delete" placement="top" arrow>
                <IconButton
                  aria-label="Delete"
                  disabled={Delete.length === 0}
                  size="small"
                  color={Delete.length === 0 ? "primary" : ""}
                >
                  <DeleteOutlineOutlinedIcon
                    color={Delete.length > 0 ? "secondary" : ""}
                    style={{ fontSize: "22px" }}
                    onClick={() => {
                      props.context.componentParent.handleDelete(props.data);

                      props.context.componentParent.setDelete();
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={"auto"}>
            <Tooltip title="Clone" placement="top" arrow>
              <IconButton
                aria-label="Clone"
                size="small"
                disabled={add.length === 0}
                color={add.length === 0 ? "primary" : ""}
              >
                <ContentCopyIcon
                  color={add.length > 0 ? "secondary" : ""}
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    dispatch(viewAticketData(props.data.ticketId, id)).then(
                      (res) => {
                        dispatch(addPrefiled(res.data));
                        props.context.componentParent.handleClone(props.data);
                      }
                    );
                    // props.context.componentParent.setView();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );
    case "adminaction2":
      return (
        <Grid container alignItems="center" justifyContent="left" spacing={0.5}>
          <Grid item xs={"auto"}>
            <Tooltip
              title="View"
              placement="top"
              arrow
              disabled={view.length === 0}
              color={view.length === 0 ? "primary" : ""}
            >
              <IconButton aria-label="View" size="small">
                <RemoveRedEyeIcon
                  color={view.length > 0 ? "secondary" : ""}
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    props.context.componentParent.handleView(props.data);

                    // dispatch(viewSource(props.data.sourceID)).then(async(res) => {
                    //   if(res.status===200){
                    //     await dispatch(fetchmanageSourceid(res.data));

                    //   }

                    // });
                    // props.context.componentParent.setView();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={"auto"}>
            <Tooltip title="Edit" placement="top" arrow>
              <IconButton
                aria-label="Edit"
                disabled={edit.length === 0}
                color={edit.length === 0 ? "primary" : ""}
              >
                <EditOutlinedIcon
                  color={edit.length > 0 ? "secondary" : ""}
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    props.context.componentParent.handleEdit(props.data);

                    props.context.componentParent.setEdit();

                    // dispatch(viewSource(props.data.sourceID)).then(async(res) => {
                    //   if(res.status===200){
                    //    await dispatch(fetchmanageSourceid(res.data));
                    //      }
                    // });
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={"auto"}>
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton
                aria-label="Delete"
                disabled={Delete.length === 0}
                size="small"
                color={Delete.length === 0 ? "primary" : ""}
              >
                <DeleteOutlineOutlinedIcon
                  color={Delete.length > 0 ? "secondary" : ""}
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    props.context.componentParent.handleDelete(props.data);

                    props.context.componentParent.setDelete();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );
    case "sla":
      return (
        <Grid container alignItems="center" justifyContent="left" spacing={0.5}>
          <Grid item xs={"auto"}>
            <Tooltip title="Edit" placement="top" arrow>
              <IconButton
                aria-label="Edit"
                disabled={edit.length === 0}
                color={edit.length === 0 ? "primary" : ""}
                              

              >
                <EditOutlinedIcon
                  color={edit.length > 0 ? "secondary" : ""}
               

                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    props.context.componentParent.handleEdit(props.data);

                    props.context.componentParent.setEdit();

                    // dispatch(viewSource(props.data.sourceID)).then(async(res) => {
                    //   if(res.status===200){
                    //    await dispatch(fetchmanageSourceid(res.data));
                    //      }
                    // });
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={"auto"}>
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton
                aria-label="Delete"
                disabled={Delete.length === 0}
                size="small"
                color={Delete.length === 0 ? "primary" : ""}
              >
                <DeleteOutlineOutlinedIcon
                  color={Delete.length > 0 ? "secondary" : ""}

                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    props.context.componentParent.handleDelete(props.data);

                    props.context.componentParent.setDelete();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );
    case "holiday":
      return (
        <Grid container alignItems="center" justifyContent="left" spacing={0.5}>
          <Grid item xs={"auto"}>
            <Tooltip title="Edit" placement="top" arrow>
              <IconButton
                aria-label="Edit"
                disabled={edit.length === 0}
                color={edit.length === 0 ? "primary" : ""}
              >
                <EditOutlinedIcon
                  color={edit.length > 0 ? "secondary" : ""}
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    props.context.componentParent.handleEdit(props.data);

                    props.context.componentParent.setEdit();

                    // dispatch(viewSource(props.data.sourceID)).then(async(res) => {
                    //   if(res.status===200){
                    //    await dispatch(fetchmanageSourceid(res.data));
                    //      }
                    // });
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={"auto"}>
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton
                aria-label="Delete"
                disabled={Delete.length === 0}
                size="small"
                color={Delete.length === 0 ? "primary" : ""}
              >
                <DeleteOutlineOutlinedIcon
                  color={Delete.length > 0 ? "secondary" : ""}
                  // color={"secondary"}
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    props.context.componentParent.handleDelete(props.data);

                    props.context.componentParent.setDelete();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );

    case "adminaction":
      return (
        <Grid container alignItems="center" justifyContent="left" spacing={0.5}>
          <Grid item xs={"auto"}>
            <Tooltip
              title="View"
              placement="top"
              arrow
              disabled={view.length === 0}
              color={view.length === 0 ? "primary" : ""}
            >
              <IconButton aria-label="View" size="small">
                <RemoveRedEyeIcon
                  color={view.length > 0 ? "secondary" : ""}
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    dispatch(viewSource(props.data.sourceID, idscource)).then(
                      async (res) => {
                        if (res.status === 200) {
                          await dispatch(fetchmanageSourceid(res.data));
                          props.context.componentParent.handleView(props.data);
                        }
                      }
                    );
                    // props.context.componentParent.setView();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={"auto"}>
            <Tooltip title="Edit" placement="top" arrow>
              <IconButton
                aria-label="Edit"
                disabled={edit.length === 0}
                color={edit.length === 0 ? "primary" : ""}
              >
                <EditOutlinedIcon
                  color={edit.length > 0 ? "secondary" : ""}
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    dispatch(viewSource(props.data.sourceID, idscource)).then(
                      async (res) => {
                        if (res.status === 200) {
                          await dispatch(fetchmanageSourceid(res.data));
                          props.context.componentParent.handleEdit(props.data);

                          props.context.componentParent.setEdit();
                        }
                      }
                    );
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={"auto"}>
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton
                aria-label="Delete"
                disabled={Delete.length === 0}
                size="small"
                color={Delete.length === 0 ? "primary" : ""}
              >
                <DeleteOutlineOutlinedIcon
                  color={Delete.length > 0 ? "secondary" : ""}
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    props.context.componentParent.handleDelete(props.data);

                    props.context.componentParent.setDelete();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );

    case "adminaction1":
      return (
        <Grid container alignItems="center" justifyContent="left" spacing={0.5}>
          <Grid item xs={"auto"}>
            <Tooltip
              title="View"
              placement="top"
              arrow
              disabled={view.length === 0}
              color={view.length === 0 ? "primary" : ""}
            >
              <IconButton aria-label="View" size="small">
                <RemoveRedEyeIcon
                  color={view.length > 0 ? "secondary" : ""}
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    dispatch(viewScope(props.data.scopeID, idscope)).then(
                      async (res) => {
                        if (res.status === 200) {
                          await dispatch(fetchmanageScopeid(res.data));

                          props.context.componentParent.handleView(props.data);
                        }
                      }
                    );

                    // props.context.componentParent.setView();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={"auto"}>
            <Tooltip title="Edit" placement="top" arrow>
              <IconButton
                aria-label="Edit"
                disabled={edit.length === 0}
                color={edit.length === 0 ? "primary" : ""}
              >
                <EditOutlinedIcon
                  color={edit.length > 0 ? "secondary" : ""}
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    dispatch(viewScope(props.data.scopeID, idscope)).then(
                      async (res) => {
                        if (res.status === 200) {
                          await dispatch(fetchmanageScopeid(res.data));

                          props.context.componentParent.handleEdit(props.data);
                          props.context.componentParent.setEdit();
                        }
                      }
                    );
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={"auto"}>
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton
                aria-label="Delete"
                disabled={Delete.length === 0}
                size="small"
                color={Delete.length === 0 ? "primary" : ""}
              >
                <DeleteOutlineOutlinedIcon
                  color={Delete.length > 0 ? "secondary" : ""}
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    props.context.componentParent.handleDelete(props.data);

                    props.context.componentParent.setDelete();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );

    case "roleaction":
      return (
        <Grid container alignItems="center" justifyContent="left" spacing={0.5}>
          <Grid item xs={"auto"}>
            <Tooltip title="Edit" placement="top" arrow>
              <IconButton
                aria-label="Edit"
                disabled={edit.length === 0}
                color={edit.length === 0 ? "primary" : ""}
              >
                <EditOutlinedIcon
                  color={edit.length > 0 ? "secondary" : ""}
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    props.context.componentParent.handleEdit(props.data);

                    props.context.componentParent.setEdit();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={"auto"}>
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton
                aria-label="Delete"
                disabled={Delete.length === 0}
                size="small"
                color={Delete.length === 0 ? "primary" : ""}
              >
                <DeleteOutlineOutlinedIcon
                  color={Delete.length > 0 ? "secondary" : ""}
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    props.context.componentParent.handleDelete(props.data);

                    props.context.componentParent.setDelete();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );
    case "role":
      return (
        <Grid container alignItems="center" justifyContent="left" spacing={0.5}>
          <Grid item xs={"auto"}>
            <Tooltip title="Edit" placement="top" arrow>
              <IconButton
                aria-label="Edit"
                disabled={edit.length === 0}
                color={edit.length === 0 ? "primary" : ""}
              >
                <EditOutlinedIcon
                  color={edit.length > 0 ? "secondary" : ""}
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    props.context.componentParent.handleEdit(props.data);

                    props.context.componentParent.setEdit();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={"auto"}>
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton
                aria-label="Delete"
                disabled={
                  Delete.length === 0 ||
                  props.data.roleName === "Merit QC" ||
                  props.data.roleName === "JATO Admin" ||
                  props.data.roleName === "Merit Admin" ||
                  props.data.roleName === "Merit Developer"
                }
                size="small"
                color={
                  Delete.length === 0 ||
                  props.data.roleName === "Merit QC" ||
                  props.data.roleName === "JATO Admin" ||
                  props.data.roleName === "Merit Admin" ||
                  props.data.roleName === "Merit Developer"
                    ? "primary"
                    : ""
                }
              >
                <DeleteOutlineOutlinedIcon
                  color={
                    Delete.length === 0 ||
                    props.data.roleName === "Merit QC" ||
                    props.data.roleName === "JATO Admin" ||
                    props.data.roleName === "Merit Admin" ||
                    props.data.roleName === "Merit Developer"
                      ? ""
                      : "secondary"
                  }
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    props.context.componentParent.handleDelete(props.data);

                    props.context.componentParent.setDelete();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );
    case "qualityaction":
      return (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={0.5}
        >
          {/* <Grid item xs={"auto"}>
            <TooltipIconButton
              size="small"
              disabled
              title="Execute"
              onClick={() => { }}
            >
              <SlowMotionVideoOutlinedIcon style={{ fontSize: "22px" }} />
            </TooltipIconButton>
          </Grid> */}
          <Grid item xs={"auto"}>
            <TooltipIconButton
              size="small"
              title="Delete"
              aria-label="Delete"
              disabled={
                Delete.length === 0 ||
                props.data.roleName === "Merit QC" ||
                props.data.roleName === "JATO Admin" ||
                props.data.roleName === "Merit Admin" ||
                props.data.roleName === "Merit Developer"
              }
              onClick={() => {
                props.context.componentParent.handleDelete(props.data);
                props.context.componentParent.setDelete();
              }}
            >
              <DeleteOutlineOutlinedIcon style={{ fontSize: "22px" }} />
            </TooltipIconButton>
          </Grid>
        </Grid>
      );
    case "bau":
      return (
        <Grid container alignItems="center" justifyContent="left" spacing={0.5}>
          <Grid item xs={"auto"}>
            <Tooltip title="Edit" placement="top" arrow>
              <IconButton
                aria-label="Edit"
                disabled={
                  edit.length === 0
                  // ||
                  // !(current_date >= startOfWeek && current_date <= endOfWeek)
                }
                color={
                  edit.length === 0
                    ? // ||
                      // !(current_date >= startOfWeek && current_date <= endOfWeek)
                      // ||
                      // props.data.date?.isBetween(startOfWeek, endOfWeek)
                      "primary"
                    : ""
                }
              >
                <EditOutlinedIcon
                  color={
                    edit.length > 0
                      ? // ||
                        // (current_date >= startOfWeek && current_date <= endOfWeek)
                        // ||
                        // props.data.date?.isBetween(startOfWeek, endOfWeek)
                        "secondary"
                      : ""
                  }
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    dispatch(SingleBAU(props.data.id, idreports)).then(
                      async (res) => {
                        console.log(res);
                        if (res.status === 200) {
                          await dispatch(filledData(res.data));
                          props.context.componentParent.handleEdit(props.data);

                          props.context.componentParent.setEdit();
                        }
                      }
                    );
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={"auto"}>
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton
                aria-label="Delete"
                disabled={Delete.length === 0}
                size="small"
                color={Delete.length === 0 ? "primary" : ""}
              >
                <DeleteOutlineOutlinedIcon
                  color={Delete.length > 0 ? "secondary" : ""}
                  style={{ fontSize: "22px" }}
                  onClick={() => {
                    props.context.componentParent.handleDelete(props.data);

                    props.context.componentParent.setDelete();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );

    default:
      <div></div>;
  }
}

export default IconCellRender;
