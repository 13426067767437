// Default React, React Components, Router and Services Components
import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";

// Dummy Data

// Importing Custom Components
import AdvanceFilters from "./advanceFilters";
import BaseLayout from "components/commonComponents/baseLayout";
import Gridnav from "components/commonComponents/gridnav/gridnav";
import MaterialUIModal from "components/commonComponents/modal/modal";
import MaterialUIButton from "components/commonComponents/button/button";
import AgGrid from "components/commonComponents/grid/agGrid";
import UsePagination from "components/commonComponents/pagination/pagination";

// Importing useStyles
import { styled } from "@mui/material/styles";
import useStyles from "./manageSources.styles";

// Import Material Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import AddSource from "./BulkEdit";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {
  addBulk,
  deletesource,
  ExportManagesource,
  getManagesource,
  updateManagesource,
  viewSource,
} from "redux/managesources/action";
import {
  errorMessage,
  fetchmanageSource,
  fetchmanageSourceid,
  filterBAU,
  getReportsData,
  saveFiltersSource,
} from "redux/app/actions";
import Toasts from "components/commonComponents/toasts/toasts";
import AlertDialog from "components/commonComponents/alertDialog";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import EditSource from "./editStatus";
import { addSource } from "redux/managesources/action";
import { toast } from "react-toastify";
import Spinner from "components/commonComponents/spinner/spinner";
import { useLocation } from "react-router-dom";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { debounce } from "lodash";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Tab } from "@material-ui/core";
import MultiFloatFilter from "./MultiFloatFilter";
import { header } from "./Data";
import {
  ExportBAU,
  ExportBAUFailure,
  bulkUpdateBAU,
  deleteBau,
  getAllBAU,
  updateSingleBAU,
} from "redux/reports/actions";
import CustomizedMenus from "components/commonComponents/menu/menu";
import EditPicker from "components/commonComponents/datepicker/editpicker";
import { useFormik } from "formik";
import { Required } from "utils/validation helper";
import { utils } from "xlsx/xlsx";
import { writeFile } from "xlsx";
function Reports() {
  const dispatch = useDispatch();
  const gridApi = useRef(null);
  const id = useSelector((state) => state.project.reports);
  const data = useSelector((state) => state.manageIssue);

  const headers = data.country.map((e) => e.countryCode);
  const head = ["Make"];
  const head2 = ["Date"];
  const combinedHeader = head.concat(headers, head2);
  const select = useSelector((state) => state.reports);
  const [saveDisabled, setsaveDisabled] = useState(false);
  const [load, setLoad] = useState(true);
  const [save, setsave] = useState(false);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [msg, setMsg] = useState("");
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [total, settotal] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  const [totalRecords, settotalRecords] = useState(0);
  const [selectExport, setselectExport] = useState([]);
  const [rows, setRows] = useState();
  const [error, setError] = useState({});
  const [edit, setEdit] = useState([]);

  const [advFilterOpen, setAdvFilterOpen] = useState(false);
  const classes = useStyles();
  const [addIssuseOpen, setAddIssuseOpen] = useState(false);
  const [bulkupload, setBulkupload] = useState(false);
  const [viewsource, SetViewsource] = useState(false);
  const [showEditSource, setShowEditSource] = useState(false);
  const [Deletedata, setDeletedata] = useState({});
  const [showDelete, setshowDelete] = useState(false);
  const [showBAU, setshowBAU] = useState(false);

  const [disableedit, setdisableedit] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const role = localStorage.getItem("role");
  const name = localStorage.getItem("firstName");
  const location = useLocation();
  const [add, setAdd] = useState([]);
  const selector = JSON.parse(localStorage.getItem("roleData"));
  const filterData = useSelector((state) => state.reports.filter);
  const filterBody = {
    timeLineStart: filterData.timeLineStart,
    timeLineEnd: filterData.timeLineEnd,
    country: filterData.country.map((e) => e.countryId),
    make: filterData.make.map((e) => e.make1),
    status: filterData.status.map((e) => e.failurereason),

    exportId: [],
  };
  const validate = (values) => {
    const errors = {};
    if (!Required(values.startdate)) {
      errors.startdate = true;
    }
    if (!Required(values.enddate)) {
      errors.enddate = true;
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      startdate: null,
      enddate: null,
    },

    validate,
    onSubmit: async (values) => {
      setLoad(true);
      const res = await dispatch(
        ExportBAUFailure({
          startDate: values.startdate,
          endDate: values.enddate,
        })
      );
      let workbook = new ExcelJS.Workbook();
      let worksheet = workbook.addWorksheet("Report");
      setLoad(false);
      const headers = Object.keys(res.data.grandTotal.monthGrandTotal);
      const column = ["Make", "Region"].concat(headers).concat("Total");
      worksheet.columns = column.map((header) => ({
        header,
        key: header,
        width: 10,
      }));

      // Apply bold font to the first row (headers)
      worksheet.getRow(1).font = { bold: true };

      const rows = res.data.reports.map((row) =>
        column.map((header) => {
          if (header === "Make" || header === "Region" || header === "Total") {
            return row[header.toLowerCase()];
          }
          // Check if the header is 'date' to apply moment.js formatting

          return row.months[header];
        })
      );
      worksheet.addRows(rows);
      const grandTotalRow = column.map((header) => {
        if (header === "Make" || header === "Region") {
          // For non-numeric columns, set the value as "Grand Total"
          return header === "Make" ? "Grand Total" : "";
        } else if (header === "Total") {
          return header === "Total" ? res.data.grandTotal.totalGrandTotal : "";
        }

        // For numeric columns, calculate the grand total
        return res.data.grandTotal.monthGrandTotal[header];
      });
      worksheet.addRow(grandTotalRow);
      // Get all rows of the worksheet with 'worksheet.rowCount'
      let lastRowNumber = worksheet.rowCount;

      // Apply bold font to the last row
      worksheet.getRow(lastRowNumber).font = { bold: true };
      const buffer = await workbook.xlsx.writeBuffer();

      // Using FileSaver.js (npm i file-saver)
      var blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `BAU_REPORTS_FAILURE_${moment().format("DMMMyy")}.xlsx`);

      getSource(page, limit, search, filterBody);

      setshowBAU(false);
      setShow(false);
      formik.handleReset();
    },
  });
  useEffect(() => {
    if (search.length > 0) {
      const timerId = setTimeout(() => {
        performSearch(search);
      }, 3000);
      return () => clearTimeout(timerId);
    } else {
      setPage(1);

      getSource(1, limit, search, filterBody);
    }
  }, [search]);

  const performSearch = (value) => {
    setPage(1);
    let encodedValue = encodeURIComponent(value);

    getSource(1, limit, encodedValue, filterBody);
    // Update the results state with the fetched data
    // setResults(responseData);
  };

  /******************************************************************************
  Function: getSource
  Argument: page,limit,search,body
  Return: 
  Usage:
  1.To call grid API
  *******************************************************************************/

  const getSource = (
    page,
    limit,
    search,
    body = {
      timeLineStart: null,
      timeLineEnd: null,
      country: [],
      make: [],
      status: [],

      exportId: [],
    }
  ) => {
    dispatch(getAllBAU(page, limit, search, body, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            settotalRecords(0);
            dispatch(getReportsData([]));
          } else {
            dispatch(getReportsData(res.data.result));
          }
          setLoad(false);

          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        }
        else{
          settotalCount(0);
          settotalRecords(0);
          dispatch(getReportsData([]));
        }
      }
    });
  };
  const exportdata = selectExport.toString();
  const exportIds = exportdata.split(",");

  /******************************************************************************
  Function: handleExport
  Argument: body
  Return: 
  Usage:
  1.To Export data from grid
  *******************************************************************************/

  const handleExport = async (body) => {
    setLoad(true);

    // Get your data here
    const res = await dispatch(
      ExportBAU(
        page,
        limit,
        search,
        (body = {
          timeLineStart: filterData.timeLineStart,
          timeLineEnd: filterData.timeLineEnd,
          country: filterData.country.map((e) => e.countryId),
          make: filterData.make.map((e) => e.make1),
          status: filterData.status.map((e) => e.failurereason),
          exportId: rows ? ["All"] : exportIds,
        }),
        id
      )
    );

    // Create workbook & worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet("Report");

    // Define columns in the worksheet
    const columns = combinedHeader.map((header) => ({ header, key: header }));
    worksheet.columns = columns;
    headers.map((e) => (worksheet.getColumn(e).width = 30));

    const colNumNoColor1 = worksheet.getColumn("Make").number;
    const colNumNoColor2 = worksheet.getColumn("Date").number;
    // Apply color to header row
    worksheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "70AD47" },
      };
      cell.font = {
        color: { argb: "FFFFFF" }, // White color
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    const rowData = res.data.result.map((row) => {
      return columns.map((col) => {
        const lowercase = col.header.toLowerCase();
        console.log(row.countries[col.header]);
        if (lowercase === "make") {
          return row[lowercase];
        } else if (lowercase === "date") {
          return moment(row[lowercase]).format("DD-MMM-YY");
        } else {
          return row.countries[col.header] === "Null"
            ? undefined
            : row.countries[col.header];
        }
      });
    });

    // Add rows to the worksheet
    worksheet.addRows(rowData);
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber === 1) return;
      row.eachCell((cell, colNumber) => {
        console.log(colNumber, colNumNoColor1, colNumNoColor2);
        // Apply condition here
        if (colNumber === colNumNoColor1 || colNumber === colNumNoColor2) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFF" },
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        } else if (
          cell.value.toLowerCase() ===
          "No change in Delta Differencing".toLowerCase()
        ) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B4C6E7" },
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        } else if (
          cell.value.toLowerCase().includes("delivered".toLowerCase())
        ) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "92D050" },
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        } else if (
          cell.value.toLowerCase() ===
          "Issue Fixing - Rerun Required".toLowerCase()
        ) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF00" },
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        } else if (cell.value.toLowerCase() === "WIP".toLowerCase()) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFF" },
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        } else {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "AEAAAA" }, // Red Color
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      });
    });
    const buffer = await workbook.xlsx.writeBuffer();

    // Using FileSaver.js (npm i file-saver)
    var blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `BAU_REPORTS_${moment().format("DMMMyy")}.xlsx`);

    setLoad(false);
    setShow(false);
    getSource(page, limit, search, filterBody);
  };

  /******************************************************************************
  Function: editBody
  Argument: data
  Return: 
  Usage:
  1.To set values in edit screen
  *******************************************************************************/

  const editBody = (data) => {
    setShowEditSource(true);
  };

  /******************************************************************************
  Function: viewBody
  Argument: data
  Return: 
  Usage:
  1.To set values in view screen
  *******************************************************************************/

  const viewBody = (data) => {
    SetViewsource(true);
  };

  /******************************************************************************
  Function: EditSourcedata
  Argument: data
  Return: 
  Usage:
  1.To edit the existing data 
  *******************************************************************************/

  const EditSourcedata = (data) => {
    setLoad(true);
    dispatch(updateSingleBAU(data, id)).then(async (res) => {
      if (res.data.statusCode === 200) {
        toast.success("Saved successfully");
        await getSource(page, limit, search);
        setShowEditSource(false);
        SetViewsource(false);
        setLoad(false);
        setdisableedit(false);
      } else if (res.data.statusCode === 400) {
        setShowEditSource(false);
        SetViewsource(false);
        setLoad(false);
        setdisableedit(false);

        toast.error(res.data.responseException.exceptionMessage.result);
      } else {
        setShowEditSource(false);
        SetViewsource(false);
        setLoad(false);
        setdisableedit(false);
        toast.error("Save failed");
      }

      await gridApi.current.redrawRows(select.reportsdata);
    });
  };
  /******************************************************************************
  Function: filterSource
  Argument: data, reset 
  Return: 
  Usage:
  1.To Filter the grid data
  *******************************************************************************/

  const filterSource = async (data, reset = false) => {
    setLoad(true);
    setPage(1);
    dispatch(getAllBAU(1, limit, search, data, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            //setState([]);
            settotalRecords(0);
            dispatch(getReportsData([]));
          } else {
            dispatch(getReportsData(res.data.result));
          }
          setLoad(false);
          //setState(res.data.result);
          if (!reset) {
            setAdvFilterOpen(false);
          }

          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        }
      }
    });
    gridApi.current.redrawRows(select.manageIssuedata);
    //
  };
  /******************************************************************************
  Function: deleteBody
  Argument: data, reset 
  Return: 
  Usage:
  1.To set id to delete
  *******************************************************************************/

  const deleteBody = (event) => {
    setDeletedata(event.id);
    //setRolename(event.);
  };

  /******************************************************************************
  Function: handleDelete
  Argument: sourceId
  Return: 
  Usage:
  1.To handle delete in grid
  *******************************************************************************/

  const handleDelete = (sourceId) => {
    setLoad(true);
    dispatch(deleteBau(sourceId, id)).then(async (res) => {
      if (res.data.statusCode === 200) {
        toast.success("Deleted successfully");
        await getSource(page, limit, search);
      } else {
        toast.error("Failed to delete");
        await getSource(page, limit, search);
      }
      await gridApi.current.redrawRows(select.reportsdata);
    });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  // useEffect for fetching results on initial load
  // useEffect(() => {
  //   performSearch(search);
  // }, []);

  useEffect(() => {
    getSource(page, limit, "");
    dispatch(
      filterBAU({
        timeline: "Week",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: null,
        timeLineEnd: null,
        country: [],
        make: [],
        status: [],

        exportId: [],
      })
    );
    setAdd(
      selector &&
      selector.roleConfigurations.filter((e) =>
        e.menuName === "Manage Issue/Request"
          ? location.pathname === "/manage-issue-/-request" &&
            e.isAdd === true
          : e.menuName.replace("&", "and").toLowerCase() ===
              location.pathname
                .replace("/", "")
                .replace("-", " ")
                .replace("-", " ")
                .replace("/", "#")
                .split("#")[0] && e.isAdd === true
      )
    );
    setEdit(
      selector &&
      selector.roleConfigurations.filter((e) =>
        e.menuName === "Manage Issue/Request"
          ? location.pathname === "/manage-issue-/-request" &&
            e.isEdit === true
          : e.menuName.replace("&", "and").toLowerCase() ===
              location.pathname
                .replace("/", "")
                .replace("-", " ")
                .replace("-", " ")
                .replace("/", "#")
                .split("#")[0] && e.isEdit === true
      )
    );
  }, [id]);

  /******************************************************************************
  Function: handleGridAction
  Argument: action, selectedRow
  Return: 
  Usage:
  1.To handle grid actions 
  *******************************************************************************/

  const handleGridAction = (action, selectedRow) => {
    switch (action) {
      case "Delete":
        handleDelete(selectedRow);
        break;
      default:
        break;
    }
  };
  /******************************************************************************
  Function: handleSelect
  Argument: row
  Return:
  Usage:
  1.To select rows to export
  *******************************************************************************/

  const handleSelect = async (row) => {
    setselectExport(row.map((e) => e.id));
    setRows(
      row.length === 10 ||
        row.length === 15 ||
        row.length === 25 ||
        row.length === 50 ||
        row.length === 100
    );
    if (row.length === 0) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  /******************************************************************************
  Function: handlePageClick
  Argument: event, data
  Return:
  Usage:
  1.To handle page navigation
  *******************************************************************************/

  const handlePageClick = async (event, data) => {
    setLoad(true);
    setPage(data);
    await getSource(data, limit, search, filterBody);
    gridApi.current.redrawRows(select.reportsdata);
  };
  /******************************************************************************
  Function: addNewsource
  Argument:  data
  Return:
  Usage:
  1.To add new source in grid
  *******************************************************************************/

  const addNewsource = (data) => {
    setLoad(true);
    setPage(1);
    dispatch(bulkUpdateBAU(data, id)).then(async (res) => {
      if (res.data.statusCode === 200) {
        toast.success("Saved successfully");
        await getSource(page, limit, search);
        setAddIssuseOpen(false);
        setLoad(false);
        setsaveDisabled(false);
      } else if (res.data.statusCode === 400) {
        setAddIssuseOpen(false);
        setLoad(false);
        toast.error(res.data.responseException.exceptionMessage.result);
        setsaveDisabled(false);
      } else {
        setAddIssuseOpen(false);
        setLoad(false);
        toast.error("Save failed");
        setsaveDisabled(false);
      }

      await gridApi.current.redrawRows(select.reportsdata);
    });
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    boxShadow: "0px 0px 4px 0px rgb(195 0 5 / 10%)",
    position: "relative",
    borderRadius: "14px",
  }));
  const [gridKey, setGridKey] = useState(0);
  const resetAll = async () => {
    dispatch(
      filterBAU({
        timeline: "Year",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: null,
        timeLineEnd: null,
        country: [],
        make: [],
        status: [],
        exportId: [],
      })
    );
    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
    if (search !== "") {
      setPage(1);
      setSearch("");
    }
    getSource(1, limit, "", {
      timeLineStart: null,
      timeLineEnd: null,
      country: [],
      make: [],
      status: [],

      exportId: [],
    });

    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
  };
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      {/* Manage Issues Page Start Here */}
      <Box>
        <Spinner open={load} />
        {/* BaseLayout Start Here */}
        <BaseLayout>
          <Box sx={{ width: "100%", typography: "body1" }}>
            {/* <TabContext value={value}> */}
            {/* <Box>
                <TabList
                  centered
                  textColor="primary"
                  indicatorColor="primary"
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Weekly BAU Status" value="1" />
                  <Tab label="Repots1" value="2" />
                  <Tab label="Reports2" value="3" />
                </TabList>
              </Box> */}
            {/* <TabPanel value="1"> */}
            {/* Action Area - Search by Keyword, Total Record Count, Add Issue, Adv. Filter and Export */}
            <Grid
              container
              spacing={2}
              className={classes.pageActionArea}
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid item xs={12} md={5}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="left"
                  spacing={2}
                >
                  <Grid item xs={"auto"}>
                    <Gridnav onChange={handleInputChange} data={search} />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      className={classes.totalResults}
                    >
                      Total Results:{" "}
                      <span className={classes.totalValue}>
                        {totalRecords ?? 0}
                      </span>
                      &nbsp;
                      <span className={classes.recordText}>Records</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={7}>
                <Grid
                  container
                  spacing={1.75}
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      message="Clear Filters"
                      startIcon={<RestartAltIcon />}
                      onClick={() => resetAll()}
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      disabled={edit.length === 0}
                      variant="outlined"
                      onClick={() => setAddIssuseOpen(true)}
                      message="Bulk Update"
                      startIcon={<AddBoxOutlinedIcon />}
                    />
                  </Grid>

                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      onClick={() => setAdvFilterOpen(true)}
                      message="Advance Filter"
                      startIcon={<FilterListOutlinedIcon />}
                    />
                  </Grid>

                  <Grid item xs={"auto"}>
                    <CustomizedMenus
                      show={show}
                      // setShow={() => setShow(false)}
                      onExportBAU={() => {
                        handleExport();
                        // setShow(false);
                      }}
                      onExportFailure={() => setshowBAU(true)}
                    />
                    {/* <MaterialUIButton
                            variant="outlined"
                            message="Export"
                            onClick={() => {
                              handleExport();
                              setShow(false);
                            }}
                            startIcon={<FileDownloadOutlinedIcon />}
                          /> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Grid Start here */}
            <Grid
              container
              spacing={2}
              className={classes.pageActionArea}
              alignItems="center"
              justifyContent="flex-start"
              sx={{ p: 0 }}
            >
              <Grid item xs={12} className={classes.fullHeight}>
                <AgGrid
                  // onGridReady={onGridReadyManageSource}
                  data={select.reportsdata}
                  header={header}
                  // actionsHandler={handleGridAction}
                  //sorting={handleSort}
                  selectAll={handleSelect}
                  showEdit={() => setShowEditSource(true)}
                  showView={() => SetViewsource(true)}
                  showDelete={() => setshowDelete(true)}
                  edit={editBody}
                  delete={deleteBody}
                  view={viewBody}
                  key={gridKey}
                />
                <UsePagination
                  pageCount={pageCount}
                  count={total}
                  t={totalRecords}
                  total={totalCount}
                  currentPage={page}
                  limit={limit}
                  value={limit}
                  handlePageClick={handlePageClick}
                  onChange={(e) => {
                    setLimit(e.target.value);
                    setPage(1);
                    getSource(1, e.target.value, search, filterBody);
                    // gridApi.current.redrawRows(select.reportsdata);
                  }}
                />
              </Grid>
            </Grid>
            {/* </TabPanel> */}
            {/* <TabPanel value="2">Reports1</TabPanel>
              <TabPanel value="3">Reports2</TabPanel> */}
            {/* </TabContext> */}
          </Box>
        </BaseLayout>

        {/* Advance Filter Model */}
        <MaterialUIModal
          open={advFilterOpen}
          anchor="right"
          body={
            <AdvanceFilters
              filter={filterSource}
              close={() => setAdvFilterOpen(false)}
            />
          }
        />

        <MaterialUIModal
          open={addIssuseOpen}
          anchor="right"
          body={
            <AddSource
              save={saveDisabled}
              onChangeSave={() => setsaveDisabled(true)}
              close={() => setAddIssuseOpen(false)}
              addsource={addNewsource}
            />
          }
        />

        <MaterialUIModal
          open={showEditSource}
          //onClose={() => setShowEditSource(false)}
          anchor="right"
          body={
            <EditSource
              save={disableedit}
              onChangeSave={() => setdisableedit(true)}
              close={() => setShowEditSource(false)}
              EditSourcedata={EditSourcedata}
            />
          }
        />
        {/* <MaterialUIModal
          drawerWidth="md"
          open={viewsource}
          //onClose={() => SetViewsource(false)}
          anchor="right"
          body={
            <ViewSource
              //data={data}
              EditSourcedata={EditSourcedata}
              save={disableedit}
              onChangeSave={() => setdisableedit(true)}
              close={() => SetViewsource(false)}
            />
          }
        /> */}
        <Toasts open={open} onClose={() => setOpen(false)} message={message} />
        <AlertDialog
          open={showDelete}
          onClose={() => setshowDelete(false)}
          // open={isDeleted}
          title={"Confirmation"}
          // maxWidth={"sm"}
          description={`Do you want to delete the record? `} /* - ${Deletedata} */
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    handleGridAction("Delete", Deletedata);
                    setshowDelete(false);
                  }}
                  message="Delete"
                  startIcon={<DoneIcon />}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setshowDelete(false);
                  }}
                  message="CANCEL"
                  startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
        <AlertDialog
          open={showBAU}
          onClose={() => setshowBAU(false)}
          // open={isDeleted}
          title={"Export Failure BAU"}
          // maxWidth={"sm"}
          description={
            <div>
              <br />
              <EditPicker
                max={moment().format("YYYY-MM-DD")}
                // min={moment().subtract(1, "year").format("YYYY-MM-DD")}
                name="startdate"
                value={formik.values.startdate}
                onChange={(e) => {
                  if (e === null) {
                    formik.setFieldValue("startdate", e);
                    // formik.setFieldValue("plannedenddate", e.format("YYYY-MM-DD"))
                  } else {
                    formik.setFieldValue("startdate", e);
                  }
                }}
                error={
                  formik.touched.startdate && Boolean(formik.errors.startdate)
                }
                helperText={formik.touched.startdate && formik.errors.startdate}
                size="small"
                fullWidth
                label="Start Date"
              />
              <br />
              <EditPicker
                max={moment().format("YYYY-MM-DD")}
                // min={formik.values.startdate}
                name="enddate"
                value={formik.values.enddate}
                onChange={(e) => {
                  if (e === null) {
                    formik.setFieldValue("enddate", e);
                    // formik.setFieldValue("plannedenddate", e.format("YYYY-MM-DD"))
                  } else {
                    formik.setFieldValue("enddate", e);
                  }
                }}
                error={formik.touched.enddate && Boolean(formik.errors.enddate)}
                helperText={formik.touched.enddate && formik.errors.enddate}
                size="small"
                fullWidth
                label="End Date"
              />
            </div>
          } /* - ${Deletedata} */
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  message="Export"
                  startIcon={<FileDownloadOutlinedIcon />}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setshowBAU(false);
                    formik.resetForm();
                  }}
                  message="CANCEL"
                  startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
      </Box>
    </div>
  );
}

export default Reports;
