import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    loginArea: {
      width: "100%",
      maxWidth: 460,
    },
    loginBlock: {
      padding: theme.spacing(3),
    },
    logo: {
      margin: theme.spacing(0),
    },
    dataIcon: {
      margin: theme.spacing(0.3, 0, -0.3, 0),
    },
    link: {
      color: "#7947F6",
    },
    passwordHint: {
      border: "1px solid rgba(121, 71, 246, 0.1) !important",
      borderRadius: 4,
      padding: theme.spacing(1.5),
    },
    passwordHeading: {
      fontSize: "12px !important",
      fontWeight: "600 !important",
    },
    passwordSubHeading: {
      fontSize: "12px !important",
      fontWeight: "500 !important",
      lineHeight: "16px !important",
      margin: theme.spacing(1, 0, 1, 0) + "!important",
    },
    passwordText: {
      fontSize: "12px !important",
      fontWeight: "normal !important",
      lineHeight: "12px !important",
    },
    successMessage: {
      fontSize: "12px !important",
      fontWeight: "400 !important",
      lineHeight: "16px !important",
      color: "Green",
    },
    errorMessage: {
      fontSize: "12px !important",
      fontWeight: "700 !important",
      lineHeight: "16px !important",
      color: "Red",
    },
  })
);

export default useStyles;
