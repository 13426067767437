import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { chartUrlScarper } from "redux/dashboard&reports/action";
import "../stackeBarChart/style.scss";
import { Grid, Typography } from "@mui/material";
export default function BarChartCom() {
  const [activeIndex, setActiveIndex] = useState(0);
  const id = useSelector((state) => state.project.dashboard);

  const colors = ["#00C49F", "#0088FE", "#FFBB28", "#FF8042", "red", "pink"];
  const [data, setdata] = useState([]);
  //const activeItem = data[activeIndex];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(chartUrlScarper(id)).then((res) => {
      if (res.data.statusCode === 200 && res.data.total > 0) {
        setdata(res.data.result);
      } else {
        setdata([]);
      }
    });
  }, [id]);
  /******************************************************************************
Function: CustomTooltip
Argument:  active, payload, label
Usage:
1. To display the custom tooltip for the barchart.
*******************************************************************************/

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="chartTitle">
            Failure Reason:{" "}
            <span className="chartValue">{`${payload[0].payload.failure_reason}`}</span>
          </p>
          <p className="chartTitle">
            Failure Count:{" "}
            <span className="chartValue">{`${payload[0].payload.failurecount}`}</span>
          </p>
          {/* <p className="intro">{`Failure Reason - ${payload[0].payload.failure_reason}`}</p>
          <p className="intro">{`Failure Count - ${payload[0].payload.failurecount}`}</p> */}
        </div>
      );
    }

    return null;
  };
  return (
    <>
      {data.length > 0 ? (
        <ResponsiveContainer width="100%" height={263}>
          <BarChart data={data}>
            <CartesianGrid />
            <XAxis
              dataKey="failure_reason"
              interval={0}
              stroke="#000000"
              strokeOpacity="0"
              strokeWidth={1}
              style={{
                fontFamily: "'Inter', sans-serif",
                fontSize: "10px",
                fontWeight: "700",
                color: "black",
              }}
            />
            <YAxis
              stroke="#000000"
              strokeOpacity="0"
              strokeWidth={1}
              style={{
                fontFamily: "'Inter', sans-serif",
                fontSize: "10px",
                fontWeight: "700",
                color: "black",
              }}
            />
            <Tooltip
              cursor={{ fill: "none" }}
              content={<CustomTooltip />}
              wrapperStyle={{ outline: "none" }}
            />
            <Bar barSize={40} dataKey="failurecount">
              {data ? (
                data.map((entry, index) => (
                  <Cell
                    cursor="pointer"
                    fill={colors[index % 20]}
                    key={`cell-${index}`}
                  />
                ))
              ) : (
                <></>
              )}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Grid container spacing={2} minHeight={250}>
          {" "}
          <Grid xs display="flex" justifyContent="center" alignItems="center">
            {" "}
            <Typography variant="body2" gutterBottom>
              No data to display{" "}
            </Typography>{" "}
          </Grid>{" "}
        </Grid>
      )}
    </>
  );
}
