import moment from "moment";
import MultiFloatFilter from "./MultiFloatFilter";

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = moment(cellValue).format("DD-MM-YYYY");
    const dateParts = dateAsString.split("-");
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  inRangeInclusive: true,
  browserDatePicker: true,
};
export const header = [
  {
    headerName: "Project Name",
    field: "projectName",
    filter: MultiFloatFilter,
    filterParams: "projectName",
    lockPosition: "left",
    sortable: true,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    width: 205,
    minWidth: 205,
  },
  {
    headerName: "Country",
    field: "country",
    filter: MultiFloatFilter,
    filterParams: "country",
    lockPosition: "left",
    sortable: true,
    minWidth: 130,
    //maxWidth: 120,
    suppressRowTransform: true,
  },
  {
    headerName: "Make",
    field: "make",
    filter: MultiFloatFilter,
    filterParams: "make",
    lockPosition: "left",

    width: 180,
    minWidth: 180,
    suppressRowTransform: true,
    sortable: true,
  },
  {
    headerName: "Status",
    field: "status",
    filter: MultiFloatFilter,
    filterParams: "status",
    lockPosition: "left",
    width: 220,
    minWidth: 220,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "bau_com",
    },
    sortable: true,
  },
  {
    headerName: "Added On",
    field: "date",
    filter: MultiFloatFilter,
    filterParams: "date",
    lockPosition: "left",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "baudate",
    },
    width: 180,
    minWidth: 180,
    sortable: true,
  },
  {
    headerName: "Action",
    field: "",

    cellRenderer: "iconCellRenderer",
    cellRendererParams: {
      actionType: "bau",
    },
    width: 140,
    minWidth: 140,
    maxWidth: 140,
    cellClass: ["ag-cell-align-center", "locked-col"],
    // cellStyle: { color: 'red', 'background-color': 'green' }
    lockPosition: "left",
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        "  </div>" +
        "</div>",
    },
  },
];
