import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./root-reducer";
import { composeWithDevTools } from "@redux-devtools/extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
const middlewares = [thunk];

const composeEnhancers = composeWithDevTools({
  name: "Redux",
  realtime: true,
  trace: true,
});
;

const Store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

export {  Store };
