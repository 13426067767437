// Default React, React Components, Router and Services Components
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  DialogActions,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { pagename } from "utils/helper";
import data from "../../auth/login/data";

// Importing Custom Components
import AddIssuse from "./addIssuse";
import Textbox from "components/commonComponents/textbox/textbox";
import BaseLayout from "../../../commonComponents/baseLayout/index";
import MaterialUIModal from "components/commonComponents/modal/modal";
import MaterialUIButton from "components/commonComponents/button/button";

// Importing useStyles
import useStyles from "./manageIssues.styles";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

// Import Material Icons
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import AdvanceFilters from "./advanceFilters";
import Gridnav from "components/commonComponents/gridnav/gridnav";
import UsePagination from "components/commonComponents/pagination/pagination";
import Confirmationmodel from "components/commonComponents/modal/confirmationmodel";
import { rowData, columnDefs, headers, headers1, headers2 } from "./Data";
import AgGrid, {
  scrambleAndRefreshAll,
} from "components/commonComponents/grid/agGrid";
import {
  addNewIssue,
  categoryDropdown,
  countryDropdown,
  deleteIssue,
  ExportManageissue,
  updateManageissue,
  fieldDropdown,
  getManageIssue,
  getManageIssueId,
  getTicketID,
  makeDropdown,
  modelDropdown,
  priorityDropdown,
  rerunDropdown,
  resolutionDropdown,
  statusDropdown,
  ticketDropdown,
  typeDropdown,
  userDropdown,
  viewAticketData,
  worktypeDropdown,
  complexityDropdown,
  openstatusDropdown,
  reportedByDropdown,
  addComments,
  providerDropdown,
  websiteDropdown,
  requestTypeDropdown,
  meritUsers,
  reportedByDropdownNew,
} from "redux/manageissues/action";
import {
  addPrefiled,
  fetchAttachments,
  fetchComments,
  fetchDevAttachments,
  fetchManageissue,
  fetchManageissueid,
  fetchResolutionstatus,
  fetchTicketlist,
  fetchusers,
  getCategory,
  getcomplexity,
  getCountry,
  getField,
  getMake,
  getModel,
  getopenstatus,
  getPriority,
  getProvider,
  getRerun,
  getStatus,
  getTicket,
  getType,
  getWebsite,
  getWorktype,
  reportedBy,
  requestType,
  saveFilters,
  setMeritUser,
} from "redux/app/actions";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/commonComponents/spinner/spinner";
import EditIssuse from "./editissue";
import moment from "moment";
import { toast } from "react-toastify";
import ViewIssue from "./viewIssue";
import Toasts from "components/commonComponents/toasts/toasts";
import AlertDialog from "components/commonComponents/alertDialog";
import { utils, writeFile } from "xlsx";
import { Store } from "redux/store";
import { debounce } from "lodash";

function ManageIssues() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);
  const filterData = useSelector((state) => state.manageIssue.filter);
  const location = useLocation();
  const Navigate = useNavigate();
  const [saveDisabled, setsaveDisabled] = useState(false);
  const gridApi = useRef(null);
  const classes = useStyles();
  const [searchc, setSearchc] = useState("");
  const id = useSelector((state) => state.project.manageissue);
  const Pname = useSelector((state) => state.project.manageissueprojectname);
  console.log(Pname);
  const [addIssuseOpen, setAddIssuseOpen] = useState(false);
  const [viewIssue, SetViewIssue] = useState(false);
  const [advFilterOpen, setAdvFilterOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [total, settotal] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  const [totalRecords, settotalRecords] = useState(0);
  const [showEditIssue, setShowEditIssue] = useState(false);
  const [showViewIssue, setShowviewIssue] = useState(false);
  const [showDeleteIssue, setShowDeleteIssue] = useState(false);
  const [selectExport, setselectExport] = useState([]);
  const [Editdata, setEditdata] = useState({});
  const [Deletedata, setDeletedata] = useState({});
  const [data, setData] = useState({});

  const selector = JSON.parse(localStorage.getItem("roleData"));
  const [list, setList] = useState();
  const [add, setAdd] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [editIssuseOpen, setEditIssuseOpen] = useState(false);
  const select = useSelector((state) => state.manageIssue);
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const [ticketno, setTicketno] = useState({});
  const [rows, setRows] = useState();
  // const comments = useSelector((state) => state.manageIssue.comments);
  const [disableedit, setdisableedit] = useState(false);
  // const role = location.state;
  const name = localStorage.getItem("firstName");
  const role = localStorage.getItem("role");
  const someValue = Store.getState().manageIssue.manageIssuedata;

  useEffect(() => {
    if (search.length > 0) {
      const timerId = setTimeout(() => {
        performSearch(search);
      }, 3000);
      return () => clearTimeout(timerId);
    } else {
      setPage(1);

      getIssue(1, limit, search, filterBody); // Update the results state with the fetched data
    }
  }, [search]);

  const performSearch = (value) => {
    setPage(1);
    let encodedValue = encodeURIComponent(value);
    getIssue(1, limit, encodedValue, filterBody); // Update the results state with the fetched data
    // setResults(responseData);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  console.log(filterData);
  // useEffect for fetching results on initial load
  // useEffect(() => {
  //   performSearch(search);
  // }, []);
  /******************************************************************************
Function: filterBody
Argument: 
Usage:
1. Send filter body to get all the issues with the filters.
*******************************************************************************/

  const filterBody = {
    timeLineStart: filterData.timeLineStart,
    timeLineEnd: filterData.timeLineEnd,
    ticketId: filterData.ticketId,
    country: filterData.country.map((e) => e.countryId),
    website: filterData.website.map((e) => e.website1),

    make: filterData.make.map((e) => e.make1),
    model: filterData.model.map((e) => e.model1),
    issueCategory: filterData.issueCategory.map((e) => e.categoryId),
    issueType: filterData.issueType.map((e) => e.typeId),
    issueComplexity: filterData.issueComplexity,
    issueField: filterData.issueField.map((e) => e.fieldId),
    priority: filterData.priority.map((e) => e.priorityId),
    attachments: filterData.attachments,
    rerun: filterData.rerun,
    workType: filterData.workType.map((e) => e.workTypeId),
    status: filterData.status.map((e) => e.statusId),
    // reportedBy: filterData.reportedBy ? [filterData.reportedBy] : [],
    requestTypeId: filterData.requestTypeId?.map((e)=>e.requestTypeId),
    reportedBy: filterData.reportedBy.map((e) => e.reportedById),
    addedBy: filterData.addedBy.map((e) => e.name),
    plannedEndDate: filterData.plannedEndDate,
    resolutionStatus: filterData.resolutionStatus.map((e) => e.resolutionId),
    resolutionDate: filterData.resolutionDate,
    leaseOperator: filterData.leaseOperator.map((e) => e.providerId),
    resolvedBy: filterData.resolvedBy.map((e) => Number(e.userId)),
    jiraId:filterData.jiraId,
    exportticketIds: filterData.exportticketIds,
  };

  useEffect(() => {
    getIssue(page, limit, "");
    dispatch(
      saveFilters({
        timeline: "Year",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: null,
        timeLineEnd: null,
        ticketId: [],
        country: [],
        make: [],
        model: [],
        addedBy: [],
        issueCategory: [],
        issueType: [],
        priority: [],
        leaseOperator: [],
        issueField: [],
        issueComplexity: [],
        attachments: "All",
        requestTypeId:[],
        rerun: 2,
        workType: [],
        website: [],
        status: [],
        reportedBy: [],
        planned: null,
        resolution: null,
        plannedEndDate: null,
        resolutionStatus: [],
        resolutionDate: null,
        resolvedBy: [],
        jiraId:[],
        exportticketIds: [],
      })
    );
    getDataFormAPIForDropdown();
    setAdd(
      selector &&
      selector.roleConfigurations.filter((e) =>
        e.menuName === "Manage Issue/Request"
          ? location.pathname === "/manage-issue-/-request" &&
            e.isAdd === true
          : e.menuName.replace("&", "and").toLowerCase() ===
              location.pathname
                .replace("/", "")
                .replace("-", " ")
                .replace("-", " ")
                .replace("/", "#")
                .split("#")[0] && e.isAdd === true
      )
    );
    // refresh()
    // resize()
    // getSearchIssue(page, limit, "");
  }, [id]);

  const refresh = () => headers;
  // const resize=()=>{
  //   const allColumnIds= [];

  //   gridApi.current.columnApi.getColumns().forEach((column) => {
  //     allColumnIds.push(column.getId());
  //   });
  //   gridApi.current.columnApi.autoSizeColumns(allColumnIds, false);
  // }
  /******************************************************************************
Function: getDataFormAPIForDropdown
Argument: 
Usage:
1. Calling API for Dropdown.
*******************************************************************************/
  const getDataFormAPIForDropdown = () => {
    dispatch(getTicketID(id)).then((res) =>
      dispatch(getTicket(res.data.result))
    );
    dispatch(countryDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getCountry([]));
        } else {
          dispatch(getCountry(res.data.result));
        }
      } else {
        dispatch(getCountry([]));
      }
    });
    // dispatch(reportedByDropdown(id)).then((res) => {
    //   if (res.data.statusCode === 200) {
    //     if (res.data.result === "No records found") {
    //       dispatch(reportedBy([]));
    //     } else {
    //       dispatch(reportedBy(res.data.result));
    //     }
    //   } else {
    //     dispatch(reportedBy([]));
    //   }
    // });
    dispatch(reportedByDropdownNew(id,role.toLowerCase().includes("merit")?false:true)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(reportedBy([]));
        } else {
          dispatch(reportedBy(res.data.result));
        }
      } else {
        dispatch(reportedBy([]));
      }
    });
    dispatch(providerDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getProvider([]));
        } else {
          dispatch(getProvider(res.data.result));
        }
      } else {
        dispatch(getProvider([]));
      }
    });
    dispatch(requestTypeDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(requestType([]));
        } else {
          dispatch(requestType(res.data.result));
        }
      } else {
        dispatch(requestType([]));
      }
    });
    dispatch(websiteDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getWebsite([]));
        } else {
          dispatch(getWebsite(res.data.result));
        }
      } else {
        dispatch(getWebsite([]));
      }
    });
    dispatch(categoryDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getCategory([]));
        } else {
          dispatch(getCategory(res.data.result));
        }
      } else {
        dispatch(getCategory([]));
      }
    });

    dispatch(typeDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getType([]));
        } else {
          dispatch(getType(res.data.result));
        }
      } else {
        dispatch(getType([]));
      }
    });
    dispatch(priorityDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getPriority([]));
        } else {
          dispatch(getPriority(res.data.result));
        }
      } else {
        dispatch(getPriority([]));
      }
    });
    dispatch(fieldDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getField([]));
        } else {
          dispatch(getField(res.data.result));
        }
      } else {
        dispatch(getField([]));
      }
    });
    dispatch(rerunDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getRerun([]));
        } else {
          dispatch(getRerun(res.data.result));
        }
      } else {
        dispatch(getRerun([]));
      }
    });
    dispatch(complexityDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getcomplexity([]));
        } else {
          dispatch(getcomplexity(res.data.result));
        }
      } else {
        dispatch(getcomplexity([]));
      }
    });

    dispatch(worktypeDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getWorktype([]));
        } else {
          dispatch(getWorktype(res.data.result));
        }
      } else {
        dispatch(getWorktype([]));
      }
    });
    dispatch(statusDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getStatus([]));
        } else {
          dispatch(getStatus(res.data.result));
        }
      } else {
        dispatch(getStatus([]));
      }
    });
    dispatch(ticketDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(fetchTicketlist([]));
        } else {
          dispatch(fetchTicketlist(res.data.result));
        }
      } else {
        dispatch(fetchTicketlist([]));
      }
    });
    dispatch(resolutionDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(fetchResolutionstatus([]));
        } else dispatch(fetchResolutionstatus(res.data.result));
      } else {
        dispatch(fetchResolutionstatus([]));
      }
    });
    dispatch(openstatusDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getopenstatus([]));
        } else dispatch(getopenstatus(res.data.result));
      } else {
        dispatch(getopenstatus([]));
      }
    });

    dispatch(userDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(fetchusers([]));
        } else dispatch(fetchusers(res.data.result));
      } else {
        dispatch(fetchusers([]));
      }
    });
    dispatch(meritUsers(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(setMeritUser([]));
        } else dispatch(setMeritUser(res.data.result));
      } else {
        dispatch(setMeritUser([]));
      }
    });
  };

  const handleSort = (field, sort) => {};
  /**************************************
Function: getIssue
Argument:page,limit,search,body
Usage:
1. To generate getIssue data
*******************************************/
  const getIssue = (
    page,
    limit,
    search,
    body = {
      timeLineStart: null,
      timeLineEnd: null,
      plannedEndDate: null,
      resolutionDate: null,
      attachments: "All",
      rerun: 2,
      ticketId: [],
      country: [],
      make: [],
      model: [],
      issueCategory: [],
      issueType: [],
      issueField: [],
      requestTypeId: [],
      issueComplexity: [],
      priority: [],
      workType: [],
      status: [],
      reportedBy: [],
      addedBy: [],
      leaseOperator: [],
      resolutionStatus: [],
      website: [],
      resolvedBy: [],
      jiraId:[],
      exportticketIds: [],
    }
  ) => {
    dispatch(getManageIssue(page, limit, search, body, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            settotalRecords(0);
            //setState([]);
            dispatch(fetchManageissue([]));
          } else {
            dispatch(fetchManageissue(res.data.result));
          }
          setLoad(false);
          //setState(res.data.result);

          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        }
        else{
          setLoad(false);
          settotalCount(0);
          settotalRecords(0);
          //setState([]);
          dispatch(fetchManageissue([]));
        }
      }
    });
  };

  /**************************************
Function: handleExport
Argument:
Usage:
1. To export data
*******************************************/
  /*   const format1 = "DD/MM/YYYY | hh:mm A";
  moment(props.data.dateRaised).format(format1); */
  const exportdata = selectExport.toString();
  const exportIds = exportdata.split(",");

  const handleExport = (body) => {
    setLoad(true);
    dispatch(
      ExportManageissue(
        page,
        limit,
        search,
        (body = {
          timeLineStart: filterData.timeLineStart,
          timeLineEnd: filterData.timeLineEnd,
          ticketId: filterData.ticketId,
          country: filterData.country.map((e) => e.countryId),
          website: filterData.website.map((e) => e.website1),

          make: filterData.make.map((e) => e.make1),
          model: filterData.model.map((e) => e.model1),
          issueCategory: filterData.issueCategory.map((e) => e.categoryId),
          issueType: filterData.issueType.map((e) => e.typeId),
          issueComplexity: filterData.issueComplexity,
          issueField: filterData.issueField.map((e) => e.fieldId),
          priority: filterData.priority.map((e) => e.priorityId),
          attachments: filterData.attachments,
          rerun: filterData.rerun,
          workType: filterData.workType.map((e) => e.workTypeId),
          status: filterData.status.map((e) => e.statusId),
          requestTypeId: filterData.requestTypeId.map((e)=>e.requestTypeId),
          // reportedBy: filterData.reportedBy ? [filterData.reportedBy] : [],
          reportedBy: filterData.reportedBy.map((e) => e.reportedById),
          addedBy: filterData.addedBy.map((e) => e.name),
          plannedEndDate: filterData.plannedEndDate,
          resolutionStatus: filterData.resolutionStatus.map(
            (e) => e.resolutionId
          ),
          resolutionDate: filterData.resolutionDate,
          leaseOperator: filterData.leaseOperator.map((e) => e.providerId),
          resolvedBy: filterData.resolvedBy.map((e) => Number(e.userId)),
          jiraId:filterData.jiraId,
          exportticketIds: rows ? ["All"] : exportIds,
        }),
        id
      )
    ).then((res) => {
      setLoad(false);
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);

      /* const commentHeaders = res.data.result[0].developerComments
        ? res.data.result[0].developerComments.map(
            (comment, index) => `Developer Comment ${index + 1}`
          )
        : ""; */
      utils.sheet_add_aoa(ws, [
        [
          "Ticket Id",
          "Jira Ticket Id",
          "Date Raised",
          "File Uploaded Date",
          "Project Name",
          "Country",
          "Make",
          "Model",
          "Issue Field",
          "Issue Type",
          "Business Priority",
          "Request Type",
          "Feedback",
          "Attachments",
          "Rerun",
          "Merit Justification",
          // "Work Type",
          "Status",
          "Reported By",
          "Added By",
          "Time To First Response TAT",
          "Time To First Response",
          "Assigned To",
          "Assigned Date",
          "Issue Category",
          "Scrapper Complexity",
          "Time to Resolution",
          "Resolution Status",
          "Actual Closed Date",
          "Resolution Attachments",
          //...commentHeaders,
          "Developer Comment_1",
          "Developer Comment_2",
          "Developer Comment_3",
          "Developer Comment_4",
          "Developer Comment_5",
          "Developer Comment_6",
          "Developer Comment_7",
          "Developer Comment_8",
          "Developer Comment_9",
          "Developer Comment_10",
        ],
      ]);

      const rows = res.data.result.map((row) => {
        var comments = 0;
        const commentValues = row.developerComments
          ? row.developerComments
              .split("``")
              .slice(-10)
              .map((comment) => {
                // console.log(values, comment);
                // values[`Comment ${comments + 1}`] = comment;
                return comment;
              }, {})
          : "";
        return [
          row.ticketId,
          row.jiraId,
          row.dateRaised ? moment(row.dateRaised).format("DD/MM/YYYY") : "",
          row.dateofFile ? moment(row.dateofFile).format("DD/MM/YYYY") : "",
          row.projectName,
          row.country,
          row.make,
          row.model,
          row.issueField,

          row.issueType,
          row.issuePriority,
          row.requestTypeName ? row.requestTypeName : "",
          row.feedback,
          row.attachments,
          row.rerun,
          row.meritJustification,
          // row.workType,
          row.status,
          row.reportedBy,
          row.addedBy,
          row.firstResponseTAT? moment(row.firstResponseTAT).format("DD/MM/YYYY HH:mm:ss")
          : "",
          row.plannedEndDate
            ? moment(row.plannedEndDate).format("DD/MM/YYYY HH:mm:ss")
            : "",
          row.assignedTo,
          row.assignedDate ? moment(row.assignedDate).format("DD/MM/YYYY HH:mm:ss") : "",
          row.issueCategory,

          row.complexity,
          row.resolutionDate
            ? moment(row.resolutionDate).format("DD/MM/YYYY HH:mm:ss")
            : "",
          row.resolutionStatus,
          row.actualClosedDate
            ? moment(row.actualClosedDate).format("DD/MM/YYYY HH:mm:ss")
            : "",
          row.devAttachments,
          //row.developerComments,
          ...commentValues,
        ];
      });

      utils.sheet_add_json(ws, rows, {
        origin: "A2",
        skipHeader: true,
      });

      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, `manage_issue/request_${moment().format("DMMMyy")}.csv`);
    });
    getIssue(page, limit, search, filterBody);
  };
  const handleExportLease = (body) => {
    setLoad(true);
    dispatch(
      ExportManageissue(
        page,
        limit,
        search,
        (body = {
          timeLineStart: filterData.timeLineStart,
          timeLineEnd: filterData.timeLineEnd,
          ticketId: filterData.ticketId,
          country: filterData.country.map((e) => e.countryId),
          website: filterData.website.map((e) => e.website1),

          make: filterData.make.map((e) => e.make1),
          model: filterData.model.map((e) => e.model1),
          issueCategory: filterData.issueCategory.map((e) => e.categoryId),
          issueType: filterData.issueType.map((e) => e.typeId),
          issueComplexity: filterData.issueComplexity,
          issueField: filterData.issueField.map((e) => e.fieldId),
          priority: filterData.priority.map((e) => e.priorityId),
          attachments: filterData.attachments,
          rerun: filterData.rerun,
          workType: filterData.workType.map((e) => e.workTypeId),
          status: filterData.status.map((e) => e.statusId),
          requestTypeId: filterData.requestTypeId.map((e)=>e.requestTypeId),
          // reportedBy: filterData.reportedBy ? [filterData.reportedBy] : [],
          reportedBy: filterData.reportedBy.map((e) => e.reportedById),
          addedBy: filterData.addedBy.map((e) => e.name),
          plannedEndDate: filterData.plannedEndDate,
          resolutionStatus: filterData.resolutionStatus.map(
            (e) => e.resolutionId
          ),
          resolutionDate: filterData.resolutionDate,
          leaseOperator: filterData.leaseOperator.map((e) => e.providerId),
          resolvedBy: filterData.resolvedBy.map((e) => Number(e.userId)),
          jiraId:filterData.jiraId,
          exportticketIds: rows ? ["All"] : exportIds,
        }),
        id
      )
    ).then((res) => {
      setLoad(false);
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);

      /* const commentHeaders = res.data.result[0].developerComments
        ? res.data.result[0].developerComments.map(
            (comment, index) => `Developer Comment ${index + 1}`
          )
        : ""; */
      utils.sheet_add_aoa(ws, [
        [
          "Ticket Id",
          "Jira Ticket Id",
          "Date Raised",
          "File Uploaded Date",
          "Country",
          "Leasing Provider",
          "Make",
          "Model",
          "Issue Field",
          "Issue Type",
          "Business Priority",
          "Request Type",

          "Feedback",
          "Attachments",
          "Rerun",
          "Merit Justification",
          // "Work Type",
          "Status",
          "Reported By",
          "Added By",
          "Time To First Response TAT",
          "Time To First Response",
          "Assigned To",
          "Assigned Date",
          "Issue Category",
          "Scrapper Complexity",
          "Time to Resolution",
          "Resolution Status",
          "Actual Closed Date",
          "Resolution Attachments",
          //...commentHeaders,
          "Developer Comment_1",
          "Developer Comment_2",
          "Developer Comment_3",
          "Developer Comment_4",
          "Developer Comment_5",
          "Developer Comment_6",
          "Developer Comment_7",
          "Developer Comment_8",
          "Developer Comment_9",
          "Developer Comment_10",
        ],
      ]);

      const rows = res.data.result.map((row) => {
        var comments = 0;
        const commentValues = row.developerComments
          ? row.developerComments
              .split("``")
              .slice(-10)
              .map((comment) => {
                // console.log(values, comment);
                // values[`Comment ${comments + 1}`] = comment;
                return comment;
              }, {})
          : "";
        return [
          row.ticketId,
          row.jiraId,
          row.dateRaised ? moment(row.dateRaised).format("DD/MM/YYYY") : "",
          row.dateofFile ? moment(row.dateofFile).format("DD/MM/YYYY") : "",
          row.country,
          row.leasingProviderName,
          row.make,
          row.model,
          row.issueField,

          row.issueType,
          row.issuePriority,
          row.requestTypeName ? row.requestTypeName : "",

          row.feedback,
          row.attachments,
          row.rerun,
          row.meritJustification,
          // row.workType,
          row.status,
          row.reportedBy,
          row.addedBy,
          row.firstResponseTAT? moment(row.firstResponseTAT).format("DD/MM/YYYY HH:mm:ss")
          : "",
          row.plannedEndDate
            ? moment(row.plannedEndDate).format("DD/MM/YYYY HH:mm:ss")
            : "",
          row.assignedTo,
          row.assignedDate ? moment(row.assignedDate).format("DD/MM/YYYY HH:mm:ss") : "",
          row.issueCategory,

          row.complexity,
          row.resolutionDate
            ? moment(row.resolutionDate).format("DD/MM/YYYY HH:mm:ss")
            : "",
          row.resolutionStatus,
          row.actualClosedDate
            ? moment(row.actualClosedDate).format("DD/MM/YYYY HH:mm:ss")
            : "",
          row.devAttachments,
          //row.developerComments,
          ...commentValues,
        ];
      });

      utils.sheet_add_json(ws, rows, {
        origin: "A2",
        skipHeader: true,
      });

      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, `manage_issue/request_${moment().format("DMMMyy")}.csv`);
    });
    getIssue(page, limit, search, filterBody);
  };
  const handleExportTA = (body) => {
    setLoad(true);
    dispatch(
      ExportManageissue(
        page,
        limit,
        search,
        (body = {
          timeLineStart: filterData.timeLineStart,
          timeLineEnd: filterData.timeLineEnd,
          ticketId: filterData.ticketId,
          country: filterData.country.map((e) => e.countryId),
          website: filterData.website.map((e) => e.website1),

          make: filterData.make.map((e) => e.make1),
          model: filterData.model.map((e) => e.model1),
          issueCategory: filterData.issueCategory.map((e) => e.categoryId),
          issueType: filterData.issueType.map((e) => e.typeId),
          issueComplexity: filterData.issueComplexity,
          issueField: filterData.issueField.map((e) => e.fieldId),
          priority: filterData.priority.map((e) => e.priorityId),
          attachments: filterData.attachments,
          rerun: filterData.rerun,
          workType: filterData.workType.map((e) => e.workTypeId),
          status: filterData.status.map((e) => e.statusId),
          requestTypeId: filterData.requestTypeId.map((e)=>e.requestTypeId),
          // reportedBy: filterData.reportedBy ? [filterData.reportedBy] : [],
          reportedBy: filterData.reportedBy.map((e) => e.reportedById),
          addedBy: filterData.addedBy.map((e) => e.name),
          plannedEndDate: filterData.plannedEndDate,
          resolutionStatus: filterData.resolutionStatus.map(
            (e) => e.resolutionId
          ),
          resolutionDate: filterData.resolutionDate,
          leaseOperator: filterData.leaseOperator.map((e) => e.providerId),
          resolvedBy: filterData.resolvedBy.map((e) => Number(e.userId)),
          jiraId:filterData.jiraId,
          exportticketIds: rows ? ["All"] : exportIds,
        }),
        id
      )
    ).then((res) => {
      setLoad(false);
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);

      /* const commentHeaders = res.data.result[0].developerComments
        ? res.data.result[0].developerComments.map(
            (comment, index) => `Developer Comment ${index + 1}`
          )
        : ""; */
      utils.sheet_add_aoa(ws, [
        [
          "Ticket Id",
          "Jira Ticket Id",
          "Date Raised",
          "File Uploaded Date",
          "Project Name",
          "Country",
          "Website",
          "Make",
          "Model",
          "Issue Field",
          "Issue Type",
          "Business Priority",
          "Request Type",

          "Feedback",
          "Attachments",
          "Rerun",
          "Merit Justification",
          // "Work Type",
          "Status",
          "Reported By",
          "Added By",
          "Time To First Response TAT",
          "Time To First Response",
          "Assigned To",
          "Assigned Date",
          "Issue Category",
          "Scrapper Complexity",
          "Time to Resolution",
          "Resolution Status",
          "Actual Closed Date",
          "Resolution Attachments",
          //...commentHeaders,
          "Developer Comment_1",
          "Developer Comment_2",
          "Developer Comment_3",
          "Developer Comment_4",
          "Developer Comment_5",
          "Developer Comment_6",
          "Developer Comment_7",
          "Developer Comment_8",
          "Developer Comment_9",
          "Developer Comment_10",
        ],
      ]);

      const rows = res.data.result.map((row) => {
        var comments = 0;
        const commentValues = row.developerComments
          ? row.developerComments
              .split("``")
              .slice(-10)
              .map((comment) => {
                // console.log(values, comment);
                // values[`Comment ${comments + 1}`] = comment;
                return comment;
              }, {})
          : "";
        return [
          row.ticketId,
          row.jiraId,
          row.dateRaised ? moment(row.dateRaised).format("DD/MM/YYYY") : "",
          row.dateofFile ? moment(row.dateofFile).format("DD/MM/YYYY") : "",
          row.projectName,
          row.country,
          row.website,
          row.make,
          row.model,
          row.issueField,

          row.issueType,
          row.issuePriority,
          row.requestTypeName ? row.requestTypeName : "",

          row.feedback,
          row.attachments,
          row.rerun,
          row.meritJustification,
          // row.workType,
          row.status,
          row.reportedBy,
          row.addedBy,
          row.firstResponseTAT? moment(row.firstResponseTAT).format("DD/MM/YYYY HH:mm:ss")
          : "",
          row.plannedEndDate
            ? moment(row.plannedEndDate).format("DD/MM/YYYY HH:mm:ss")
            : "",
          row.assignedTo,
          row.assignedDate ? moment(row.assignedDate).format("DD/MM/YYYY HH:mm:ss") : "",
          row.issueCategory,

          row.complexity,
          row.resolutionDate
            ? moment(row.resolutionDate).format("DD/MM/YYYY HH:mm:ss")
            : "",
          row.resolutionStatus,
          row.actualClosedDate
            ? moment(row.actualClosedDate).format("DD/MM/YYYY HH:mm:ss")
            : "",
          row.devAttachments,
          //row.developerComments,
          ...commentValues,
        ];
      });

      utils.sheet_add_json(ws, rows, {
        origin: "A2",
        skipHeader: true,
      });

      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, `manage_issue/request_${moment().format("DMMMyy")}.csv`);
    });
    getIssue(page, limit, search, filterBody);
  };
  /**************************************
Function: filterIssue
Argument:data, reset = false
Usage:
1. To filter the data
*******************************************/
  const filterIssue = async (data, reset = false) => {
    setLoad(true);
    setPage(1);
    dispatch(getManageIssue(1, limit, search, data, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            //setState([]);
            settotalRecords(0);
            dispatch(fetchManageissue([]));
            setLoad(false);
          } else {
            dispatch(fetchManageissue(res.data.result));
          }
          setLoad(false);
          //setState(res.data.result);
          if (!reset) {
            setAdvFilterOpen(false);
          }

          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        }
      }
    });
    //
  };

  /**************************************
Function: handleSelect
Argument: row
Usage:
1. To select the data to export
*******************************************/
  const handleSelect = async (row) => {
    setselectExport(row.map((e) => e.ticketId));
    setRows(
      row.length === 10 ||
        row.length === 15 ||
        row.length === 25 ||
        row.length === 50 ||
        row.length === 100
    );
    if (row.length === 0) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  /******************************************************************************
Function: handlePageClick
Argument: data
Usage:
1. Pagination change action.
*******************************************************************************/
  const handlePageClick = async (event, data) => {
    setLoad(true);
    setPage(data);
    await getIssue(data, limit, search, filterBody);
  };
  /******************************************************************************
Function: editBody
Argument: data
Usage:
1. Will edit grid data in the page.
*******************************************************************************/
  const editBody = async (data) => {
    setLoad(true);
    setShowEditIssue(true);
    setLoad(false);
  };
  /******************************************************************************
Function: viewBody
Argument: data
Usage:
1. Will get grid data in the view.
*******************************************************************************/
  const viewBody = async (data) => {
    setLoad(true);
    SetViewIssue(true);
    setLoad(false);
  };
  /******************************************************************************
Function: cloneBody
Argument: data
Usage:
1. Will get grid data in the view.
*******************************************************************************/
  const cloneBody = (data) => {
    setAddIssuseOpen(true);
  };
  /******************************************************************************
Function: addNewissue
Argument: data
Usage:
1. To add new issue to the grid.
*******************************************************************************/
  const addNewissue = (data) => {
    setLoad(true);
    dispatch(addNewIssue(data, id)).then(async (res) => {
      if (res.data.statusCode === 201) {
        dispatch(addPrefiled({}));

        setPage(1);
        await getIssue(1, limit, search);
        toast.success("Issue saved successfully");
        setAddIssuseOpen(false);
        setsaveDisabled(false);
        setLoad(false);
      } else {
        dispatch(addPrefiled({}));
        setPage(1);
        setAddIssuseOpen(false);
        setPage(1);
        toast.error("Issue save failed");
        setsaveDisabled(false);
        setLoad(false);
      }

    });
  };
  const addNewcomment = () => {
    setLoad(true);
    getIssue(1, limit, search);
  };
  /******************************************************************************
Function: EditIssuedata
Argument: data
Usage:
1. To edit issue.
*******************************************************************************/
  const EditIssuedata = (data, values) => {
    setLoad(true);
    dispatch(addComments(values, id)).then(async (res) => {
      if (res.status === 200) {
        dispatch(fetchComments([]));
        dispatch(updateManageissue(data, id)).then(async (res) => {
          if (res.data.statusCode === 200) {
            toast.success("Issue saved successfully");
            await getIssue(page, limit, search);
            setShowEditIssue(false);
            SetViewIssue(false);
            setdisableedit(false);
            setLoad(false);
          } else if (res.data.statusCode === 400) {
            toast.error(res.data.result);
            setShowEditIssue(false);
            setdisableedit(false);
            setLoad(false);
          } else {
            setShowEditIssue(false);
            SetViewIssue(false);
            setdisableedit(false);
            setLoad(false);
            toast.error("Issue save failed");
          }

        });
      } else {
        dispatch(fetchComments([]));
        setShowEditIssue(false);
        SetViewIssue(false);
        setdisableedit(false);
        setLoad(false);
        toast.error("Issue save failed");
      }
    });
  };
  /******************************************************************************
Function: deleteBody
Argument: event
Usage:
1. get the selected record and delete
*******************************************************************************/
  const deleteBody = (event) => {
    setDeletedata(event.ticketId);
    //setRolename(event.);
  };
  /******************************************************************************
Function: handleDelete
Argument: ticketId
Usage:
1. To delete the selected records
*******************************************************************************/
  const handleDelete = (ticketId) => {
    setLoad(true);
    dispatch(deleteIssue(ticketId, id)).then(async (res) => {
      if (res.data.statusCode === 200) {
        toast.success("Issue deleted successfully");
        await getIssue(page, limit, search);
      } else {
        setLoad(false);
        toast.error("Failed to delete");
      }
    });
  };
  /******************************************************************************
Function: handleGridAction
Argument: action, selectedRow
Usage:
 1. handleGridAction -  Performs the actions inside grid (delete).
*******************************************************************************/
  const handleGridAction = (action, selectedRow) => {
    switch (action) {
      case "Delete":
        handleDelete(selectedRow);
        break;
      default:
        break;
    }
  };
  const [gridKey, setGridKey] = useState(0);
  const resetAll = async () => {
    dispatch(
      saveFilters({
        timeline: "Year",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: null,
        timeLineEnd: null,
        ticketId: [],
        country: [],
        make: [],
        model: [],
        addedBy: [],
        issueCategory: [],
        issueType: [],
        priority: [],
        issueField: [],
        requestTypeId: [],
        issueComplexity: [],
        attachments: "All",
        rerun: 2,
        workType: [],
        status: [],
        reportedBy: [],
        planned: null,
        resolution: null,
        plannedEndDate: null,
        resolutionStatus: [],
        resolutionDate: null,
        leaseOperator: [],
        resolvedBy: [],
        exportticketIds: [],
        jiraId:[],
        website: [],
      })
    );
    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
    if (search !== "") {
      setPage(1);
      setSearch("");
      // gridApi.current.api.redrawRows(select.manageIssuedata);
    }
    await getIssue(1, limit, "", {
      timeLineStart: null,
      timeLineEnd: null,
      ticketId: [],
      country: [],
      make: [],
      model: [],
      addedBy: [],
      issueCategory: [],
      issueType: [],
      priority: [],
      issueField: [],
      issueComplexity: [],
      attachments: "All",
      rerun: 2,
      workType: [],
      status: [],
      requestTypeId: [],
      reportedBy: [],
      leaseOperator: [],
      plannedEndDate: null,
      resolutionStatus: [],
      resolutionDate: null,
      resolvedBy: [],
      exportticketIds: [],
      jiraId:[],
      website: [],
    });
  };

  const refreshCells = () => {};

  return (
    <Box>
      <Spinner open={load} />
      <BaseLayout>
        <Grid
          container
          spacing={2}
          className={classes.pageActionArea}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item xs={12} md={6}>
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={2}
            >
              <Grid item xs={"auto"}>
                <Gridnav onChange={handleInputChange} data={search} />
              </Grid>
              <Grid item xs={"auto"}>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className={classes.totalResults}
                >
                  Total Results:{" "}
                  <span className={classes.totalValue}>
                    {totalRecords ?? 0}
                  </span>
                  &nbsp;
                  <span className={classes.recordText}>Records</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              spacing={1.75}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  message="Clear Filters"
                  startIcon={<RestartAltOutlinedIcon />}
                  onClick={() => resetAll()}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  message={role.toLowerCase().includes("merit")?"Raise Issue":"Raise Issue/Request"}
                  disabled={add.length === 0}
                  startIcon={<AddBoxOutlinedIcon />}
                  onClick={() => setAddIssuseOpen(true)}
                />
              </Grid>

              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => setAdvFilterOpen(true)}
                  message="Advance Filter"
                  startIcon={<FilterListOutlinedIcon />}
                />
              </Grid>
              {show === true ? (
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    message="Export"
                    onClick={() => {
                      Pname === "TRANSACTIONAL ANALYSIS"
                        ? handleExportTA()
                        : Pname === "LEASE OPERATOR"
                        ? handleExportLease()
                        : handleExport();
                      setShow(false);
                    }}
                    startIcon={<FileDownloadOutlinedIcon />}
                  />
                </Grid>
              ) : (
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    message="Export"
                    onClick={() => handleExport()}
                    disabled
                    startIcon={<FileDownloadOutlinedIcon />}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Grid Start here */}
        <Grid
          container
          spacing={2}
          className={classes.pageActionArea}
          alignItems="center"
          justifyContent="flex-start"
          sx={{ p: 0 }}
        >
          <Grid item xs={12} className={classes.fullHeight}>
            <AgGrid
              // onGridReady={onGridReadyManageIssue}
              data={select.manageIssuedata}
              header={
                Pname === "TRANSACTIONAL ANALYSIS"
                  ? headers2
                  : Pname === "LEASE OPERATOR"
                  ? headers1
                  : headers
              }
              actionsHandler={handleGridAction}
              selectAll={handleSelect}
              showEdit={() => setShowEditIssue(true)}
              showView={() => SetViewIssue(true)}
              showDelete={() => setShowDeleteIssue(true)}
              edit={editBody}
              sideBar={"filters"}
              delete={deleteBody}
              view={viewBody}
              clone={cloneBody}
              key={gridKey}
            />
            <UsePagination
              pageCount={pageCount}
              count={total}
              t={totalRecords}
              total={totalCount}
              currentPage={page}
              limit={limit}
              value={limit}
              handlePageClick={handlePageClick}
              onChange={(e) => {
                setLimit(e.target.value);
                setPage(1);
                getIssue(1, e.target.value, search, filterBody);
                // gridApi.current.api.redrawRows(select.manageIssuedata);
              }}
            />
          </Grid>
        </Grid>
        {/* Drawer Add Issuse */}
        {/* <Spinner open={load}/> */}
        <MaterialUIModal
          drawerWidth="md"
          open={showEditIssue}
          //onClose={() => setShowEditIssue(false)}
          anchor="right"
          body={
            <EditIssuse
              save={disableedit}
              data={ticketno}
              onChangeSave={() => setdisableedit(true)}
              close={() => setShowEditIssue(false)}
              EditIssuedata={EditIssuedata}
              addnewComment={addNewcomment}
            />
          }
        />

        <MaterialUIModal
          open={advFilterOpen}
          anchor="right"
          body={
            <AdvanceFilters
              filter={filterIssue}
              close={() => setAdvFilterOpen(false)}
            />
          }
        />
        <MaterialUIModal
          open={addIssuseOpen}
          anchor="right"
          body={
            <AddIssuse
              save={saveDisabled}
              onChangeSave={() => setsaveDisabled(true)}
              close={() => setAddIssuseOpen(false)}
              addNewissue={addNewissue}
            />
          }
        />
        <MaterialUIModal
          drawerWidth="md"
          open={viewIssue}
          //onClose={() => SetViewIssue(false)}
          anchor="right"
          body={
            <ViewIssue
              data={data}
              EditIssuedata={EditIssuedata}
              save={disableedit}
              addnewComment={addNewcomment}
              onChangeSave={() => setdisableedit(true)}
              close={() => SetViewIssue(false)}
            />
          }
        />
        <Toasts open={open} onClose={() => setOpen(false)} message={message} />
        <AlertDialog
          open={showDeleteIssue}
          onClose={() => setShowDeleteIssue(false)}
          // open={isDeleted}
          title={"Confirmation"}
          // maxWidth={"sm"}
          description={`Do you want to delete issue id - ${Deletedata}`}
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    handleGridAction("Delete", Deletedata);
                    setShowDeleteIssue(false);
                  }}
                  message="Delete"
                  startIcon={<DoneIcon />}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setShowDeleteIssue(false);
                  }}
                  message="CANCEL"
                  startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
      </BaseLayout>
    </Box>
  );
}

export default ManageIssues;
