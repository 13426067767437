export const headers = [
  {
    headerName: "Pages/Permissions",
    field: "menuName",
    minWidth: 240,
    maxWidth: 240,
    lockPosition: "left",
  },
  {
    headerName: "View",
    field: "isView",
    minWidth: 50,
    maxWidth: 75,
    lockPosition: "left",
    cellRenderer: "anchorCellRenderer",
    cellRendererParams: {
      actionType: "view",
    },
  },
  {
    headerName: "Add",
    field: "isAdd",
    minWidth: 50,
    maxWidth: 75,
    lockPosition: "left",
    cellRenderer: "anchorCellRenderer",
    cellRendererParams: {
      actionType: "add",
    },
  },
  {
    headerName: "Edit",
    field: "isEdit",
    minWidth: 50,
    maxWidth: 75,
    lockPosition: "left",
    cellRenderer: "anchorCellRenderer",
    cellRendererParams: {
      actionType: "edit",
    },
  },

  {
    headerName: "Delete",
    field: "isDelete",
    minWidth: 50,
    maxWidth: 75,
    lockPosition: "left",
    cellRenderer: "anchorCellRenderer",
    cellRendererParams: {
      actionType: "delete",
    },
  },
];


