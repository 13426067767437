import MaterialUIButton from "components/commonComponents/button/button";
import Textbox from "components/commonComponents/textbox/textbox";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  isValidEmailAddress,
  onlySting,
  Required,
} from "utils/validation helper";
import MultipleAutocomplete from "components/commonComponents/autocomplete/multipleAutocomplete";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocompletedropdown from "components/commonComponents/autocomplete/autocompletedropdown";
import DoneIcon from "@mui/icons-material/Done";
import AlertDialog from "components/commonComponents/alertDialog";
import useStyles from "./userManagement.styles";
import { useEffect } from "react";
import { getRoles } from "redux/rolemanagement/action";
import { fetchRoles, fetchRolesDropdown } from "redux/app/actions";
import { rolesDropdown } from "redux/manageissues/action";

function AddNewUser(props) {
  const data = useSelector((state) => state.manageIssue);
  const select = useSelector((state) => state.userManagement);
  const name = localStorage.getItem("firstName");
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const id=useSelector((state) => state.project.user)

  /******************************************************************************
  Function: validate
  Argument: values
  Return: errors
  Usage:
  1.To validate the form
  *******************************************************************************/

  const validate = (values) => {
    const errors = {};

    if (!Required(values.lastName)) {
      errors.lastName = true;
    } else if (!onlySting(values.lastName)) {
      errors.lastName = "Alphabets and underscore are allowed";
      // toast.error("only alphabets and under score  are allowed")
    } else if (values.lastName.length > 15) {
      errors.lastName = "Exceeds 15 characters";
    }
    if (!Required(values.firstName)) {
      errors.firstName = true;
    } else if (!onlySting(values.firstName)) {
      errors.firstName = "Alphabets and underscore are allowed";
      // toast.error("only alphabets and under score  are allowed")
    } else if (values.firstName.length > 15) {
      errors.firstName = "Exceeds 15 characters";
    }

    if (!Required(values.roleId)) {
      errors.roleId = true;
    }
    if (!Required(values.email)) {
      errors.email = true;
    } else if (!isValidEmailAddress(values.email)) {
      errors.email = "Invalid email ID";
      // toast.error("Invalid email address")
    } else if (values.email.length > 50) {
      errors.email = "Exceeds 50 characters";
    }

    return errors;
  };
  useEffect(() => {
    dispatch(rolesDropdown(id)).then((res) => {
      if (res) {
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            dispatch(fetchRolesDropdown([]));
          } else {
            dispatch(fetchRolesDropdown(res.data.result));
          }
        }
      }
    });
  }, []);
  /******************************************************************************
  Function: reset
  Argument: 
  Return: 
  Usage:
  1.To reset the form
  *******************************************************************************/

  const reset = () => {
    if (formik && formik.values) {
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues: {
      lastName: "",
      firstName: "",
      roleId: null,
      isActive: true,
      email: "",
      createdBy: name,
    },
    validate,
    onSubmit: (values) => {
      // props.onChangeSave(true);
      props.addNewUser({
        lastName: values.lastName,
        firstName: values.firstName,
        roleId: values.roleId.id,
        isActive: true,
        email: values.email,
        createdBy: name,
      });
    },
  });

  return (
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                Add New User
              </Typography>
            </Grid>
            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs="auto">
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => reset()}
                    message="RESET"
                    startIcon={<RestartAltOutlinedIcon />}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                          JSON.stringify(formik.values) &&
                        data.files.length === 0
                          ? props.close()
                          : setShow(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}
        <Grid item xs={12}>
          <Textbox
            required
            size="small"
            label="First Name"
            fullWidth
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={12}>
          <Textbox
            required
            size="small"
            label="Last Name"
            fullWidth
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Textbox
            required
            size="small"
            label="Email ID"
            fullWidth
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocompletedropdown
            required
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            id="Role"
            name="roleId"
            label="Role"
            placeholder="roleId"
            value={formik.values.roleId}
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("roleId", e)}
            getOptionLabel={(e) => e.roleName}
            error={formik.touched.roleId && Boolean(formik.errors.roleId)}
            helperText={formik.touched.roleId && formik.errors.roleId}
            options={select.roles.map((e) => {
              return {
                label: e.roleName,
                id: e.roleId,
              };
            })}
          />
        </Grid>

        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="SAVE"
                disabled={props.save}
                startIcon={<SaveOutlinedIcon />}
                onClick={() => formik.handleSubmit()}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                size="small"
                variant="outlined"
                onClick={() => reset()}
                message="RESET"
                startIcon={<RestartAltOutlinedIcon />}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="CANCEL"
                startIcon={<EventBusyOutlinedIcon />}
                onClick={() => {
                  JSON.stringify(formik.initialValues) ===
                    JSON.stringify(formik.values) && data.files.length === 0
                    ? props.close()
                    : setShow(true);
                }}
              />
            </Grid>
          </Grid>
          <AlertDialog
            open={show}
            onClose={() => setShow(false)}
            // open={isDeleted}
            title={"Confirmation"}
            // maxWidth={"sm"}
            description={`Do you want to discard the changes ?`}
            action={
              <Grid
                container
                alignItems="center"
                justifyContent="right"
                spacing={2}
                className={classes.alertDialogbtn}
              >
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      reset();

                      props.close();
                      setShow(false);
                    }}
                    message="Yes"
                    startIcon={<DoneIcon />}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      setShow(false);
                    }}
                    message="No"
                    startIcon={<CloseIcon />}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
        {/* Action Area End Here */}
      </Grid>
    </Box>
  );
}

export default AddNewUser;
