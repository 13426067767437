import { Box, Grid, MenuItem, Typography } from "@mui/material";
import BaseLayout from "components/commonComponents/baseLayout";
import Label from "components/commonComponents/label/label";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "../../../commonComponents/dropdown/dropdown";
import { logo } from "../../../../assets/index";

// Importing useStyles
import useStyles from "./forgotPassword.styles";
import Textbox from "components/commonComponents/textbox/textbox";
import MaterialUIButton from "components/commonComponents/button/button";
import LoginIcon from "@mui/icons-material/Login";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { saveFilters } from "redux/app/actions";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  isValidEmailAddress,
  onlySting,
  passwordMismatch,
  Required,
  validPassword,
} from "utils/validation helper";
import { forgotPassword, login } from "redux/usermanagement/action";
import Spinner from "components/commonComponents/spinner/spinner";
import AlertDialog from "components/commonComponents/alertDialog";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import KeyIcon from "@mui/icons-material/Key";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
function ForgotPassword() {
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const user = useSelector((state) => state.userManagement.isLoading);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const classes = useStyles();

  
  /******************************************************************************
  Function: validate
  Argument: values
  Return: errors
  Usage:
  1.To validate the form
  *******************************************************************************/
  const validate = (values) => {
    const errors = {};
    if (!Required(values.email)) {
      errors.email = true;
    } else if (!isValidEmailAddress(values.email)) {
      errors.email = "Invalid email ID";
    }
    /*  if (!Required(values.password)) {
      errors.password = true;
    } else if (!validPassword(values.password)) {
      errors.password = "password min length is 8 and max length is 15 ";
    }
    if (!Required(values.confirmPassword)) {
      errors.confirmPassword = true;
    } else if (!validPassword(values.confirmPassword)) {
      errors.confirmPassword = "password min length is 8 and max length is 15 ";
    } else if (!passwordMismatch(values.password, values.confirmPassword)) {
      errors.confirmPassword = "passwords did not match";
    } */

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      //token: searchParams.get("Id"),
      email: "",
      /* password: "",
      confirmPassword: "", */
    },
    validate,
    onSubmit: async (values) => {
     
      setLoad(true);
      setShow(true);
      await dispatch(forgotPassword(values)).then((res) => {
      
        if (res.data.statusCode === 201) {
          setError(false);
          setMessage(res.data.result);
          setLoad(false);
        } else if (res.data.statusCode === 404) {
          setError(true);
          setShow(false);
          setError(res.data.responseException.exceptionMessage);
          setLoad(false);
        } else if (res.data.statusCode === 400) {
          setError(true);
          setShow(false);
          setError(res.data.responseException.exceptionMessage);
          setLoad(false);
        }
      });
    },
  });
   /******************************************************************************
  Function: reset
  Argument: 
  Return: 
  Usage:
  1.To reset the form
  *******************************************************************************/
  const reset = () => {
    if (formik && formik.values) {
      formik.resetForm();
      setError(false);
    }
  };
  return (
    <>
      <Box>
        <Spinner open={load} />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh" }}
        >
          <Grid item xs={12} sm={12} md={7} lg={6} xl={5}>
            <Box className={classes.loginArea}>
              <BaseLayout>
                {/* Action Area - Search by Keyword, Total Record Count, Add Issue, Adv. Filter and Export */}
                <Grid
                  container
                  spacing={2}
                  className={classes.loginBlock}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Grid item xs={12} textAlign="center">
                    <img
                      className={classes.logo}
                      src={logo}
                      width="120"
                      alt="JATO Dynamics"
                    />
                  </Grid>
                  <Grid item xs={12} textAlign="center" sx={{ mt: 1 }}>
                    <Typography variant="h6" gutterBottom>
                      Forgot Password
                    </Typography>
                  </Grid>

                  <Grid item xs={12} textAlign="center">
                    <div className={classes.errorMessage}>{error}</div>
                  </Grid>
                  {show && !load ? (
                    <>
                      <Grid item xs={12} textAlign="center">
                        <div>{message}</div>
                      </Grid>
                      <Grid item xs={12} textAlign="center" sx={{ mt: 1 }}>
                        <Typography variant="caption" gutterBottom>
                          If you do not receive the password reset email
                          shortly, please check your spam folder.
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Textbox
                          required
                          size="small"
                          label="Email ID"
                          fullWidth
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />
                        {/*  <div className={classes.successMessage}>
                      Provided email ID have been matched to our database
                    </div>
                    <div className={classes.errorMessage}>
                      Provided email ID have been not matched to our database
                    </div> */}
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Grid
                          container
                          spacing={1.75}
                          justifyContent="flex-end"
                          alignItems="flex-end"
                        >
                          <Grid item xs={"auto"}>
                            <MaterialUIButton
                              variant="outlined"
                              message="BACK"
                              onClick={() => navigate(-1)}
                              startIcon={<ArrowBackIcon />}
                            />
                          </Grid>
                          <Grid item xs={"auto"}>
                            <MaterialUIButton
                              variant="outlined"
                              message="RESET PASSWORD"
                              onClick={() => {
                                formik.handleSubmit();
                              }}
                              startIcon={<KeyIcon />}
                            />
                          </Grid>
                          <Grid item xs={"auto"}>
                            <MaterialUIButton
                              variant="outlined"
                              message="CLEAR"
                              onClick={() => reset()}
                              startIcon={<RestartAltIcon />}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {/*  <Grid item xs={12} sm={12} md={12}>
                    <Box className={classes.passwordHint}>
                      <Typography
                        variant="body2"
                        gutterBottom
                        className={classes.passwordHeading}
                      >
                        New Password and Confirm Password Should match
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        className={classes.passwordSubHeading}
                      >
                        Password should be a minimum of 8-character limit, and
                        it should cover the below-mentioned criteria
                      </Typography>

                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        className={classes.passwordText}
                      >
                        - There should be at least one uppercase letter
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        className={classes.passwordText}
                      >
                        - There should be at least one lowercase letter
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        className={classes.passwordText}
                      >
                        - There should be at least one special character
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        className={classes.passwordText}
                      >
                        - There should be at least one numeric
                      </Typography>
                    </Box>
                  </Grid> */}
                </Grid>
              </BaseLayout>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ForgotPassword;
