import MultiFloatFilter from "./MultiFloatFilter";

export const headers = [
  {
    headerName: "First Name",
    field: "firstName",
    filter: MultiFloatFilter,
    filterParams: "firstName",
    lockPosition: "left",
    sortable: true,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: "left",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatfname",
    },
    minWidth: 190,
    //maxWidth: 238,
  },

  {
    headerName: "Last Name",
    field: "lastName",
    filter: MultiFloatFilter,
    filterParams: "lastName",
    lockPosition: "left",
    sortable: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatlname",
    },
    minWidth: 190,
    //maxWidth: 238,
  },
  {
    headerName: "Email ID",
    field: "email",
    filter: MultiFloatFilter,
    filterParams: "email",
    lockPosition: "left",
    sortable: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatemail",
    },
    minWidth: 300,
    // maxWidth: 350,
  },
  {
    headerName: "Role",
    field: "roleName",
    filter: MultiFloatFilter,
    filterParams: "roleName",
    lockPosition: "left",
    sortable: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatrole",
    },
    minWidth: 190,
    // maxWidth: 250,
  },
  {
    headerName: "Action",
    lockPosition: "right",
    field: "",
    cellRenderer: "iconCellRenderer",
    cellRendererParams: {
      actionType: "adminaction2",
    },
    minWidth: 130,
    maxWidth: 130,
    cellClass: ["ag-cell-align-center", "locked-col"],
    // cellStyle: { color: 'red', 'background-color': 'green' }
    width: 100,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        "  </div>" +
        "</div>",
    },
  },
];
