import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Switch, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import useStyles from "../dashboardAndReports.styles";
import MaterialUIButton from "components/commonComponents/button/button";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import BaseLayout from "components/commonComponents/baseLayout";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AgGrid from "components/commonComponents/grid/agGrid";
import UsePagination from "components/commonComponents/pagination/pagination";
import Gridnav from "components/commonComponents/gridnav/gridnav";
import { headers } from "./Data";
import { useDispatch, useSelector } from "react-redux";
import { utils, writeFile } from "xlsx";
import moment from "moment";
import {
  ExportCountryWise,
  ExportPricePoint,
  ExportSourceUrlNoRecords,
  exportDeliveryData,
  getCountryWise,
  getDeliveryData,
  getDetailCountryWise,
  getPricePontChanges,
  getSourceUrlNoRecords,
} from "redux/dashboard&reports/action";
import {
  fetchCountrywise,
  fetchDetailCountrywise,
  fetchPricepointchanges,
  deliveryDetailData,
  deliveryFilter,
} from "redux/app/actions";
import AdvanceFilters from "../advanceFilters";
import MaterialUIModal from "components/commonComponents/modal/modal";
import AdvanceFiltersNo from "./advanceFilter";
import Spinner from "components/commonComponents/spinner/spinner";
import { useNavigate } from "react-router-dom";
function Delivered(props) {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [advFilterOpen, setAdvFilterOpen] = useState(false);
  const dispatch = useDispatch();
  const gridApi = useRef(null);
  const select = useSelector((state) => state.dashboardandreports);
  const id = useSelector((state) => state.project.dashboard);

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [total, settotal] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  const [totalRecords, settotalRecords] = useState(0);
  const [load, setLoad] = useState(true);
  const [selectExport, setselectExport] = useState([]);
  const [rows, setRows] = useState();

  const filterData = useSelector(
    (state) => state.dashboardandreports.filterdelivery
  );
  const navigate = useNavigate();
  useEffect(() => {
    getDeliveryRecords(page, limit, "");
    dispatch(
      deliveryFilter({
        timeline: "Year",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: null,
        timeLineEnd: null,
        country: [],
        make: [],
        model: [],

        exportId: [],
      })
    );
  }, [id]);
  /******************************************************************************
Function: filterBody
Argument: 
Usage:
1. Send filter body to get all the issues with the filters.
*******************************************************************************/

  const filterBody = {
    timeLineStart: filterData.timeLineStart,
    timeLineEnd: filterData.timeLineEnd,
    country: filterData.country.map((e) => String(e.countryId)),
    make: filterData.make.map((e) => e.make1),
    model: filterData.model.map((e) => e.model1),
    exportId: filterData.exportId,
  };
  const [gridKey, setGridKey] = useState(0);
  const resetAll = async () => {
    dispatch(
      deliveryFilter({
        timeline: "Year",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: null,
        timeLineEnd: null,
        country: [],
        make: [],
        model: [],
        exportId: [],
      })
    );
    setGridKey(gridKey + 1);

    if (search !== "") {
      setPage(1);
      setSearch("");
    }
    getDeliveryRecords(1, limit, "", {
      timeLineStart: null,
      timeLineEnd: null,
      country: [],
      make: [],
      model: [],
      exportId: [],
    });

    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
  };
  /**************************************
Function: getDeliveryRecords
Argument:page,limit,search,body
Usage:
1. To generate source url with no records data
*******************************************/
  const getDeliveryRecords = (
    page,
    limit,
    search,
    body = {
      timeLineStart: filterData.timeLineStart,
      timeLineEnd: filterData.timeLineEnd,
      country: [],
      make: [],
      model: [],
      exportId: [],
    }
  ) => {
    dispatch(getDeliveryData(page, limit, search, body, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            settotalRecords(0);
            //setState([]);
            dispatch(deliveryDetailData([]));
          } else {
            dispatch(deliveryDetailData(res.data.result));
          }
          setLoad(false);
          //setState(res.data.result);

          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        } else {
          dispatch(deliveryDetailData([]));
          settotalRecords(0);
          settotal(0);
        }
      }
    });
  };
  /**************************************
Function: handleSelect
Argument: row
Usage:
1. To select the data to export
*******************************************/
  const handleSelect = async (row) => {
    console.log(row);
    setselectExport(row.map((e) => e.id));

    setRows(
      row.length === 10 ||
        row.length === 15 ||
        row.length === 25 ||
        row.length === 50 ||
        row.length === 100
    );

    console.log(row.length);
    if (row.length === 0) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  const exportdata = selectExport.toString();
  const exportIds = exportdata.split(",");
  console.log(selectExport, exportIds);
  /**************************************
Function: handleExport
Argument:
Usage:
1. To export data
*******************************************/
  const handleExport = (body) => {
    console.log(rows);
    setLoad(true);
    dispatch(
      exportDeliveryData(
        page,
        limit,
        search,
        (body = {
          timeLineStart: filterData.timeLineStart,
          timeLineEnd: filterData.timeLineEnd,
          country: filterData.country.map((e) => String(e.countryId)),
          make: filterData.make.map((e) => e.make1),
          model: filterData.model.map((e) => e.model1),
          exportId: rows ? ["All"] : exportIds,
        }),
        id
      )
    ).then((res) => {
      setLoad(false);
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);

      utils.sheet_add_aoa(ws, [
        [
          "Date",
          "Country",
          "Make",
          "Model",
          "Count of File Name",
          "Sum of Delivered records",
          "Distinct of version",
          "New records",
          "Updated records",
          "Removed records",
        ],
      ]);
      const rows = res.data.result.map((row) => [
        moment(row.date).format("DD-MM-YYYY"),
        row.country,
        row.make,
        row.model,
        row.fileNameCount,
        row.deliveredRecords,
        row.version,
        row.newRecords,
        row.updatedRecords,
        row.removedRecords,
      ]);
      utils.sheet_add_json(ws, rows, {
        origin: "A2",
        skipHeader: true,
      });

      utils.book_append_sheet(wb, ws, "Report");
      writeFile(
        wb,
        `DeliverdFileAndDataCount_${moment().format("DMMMyy")}.csv`
      );
    });
    getDeliveryRecords(page, limit, search, filterBody);
  };
  /******************************************************************************
Function: handlePageClick
Argument: data
Usage:
1. Pagination change action.
*******************************************************************************/

  const handlePageClick = async (event, data) => {
    setLoad(true);
    setPage(data);
    await getDeliveryRecords(data, limit, search, filterBody);
  };
  /**************************************
Function: filterSource
Argument:page,limit,search,body
Usage:
1. To call the filter api
*******************************************/
  const filterSource = async (data, reset = false) => {
    setLoad(true);
    setPage(1);
    dispatch(getDeliveryData(1, limit, search, data, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            settotalRecords(0);
            //setState([]);
            dispatch(deliveryDetailData([]));
          } else {
            dispatch(deliveryDetailData(res.data.result));
          }
          setLoad(false);
          //setState(res.data.result);
          if (!reset) {
            setAdvFilterOpen(false);
          }
          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        }
      }
    });
    //
  };

  return (
    <div>
      <Box>
        <Spinner open={load} />
        {/* BaseLayout Start Here */}
        <BaseLayout>
          {/* Action Area - Search by Keyword, Total Record Count, Add Issue, Adv. Filter and Export */}
          <Grid
            container
            spacing={2}
            className={classes.pageActionArea}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={12} md={6}>
              <Grid
                container
                alignItems="center"
                justifyContent="left"
                spacing={2}
              >
                <Grid item xs={"auto"}>
                  <Gridnav
                    onChange={async (e) => {
                      if (e.target.value === "") {
                        setPage(1);
                        setSearch("");
                        await getDeliveryRecords(1, limit, "", filterBody);
                      } else {
                        setPage(1);
                        setSearch(e.target.value);
                        await getDeliveryRecords(
                          1,
                          limit,
                          e.target.value,
                          filterBody
                        );
                      }
                    }}
                    data={search}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    className={classes.totalResults}
                  >
                    Total Results:{" "}
                    <span className={classes.totalValue}>
                      {totalRecords ?? 0}
                    </span>
                    &nbsp;
                    <span className={classes.recordText}>Records</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={1.75}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => navigate(-1)}
                    message="Back"
                    startIcon={<ArrowBackIcon />}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    message="Clear Filters"
                    startIcon={<RestartAltOutlinedIcon />}
                    onClick={() => resetAll()}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => setAdvFilterOpen(true)}
                    message="Advance Filter"
                    startIcon={<FilterListOutlinedIcon />}
                  />
                </Grid>

                {show === true ? (
                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      message="Export"
                      onClick={() => {
                        handleExport();
                        setShow(false);
                      }}
                      startIcon={<FileDownloadOutlinedIcon />}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      message="Export"
                      onClick={() => handleExport()}
                      disabled
                      startIcon={<FileDownloadOutlinedIcon />}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* Grid Start here */}
          <Grid
            container
            spacing={2}
            className={classes.pageActionArea}
            alignItems="center"
            justifyContent="flex-start"
            sx={{ p: 0 }}
          >
            <Grid item xs={12} className={classes.fullHeight}>
              <AgGrid
                // onGridReady={onGridReadyManageSource}
                data={select.detaildeliverydata}
                header={headers}
                // actionsHandler={handleGridAction}
                //sorting={handleSort}
                selectAll={handleSelect}
                key={gridKey}
              />
              <UsePagination
                pageCount={pageCount}
                count={total}
                t={totalRecords}
                total={totalCount}
                currentPage={page}
                limit={limit}
                value={limit}
                handlePageClick={handlePageClick}
                onChange={(e) => {
                  setLimit(e.target.value);
                  setPage(1);
                  let encodedValue = encodeURIComponent(e.target.value);

                  getDeliveryRecords(1, encodedValue, search, filterBody);
                }}
              />
            </Grid>
          </Grid>
        </BaseLayout>

        {/* Advance Filter Model */}
        <MaterialUIModal
          open={advFilterOpen}
          anchor="right"
          body={
            <AdvanceFiltersNo
              close={() => setAdvFilterOpen(false)}
              filter={filterSource}
            />
          }
        />
      </Box>

      {/* Manage Issues Page Start Here */}
    </div>
  );
}

export default Delivered;
