import { Box, Grid, MenuItem, TextField, Typography } from "@mui/material";
import BaseLayout from "components/commonComponents/baseLayout";
import Label from "components/commonComponents/label/label";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dropdown from "../../../commonComponents/dropdown/dropdown";
import data from "./data.json";
import { logo } from "../../../../assets/index";

// Importing useStyles
import useStyles from "./login.styles";
import Textbox from "components/commonComponents/textbox/textbox";
import MaterialUIButton from "components/commonComponents/button/button";
import LoginIcon from "@mui/icons-material/Login";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { fetchRole, saveFilters } from "redux/app/actions";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  isValidEmailAddress,
  onlySting,
  Required,
} from "utils/validation helper";
import { login } from "redux/usermanagement/action";
import Spinner from "components/commonComponents/spinner/spinner";
import { viewRoles } from "redux/rolemanagement/action";
import { checkTokenExpiry, removeToken, setCookie } from "utils/helper";
import { useRef } from "react";

function Login() {
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.userManagement.isLoading);
  const location = useLocation();

  const [error, setError] = useState("");
  const [clear, setclear] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectToPage = () => {
    const redirectUrl = encodeURIComponent("https://uat.merit.jato.com/");

    // const redirectUrl = encodeURIComponent("http://localhost:3000");
    localStorage.removeItem("role");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("roleId");
    localStorage.removeItem("email");
    localStorage.removeItem("roleData");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");

    window.location.href = `${process.env.REACT_APP_MANAGE_ISSUE_BASE_URL}api/v1/users/signout`;

    //  const redirectUrl = encodeURIComponent("https://jatodashboarddevui.azurewebsites.net");
    //  const redirectUrl = encodeURIComponent("http://localhost:3000");

    // window.location.href =
    // `https://jatodashboarddevapi.azurewebsites.net/api/v1/users/signin?redirectUrl=${redirectUrl}`;
  };

  const classes = useStyles();
  const inputRef = useRef();
  // useEffect(() => {
  // setTimeout(() => {
  //   redirectToPage()
  // }, 5000);
  // }, []);

  /******************************************************************************
  Function: validate
  Argument: values
  Return: errors
  Usage:
  1.To validate the form
  *******************************************************************************/
  const validate = (values) => {
    const errors = {};
    if (!Required(values.email)) {
      errors.email = true;
    } else if (!isValidEmailAddress(values.email)) {
      errors.email = "Invalid email ID";
      // toast.error("Invalid email address")
    }
    if (!Required(values.password)) {
      errors.password = true;
    }
    return errors;
  };

  /******************************************************************************
  Function: reset
  Argument: 
  Return: 
  Usage:
  1.To reset the form
  *******************************************************************************/
  const reset = () => {
    if (formik && formik.values) {
      setclear(true);
      formik.resetForm();
      setError(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: async (values) => {
      await dispatch(login(values)).then((res) => {
        setOpen(true);
        if (res.data.statusCode === 400) {
          setError(res.data.responseException.exceptionMessage);
        } else {
          // localStorage.setItem('roleData', JSON.stringify(res.data.result));
          dispatch(fetchRole(res.data.result));
          localStorage.setItem("lastName", res.data.result.lastName);
          localStorage.setItem("firstName", res.data.result.firstName);
          localStorage.setItem("userId", res.data.result.userId);
          localStorage.setItem("token", res.data.result.jwtToken);
          localStorage.setItem("refreshToken", res.data.result.refreshToken);
          localStorage.setItem(
            "roleData",
            JSON.stringify(res.data.result.roleDeatils)
          );
          localStorage.setItem("role", res.data.result.roleName);
          localStorage.setItem("email", res.data.result.email);
          localStorage.setItem("roleId", res.data.result.roleId);
          // setCookie("token", res.data.result.jwtToken);
          navigate("/manage-issue-/-request");
          window.location.reload(false);
          setError(false);
        }
      });
    },
  });

  return (
    <>
      <Box id="click">
        <Spinner open={user} />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh" }}
        >
          <Grid item xs={12} sm={12} md={7} lg={6} xl={5}>
            <Box className={classes.loginArea}>
              <BaseLayout>
                {/* Action Area - Search by Keyword, Total Record Count, Add Issue, Adv. Filter and Export */}
                <Grid
                  container
                  spacing={2}
                  className={classes.loginBlock}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Grid item xs={12} textAlign="center">
                    <img
                      className={classes.logo}
                      src={logo}
                      width="120"
                      alt="JATO Dynamics"
                    />
                  </Grid>
                  <Grid item xs={12} textAlign="center" sx={{ mt: 1 }}>
                    <Typography id="typo" variant="h6" gutterBottom auto>
                      Welcome to JATO Dynamics
                    </Typography>
                  </Grid>
                  <Grid item xs={12} textAlign="center">
                    <div className={classes.successLogout}>
                      {location.state}
                    </div>
                  </Grid>

                  <Grid item xs={12} textAlign="center" sx={{ mt: 2 }}>
                    <MaterialUIButton
                      variant="outlined"
                      message="Sign with other account"
                      startIcon={<LoginIcon />}
                      onClick={redirectToPage}
                    />
                  </Grid>

                  <Grid item xs={12} textAlign="center" sx={{ mt: 1 }}>
                    <Typography variant="caption" gutterBottom>
                      © JATO Dynamics Limited. All rights reserved.
                    </Typography>
                  </Grid>
                </Grid>
              </BaseLayout>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Login;
