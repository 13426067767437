import { AppActionsTypes } from "./actions-types";

const INITIAL_STATE = {
  country: [],
  make: [],
  // model: [],
  sourceurl: [],
  status: [],
  nextrunstatus: [],
  reasonForFailure: [],
  manageScrapercount: {},
  manageScraperdata: [],
  filter: {
    timeline: "Year",
    quater: "",
    startdate: null,
    enddate: null,
    timeLineStart: null,
    timeLineEnd: null,
    country: [],
    make: [],
    // model: [],
    sourceurl: [],
    active: "All",
    status: [],
    lastrun: null,
    nextrun: null,
    nextrunstatus: [],
    website: [],
    reasonForFailure: [],
    exportId: [],
  },
};
const manageScraperReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppActionsTypes.SOURCEURL:
      return {
        ...state,
        sourceurl: action.payload,
      };
    case AppActionsTypes.SCRAPER_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case AppActionsTypes.NEXT_RUN_STATUS:
      return {
        ...state,
        nextrunstatus: action.payload,
      };
    case AppActionsTypes.FILTER_SCRAPER:
      return {
        ...state,
        filter: action.payload,
      };
    case AppActionsTypes.SCRAPER_REASON:
      return {
        ...state,
        reasonForFailure: action.payload,
      };
    case AppActionsTypes.FETCH_MANAGE_SCRAPER:
      return {
        ...state,
        manageScraperdata: action.payload,
      };
    case AppActionsTypes.FETCH_MANAGE_SCRAPER_COUNT:
      return {
        ...state,
        manageScrapercount: action.payload,
      };

    default:
      return state;
  }
};

export default manageScraperReducer;
