import MultiFloatFilter from "./MultiFloatFilter";

// export const rowData = [
//   {
//     id: "1",
//     projectName: "Monthly Payments",
//     qcInputFile: "File Name G Ver0.1.xls",
//     qcOutputFile: "File Name G Ver0.1.xls",
//     qcStartDate: "2023-02-13T10:54:23",
//     qcEndDate: "2023-02-13T10:54:23",
//     qcStatus: "PASS",
//     ftpUploadStatus: "YES",
//   },
//   {
//     id: "2",
//     projectName: "Monthly Payments",
//     qcInputFile: "File Name G Ver0.1.xls",
//     qcOutputFile: "File Name G Ver0.1.xls",
//     qcStartDate: "2023-02-13T10:54:23",
//     qcEndDate: "2023-02-13T10:54:23",
//     qcStatus: "FAIL",
//     ftpUploadStatus: "NO",
//   },
// ];

export const headers = [
  {
    headerName: "ID",
    field: "qualityID",
    filter: MultiFloatFilter,
    filterParams: "qualityID",
    lockPosition: "left",
    sortable: true,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    width: 100,
    minWidth: 100,
  },
  {
    headerName: "Project Name",
    field: "projectName",
    // filter: MultiFloatFilter,
    // filterParams: "projectName",
    lockPosition: "left",
    sortable: true,
    width: 130,
    minWidth: 130,
  },
  {
    headerName: "Country",
    field: "country",
    filter: MultiFloatFilter,
    filterParams: "country",
    lockPosition: "left",
    sortable: true,
    minWidth: 130,
    //maxWidth: 120,
    suppressRowTransform: true,
  },

  {
    headerName: "Make",

    lockPosition: "left",
    field: "make",
    filter: MultiFloatFilter,
    filterParams: "make",
    width: 180,
    minWidth: 180,
    //maxWidth: 120,
    sortable: true,
    // cellClass: "trimText",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatmake",
    },
  },
  {
    headerName: "Date Of File",
    field: "dateOfFile",
    filter: MultiFloatFilter,
    filterParams: "dateOfFile",
    lockPosition: "left",
    sortable: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatEndOfFile",
    },
    width: 200,
    minWidth: 200,
  },
  {
    headerName: "QC Input File",
    field: "inputFile",
    filter: MultiFloatFilter,
    filterParams: "inputFile",
    lockPosition: "left",
    sortable: true,
    cellRenderer: "actionCellRenderer",
    cellRendererParams: {
      actionType: "formatInputFile",
    },
    width: 200,
    minWidth: 200,
  },
  {
    headerName: "QC Output File",
    field: "outputFile",
    filter: MultiFloatFilter,
    filterParams: "outputFile",
    lockPosition: "left",
    sortable: true,
    cellRenderer: "actionCellRenderer",
    cellRendererParams: {
      actionType: "formatOutputFile",
    },
    width: 200,
    minWidth: 200,
  },
  {
    headerName: "QC Start Date",
    field: "startDate",
    filter: MultiFloatFilter,
    filterParams: "startDate",
    lockPosition: "left",
    sortable: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatStartDate",
    },
    width: 150,
    minWidth: 150,
  },
  {
    headerName: "QC End Date",
    field: "endDate",
    filter: MultiFloatFilter,
    filterParams: "endDate",
    lockPosition: "left",
    sortable: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatEndDate",
    },
    width: 150,
    minWidth: 150,
  },
  {
    headerName: "QC Status",
    field: "status",
    filter: MultiFloatFilter,
    filterParams: "status",
    lockPosition: "left",
    sortable: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "qcStatus",
    },
    width: 100,
    minWidth: 100,
  },
  {
    headerName: "FTP Upload Status",
    field: "uploadStatus",
    filter: MultiFloatFilter,
    filterParams: "uploadStatus",
    lockPosition: "left",
    sortable: true,
    width: 170,
    minWidth: 170,
  },
  {
    headerName: "Action",
    lockPosition: "right",
    field: "",
    cellRenderer: "iconCellRenderer",
    cellRendererParams: {
      actionType: "qualityaction",
    },
    minWidth: 90,
    maxWidth: 90,
    cellClass: ["ag-cell-align-center", "locked-col"],
    width: 90,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="justify-content:center">' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        "  </div>" +
        "</div>",
    },
  },
];
export const headers1 = [
  {
    headerName: "ID",
    field: "qualityID",
    filter: MultiFloatFilter,
    filterParams: "qualityID",
    lockPosition: "left",
    sortable: true,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    width: 100,
    minWidth: 100,
  },
  {
    headerName: "Project Name",
    field: "projectName",
    // filter: MultiFloatFilter,
    // filterParams: "projectName",
    lockPosition: "left",
    sortable: true,
    width: 130,
    minWidth: 130,
  },
  {
    headerName: "Country",
    field: "country",
    filter: MultiFloatFilter,
    filterParams: "country",
    lockPosition: "left",
    sortable: true,
    minWidth: 130,
    //maxWidth: 120,
    suppressRowTransform: true,
  },

  {
    headerName: "Leasing Provider",
    lockPosition: "left",
    field: "leasingProviderName",
    filter: MultiFloatFilter,
    filterParams: "leasingProviderName",
    wrapText: true,
    autoHeight: true,
    width: 190,
    minWidth: 190,
    sortable: true,

    cellClass: "trimText",
  },
  {
    headerName: "Date Of File",
    field: "dateOfFile",
    filter: MultiFloatFilter,
    filterParams: "dateOfFile",
    lockPosition: "left",
    sortable: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatEndOfFile",
    },
    width: 200,
    minWidth: 200,
  },
  {
    headerName: "QC Input File",
    field: "inputFile",
    filter: MultiFloatFilter,
    filterParams: "inputFile",
    lockPosition: "left",
    sortable: true,
    cellRenderer: "actionCellRenderer",
    cellRendererParams: {
      actionType: "formatInputFile",
    },
    width: 200,
    minWidth: 200,
  },
  {
    headerName: "QC Output File",
    field: "outputFile",
    filter: MultiFloatFilter,
    filterParams: "outputFile",
    lockPosition: "left",
    sortable: true,
    cellRenderer: "actionCellRenderer",
    cellRendererParams: {
      actionType: "formatOutputFile",
    },
    width: 200,
    minWidth: 200,
  },
  {
    headerName: "QC Start Date",
    field: "startDate",
    filter: MultiFloatFilter,
    filterParams: "startDate",
    lockPosition: "left",
    sortable: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatStartDate",
    },
    width: 150,
    minWidth: 150,
  },
  {
    headerName: "QC End Date",
    field: "endDate",
    filter: MultiFloatFilter,
    filterParams: "endDate",
    lockPosition: "left",
    sortable: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatEndDate",
    },
    width: 150,
    minWidth: 150,
  },
  {
    headerName: "QC Status",
    field: "status",
    filter: MultiFloatFilter,
    filterParams: "status",
    lockPosition: "left",
    sortable: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "qcStatus",
    },
    width: 100,
    minWidth: 100,
  },
  {
    headerName: "FTP Upload Status",
    field: "uploadStatus",
    filter: MultiFloatFilter,
    filterParams: "uploadStatus",
    lockPosition: "left",
    sortable: true,
    width: 170,
    minWidth: 170,
  },
  {
    headerName: "Action",
    lockPosition: "right",
    field: "",
    cellRenderer: "iconCellRenderer",
    cellRendererParams: {
      actionType: "qualityaction",
    },
    minWidth: 90,
    maxWidth: 90,
    cellClass: ["ag-cell-align-center", "locked-col"],
    width: 90,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="justify-content:center">' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        "  </div>" +
        "</div>",
    },
  },
];
