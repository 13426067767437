import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    totalResults: {
      fontSize: 12,
      fontFamily: "'Inter', sans-serif",
      fontWeight: "500 !important",
      color: "#333333",
    },
    uploadTitle: {
      fontWeight: "700 !important",
    },
    uploadFiles: {
      width: "100%",
      border: "1px solid #afafaf !important",
      padding: theme.spacing(0) + "!important",
      borderRadius: "5px",
      background: "#F8F5FF",
      overflow: "auto",
    },
    totalValue: {
      fontSize: 12,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600,
      color: "#2C0957",
    },
    recordText: {
      fontSize: 12,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400,
      color: "#666666",
    },
    errorMessages: {
      maxHeight: 250,
      overflow: "auto",
      listStyle: "none",
      padding: theme.spacing(1, 1),
      border: "1px solid #bdbdbd",
      borderRadius: "5px",
      margin: theme.spacing(1.5, 0, 0, 0),
    },
    messages: {
      padding: theme.spacing(1, 1),
      borderBottom: "1px solid #bdbdbd",
      color: "red",
      fontSize: "14px",
    },
    errorMessagesTitle: {
      fontWeight: "bold",
    },
    // Page Action Area
    pageActionArea: {
      padding: theme.spacing(0, 2, 2, 2),
    },

    borderLine: {
      border: "1px solid #afafaf !important",
      borderRadius: "14px",
      padding: theme.spacing(2, 3, 3, 3),
    },
    titleLine: {
      color: "#7947F6",
      fontWeight: 600 + "!important",
      fontSize: "16px !important",
    },
    artLine: {
      color: "#333333 !important",
    },
    artSubText: {
      color: "#333333 !important",
    },
    dataIcon: {
      margin: theme.spacing(0.3, 0, -0.3, 0),
    },
    alertDialogbtn: {
      margin: theme.spacing(0, 1, 1, 0),
      color: "#333333",
    },
    readOnly: {
      background: "green !important",
    },
    fullHeight: {
      height: "calc(100vh - 140px)",
    },
  })
);

export default useStyles;
