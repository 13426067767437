import React from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import useStyles from './textbox.styles';
// import useStylesArea from './textarea.styles';

function Textbox(props) {
  const classes = useStyles();
  // const classesarea = useStylesArea();
  return (
    <TextField
      required={props.required}
      // autoFocus={props.autoFocus}
      className={props.multiline ? classes.textArea : classes.textField}
      sx={{ minWidth: props.width, display: props.display, }}
      size="small"
      multiline={props.multiline}
      disabled={props.disabled}
      rows={props.rows}
      label={props.label}
      variant={props.variant}
      ref={props.ref}
      id={props.id}
      // focused={props.autoFocus}
      defaultValue={props.defaultValue}
      type={props.type}
      autoComplete={props.autoComplete}
      // InputProps={props.InputProps}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      error={props.error}
      helperText={props.helperText}
      fullWidth={props.fullWidth}
      placeholder={props.placeholder}
      InputProps={{
        startAdornment: props.start,
        maxLength: props.length,
        readOnly:props.readOnly
      }}




    />
  );
}

export default Textbox;
