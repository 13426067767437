import moment from "moment";
import MultiFloatFilter from "./MultiFloatFilter";

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = moment(cellValue).format("DD-MM-YYYY");
    const dateParts = dateAsString.split("-");
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  inRangeInclusive: true,
  browserDatePicker: true,
};

export const headers = [
  {
    headerName: "Project Name",
    field: "projectName",
    filter: MultiFloatFilter,
    filterParams: "projectName",
    lockPosition: "left",
    sortable: true,
    width: 205,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    minWidth: 205,
  },
  {
    headerName: "Country",
    field: "country",
    sortable: true,
    filter: MultiFloatFilter,
    filterParams: "country",
    lockPosition: "left",

   
    width: 180,
    minWidth: 180,
  },

  {
    headerName: "Make",
    field: "make",
    filter: MultiFloatFilter,
    filterParams: "make",
    lockPosition: "left",

    width: 180,
    minWidth: 180,
    suppressRowTransform: true,
    sortable: true,
  },
  {
    headerName: "Model",
    field: "model",
    filter: MultiFloatFilter,
    filterParams: "model",
    lockPosition: "left",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "scopemodel",
    },
    width: 180,
    minWidth: 180,
    sortable: true,
  },
  {
    headerName: "Business Priority",
    field: "priority_Level",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "priority_Level",
    },
    filter: MultiFloatFilter,
    filterParams: "priority_Level",
    lockPosition: "left",
    sortable: true,
    width: 180,
    minWidth: 180,
  },
  {
    headerName: "Source URL",
    field: "sourceURL",
    filter: MultiFloatFilter,
    filterParams: "sourceURL",
    // tooltipField: "sourceUrl",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "managesourceurl",
    },
    lockPosition: "left",

    sortable: true,
    width: 250,
    minWidth: 250,
  },
  {
    headerName: "Previous Source URL",
    field: "previousSourceURL",
    filter: MultiFloatFilter,
    filterParams: "previousSourceURL",
    // tooltipField: "sourceUrl",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "managepreviousSourceUrl",
    },
    lockPosition: "left",
    sortable: true,
    width: 250,
    minWidth: 250,
  },

  {
    headerName: "Scrapper Complexity",
    field: "complexity_Level",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "priority",
    },
    filter: MultiFloatFilter,
    filterParams: "complexity_Level",
    lockPosition: "left",
    sortable: true,
    width: 180,
    minWidth: 180,
  },

  {
    headerName: "URL Status",
    field: "urlStatus",
    filter: MultiFloatFilter,
    filterParams: "urlStatus",
    lockPosition: "left",
    width: 220,
    minWidth: 220,
    sortable: true,
  },

  {
    headerName: "Source Received date",
    field: "receivedDate",
    filter: MultiFloatFilter,
    filterParams: "receivedDate",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatreceiveddate",
    },
    lockPosition: "left",

    sortable: true,
    width: 180,
    minWidth: 180,
  },

  // {
  //   headerName: "New Source Status",
  //   field: "newSourceStatus",
  //   filter: MultiFloatFilter,
  //   filterParams: "newSourceStatus",
  //   lockPosition: "left",

  //   sortable: true,
  //   width: 250,
  //   minWidth: 250,
  // },
  {
    headerName: "Comments",
    field: "comments",
    filter: MultiFloatFilter,
    filterParams: "comments",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatsourcecomments",
    },
    wrapText: true,
    autoHeight: true,
    cellClass: "trimText",
    lockPosition: "left",

    sortable: true,
    width: 300,
    minWidth: 300,
  },
  {
    headerName: "Action",
    field: "",

    cellRenderer: "iconCellRenderer",
    cellRendererParams: {
      actionType: "adminaction",
    },
    width: 140,
    minWidth: 140,
    maxWidth: 140,
    cellClass: ["ag-cell-align-center", "locked-col"],
    // cellStyle: { color: 'red', 'background-color': 'green' }
    lockPosition: "left",
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        "  </div>" +
        "</div>",
    },
  },
];

