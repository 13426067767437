import React from "react";
import { Button, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import { styled } from "@mui/material/styles";
import { grey } from '@mui/material/colors';
import { UnfoldMoreOutlined as UnfoldIcon } from '@mui/icons-material';

const JDListItemButton = styled(ListItemButton)(({ theme }) => ({
  display: "inline-flex",
  minWidth: theme.spacing(25),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  "&:hover": {
    borderColor: theme.palette.grey[800]
  },
  "& .MuiListItemText-root": {
    margin: 0,
  },
  "& .MuiListItemText-root > .MuiListItemText-primary": {
    color: grey[600],
    fontWeight: 500
  },
  "& .MuiListItemText-root > .MuiListItemText-secondary": {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 600
  },
  "& .MuiListItemIcon-root": {
    minWidth: theme.spacing(4),
    justifyContent: "end"
  }
}));

function ListButton(props) {
  console.log(props);
  return (
    <JDListItemButton
      onClick={props.onClick}
    >
      <ListItemText
        primary={props.message}
        secondary={props.secondary}
      />
      <ListItemIcon>
        <UnfoldIcon />
      </ListItemIcon>
    </JDListItemButton>
  );
}

const JDButton = styled(Button)(({ theme }) => ({
  "& .MuiButton-startIcon": {
    color: theme.palette.primary.main
  }
}));

function MaterialUIButton(props) {
  return (
    <JDButton
      variant={props.variant}
      startIcon={props.startIcon}
      disabled={props.disabled}
      endIcon={props.endIcon}
      onClick={props.onClick}
      size="small"
      color={props.color}
    >
      {props.message}
    </JDButton>
  );
}

export default MaterialUIButton;
export { ListButton, MaterialUIButton }