import { BAU_STATUS } from "apiConfig/manageissue";
import {
  BAU_BASE,
  BAU_EXPORT,
  BAU_EXPORT_FAILURE,
  BAU_REPORTS,
  BULK_UPDATE,
} from "apiConfig/reports";
import {
  deleteData,
  getData,
  postData,
  putData,
} from "services/basicApiService";

export const getBauStatus = (header) => {
  console.log(header);
  //checkTokenExpiry()
  return async () => {
    const response = await getData(BAU_STATUS, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const getAllBAU = (pagenumber, pagesize, search, data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(
      BAU_REPORTS +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const bulkUpdateBAU = (data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(BULK_UPDATE, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const updateSingleBAU = (data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await putData(BAU_BASE, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const SingleBAU = (data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(BAU_BASE + "/" + data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: ExportManagesource
  Argument: pagenumber, pagesize, search, data
  Return: response
  Usage:
  1.Export Manage Source data 
  *******************************************************************************/

export const ExportBAU = (pagenumber, pagesize, search, data, header) => {
  return async (dispatch) => {
    ////checkTokenExpiry();
    const response = await postData(
      BAU_EXPORT +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};

export const ExportBAUFailure = (data, header) => {
  return async (dispatch) => {
    ////checkTokenExpiry();
    const response = await postData(BAU_EXPORT_FAILURE, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const deleteBau = (id, header) => {
  return async () => {
    ////checkTokenExpiry();
    const response = await deleteData(BAU_BASE + `?id=${id}`, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
