import Unauthorized from "components/pages/auth/unauthorized/unauthorized";
import React, { useEffect } from "react";
import { useState } from "react";

import { Navigate, useLocation } from "react-router-dom";

export default function ProtectedRoute({ children }) {
  const [first, setfirst] = useState([]);
  const location = useLocation();
  const selector = JSON.parse(localStorage.getItem("roleData"));

  const user = localStorage.getItem("token");
  useEffect(() => {
    setfirst(
      selector &&
        selector.roleConfigurations.filter((e) =>
          e.menuName === "Manage Issue/Request"
            ? location.pathname === "/manage-issue-/-request" &&
              e.isView === true
            : e.menuName.replace("&", "and").toLowerCase() ===
                location.pathname
                  .replace("/", "")
                  .replace("-", " ")
                  .replace("-", " ")
                  .replace("/", "#")
                  .split("#")[0] && e.isView === true
        )
    );
  }, [location.pathname]);

  if (!user) {
    return <Navigate to="/" state={{ path: location.pathname }} />;
  } else {
    if (first.length === 0) {
      return <Unauthorized />;
    } else {
      return children;
    }
  }
}
