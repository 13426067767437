var baseApiUrl = process.env.REACT_APP_MANAGE_ISSUE_BASE_URL;
const baseurl = baseApiUrl + "api";
const baseurlforcountrywise = baseurl + "/v1/dashboard";
export const BASE_COUNTRY_URL = baseurlforcountrywise;

export const DASHBOARD_COUNTRY_WISE_URL =
  baseurlforcountrywise + "/getcountrywisedataforcurrentdatetableview";
export const DASHBOARD_COUNTRY_WISE_EXPORT_URL =
  baseurlforcountrywise + "/exportgetcountrywisedataforcurrentdatetableview";
export const DASHBOARD_DETAILED_COUNTRY_WISE_URL =
  baseurlforcountrywise + "/getcountrywisedatafordetailedview";
export const DASHBOARD_DETAILED_COUNTRY_WISE_EXPORT_URL =
  baseurlforcountrywise + "/exportgetcountrywisedatafordetailedview";
