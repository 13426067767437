import React from 'react'
import { IconButton, Tooltip } from '@mui/material'

function TooltipIconButton(props) {
  const { children, title, placement = "top", arrow = true, ...rest } = props;
  if (props.disabled) {
    return (
      <IconButton {...rest}>
        {children}
      </IconButton>
    )
  }

  return (
    <Tooltip title={title} placement={placement} arrow={arrow}>
      <IconButton {...rest}>
        {children}
      </IconButton>
    </Tooltip>
  )
}

export default TooltipIconButton
export { TooltipIconButton }