import MaterialUIButton from "components/commonComponents/button/button";
import Datepicker from "components/commonComponents/datepicker/datepicker";
import Dropdown from "components/commonComponents/dropdown/dropdown";
import Textbox from "components/commonComponents/textbox/textbox";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useState } from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";

import DoneIcon from "@mui/icons-material/Done";
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import useStyles from "./manageIssues.styles";
import Autocompletedropdown from "components/commonComponents/autocomplete/autocompletedropdown";
import { Required } from "utils/validation helper";

import { useDispatch, useSelector } from "react-redux";
import {
  categoryDropdown,
  countryDropdown,
  fieldDropdown,
  getTicketID,
  makeDropdown,
  meritUsers,
  modelDropdown,
  openstatusDropdown,
  priorityDropdown,
  priorityDropdownLease,
  priorityDropdownNew,
  providerDropdown,
  reportedByDropdown,
  reportedByDropdownNew,
  requestTypeDropdown,
  rerunDropdown,
  resolutionDropdown,
  statusDropdown,
  ticketDropdown,
  typeDropdown,
  userDropdown,
  websiteDropdown,
  worktypeDropdown,
} from "redux/manageissues/action";
import {
  addPrefiled,
  fetchResolutionstatus,
  fetchTicketlist,
  fetchusers,
  getCategory,
  getCountry,
  getField,
  getMake,
  getModel,
  getopenstatus,
  getPriority,
  getProvider,
  getRerun,
  getStatus,
  getTicket,
  getType,
  getWebsite,
  getWorktype,
  reportedBy,
  requestType,
  saveFiles,
  setMeritUser,
} from "redux/app/actions";
import moment from "moment/moment";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import Dropzone from "components/commonComponents/dragDropField";
import { useLocation } from "react-router-dom";
import AlertDialog from "components/commonComponents/alertDialog";
import MultipleAutocomplete from "components/commonComponents/autocomplete/multipleAutocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { makeDependency, modelDependency } from "redux/managesources/action";
import Spinner from "components/commonComponents/spinner/spinner";

function AddIssuse(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [isFileCleared, setIsFileCleared] = useState(false);
  const name = localStorage.getItem("firstName");
  const last = localStorage.getItem("lastName");
  const role = localStorage.getItem("role");
  const dataFilled = useSelector((state) => state.manageIssue.addprefilled);
  const data = useSelector((state) => state.manageIssue);
  const id = useSelector((state) => state.project.manageissue);
  const Pname = useSelector((state) => state.project.manageissueprojectname);

  const classes = useStyles();
  const muifilter = createFilterOptions();

  useEffect(() => {
    getDataFormAPIForDropdown();
    dispatch(getModel([]));
    dispatch(getMake([]));
  }, [id]);
  // dispatch(addPrefiled(data));
  /******************************************************************************
Function: getDataFormAPIForDropdown
Argument: 
Usage:
1. Calling API for Dropdown.
*******************************************************************************/
  const getDataFormAPIForDropdown = () => {
    dispatch(getTicketID(id)).then((res) =>
      dispatch(getTicket(res.data.result))
    );
    dispatch(countryDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getCountry([]));
        } else {
          dispatch(getCountry(res.data.result));
        }
      } else {
        dispatch(getCountry([]));
      }
    });
    dispatch(providerDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getProvider([]));
        } else {
          dispatch(getProvider(res.data.result));
        }
      } else {
        dispatch(getProvider([]));
      }
    });

    // dispatch(reportedByDropdown(id)).then((res) => {
    //   if (res.data.statusCode === 200) {
    //     if (res.data.result === "No records found") {
    //       dispatch(reportedBy([]));
    //     } else {
    //       dispatch(reportedBy(res.data.result));
    //     }
    //   } else {
    //     dispatch(reportedBy([]));
    //   }
    // });
    dispatch(
      reportedByDropdownNew(
        id,
        role.toLowerCase().includes("merit") ? false : true
      )
    ).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(reportedBy([]));
        } else {
          dispatch(reportedBy(res.data.result));
        }
      } else {
        dispatch(reportedBy([]));
      }
    });
    dispatch(categoryDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getCategory([]));
        } else {
          dispatch(getCategory(res.data.result));
        }
      } else {
        dispatch(getCategory([]));
      }
    });

    dispatch(typeDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getType([]));
        } else {
          dispatch(getType(res.data.result));
        }
      } else {
        dispatch(getType([]));
      }
    });
    Object.keys(dataFilled).length > 0 &&
      dispatch(makeDependency([dataFilled.countryId], id)).then((res) => {
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            dispatch(getMake([]));
          } else {
            dispatch(getMake(res.data.result));
          }
        } else {
          dispatch(getMake([]));
        }
      });
    Object.keys(dataFilled)?.length > 0 &&
      dispatch(
        modelDependency(
          dataFilled?.fkMakeIssueTracker?.map((e) => e.makeId),
          id
        )
      ).then((res) => {
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            dispatch(getModel([]));
          } else {
            dispatch(getModel(res.data.result));
          }
        } else {
          dispatch(getModel([]));
        }
      });
    dispatch(requestTypeDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(requestType([]));
        } else {
          dispatch(requestType(res.data.result));
        }
      } else {
        dispatch(requestType([]));
      }
    });
    dispatch(priorityDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getPriority([]));
        } else {
          dispatch(getPriority(res.data.result));
        }
      } else {
        dispatch(getPriority([]));
      }
    });
    dispatch(fieldDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getField([]));
        } else {
          dispatch(getField(res.data.result));
        }
      } else {
        dispatch(getField([]));
      }
    });
    dispatch(rerunDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getRerun([]));
        } else {
          dispatch(getRerun(res.data.result));
        }
      } else {
        dispatch(getRerun([]));
      }
    });
    dispatch(worktypeDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getWorktype([]));
        } else {
          dispatch(getWorktype(res.data.result));
        }
      } else {
        dispatch(getWorktype([]));
      }
    });
    dispatch(openstatusDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getopenstatus([]));
        } else dispatch(getopenstatus(res.data.result));
      } else {
        dispatch(getopenstatus([]));
      }
    });
    dispatch(statusDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getStatus([]));
        } else {
          dispatch(getStatus(res.data.result));
        }
      } else {
        dispatch(getStatus([]));
      }
    });
    dispatch(websiteDropdown(id)).then((res) => {
     
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getWebsite([]));
        } else {
          dispatch(getWebsite(res.data.result));
        }
      } else {
        dispatch(getWebsite([]));
      }
    });
    dispatch(ticketDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(fetchTicketlist([]));
        } else {
          dispatch(fetchTicketlist(res.data.result));
        }
      } else {
        dispatch(fetchTicketlist([]));
      }
    });
    dispatch(resolutionDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(fetchResolutionstatus([]));
        } else dispatch(fetchResolutionstatus(res.data.result));
      } else {
        dispatch(fetchResolutionstatus([]));
      }
    });
    dispatch(userDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(fetchusers([]));
        } else dispatch(fetchusers(res.data.result));
      } else {
        dispatch(fetchusers([]));
      }
    });
    dispatch(meritUsers(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(setMeritUser([]));
        } else dispatch(setMeritUser(res.data.result));
      } else {
        dispatch(setMeritUser([]));
      }
    });
  };

  /******************************************************************************
Function: setplanned
Argument: 
Usage:
1. To set the response date as per the Business Priority (SLA).
*******************************************************************************/
  const setplanned = () => {
    if (dataFilled.priority.issuePriority1.toLowerCase() === "critical")
      return moment().add(1, "days");

    if (dataFilled.priority.issuePriority1.toLowerCase() === "high")
      return moment().add(2, "days");

    if (dataFilled.priority.issuePriority1.toLowerCase() === "medium")
      return moment().add(3, "days");
    if (dataFilled.priority.issuePriority1.toLowerCase() === "low")
      return moment().add(4, "days");
  };
  const handleClearOptions = () => formik.setFieldValue("modelId", []);
  const handleSelectAll = (isSelected) =>
    isSelected
      ? formik.setFieldValue("modelId", data.model)
      : handleClearOptions();

  const handleToggleOption = (selectedOptions) => {
    formik.setFieldValue("modelId", selectedOptions);
  };

  const handleToggleOptionm = (selectedOptions) => {
    formik.setFieldValue("make", selectedOptions);
  };
  const handleToggleOptionp = (selectedOptions) => {
    formik.setFieldValue("provider", selectedOptions);
  };
  // const getOptionSelected=(option,value)=>option.value === value.value;
  /******************************************************************************
Function: validate
Argument: values
Usage:
1. To validate the fileds.
*******************************************************************************/
  const validate = (values) => {
    const errors = {};
    if (role !== "Merit Developer") {
      if (!Required(values.dateofFile)) {
        errors.dateofFile = true;
      }
    }

    if (!Required(values.countryId)) {
      errors.countryId = true;
    }

    if (values.make.length === 0) {
      errors.make = true;
    }

    if (Pname === "OEMS") {
      if (values.modelId.length === 0) {
        errors.modelId = true;
      }
    }
    if (Pname === "LEASE OPERATOR") {
      if (!Required(values.provider)) {
        errors.provider = true;
      }
    }
    if (Pname === "TRANSACTIONAL ANALYSIS") {
      if (!Required(values.website)) {
        errors.website = true;
      }
    }
    if (!Required(values.reportedBy)) {
      errors.reportedBy = true;
    } else if (values.reportedBy.length > 50) {
      errors.reportedBy = "Exceeds 50 characters";
    }
    if (!Required(values.typeId)) {
      errors.typeId = true;
    }
    if (!Required(values.requestId)) {
      errors.requestId = true;
    }
    // if (role !== "JATO Admin" && !Required(values.workTypeId)) {
    //   errors.workTypeId = true;
    // }
    if (!Required(values.priorityId)) {
      errors.priorityId = true;
    }
    if (role !== "JATO Admin" && !Required(values.statusId)) {
      errors.statusId = true;
    }
    if (!Required(values.feedback)) {
      errors.feedback = true;
    } else if (values.feedback.length > 2000) {
      errors.feedback = "Exceeds 2000 characters";
    }
    if (values.meritJustification && values.meritJustification.length > 400) {
      errors.meritJustification = "Exceeds 400 characters";
    }
    if (!Required(values.rerun)) {
      errors.rerun = true;
    }
    return errors;
  };
  /******************************************************************************
Function: reset
Argument: 
Usage:
1. To reset the fileds.
*******************************************************************************/
  const formData = new FormData();

  const reset = () => {
    //dispatch(addPrefiled({}));
    if (formik && formik.values) {
      formik.resetForm();
      setIsFileCleared(true);
      dispatch(saveFiles([]));
      formik.values.dateofFile = null;
      formik.values.countryId = null;
      formik.values.make = [];
      formik.values.modelId = [];
      formik.values.provider = null;
      formik.values.wesbite = null;
      // formik.values.categoryId = null;
      formik.values.typeId = null;
      formik.values.priorityId = null;
      formik.values.fieldId = null;
      formik.values.feedback = "";
      formik.values.rerun = null;
      formik.values.requestId = null;
      formik.values.meritJustification = "";
      // formik.values.workTypeId = null;
      formik.values.statusId = null;
      formik.values.reportedBy = null;
      formik.values.plannedEndDate = null;
    }
  };

  const premake =
    dataFilled.fkMakeIssueTracker &&
    dataFilled.fkMakeIssueTracker.map((e) => {
      return {
        makeId: e.make.makeId,
        make1: e.make.make1,
      };
    });
  // const precategory = dataFilled.category && {
  //   label: dataFilled.category.issueCategory1,
  //   id: dataFilled.category.categoryId,
  // };
  const pretype = dataFilled.type && {
    label: dataFilled.type.issueType1,
    id: dataFilled.type.typeId,
  };
  const prepriority = dataFilled.priority && {
    label: dataFilled.priority.issuePriority1,
    id: dataFilled.priority.priorityId,
  };
  const prefield = dataFilled.field && {
    label: dataFilled.field.issueField1,
    id: dataFilled.field.fieldId,
  };
  const prerequest = dataFilled.requestType && {
    label: dataFilled.requestType.requestTypeName,
    id: dataFilled.requestType.requestTypeId,
  };
  const prererun = dataFilled.rerun && {
    label: dataFilled.rerun.rerun1,
    id: dataFilled.rerun.rerunId,
  };
  // const preworktype = dataFilled.workType && {
  //   label: dataFilled.workType.workType1,
  //   id: dataFilled.workType.workTypeId,
  // };
  const prestatus = dataFilled.status && {
    label: dataFilled.status.status1,
    id: dataFilled.status.statusId,
  };
  const preprovider = dataFilled.provider && {
    label: dataFilled.provider.leasingProviderName,
    id: dataFilled.provider.providerId,
  };
  const prewebsite = dataFilled.website && {
    label: dataFilled.website.website1,
    id: dataFilled.website.websiteId,
  };

  const precountry = dataFilled.country && {
    id: dataFilled.country.countryId,
    label: dataFilled.country.countryCode + "-" + dataFilled.country.region,
  };
  const prereportedBy = dataFilled.reportedByNavigation && {
    label: dataFilled.reportedByNavigation.reportedBy1,
    id: dataFilled.reportedByNavigation.reportedById,
  };

  const premodel =
    dataFilled.fkIssueTracker &&
    dataFilled.fkIssueTracker.map((e) => {
      return {
        modelId: e.model.modelId,
        model1: e.model.model1,
      };
    });
  /******************************************************************************
Function: formik
Argument: 
Usage:
1. To submit the data.
*******************************************************************************/
  const formik = useFormik({
    initialValues: {
      ticketId: data.ticket,
      dateRaised: moment().format("YYYY-MM-DD HH:mm:ss"),
      dateofFile: null,
      countryId: Object.keys(dataFilled).length > 0 ? precountry : null,
      make: Object.keys(dataFilled).length > 0 ? premake : [],
      modelId: Object.keys(dataFilled).length > 0 ? premodel : [],
      website: Object.keys(dataFilled).length > 0 ? prewebsite : null,
      provider: Object.keys(dataFilled).length > 0 ? preprovider : null,
      typeId: Object.keys(dataFilled).length > 0 ? pretype : null,
      priorityId: Object.keys(dataFilled).length > 0 ? prepriority : null,
      fieldId:
        Object.keys(dataFilled).length > 0
          ? dataFilled.fieldId
            ? prefield
            : null
          : null,
      requestId:
        Object.keys(dataFilled).length > 0
          ? dataFilled?.requestType?.requestTypeId
            ? prerequest
            : null
          : null,
      feedback: Object.keys(dataFilled).length > 0 ? dataFilled.feedback : "",
      attachments: null,
      files: [],
      rerun: Object.keys(dataFilled).length > 0 ? prererun : null,
      meritJustification:
        Object.keys(dataFilled).length > 0
          ? dataFilled.meritJustification
            ? dataFilled.meritJustification
            : ""
          : "",
      // workTypeId:
      //   Object.keys(dataFilled).length > 0
      //     ? dataFilled.workTypeId
      //       ? preworktype
      //       : null
      //     : null,
      statusId: null,
      reportedBy:
        Object.keys(dataFilled).length > 0
          ? dataFilled.reportedByNavigation
            ? prereportedBy
            : null
          : null,
      addedBy: name + " " + last,
      assignedTo: "",
      // changes as per SLA requested and FIxed ON 28-05-2024
      // plannedEndDate: Object.keys(dataFilled).length > 0 ? setplanned() : null,
      plannedEndDate: null,
      resolutionId: 0,
      resolutionDate: null,
      developerComments: "",
      createdBy: name + " " + last,
    },
    validate,
    onSubmit: (values) => {
 
      props.onChangeSave(true);
      formData.append("ticketId", 0);
      formData.append("dateRaised", values.dateRaised);
      values.dateofFile &&
        formData.append(
          "dateofFile",
          values.dateofFile.format("YYYY-MM-DD HH:mm:ss")
        );
      formData.append("countryId", values.countryId.id);

      Pname === "LEASE OPERATOR" &&
        formData.append("ProviderId", values.provider.id);
      Pname === "TRANSACTIONAL ANALYSIS" &&
        formData.append("websiteId", values.website.id);
      for (let i = 0; i < values.make?.length; i++) {
        formData.append("makeId", values.make[i]?.makeId);
      }
      for (let i = 0; i < values.modelId?.length; i++) {
        formData.append("modelId", values.modelId[i]?.modelId);
      }

      for (let i = 0; i < data.files.length; i++) {
        formData.append("files", data.files[i]);
      }
      formData.append("typeId", values.typeId.id);
      formData.append("priorityId", values.priorityId.id);
      formData.append("fieldId", values.fieldId ? values.fieldId.id : "");
      formData.append("feedback", values.feedback.trim());
      formData.append("rerunId", values.rerun.id);
      formData.append("meritJustification", values.meritJustification.trim());
      formData.append("requestTypeId", values.requestId.id);

      // formData.append(
      //   "workTypeId",
      //   values.workTypeId ? values.workTypeId.id : ""
      // );
      formData.append("statusId", values.statusId ? values.statusId.id : "");
      formData.append("reportedBy", values.reportedBy.id);
      // changes as per SLA requested and FIxed ON 28-05-2024
      // formData.append(
      //   "plannedEndDate",
      //   values.plannedEndDate.format("YYYY-MM-DD HH:mm:ss")
      // );
      formData.append("plannedEndDate", "");
      formData.append("resolutionId", "");
      formData.append("createdBy", values.createdBy);
      formData.append("addedBy", values.addedBy.toUpperCase());
      props.addNewissue(formData);
      dispatch(saveFiles([]));
      reset();
    },
  });

  /******************************************************************************
Function: reset
Argument: 
Usage:
1. To reset the fileds.
*******************************************************************************/
  const closeReset = () => {
    props.close();
    dispatch(addPrefiled({}));
  };

  const optionRenderer = (props, option, { selected }) => {
    const selectAllProps =
      option.model1 === "Select All" // To control the state of 'select-all' checkbox
        ? { checked: data.model?.length === formik.values.modelId?.length }
        : {};
    return (
      <li {...props}>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {option.model1}
      </li>
    );
  };
  const optionRendererp = (props, option, { selected }) => {
    const selectAllProps =
      option.leasingProviderName === "Select All" // To control the state of 'select-all' checkbox
        ? { checked: data.provider.length === formik.values.provider.length }
        : {};
    return (
      <li {...props}>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {option.leasingProviderName}
      </li>
    );
  };
  const optionRendererm = (props, option, { selected }) => {
    const selectAllProps =
      option.make1 === "ALL" // To control the state of 'select-all' checkbox
        ? {
            checked:
              formik.values.make?.length === 0
                ? false
                : data.make.length === formik.values.make?.length,
          }
        : {};
    return (
      <li {...props}>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {option.make1}
      </li>
    );
  };
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  /******************************************************************************
Function: cancel
Argument: 
Usage:
1. To cancel the changes.
*******************************************************************************/
  const cancel = () => {
    dispatch(addPrefiled({}));
    props.close();
    dispatch(saveFiles([]));
  };
 
  return (
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                {role.toLowerCase().includes("merit") ? (
                  <>Raise Issue</>
                ) : (
                  <>Raise Issue/Request</>
                )}
              </Typography>
            </Grid>
            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs="auto">
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => reset()}
                    message="RESET"
                    startIcon={<RestartAltOutlinedIcon />}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                          JSON.stringify(formik.values) &&
                        data.files.length === 0
                          ? cancel()
                          : setShow(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}

        <Grid item xs={12} sx={{ mt: -1, mb: -1 }}>
          <Typography variant="caption" gutterBottom>
            Ticket ID: <strong>{data.ticket}</strong>
          </Typography>
        </Grid>
        {role !== "Merit Developer" ? (
          <Grid item xs={12}>
            <Datepicker
              required
              onKeyDown={onKeyDown}
              max={moment().format("YYYY-MM-DD")}
              name="dateofFile"
              value={formik.values.dateofFile}
              onChange={(e) => {
                formik.setFieldValue("dateofFile", e);
              }}
              error={
                formik.touched.dateofFile && Boolean(formik.errors.dateofFile)
              }
              helperText={formik.touched.dateofFile && formik.errors.dateofFile}
              size="small"
              fullWidth
              label="File Uploaded Date"
            />
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12}>
          <Autocompletedropdown
            required
            value={formik.values.countryId}
            onChange={(_, data) => {
              formik.setFieldValue("countryId", data);
              formik.setFieldValue("make", []);
              formik.setFieldValue("modelId", []);
              Pname !== "LEASE OPERATOR"&& formik.setFieldValue("priorityId", null);
              dispatch(makeDependency([data.id], id)).then((res) => {
                if (res.data.statusCode === 200) {
                  if (res.data.result === "No records found") {
                    dispatch(getMake([]));
                  } else {
                    dispatch(getMake(res.data.result));
                  }
                } else {
                  dispatch(getMake([]));
                }
              });
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            error={formik.touched.countryId && Boolean(formik.errors.countryId)}
            helperText={formik.touched.countryId && formik.errors.countryId}
            labelId="Country"
            id="Country"
            name="countryId"
            label="Country"
            options={data.country.map((e) => {
              return {
                label: e.countryCode + " - " + e.region,
                id: e.countryId,
              };
            })}
          />
        </Grid>

        {Pname === "LEASE OPERATOR" ? (
          <Grid item xs={12}>
            <Autocompletedropdown
              required
              value={
                formik.values.provider
                // !formik.values.make.make1
                //   ? formik.values.make
                //   : formik.values.make.make1
              }
              onChange={(_, data,value) => {
                formik.setFieldValue("provider", data);
                if(value==="clear"){
                  formik.setFieldValue("priorityId", null);
                }else{
                  dispatch(
                    priorityDropdownLease(
                      data.priorityId,
                      id
                    )
                  ).then((res) => {
                   
                    if (res.status === 200) {
                      if (res.data.result === "No records found"||Object.keys(res.data).length===0) {
                        formik.setFieldValue("priorityId", null);
                      } else {
                        console.log(res.data);
                        
                        formik.setFieldValue("priorityId",{
                          label: res.data.issuePriority1,
                          id: res.data.priorityId
                        });
                      }
                    } else {
                       formik.setFieldValue("priorityId", null);
                    }
                  });
                }
              
              }}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              labelId="provider"
              id="provider"
              name="provider"
              label="Leasing Provider"
              getOptionLabel={(e) => e.leasingProviderName}
              error={formik.touched.provider && Boolean(formik.errors.provider)}
              helperText={formik.touched.provider && formik.errors.provider}
              options={data.provider.map((e) => {
                return {
                  priorityId:e.priorityId,
                  label: e.leasingProviderName,
                  id: e.providerId,
                };
              })}
            />
          </Grid>
        ) : (
          <></>
        )}
        {Pname === "TRANSACTIONAL ANALYSIS" ? (
          <Grid item xs={12}>
            <Autocompletedropdown
              required
              value={formik.values.website}
              onChange={(_, data) => {
                formik.setFieldValue("website", data);
              }}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              labelId="website"
              id="website"
              name="website"
              label="Website"
              getOptionLabel={(e) => e.website1}
              error={formik.touched.website && Boolean(formik.errors.website)}
              helperText={formik.touched.website && formik.errors.website}
              options={data.website.map((e) => {
                return {
                  label: e.website1,
                  id: e.websiteId,
                };
              })}
            />
          </Grid>
        ) : (
          <> </>
        )}
        <Grid item xs={12}>
          <MultipleAutocomplete
            id="Make"
            required
            label="Make"
            labelId="Make"
            disabled={!formik.values.countryId ? true : false}
            name="make"
            value={formik.values.make}
            filterOptions={(options, params) => {
              const filtered = muifilter(options, params);
              return [{ makeId: 0, make1: "ALL" }, ...filtered];
            }}
            onChange={(_, e, value) => {
              if (e.some((element) => element.make1 === "ALL")) {
                dispatch(
                  modelDependency(
                    data.make.map((f) => f.makeId),
                    id
                  )
                ).then((res) => {
                  if (res.data.statusCode === 200) {
                    if (res.data.result === "No records found") {
                      dispatch(getModel([]));
                    } else {
                      dispatch(getModel(res.data.result));
                    }
                  } else {
                    dispatch(getModel([]));
                  }
                });
                Pname !== "LEASE OPERATOR"&& dispatch(
                  priorityDropdownNew(
                    {
                      countryId: formik.values.countryId.id,
                      makeIds:data.make.map((f) => f.makeId),
                    },
                    id
                  )
                ).then((res) => {
                 
                  if (res.status === 200) {
                    if (res.data.result === "No records found"||Object.keys(res.data).length===0) {
                      Pname !== "LEASE OPERATOR"&& formik.setFieldValue("priorityId", null);
                    } else {
                      console.log(res.data);
                      
                      formik.setFieldValue("priorityId",{
                        label: res.data.issuePriority1,
                        id: res.data.priorityId
                      });
                    }
                  } else {
                    Pname !== "LEASE OPERATOR"&&  formik.setFieldValue("priorityId", null);
                  }
                });
              } else {
                dispatch(
                  modelDependency(
                    e.map((f) => f.makeId),
                    id
                  )
                ).then((res) => {
                  if (res.data.statusCode === 200) {
                    if (res.data.result === "No records found") {
                      dispatch(getModel([]));
                    } else {
                      dispatch(getModel(res.data.result));
                    }
                  } else {
                    dispatch(getModel([]));
                  }
                });
                Pname !== "LEASE OPERATOR"&& dispatch(
                  priorityDropdownNew(
                    {
                      countryId: formik.values.countryId.id,
                      makeIds: e.map((f) => f.makeId),
                    },
                    id
                  )
                ).then((res) => {
                
                  if (res.status === 200) {
                    if (res.data.result === "No records found"||Object.keys(res.data).length===0) {
                      Pname !== "LEASE OPERATOR"&& formik.setFieldValue("priorityId", null);
                    } else {
                      console.log(res);
                      formik.setFieldValue("priorityId", {
                        label: res.data.issuePriority1,
                        id: res.data.priorityId
                      });
                    }
                  } else {
                    Pname !== "LEASE OPERATOR"&& formik.setFieldValue("priorityId", null);
                  }
                });
              }

              formik.setFieldValue("modelId", []);

              if (value === "selectOption" || value === "removeOption") {
                if (e.find((option) => option.make === "ALL")) {
                  let result = [];
                  result = data.make.filter((el) => el.make1 !== "ALL");
                  formik.setFieldValue("make", result);
                } else {
                  handleToggleOptionm && handleToggleOptionm(e);

                  formik.setFieldValue("make", e);
                }
              } else if (value === "clear") {
                formik.setFieldValue("make", []);
              }
              if (e.find((option) => option.make1 === "ALL")) {
                data.make.length === formik.values.make?.length
                  ? formik.setFieldValue("make", [])
                  : formik.setFieldValue("make", data.make);
              }
            }}
            renderTags={(value, getTagProps) =>
              value.length === data.make.length ? (
                <Chip
                  size="small"
                  variant="outlined"
                  label="ALL"
                  //{...getTagProps({ index })}
                />
              ) : (
                value.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={option.make1}
                    //{...getTagProps({ index })}
                  />
                ))
              )
            }
            //  isOptionEqualToValue={(option, value) =>  option.value === value.value }
            isOptionEqualToValue={(option, value) => {
              return option.makeId === value.makeId;
            }}
            // getOptionSelected={(option, value) => option.value === value.value}
            error={formik.touched.make && Boolean(formik.errors.make)}
            helperText={formik.touched.make && formik.errors.make}
            placeholder="Make"
            limitTags={4}
            options={data.make}
            getOptionLabel={(e) => e.make1}
            renderOption={optionRendererm}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            id="Model"
            required={Pname === "OEMS"}
            label="Model"
            labelId="Model"
            disabled={!formik.values.make?.length > 0 ? true : false}
            name="modelId"
            value={formik.values.modelId}
            filterOptions={(options, params) => {
              const filtered = muifilter(options, params);
              return [{ modelId: 0, model1: "Select All" }, ...filtered];
            }}
            onChange={(_, e, value) => {
              if (value === "selectOption" || value === "removeOption") {
                if (e.find((option) => option.model === "Select All")) {
                  let result = [];
                  result = data.model.filter(
                    (el) => el.model1 !== "Select All"
                  );
                  formik.setFieldValue("modelId", result);
                } else {
                  handleToggleOption && handleToggleOption(e);

                  formik.setFieldValue("modelId", e);
                }
              } else if (value === "clear") {
                formik.setFieldValue("modelId", []);
              }
              if (e.find((option) => option.model1 === "Select All")) {
                data.model.length === formik.values.modelId.length
                  ? formik.setFieldValue("modelId", [])
                  : formik.setFieldValue("modelId", data.model);
              }
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.model1}
                  //{...getTagProps({ index })}
                />
              ))
            }
            //  isOptionEqualToValue={(option, value) =>  option.value === value.value }
            isOptionEqualToValue={(option, value) => {
              return option.modelId === value.modelId;
            }}
            // getOptionSelected={(option, value) => option.value === value.value}
            error={formik.touched.modelId && Boolean(formik.errors.modelId)}
            helperText={formik.touched.modelId && formik.errors.modelId}
            placeholder="Model"
            limitTags={4}
            options={data.model}
            getOptionLabel={(e) => e.model1}
            renderOption={optionRenderer}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocompletedropdown
            required
            name="typeId"
            value={formik.values.typeId}
            clear={() => formik.setFieldValue("typeId", "")}
            onChange={(_, data) => formik.setFieldValue("typeId", data)}
            error={formik.touched.typeId && Boolean(formik.errors.typeId)}
            helperText={formik.touched.typeId && formik.errors.typeId}
            labelId="Issue Type"
            id="Issue Type"
            label="Issue Type"
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            getOptionLabel={(e) => e.issueType1}
            options={data.type.map((e) => {
              return {
                label: e.issueType1,
                id: e.typeId,
              };
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocompletedropdown
            fullWidth
            readOnly
            required
            name="priorityId"
            onChange={(_, e, value) => {
              formik.setFieldValue("priorityId", e);
            }}
            value={formik.values.priorityId}
            error={
              formik.touched.priorityId && Boolean(formik.errors.priorityId)
            }
            helperText={formik.touched.priorityId && formik.errors.priorityId}
            labelId="Business Priority"
            id="Business Priority"
            label="Business Priority"
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            getOptionLabel={(e) => e.issuePriority1}
            options={data.priority.map((e) => {
              return {
                label: e.issuePriority1,
                id: e.priorityId,
              };
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocompletedropdown
            name="fieldId"
            value={formik.values.fieldId}
            onChange={(_, data) => formik.setFieldValue("fieldId", data)}
            error={formik.touched.fieldId && Boolean(formik.errors.fieldId)}
            helperText={formik.touched.fieldId && formik.errors.fieldId}
            labelId="Issue Field"
            id="Issue Field"
            label="Issue Field"
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            getOptionLabel={(e) => e.issueField1}
            options={data.field.map((e) => {
              return {
                label: e.issueField1,
                id: e.fieldId,
              };
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocompletedropdown
            name="requestId"
            required
            value={formik.values.requestId}
            onChange={(_, data) => formik.setFieldValue("requestId", data)}
            error={formik.touched.requestId && Boolean(formik.errors.requestId)}
            helperText={formik.touched.requestId && formik.errors.requestId}
            labelId="Request ID"
            id="request id"
            label="Issue/Request  Type"
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            getOptionLabel={(e) => e.requestTypeName}
            options={data.requestType.map((e) => {
              return {
                label: e.requestTypeName,
                id: e.requestTypeId,
              };
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Textbox
            required
            multiline
            rows={3}
            length={2000}
            label="Feedback"
            fullWidth
            name="feedback"
            value={formik.values.feedback}
            onChange={formik.handleChange}
            error={formik.touched.feedback && Boolean(formik.errors.feedback)}
            helperText={formik.touched.feedback && formik.errors.feedback}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Attachments
          </Typography>
          <Dropzone
            margin="normal"
            showSelectedFile={true}
            isFileCleared={isFileCleared}
            onFilesAdded={(e, fileUpload) => fileUpload}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocompletedropdown
            required
            fullWidth
            name="rerun"
            value={formik.values.rerun}
            onChange={(_, data) => formik.setFieldValue("rerun", data)}
            error={formik.touched.rerun && Boolean(formik.errors.rerun)}
            helperText={formik.touched.rerun && formik.errors.rerun}
            labelId="Rerun"
            id="Rerun"
            label="Rerun"
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            getOptionLabel={(e) => e.rerun1}
            options={data.rerun.map((e) => {
              return {
                label: e.rerun1,
                id: e.rerunId,
              };
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Textbox
            multiline
            rows={3}
            length={400}
            label="Merit Justification"
            name="meritJustification"
            value={formik.values.meritJustification}
            onChange={formik.handleChange}
            error={
              formik.touched.meritJustification &&
              Boolean(formik.errors.meritJustification)
            }
            helperText={
              formik.touched.meritJustification &&
              formik.errors.meritJustification
            }
            fullWidth
          />
        </Grid>
        {/* <Grid item xs={12}>
          <Autocompletedropdown
            fullWidth
            required={role !== "JATO Admin"}
            labelId="Work Type"
            id="Work Type"
            name="workTypeId"
            value={formik.values.workTypeId}
            onChange={(_, data) => formik.setFieldValue("workTypeId", data)}
            error={
              formik.touched.workTypeId && Boolean(formik.errors.workTypeId)
            }
            helperText={formik.touched.workTypeId && formik.errors.workTypeId}
            label="Work Type"
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            getOptionLabel={(e) => e.workType1}
            options={data.worktype.map((e) => {
              return {
                label: e.workType1,
                id: e.workTypeId,
              };
            })}
          />
        </Grid> */}
        <Grid item xs={12}>
          <Autocompletedropdown
            fullWidth
            required={role !== "JATO Admin"}
            labelId="status"
            id="status"
            label="Status"
            name="statusId"
            value={formik.values.statusId}
            onChange={(_, data) => formik.setFieldValue("statusId", data)}
            error={formik.touched.statusId && Boolean(formik.errors.statusId)}
            helperText={formik.touched.statusId && formik.errors.statusId}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            getOptionLabel={(e) => e.status1}
            options={data.openStatus.map((e) => {
              return {
                label: e.status1,
                id: e.statusId,
              };
            })}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocompletedropdown
            required
            value={formik.values.reportedBy}
            onChange={(_, data) => {
              formik.setFieldValue("reportedBy", data);
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            error={
              formik.touched.reportedBy && Boolean(formik.errors.reportedBy)
            }
            helperText={formik.touched.reportedBy && formik.errors.reportedBy}
            labelId="Reported By"
            id="Reported By"
            name="reportedBy"
            label="Reported By"
            options={data.reportedBy.map((e) => {
              return {
                label: e.reportedBy1,
                id: e.reportedById,
              };
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Textbox
            readOnly={true}
            label="Added By"
            defaultValue={formik.values.addedBy.toUpperCase()}
            fullWidth
          />
        </Grid>
        {/* changes as per SLA requested and FIxed ON 28-05-2024 */}
        {/* <Grid item xs={12}>
          <Datepicker
            onKeyDown={onKeyDown}
            name="plannedEndDate"
            min={moment().format("YYYY-MM-DD")}
            value={formik.values.plannedEndDate}
            onChange={(e) => {
              formik.setFieldValue("plannedEndDate", e);
            }}
            error={
              formik.touched.plannedEndDate &&
              Boolean(formik.errors.plannedEndDate)
            }
            helperText={
              formik.touched.plannedEndDate && formik.errors.plannedEndDate
            }
            fullWidth
            label="Response Date"
          />
        </Grid> */}

        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                disabled={props.save}
                message="SAVE"
                startIcon={<SaveOutlinedIcon />}
                onClick={() => formik.handleSubmit()}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                size="small"
                variant="outlined"
                onClick={() => {
                  reset();
                }}
                message="RESET"
                startIcon={<RestartAltOutlinedIcon />}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="CANCEL"
                startIcon={<EventBusyOutlinedIcon />}
                onClick={() => {
                  JSON.stringify(formik.initialValues) ===
                    JSON.stringify(formik.values) && data.files.length === 0
                    ? cancel()
                    : setShow(true);
                }}
              />
            </Grid>
          </Grid>
          <AlertDialog
            open={show}
            onClose={() => setShow(false)}
            // open={isDeleted}
            title={"Confirmation"}
            // maxWidth={"sm"}
            description={`Do you want to discard the changes ?`}
            action={
              <Grid
                container
                alignItems="center"
                justifyContent="right"
                spacing={2}
                className={classes.alertDialogbtn}
              >
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      reset();
                      closeReset();
                      props.close();
                      setShow(false);
                    }}
                    message="Yes"
                    startIcon={<DoneIcon />}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      setShow(false);
                    }}
                    message="No"
                    startIcon={<CloseIcon />}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
        {/* Action Area End Here */}
      </Grid>
    </Box>
  );
}

export default AddIssuse;
