import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    pageContent: {
        borderRadius: "14px !important",
        minHeight: 60,
        margin: theme.spacing(2.5),
        padding: theme.spacing(0),
        boxShadow: "0px 0px 4px 0px rgba(195, 0, 5, 0.1) !important",
        background: "#ffffff !important",
    },
}));

export default useStyles;