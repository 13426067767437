import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Icon, IconButton, SvgIcon, Typography } from "@mui/material";

import ButtonBase from "@mui/material/ButtonBase";

import { styled } from "@mui/material/styles";
import useStyles from "./navbar.styles";

import { logo } from "../../../assets/index";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Customescrollbar from "../scrollbar/scrollbar";

function Sidebar() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  // const selector = JSON.parse(localStorage.getItem("roleData"));
  const [view, setView] = useState([]);

  const images = [
    {
      icon: (
        <svg
          width="24"
          height="30"
          className={classes.menuIcon}
          viewBox="0 0 24 30"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10.5126 23.4999L18.5167 15.4958L16.4626 13.4416L10.4772 19.427L7.50216 16.452L5.48341 18.4708L10.5126 23.4999ZM3.50008 29.1666C2.72091 29.1666 2.05414 28.8894 1.49975 28.335C0.944414 27.7797 0.666748 27.1124 0.666748 26.3333V3.66659C0.666748 2.88742 0.944414 2.22017 1.49975 1.66484C2.05414 1.11045 2.72091 0.833252 3.50008 0.833252H14.8334L23.3334 9.33325V26.3333C23.3334 27.1124 23.0562 27.7797 22.5018 28.335C21.9465 28.8894 21.2792 29.1666 20.5001 29.1666H3.50008ZM13.4167 10.7499V3.66659H3.50008V26.3333H20.5001V10.7499H13.4167Z" />
        </svg>
      ),
      // icon: icon1,
      title: "Manage Scraper",
      url: "/manage-scraper",
      width: "100%",
    },
    {
      icon: (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          className={classes.menuIcon}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 3.64286C0 1.63096 1.63096 0 3.64286 0H16.3929C18.4048 0 20.0357 1.63096 20.0357 3.64286V15.3929H25.5V20.0357C25.5 23.0536 23.0536 25.5 20.0357 25.5H5.46429C2.44645 25.5 0 23.0536 0 20.0357V3.64286ZM20.0357 18.2143V22.6786C22.0477 22.6786 22.6786 22.0477 22.6786 20.0357V18.2143H20.0357ZM17.2143 22.6786V4.64286C17.2143 3.63692 16.3988 2.82143 15.3929 2.82143H4.64286C3.63692 2.82143 2.82143 3.63692 2.82143 4.64286V19.0357C2.82143 21.0477 4.45239 22.6786 6.46429 22.6786H17.2143Z" />
          <path d="M5.46436 6.37507C5.46436 5.8721 5.8721 5.46436 6.37507 5.46436H13.6608C14.1637 5.46436 14.5715 5.8721 14.5715 6.37507C14.5715 6.87804 14.1637 7.28578 13.6608 7.28578H6.37507C5.8721 7.28578 5.46436 6.87804 5.46436 6.37507ZM5.46436 11.8394C5.46436 11.3364 5.8721 10.9286 6.37507 10.9286H13.6608C14.1637 10.9286 14.5715 11.3364 14.5715 11.8394C14.5715 12.3423 14.1637 12.7501 13.6608 12.7501H6.37507C5.8721 12.7501 5.46436 12.3423 5.46436 11.8394ZM5.46436 17.3036C5.46436 16.8007 5.8721 16.3929 6.37507 16.3929H10.0179C10.5209 16.3929 10.9286 16.8007 10.9286 17.3036C10.9286 17.8065 10.5209 18.2144 10.0179 18.2144H6.37507C5.8721 18.2144 5.46436 17.8065 5.46436 17.3036Z" />
        </svg>
      ),
      // icon: icon2,
      title: "Manage Model scope",
      url: "/manage-model-scope",
      width: "100%",
    },
    {
      icon: (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          className={classes.menuIcon}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13 20.0833C13.4014 20.0833 13.7381 19.9473 14.0101 19.6753C14.2811 19.4043 14.4167 19.0681 14.4167 18.6667C14.4167 18.2653 14.2811 17.9286 14.0101 17.6566C13.7381 17.3855 13.4014 17.25 13 17.25C12.5986 17.25 12.2624 17.3855 11.9913 17.6566C11.7193 17.9286 11.5833 18.2653 11.5833 18.6667C11.5833 19.0681 11.7193 19.4043 11.9913 19.6753C12.2624 19.9473 12.5986 20.0833 13 20.0833ZM11.5833 14.4167H14.4167V5.91667H11.5833V14.4167ZM7.6875 25.75L0.25 18.3125V7.6875L7.6875 0.25H18.3125L25.75 7.6875V18.3125L18.3125 25.75H7.6875ZM8.89167 22.9167H17.1083L22.9167 17.1083V8.89167L17.1083 3.08333H8.89167L3.08333 8.89167V17.1083L8.89167 22.9167Z" />
        </svg>
      ),
      // icon: icon3,
      title: "Manage Issue/Request",
      url: "/manage-issue-/-request",
      width: "100%",
    },
    {
      icon: (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          className={classes.menuIcon}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13 11.5833C9.45833 11.5833 6.44792 11.0285 3.96875 9.91875C1.48958 8.80903 0.25 7.475 0.25 5.91667C0.25 4.35833 1.48958 3.02431 3.96875 1.91458C6.44792 0.804861 9.45833 0.25 13 0.25C16.5417 0.25 19.5521 0.804861 22.0312 1.91458C24.5104 3.02431 25.75 4.35833 25.75 5.91667C25.75 7.475 24.5104 8.80903 22.0312 9.91875C19.5521 11.0285 16.5417 11.5833 13 11.5833ZM13 18.6667C9.45833 18.6667 6.44792 18.1118 3.96875 17.0021C1.48958 15.8924 0.25 14.5583 0.25 13V9.45833C0.25 10.4972 0.734028 11.377 1.70208 12.0976C2.67014 12.8172 3.82708 13.4014 5.17292 13.85C6.51875 14.2986 7.91794 14.623 9.3705 14.8232C10.8221 15.0244 12.0319 15.125 13 15.125C13.9681 15.125 15.1779 15.0244 16.6295 14.8232C18.0821 14.623 19.4812 14.2986 20.8271 13.85C22.1729 13.4014 23.3299 12.8172 24.2979 12.0976C25.266 11.377 25.75 10.4972 25.75 9.45833V13C25.75 14.5583 24.5104 15.8924 22.0312 17.0021C19.5521 18.1118 16.5417 18.6667 13 18.6667ZM13 25.75C9.45833 25.75 6.44792 25.1951 3.96875 24.0854C1.48958 22.9757 0.25 21.6417 0.25 20.0833V16.5417C0.25 17.5806 0.734028 18.4603 1.70208 19.1809C2.67014 19.9006 3.82708 20.4847 5.17292 20.9333C6.51875 21.3819 7.91794 21.7068 9.3705 21.908C10.8221 22.1082 12.0319 22.2083 13 22.2083C13.9681 22.2083 15.1779 22.1082 16.6295 21.908C18.0821 21.7068 19.4812 21.3819 20.8271 20.9333C22.1729 20.4847 23.3299 19.9006 24.2979 19.1809C25.266 18.4603 25.75 17.5806 25.75 16.5417V20.0833C25.75 21.6417 24.5104 22.9757 22.0312 24.0854C19.5521 25.1951 16.5417 25.75 13 25.75Z" />
        </svg>
      ),
      // icon: icon4,
      title: "Manage Sources",
      url: "/manage-sources",
      width: "100%",
    },
    {
      icon: (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          className={classes.menuIcon}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8.86705 24.3409L6.66477 20.5739L2.2892 19.6756L2.78182 15.4159L0 12.1705L2.78182 8.95398L2.2892 4.69432L6.66477 3.79602L8.86705 0L12.75 1.79659L16.633 0L18.8642 3.79602L23.2108 4.69432L22.7182 8.95398L25.5 12.1705L22.7182 15.4159L23.2108 19.6756L18.8642 20.5739L16.633 24.3409L12.75 22.5443L8.86705 24.3409ZM9.64943 22.0517L12.75 20.7477L15.9375 22.0517L17.879 19.154L21.2693 18.2847L20.9216 14.8364L23.2688 12.1705L20.9216 9.44659L21.2693 5.9983L17.879 5.18693L15.8795 2.2892L12.75 3.59318L9.5625 2.2892L7.62102 5.18693L4.23068 5.9983L4.57841 9.44659L2.23125 12.1705L4.57841 14.8364L4.23068 18.3426L7.62102 19.154L9.64943 22.0517ZM11.504 16.0244L18.0818 9.50455L16.7778 8.31648L11.504 13.5324L8.75114 10.6636L7.41818 11.9676L11.504 16.0244Z" />
        </svg>
      ),
      // icon: icon4,
      title: "Manage Quality",
      url: "/manage-quality",
      width: "100%",
    },
    {
      icon: (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          className={classes.menuIcon}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5.91667 20.0833H8.75V13H5.91667V20.0833ZM17.25 20.0833H20.0833V5.91667H17.25V20.0833ZM11.5833 20.0833H14.4167V15.8333H11.5833V20.0833ZM11.5833 13H14.4167V10.1667H11.5833V13ZM3.08333 25.75C2.30417 25.75 1.63692 25.4728 1.08158 24.9184C0.527194 24.3631 0.25 23.6958 0.25 22.9167V3.08333C0.25 2.30417 0.527194 1.63692 1.08158 1.08158C1.63692 0.527194 2.30417 0.25 3.08333 0.25H22.9167C23.6958 0.25 24.3631 0.527194 24.9184 1.08158C25.4728 1.63692 25.75 2.30417 25.75 3.08333V22.9167C25.75 23.6958 25.4728 24.3631 24.9184 24.9184C24.3631 25.4728 23.6958 25.75 22.9167 25.75H3.08333ZM3.08333 22.9167H22.9167V3.08333H3.08333V22.9167Z" />
        </svg>
      ),
      // icon: icon5,
      title: "Dashboard & Reports",
      url: "/dashboard-and-reports",
      children: "/dashboard-and-reports/country-wise",
      width: "100%",
    },
    {
      icon: (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          className={classes.menuIcon}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 3.64286C0 1.63096 1.63096 0 3.64286 0H16.3929C18.4048 0 20.0357 1.63096 20.0357 3.64286V15.3929H25.5V20.0357C25.5 23.0536 23.0536 25.5 20.0357 25.5H5.46429C2.44645 25.5 0 23.0536 0 20.0357V3.64286ZM20.0357 18.2143V22.6786C22.0477 22.6786 22.6786 22.0477 22.6786 20.0357V18.2143H20.0357ZM17.2143 22.6786V4.64286C17.2143 3.63692 16.3988 2.82143 15.3929 2.82143H4.64286C3.63692 2.82143 2.82143 3.63692 2.82143 4.64286V19.0357C2.82143 21.0477 4.45239 22.6786 6.46429 22.6786H17.2143Z" />
          <path d="M5.46436 6.37507C5.46436 5.8721 5.8721 5.46436 6.37507 5.46436H13.6608C14.1637 5.46436 14.5715 5.8721 14.5715 6.37507C14.5715 6.87804 14.1637 7.28578 13.6608 7.28578H6.37507C5.8721 7.28578 5.46436 6.87804 5.46436 6.37507ZM5.46436 11.8394C5.46436 11.3364 5.8721 10.9286 6.37507 10.9286H13.6608C14.1637 10.9286 14.5715 11.3364 14.5715 11.8394C14.5715 12.3423 14.1637 12.7501 13.6608 12.7501H6.37507C5.8721 12.7501 5.46436 12.3423 5.46436 11.8394ZM5.46436 17.3036C5.46436 16.8007 5.8721 16.3929 6.37507 16.3929H10.0179C10.5209 16.3929 10.9286 16.8007 10.9286 17.3036C10.9286 17.8065 10.5209 18.2144 10.0179 18.2144H6.37507C5.8721 18.2144 5.46436 17.8065 5.46436 17.3036Z" />
        </svg>
      ),
      // icon: icon2,
      title: "Reports",
      url: "/reports",
      width: "100%",
    },
    {
      icon: (
        <svg
          width="25"
          height="26"
          viewBox="0 0 25 26"
          className={classes.menuIcon}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10.0925 17.6047C10.3018 16.9568 10.5927 15.131 10.9529 14.5684H2.73128C1.22283 14.5684 0 15.7913 0 17.2997V18.0014C0 19.0856 0.386834 20.1343 1.09097 20.9588C2.99326 23.1866 5.8911 24.286 9.71183 24.286C10.4404 24.286 11.1355 24.2459 11.7965 24.1657C11.3217 23.6499 9.69993 21.8561 9.3748 21.2275C9.08988 21.2423 8.79742 21.2497 8.49732 21.2497C5.17157 21.2497 5.05006 20.7805 3.63879 20.0347C2.97947 19.6863 1.82177 18.6519 1.82177 18.0014L1.81702 17.6057C1.81702 17.6057 2.22897 17.6047 2.73128 17.6047H10.0925ZM9.71183 0C13.0656 0 15.7844 2.71877 15.7844 6.07256C15.7844 9.42634 13.0656 12.1451 9.71183 12.1451C6.358 12.1451 3.63922 9.42634 3.63922 6.07256C3.63922 2.71877 6.358 0 9.71183 0ZM9.71183 3.03628C7.36414 3.03628 6.6755 3.72491 6.6755 6.07256C6.6755 8.4202 7.36414 9.10883 9.71183 9.10883C12.0595 9.10883 12.7481 8.4202 12.7481 6.07256C12.7481 3.72491 12.0595 3.03628 9.71183 3.03628ZM24.2855 18.8202C24.2855 22.5094 21.2949 25.5 17.6057 25.5C13.9165 25.5 10.9259 22.5094 10.9259 18.8202C10.9259 15.131 13.9165 12.1404 17.6057 12.1404C21.2949 12.1404 24.2855 15.131 24.2855 18.8202ZM18.2802 15.0802C18.0679 14.3991 17.1434 14.3991 16.9311 15.0802L16.2546 17.2507H14.0655C13.3784 17.2507 13.0928 18.1671 13.6486 18.5881L15.4197 19.9295L14.7432 22.0999C14.5309 22.781 15.2787 23.3474 15.8345 22.9265L17.6057 21.5851L19.3768 22.9265C19.9327 23.3474 20.6805 22.781 20.4682 22.0999L19.7917 19.9295L21.5628 18.5881C22.1186 18.1671 21.8329 17.2507 21.1459 17.2507H18.9567L18.2802 15.0802Z" />
        </svg>
      ),
      // icon: icon6,
      title: "Role Management",
      url: "/role-management",
      width: "100%",
    },
    {
      icon: (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          className={classes.menuIcon}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M18.8213 12.1399C22.5105 12.1399 25.5013 15.1307 25.5013 18.82C25.5013 22.5092 22.5105 25.5 18.8213 25.5C15.1319 25.5 12.1412 22.5092 12.1412 18.82C12.1412 15.1307 15.1319 12.1399 18.8213 12.1399ZM15.607 18.3905C15.3699 18.1533 14.9853 18.1533 14.7481 18.3905C14.5111 18.6277 14.5111 19.0122 14.7481 19.2493L17.1772 21.6784C17.4144 21.9156 17.799 21.9156 18.0361 21.6784L22.8943 16.8202C23.1315 16.5831 23.1315 16.1986 22.8943 15.9614C22.6572 15.7242 22.2726 15.7242 22.0354 15.9614L17.6067 20.3901L15.607 18.3905ZM12.1682 14.5682C11.8078 15.1309 10.3024 16.9569 10.093 17.6049L3.94592 17.6053C3.44359 17.6053 3.03223 17.6051 3.03223 17.6051L3.03638 18.0019C3.03638 18.6525 3.21701 18.9322 3.6395 19.4269C4.85405 20.6414 5.17174 21.2503 8.49756 21.2503C9.22194 21.2503 9.90197 21.2073 10.5383 21.1217C10.8361 21.7227 12.4241 23.4943 12.8599 23.9958C11.8937 24.1904 10.8432 24.2867 9.71211 24.2867C5.89129 24.2867 2.99336 23.1874 1.09101 20.9595C0.386859 20.135 0 19.0862 0 18.0019V17.3003C0 15.7917 1.22287 14.5689 2.73137 14.5689L12.1682 14.5682ZM9.71211 0C13.066 0 15.7849 2.71887 15.7849 6.07275C15.7849 9.42665 13.066 12.1455 9.71211 12.1455C6.35821 12.1455 3.63935 9.42665 3.63935 6.07275C3.63935 2.71887 6.35821 0 9.71211 0ZM9.71211 3.03638C7.36438 3.03638 6.67573 3.72504 6.67573 6.07275C6.67573 8.42048 7.36438 9.10915 9.71211 9.10915C12.0599 9.10915 12.7485 8.42048 12.7485 6.07275C12.7485 3.72504 12.0599 3.03638 9.71211 3.03638Z" />
        </svg>
      ),
      // icon: icon7,
      title: "User Management",
      url: "/user-management",
      width: "100%",
    },
    {
      icon: (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          className={classes.menuIcon}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7.15 16.3443H9.1V11.2295H7.15V12.8279H5.2V14.7459H7.15V16.3443ZM10.4 14.7459H20.8V12.8279H10.4V14.7459ZM16.9 11.2295H18.85V9.63115H20.8V7.71311H18.85V6.11475H16.9V11.2295ZM5.2 9.63115H15.6V7.71311H5.2V9.63115ZM7.8 26V22.459H2.6C1.885 22.459 1.27292 22.2086 0.76375 21.7078C0.254583 21.207 0 20.6049 0 19.9016V2.55738C0 1.8541 0.254583 1.25205 0.76375 0.751229C1.27292 0.25041 1.885 0 2.6 0H23.4C24.115 0 24.7271 0.25041 25.2362 0.751229C25.7454 1.25205 26 1.8541 26 2.55738V19.9016C26 20.6049 25.7454 21.207 25.2362 21.7078C24.7271 22.2086 24.115 22.459 23.4 22.459H18.2V26H7.8ZM2.6 19.9016H23.4V2.55738H2.6V19.9016Z" />
        </svg>
      ),
      // icon: icon7,
      title: "SLA and Holidays",
      url: "/sla-and-holidays",
      width: "100%",
    },
  ];

  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    borderRadius: 8,
    padding: 8,
    marginBottom: 8,
  }));
  const [top, setTop] = useState(0);
  const Image = styled("span")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  }));

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "grey",
    };

    return (
      <div
        className="scrollbar-thumb"
        style={{ ...style, ...thumbStyle }}
        {...props}
      />
    );
  };

  return (
    <>
      {location.pathname !== "/" &&
        location.pathname !== "/reset-password" &&
        location.pathname !== "/change-password" &&
        location.pathname !== "/error" &&
        location.pathname !== "/projectselection" &&
        location.pathname !== "/forgot-password" && (
          <div className="sideBar">
            <IconButton aria-label="delete" disableRipple>
              <img
                className={classes.logo}
                src={logo}
                width="80"
                alt="JATO Dynamics"
              />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                height: "calc(100vh - 50px)",
              }}
            >
              <Customescrollbar
                renderThumbVertical={renderThumb}
                className="nav-menu-items"
                children={
                  <ul>
                    {images.map((image) => (
                      <ImageButton
                        focusRipple
                        // disabled={image.url!=="/manage-issue-/-request"}
                        onClick={() => {
                          // image.children?navigate(image.children, { state: localStorage.getItem("role") }):
                          navigate(image.url, {
                            state: localStorage.getItem("role"),
                          });
                        }}
                        key={image.title}
                        style={{
                          width: image.width,
                        }}
                        className={
                          image.children
                            ? location.pathname.split("/")[1] ===
                              image.url.split("/")[1]
                              ? classes.selectedImageButton
                              : classes.imageButton
                            : location.pathname === image.url
                              ? classes.selectedImageButton
                              : classes.imageButton
                        }
                      >
                        <Image>
                          {/* <img className={classes.menuIcon} src={image.icon} alt="JATO Dynamics" /> */}
                          {image.icon}
                          <Typography
                            component="span"
                            variant="subtitle1"
                            color="inherit"
                            sx={{
                              fontSize: 12,
                              position: "relative",
                              p: 0.2,
                            }}
                          >
                            {/* <{image.icon} /> */}
                            {image.title}
                          </Typography>
                        </Image>
                      </ImageButton>
                    ))}
                  </ul>
                }
              />
            </Box>
          </div>
        )}
    </>
  );
}

export default Sidebar;
