import {
  BASE_URL_QUALITY,
  MANAGE_DOWNLOAD,
  MANAGE_QUALITY_EXPORT,
  MANAGE_QUALITY_GET,
} from "apiConfig/managequality";
import fileDownload from "js-file-download";
import { deleteData, getData, postData } from "services/basicApiService";

/******************************************************************************
  Function: getManagescope
  Argument: pagenumber, pagesize, search,data
  Return: response
  Usage:
  1.Get Manage Scope Data
  *******************************************************************************/
export const getAllManageQuality = (
  pagenumber,
  pagesize,
  search,
  data,
  header
) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(
      MANAGE_QUALITY_GET +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const getAllManageQualityexport = (
  pagenumber,
  pagesize,
  search,
  data,
  header
) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(
      MANAGE_QUALITY_EXPORT +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const deleteQuality = (id, header) => {
  return async () => {
    ////checkTokenExpiry();
    const response = await deleteData(BASE_URL_QUALITY + `?id=${id}`, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const downloadquality = (id, name1, header) => {
  const url=encodeURIComponent(id)
  return async () => {
    var UrlName = MANAGE_DOWNLOAD + `?projectId=${header}&Filepath=${url}`;
    var name = name1;
    ////checkTokenExpiry();
    const response = await getData(UrlName
    //   , 
    //   {
    //   headers: {
    //     "X-Project-ID": header,
    //   },
    // }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      // window.open(UrlName);

      // fileDownload(UrlName, name);
      return response;
    }
  };
};
