var baseApiUrl = process.env.REACT_APP_MANAGE_ISSUE_BASE_URL;
const baseurl = baseApiUrl + "api";
const baseurlforsla = baseurl + "/v1/holidays";
export const BASE_URL_HOLIDAY = baseurlforsla;
export const GETALL_HOLIDAY = baseurlforsla;
export const ADD_HOLIDAY = baseurlforsla ;
export const HOLIDAY_LIST = baseurlforsla+"/getholidaybyyear" ;

export const GET_HOLIDAY = baseurlforsla+"/getholidaybyid";
export const UPDATE_HOLIDAY = baseurlforsla;
export const DELETE_HOLIDAY = baseurlforsla + "/deleteholiday";
