import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    loginArea: {
      width: "100%",
      maxWidth: 460,
    },
    loginBlock: {
      padding: theme.spacing(3),
    },
    logo: {
      margin: theme.spacing(0),
    },
    dataIcon: {
      margin: theme.spacing(0.3, 0, -0.3, 0),
    },
    link: {
      color: "#7947F6",
    },
    errorMessage: {
      fontSize: "12px !important",
      fontWeight: "700 !important",
      lineHeight: "16px !important",
      color: "Red",
    },
  })
);

export default useStyles;
