import { makeStyles, createStyles } from "@material-ui/core/styles";
import { red } from "@mui/material/colors";

const useStyles = makeStyles(theme => ({
  textField: {
  //   padding: "0 !important",
    "& .MuiInputBase-root": {
      // height: 32,
      fontSize: 13,
      fontFamily: "'Inter', sans-serif",
    },
    "& .Mui-required":{
      color:red,
    }
  //   "& .MuiFormLabel-root": {
  //     fontSize: "13px !important",
  //     marginTop: "-2px !important",
  //   },
  //   inputIcon: {
  //     fontSize: "13px !important",
  //   }
  },
  textArea: {
    padding: "0 !important",
    "& .MuiInputBase-root": {
      height: 80,
      
      // fontSize: 13,
      // fontFamily: "'Inter', sans-serif",
    },
    "& .MuiFormLabel-root": {
      "& .Mui-required":{
        color:red,
      }
    },
    
    // .MuiFormLabel-root .MuiInputLabel-root
    // "& .MuiInputLabel-root": {
    //   fontSize: "12px !important",
    // },
    // "& .MuiFormLabel-root": {
    //   fontSize: "13px !important",
    //   marginTop: "-2px !important",
    // },
  },
})
);

export default useStyles;
