import * as React from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { Box, Stack } from "@mui/system";
import Paper from "@mui/material/Paper";
import Dropdown from "../dropdown/dropdown";
import Data from "./Data.json";
import { Grid, MenuItem, Pagination, Select, Typography } from "@mui/material";

import useStyles from "./pagination.style";
import MaterialUIButton from "../button/button";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#D3D3D3",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  justifyContent: "right",
  color: theme.palette.text.secondary,
}));

// count={total}
// total={totalCount}
// currentPage={page}
export default function UsePagination(props) {
  const { items } = usePagination({
    count: props.t?Math.ceil(props.t / props.limit):0
  });

  const initializePaginator = () => {
    const pageSize = this.props.pageSize,
      totalRecords = this.props.totalRecords,
      lastPage = Math.ceil(totalRecords / pageSize);

    var pages = [];
    if (lastPage <= 5) {
      if (lastPage > 1)
        pages = Array.from(Array(lastPage - 2), (_, i) => i + 2);
    } else {
      var median = this.props.pageNumber;
      if (this.props.pageNumber - 2 <= 1) {
        median = 3;
      } else if (this.props.pageNumber + 2 >= lastPage) {
        median = lastPage - 2;
      }
      for (var i = median - 2; i <= median + 2; i++) {
        if (i > 1 && i < lastPage) {
          pages.push(i);
        }
      }
    }
    this.setState({ pages, lastPage });
  };
  const lastPage = Math.ceil(props.total / props.limit);
  const classes = useStyles();
  // const handlePageNumberClick = (event, data) => {
  //   event.preventDefault();
  //   props.onPageChange(data);
  // };
  // const handlePreviousButtonClick = () => {
  //   props.handlePageClick(props.currentPage - 1);
  // };

  // const handleNextButtonClick = () => {
  //   props.handlePageClick(props.currentPage + 1);
  // };

  // const handleButtonClick = () => {
  //   if (props.currentPage - 1) {
  //     handlePreviousButtonClick();
  //   }
  //   handleNextButtonClick();
  // };
  return (
    <Box>
      <Grid container sx={{ pt: 2, pb: 2, pl: 1, pr: 1.5 }}>
        
        <Grid item xs={12} md={6}>
          <Grid
            container
            spacing={1.5}
            alignItems="center"
            justifyContent="left"
          >
            <Grid item xs={"auto"}>
              <Typography
                variant="caption"
                component="p"
                align="center"
                className={classes.rowPerPage}
              >
                Row per page
              </Typography>
            </Grid>

            <Grid item xs={"auto"}>
              <Dropdown
                size="small"
                labelId="Rows"
                id="Rows"
                pageination={true}
                value={props.value}
                onChange={props.onChange}
                menuItems={Data.data.map((value, index) => (
                  <MenuItem key={`RowCount_${index}`} value={value}>{value}</MenuItem>
                ))}
              />
            </Grid>

            <Grid item xs={"auto"} className={classes.recountCountDetails}>
              <>
                {10 > 0 ? (
                  <span className="font-size-extra-small me-2">
                    {props.t?(props.currentPage - 1) * props.limit + 1:0} to{" "}
                    {props.t?props.currentPage * props.limit >= props.t??0
                      ? props.t??0
                      : props.currentPage * props.limit:0}{" "}
                    of {props.t??0} entries
                  </span>
                ) : (
                  <span>{""}</span>
                )}
              </>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid
            container
            spacing={1.5}
            alignItems="center"
            justifyContent="right"
          >
            <Grid item xs={"auto"}>
              <List>
                <Pagination
                  // size="small"
                  count={props.t?Math.ceil(props.t / props.limit):0}
                  page={props.currentPage}
                  onChange={props.handlePageClick}
                  // variant="outlined"
                  color="primary"
                  shape="rounded"
                />
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
