// Default React Components
import React from 'react'
import { Paper } from '@mui/material'

// Importing useStyles
import useStyles from "./baseLayout.styles";
import { ToastContainer } from 'react-toastify';

const BaseLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.pageContent}>
      <ToastContainer autoClose={5000}/>
      {children}
    </Paper>
  )
}

export default BaseLayout