// Default React, React Components, Router and Services Components
import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { ClassNames } from "@emotion/react";
import { formatBytes, getMonth } from "utils/helper";
import { MAX_COUNT } from "constants/constants";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

// Importing Custom Components
import Textbox from "components/commonComponents/textbox/textbox";
import Dropdown from "components/commonComponents/dropdown/dropdown";
import MaterialUIButton from "components/commonComponents/button/button";
import Datepicker from "components/commonComponents/datepicker/datepicker";
import Radiobutton from "components/commonComponents/radiobutton/radiobutton";
import MultipleAutocomplete from "components/commonComponents/autocomplete/multipleAutocomplete";

// Importing useStyles
import useStyles from "./manageSources.styles";

// Import Material Icons
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import EditPicker from "components/commonComponents/datepicker/editpicker";
import AlertDialog from "components/commonComponents/alertDialog";
import DoneIcon from "@mui/icons-material/Done";
import {
  countryDropdown,
  devcomplexityDropdown,
  makeString,
  modelDropdown,
  priorityDropdown,
} from "redux/manageissues/action";
import {
  getCountrySource,
  getDevcomplexity,
  getMakeSource,
  getModelSource,
  getNewSourceStatus,
  getPrioritySource,
  getSourceurlSource,
  getUrlStatusSource,
  saveFiltersSource,
} from "redux/app/actions";
import {
  ComplexitySource,
  makeDependency,
  modelDependency,
  newsourceStatus,
  sourceurlDependency,
  sourceurlStatus,
} from "redux/managesources/action";
import { sourceUrlDropdown } from "redux/managescraper/action";
function AdvanceFilters(props) {
  // Declaration of States and Component Variables Start Here
  const [selectedValue, setSelectedValue] = React.useState("Year");
  const months = getMonth();
  const [show, setShow] = useState(false);
  const data = useSelector((state) => state.manageSource);
  const data1 = useSelector((state) => state.manageIssue);
  const id=useSelector((state) => state.project.managesource)
  const select = useSelector((state) => state.manageSource.filter);
  const classes = useStyles();
  const dispatch = useDispatch();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  // Declaration of States and Component Variables End Here
  useEffect(() => {
    getDataFormAPIForDropdown();
  }, []);
  const formik = useFormik({
    initialValues: {
      timeline: select.timeline,
      quater: select.quater,
      startdate: select.startdate,
      enddate: select.enddate,
      timeLineStart: select.timeLineStart,
      timeLineEnd: select.timeLineEnd,
      country: select.country,
      make: select.make,
      model: select.model,
      sourceurl: select.sourceurl,
      priorityLevel:select.priorityLevel,
      complexityLevel:select.complexityLevel,
      urlstatus: select.urlstatus,
      receiveddate: select.receiveddate,
      newsourcestatus: select.newsourcestatus,
      exportId: select.exportId,
    },

    onSubmit: (values) => {
      dispatch(saveFiltersSource(values));
      props.filter({
        timeLineStart: values.timeLineStart,
        timeLineEnd: values.timeLineEnd,
        country: values.country.map((e) => e.countryId),
        make: values.make.map((e) => e.make1),
        model: values.model.map((e) => e.model1),
        sourceUrl: values.sourceurl.map((e) => e.sourceUrl),
        urlStatus: values.urlstatus.map((e) => e.urlstatusId),
        receivedDate: values.receiveddate,
        priorityLevel:values.priorityLevel,
        complexityLevel:values.complexityLevel,
        newSourceStatus: values.newsourcestatus,
        exportId: [],
      });
    },
  });

  /******************************************************************************
  Function: reset
  Argument:
  Return:
  Usage:
  1.To reset the form values
  *******************************************************************************/

  const reset = () => {
    if (formik && formik.values) {
      //formik.resetForm();
      formik.values.timeline = "Year";
      formik.values.quater = "";
      formik.values.startdate = null;
      formik.values.enddate = null;
      formik.values.timeLineStart = null;
      formik.values.timeLineEnd = null;
      formik.values.country = [];
      formik.values.make = [];
      formik.values.model = [];
      formik.values.sourceurl = [];
      formik.values.urlstatus = [];
      formik.values.complexityLevel=[]
      formik.values.priorityLevel=[]
      formik.values.receiveddate = null;
      formik.values.newsourcestatus = [];
      formik.values.exportId = [];

      props.filter(
        {
          timeLineStart: null,
          timeLineEnd: null,
          country: [],
          make: [],
          model: [],
          sourceUrl: [],
          urlStatus: [],
          receivedDate: null,
          complexityLevel:[],
          newSourceStatus: [],
          priorityLevel:[],
          exportId: [],
        },
        true
      );
      dispatch(
        saveFiltersSource({
          timeline: "Year",
          quater: "",
          startdate: null,
          enddate: null,
          timeLineStart: null,
          timeLineEnd: null,
          country: [],
          make: [],
          model: [],
          sourceurl: [],
          urlstatus: [],
          priorityLevel:[],
          receiveddate: null,
          newsourcestatus: [],
          complexityLevel:[],
          exportId: [],
        })
      );
    }
  };
  /******************************************************************************
  Function: getDataFormAPIForDropdown
  Argument:
  Return:
  Usage:
  1.To Call dropdown API 
  *******************************************************************************/

  const getDataFormAPIForDropdown = () => {
    dispatch(countryDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getCountrySource([]));
        } else {
          dispatch(getCountrySource(res.data.result));
        }
      } else {
        dispatch(getCountrySource([]));
      }
    });
    dispatch(devcomplexityDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getDevcomplexity([]));
        } else dispatch(getDevcomplexity(res.data.result));
      } else {
        dispatch(getDevcomplexity([]));
      }
    });

    dispatch(ComplexitySource(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getPrioritySource([]));
        } else {
          dispatch(getPrioritySource(res.data.result));
        }
      } else {
        dispatch(getPrioritySource([]));
      }
    });
    dispatch(makeString([],id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getMakeSource([]));
        } else {
          dispatch(getMakeSource(res.data.result));
        }
      } else {
        dispatch(getMakeSource([]));
      }
    });

    dispatch(sourceurlStatus(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getUrlStatusSource([]));
        } else {
          dispatch(getUrlStatusSource(res.data.result));
        }
      } else {
        dispatch(getUrlStatusSource([]));
      }
    });
    dispatch(newsourceStatus(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getNewSourceStatus([]));
        } else {
          dispatch(getNewSourceStatus(res.data.result));
        }
      } else {
        dispatch(getNewSourceStatus([]));
      }
    });
    // dispatch(sourceUrlDropdown()).then((res) => {
    //   if (res.data.statusCode === 200) {
    //     if (res.data.result === "No records found") {
    //       dispatch(getSourceurlSource([]));
    //     } else {
    //       dispatch(getSourceurlSource(res.data.result));
    //     }
    //   } else {
    //     dispatch(getSourceurlSource([]));
    //   }
    // });
  };

  let fmt = "[Q]Q-Y";
  let quarters = [
    moment().format(fmt),
    moment().subtract(1, "Q").format(fmt),
    moment().subtract(2, "Q").format(fmt),
    moment().subtract(3, "Q").format(fmt),
  ];
console.log(data);
  return (
    // Advance Filter Form Start Here
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                Advance Filter
              </Typography>
            </Grid>

            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs="auto">
                  <MaterialUIButton
                    color="secondary"
                    message="RESET"
                    onClick={() => {
                      reset();
                    }}
                    startIcon={<RestartAltOutlinedIcon />}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                        JSON.stringify(formik.values)
                          ? props.close()
                          : setShow(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}
        <Grid item xs={12} sx={{ mt: -1, mb: -1 }}>
          <Typography variant="subtitle2" gutterBottom>
            Timeline
          </Typography>

          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Year"
                name="timeline"
                itemvalue="Year"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);

                  formik.setFieldValue(
                    "timeLineStart",
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Quarter"
                // checked={formik.values.timeline === "Quater"}
                name="timeline"
                value={formik.values.timeline}
                itemvalue="Quater"
                onChange={(e) =>
                  formik.setFieldValue("timeline", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Month"
                name="timeline"
                value={formik.values.timeline}
                itemvalue="Month"
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().startOf("month").format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().endOf("month").format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Week"
                // checked={formik.values.timeline === "Week"}
                name="timeline"
                itemvalue="Week"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue("startdate", moment().startOf("week"));
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().startOf("week").format("YYYY-MM-DD")
                  );
                  formik.setFieldValue("enddate", moment().endOf("week"));
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().endOf("week").format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Today"
                // checked={formik.values.timeline === "Today"}
                name="timeline"
                itemvalue="Today"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Custom"
                value={formik.values.timeline}
                // checked={formik.values.timeline === "Custom"}
                name="timeline"
                itemvalue="Custom"
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue("startdate", null);
                  formik.setFieldValue("enddate", null);
                  formik.setFieldValue("timeLineStart", null);
                  formik.setFieldValue("timeLineEnd", null);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {formik.values.timeline === "Week" && (
            <Textbox
              fullWidth
              disabled
              id="week"
              name="week"
              value={
                moment().startOf("week").format("ll") +
                " to " +
                moment().endOf("week").format("ll")
              }
            />
          )}
          {formik.values.timeline === "Month" && (
            <Textbox
              fullWidth
              disabled
              id="year"
              name="year"
              value={months[0]}
            />
          )}
          {formik.values.timeline === "Year" && (
            <Textbox
              fullWidth
              disabled
              id="year"
              name="year"
              value={months[months.length - 1] + " to " + months[0]}
            />
          )}
          {formik.values.timeline === "Today" && (
            <Textbox
              fullWidth
              disabled
              id="today"
              name="today"
              value={moment().format("LL")}
            />
          )}
          {formik.values.timeline === "Quater" && (
            <Dropdown
              fullWidth
              labelId="quater"
              id="quater"
              name="quater"
              label="Quarter"
              value={formik.values.quater}
              onChange={(e) => {
                const value = e.target.value.split("-");
                formik.setFieldValue("quater", e.target.value);

                if (value[0] === "Q1") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("1/1/" + value[1]),
                        moment("3/31/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("3/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("1/1/" + value[1]),
                      moment("3/31/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("1/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("1/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("3/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q2") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("4/1/" + value[1]),
                        moment("6/30/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("6/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("4/1/" + value[1]),
                      moment("6/30/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("4/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("4/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("6/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q3") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("7/1/" + value[1]),
                        moment("9/30/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("9/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("4/1/" + value[1]),
                      moment("6/30/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("7/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("7/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("9/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q4") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("10/1/" + value[1]),
                        moment("12/31/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("12/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("10/1/" + value[1]),
                      moment("12/31/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("10/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("10/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("12/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
              }}
              menuItems={quarters.map((e) => (
                <MenuItem value={e}>{e}</MenuItem>
              ))}
            />
          )}
          {formik.values.timeline === "Custom" && (
            <>
              <EditPicker
                max={moment().format("YYYY-MM-DD")}
                min={moment().subtract(1, "year").format("YYYY-MM-DD")}
                name="timeLineStart"
                value={formik.values.startdate}
                onChange={(e) => {
                  if (e === null) {
                    formik.setFieldValue("startdate", e);
                    // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
                  } else {
                    formik.setFieldValue("startdate", e);

                    formik.setFieldValue(
                      "timeLineStart",
                      e.format("YYYY-MM-DD")
                    );
                  }
                }}
                error={
                  formik.touched.startdate && Boolean(formik.errors.startdate)
                }
                helperText={formik.touched.startdate && formik.errors.startdate}
                size="small"
                fullWidth
                label="Start Date"
              />
              <br />
              <EditPicker
                max={moment().format("YYYY-MM-DD")}
                min={formik.values.startdate}
                name="timeLineEnd"
                value={formik.values.enddate}
                onChange={(e) => {
                  if (e === null) {
                    formik.setFieldValue("enddate", e);
                    // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
                  } else {
                    formik.setFieldValue("enddate", e);
                    formik.setFieldValue("timeLineEnd", e.format("YYYY-MM-DD"));
                  }
                }}
                error={formik.touched.enddate && Boolean(formik.errors.enddate)}
                helperText={formik.touched.enddate && formik.errors.enddate}
                size="small"
                fullWidth
                label="End Date"
              />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="Country"
            id="Country"
            name="country"
            label="Country"
            onChange={(_, e, value) => {
              formik.setFieldValue("country", e);
              if (value !== "clear") {
                dispatch(makeString(e.map((i) => i.countryId),id)).then((res) => {
                  if (res.data.statusCode === 200) {
                    if (res.data.result === "No records found") {
                      dispatch(getMakeSource([]));
                    } else {
                      dispatch(getMakeSource(res.data.result));
                    }
                  } else {
                    dispatch(getMakeSource([]));
                  }
                });
              } 
              else{
                dispatch(makeString([],id)).then((res) => {
                  if (res.data.statusCode === 200) {
                    if (res.data.result === "No records found") {
                      dispatch(getMakeSource([]));
                    } else {
                      dispatch(getMakeSource(res.data.result));
                    }
                  } else {
                    dispatch(getMakeSource([]));
                  }
                });
              }
            }}
            value={formik.values.country}
            placeholder="Country"
            limitTags={2}
            options={data.country}
            isOptionEqualToValue={(option, value) => {
              return option.countryId === value.countryId;
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.countryCode + " - " + option.region}
                  //{...getTagProps({ index })}
                />
              ))
            }
            getOptionLabel={(e) => e.countryCode + " - " + e.region}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.countryCode + " - " + e.region}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="Make"
          
            id="Make"
            name="make"
            label="Make"
            onChange={(_, e, value) => {
              formik.setFieldValue("make", e);
              if (value !== "clear") {
                dispatch(modelDropdown(e.map((i) => i.make1),id)).then((res) => {
                  if (res.data.statusCode === 200) {
                    if (res.data.result === "No records found") {
                      dispatch(getModelSource([]));
                    } else {
                      dispatch(getModelSource(res.data.result));
                    }
                  } else {
                    dispatch(getModelSource([]));
                  }
                });
              } 
              else if(value==="removeOption"&&e.length===0){
                formik.setFieldValue("model", []);

              }
              else {
                formik.setFieldValue("model", []);
              }
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.make1}
                  //{...getTagProps({ index })}
                />
              ))
            }
            value={formik.values.make}
            placeholder="Make"
            limitTags={3}
            options={data.make}
            getOptionLabel={(e) => e.make1}
            isOptionEqualToValue={(option, value) => {
              return option.make1 === value.make1;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  size="small"
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.make1}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="Model"
            disabled={formik.values.make.length === 0 ? true : false}
            id="Model"
            name="model"
            label="Model"
            onChange={(_, e) => {
              formik.setFieldValue("model", e);
              dispatch(sourceurlDependency(e.map((i) => i.model1),id)).then(
                (res) => {
                  if (res.data.statusCode === 200) {
                    if (res.data.result.length === 0) {
                      dispatch(getSourceurlSource([]));
                    } else {
                      dispatch(getSourceurlSource(res.data.result));
                    }
                  } else {
                    dispatch(getSourceurlSource([]));
                  }
                }
              );
            }}
            isOptionEqualToValue={(option, value) => {
              return option.model1 === value.model1;
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.model1}
                  //{...getTagProps({ index })}
                />
              ))
            }
            value={formik.values.model}
            placeholder="Model"
            limitTags={3}
            options={data.model}
            getOptionLabel={(e) => e.model1}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  size="small"
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.model1}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            // disabled={formik.values.model.length === 0 ? true : false}
            labelId="sourceurl"
            id="sourceurl"
            name="priorityLevel"
            label="Business Priority"
            onChange={(_, e) => {
              formik.setFieldValue("priorityLevel", e);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option}
                  //{...getTagProps({ index })}
                />
              ))
            }
            value={formik.values.priorityLevel}
            placeholder="Priority Level"
            limitTags={1}
            options={data.priority}
            isOptionEqualToValue={(option, value) => {
              return option === value;
            }}
            getOptionLabel={(e) => e}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  size="small"
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            disabled={formik.values.model.length === 0 ? true : false}
            labelId="sourceurl"
            id="sourceurl"
            name="sourceurl"
            label="Source URL"
            onChange={(_, e) => {
              formik.setFieldValue("sourceurl", e);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.sourceUrl}
                  //{...getTagProps({ index })}
                />
              ))
            }
            value={formik.values.sourceurl}
            placeholder="Source URL"
            limitTags={1}
            options={data.sourceurl}
            isOptionEqualToValue={(option, value) => {
              return option === value;
            }}
            getOptionLabel={(e) => e}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  size="small"
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.sourceUrl}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            // disabled={formik.values.model.length === 0 ? true : false}
            labelId="sourceurl"
            id="sourceurl"
            name="complexityLevel"
            label="Scrapper Complexity"
            onChange={(_, e) => {
              formik.setFieldValue("complexityLevel", e);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option}
                  //{...getTagProps({ index })}
                />
              ))
            }
            value={formik.values.complexityLevel}
            placeholder="Scrapper Complexity"
            limitTags={1}
            options={data1.developercomplexity}
            isOptionEqualToValue={(option, value) => {
              return option === value;
            }}
            getOptionLabel={(e) => e}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  size="small"
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="status"
            id="status"
            label="URL Status"
            name="urlstatus"
            placeholder="URL status"
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("urlstatus", e)}
            value={formik.values.urlstatus}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.urlstatus1}
                  //{...getTagProps({ index })}
                />
              ))
            }
            options={data.urlstatus}
            getOptionLabel={(e) => e}
            isOptionEqualToValue={(option, value) => {
              return option.urlstatus1 === value.urlstatus1;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.urlstatus1}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <EditPicker
            name="receiveddate"
            value={formik.values.receiveddate}
            onChange={(e) => {
              if (e === null) {
                formik.setFieldValue("receiveddate", e);
                // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
              } else {
                formik.setFieldValue("receiveddate", e);
                formik.setFieldValue("receiveddate", e.format("YYYY-MM-DD"));
              }
            }}
            error={
              formik.touched.receiveddate && Boolean(formik.errors.receiveddate)
            }
            helperText={
              formik.touched.receiveddate && formik.errors.receiveddate
            }
            fullWidth
            label="Source Received date"
          />
        </Grid>
        {/* <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="New Source Status"
            id="New Source Status"
            label="New Source Status"
            name="newsourcestatus"
            placeholder="Next Run Status"
            limitTags={4}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option}
                  //{...getTagProps({ index })}
                />
              ))
            }
            onChange={(_, e) => formik.setFieldValue("newsourcestatus", e)}
            value={formik.values.newsourcestatus}
            options={data.newsourcestatus}
            getOptionLabel={(e) => e}
            isOptionEqualToValue={(option, value) => {
              return option === value;
            }}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e}
              </li>
            )}
          />
        </Grid> */}

        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="FILTER"
                startIcon={<FilterListOutlinedIcon />}
                onClick={() => formik.handleSubmit()}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                onClick={() => {
                  reset();
                }}
                message="RESET"
                startIcon={<RestartAltOutlinedIcon />}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="CANCEL"
                startIcon={<EventBusyOutlinedIcon />}
                onClick={() => {
                  JSON.stringify(formik.initialValues) ===
                  JSON.stringify(formik.values)
                    ? props.close()
                    : setShow(true);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <AlertDialog
          open={show}
          onClose={() => setShow(false)}
          // open={isDeleted}
          title={"Confirmation"}
          // maxWidth={"sm"}
          description={`Do you want to discard the changes ?`}
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setShow(false);
                    props.close();
                    reset();
                  }}
                  message="Yes"
                  startIcon={<DoneIcon />}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setShow(false);
                  }}
                  message="No"
                  startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
        {/* Action Area End Here */}
      </Grid>
    </Box>
    // Advance Filter Form End Here
  );
}

export default AdvanceFilters;
