import {
  Box,
  Grid,
  MenuItem,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import BaseLayout from "components/commonComponents/baseLayout";
import Label from "components/commonComponents/label/label";
import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Dropdown from "../../../commonComponents/dropdown/dropdown";
import { logo } from "../../../../assets/index";

// Importing useStyles
import useStyles from "./changePassword.styles";
import Textbox from "components/commonComponents/textbox/textbox";
import MaterialUIButton from "components/commonComponents/button/button";
import LoginIcon from "@mui/icons-material/Login";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { saveFilters } from "redux/app/actions";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import DoneIcon from "@mui/icons-material/Done";

import {
  isValidEmailAddress,
  onlySting,
  passwordMismatch,
  Required,
  validPassword,
} from "utils/validation helper";
import {
  login,
  resetPassword,
  resetknownPassword,
} from "redux/usermanagement/action";
import KeyIcon from "@mui/icons-material/Key";
import Spinner from "components/commonComponents/spinner/spinner";
import CloseIcon from "@mui/icons-material/Close";
import AlertDialog from "components/commonComponents/alertDialog";
import { toast } from "react-toastify";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
function ChangePassword(props) {
  const [open, setOpen] = useState(false);
  const data = useSelector((state) => state.userManagement.usersdataid);
 
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showalert, setShowalert] = useState(false);
  const [popup, setPopup] = useState(false);
  const classes = useStyles();
  /******************************************************************************
  Function: validate
  Argument: values
  Return: errors
  Usage:
  1.To validate the form
  *******************************************************************************/
  const validate = (values) => {
    const errors = {};
    if (!Required(values.email)) {
      errors.email = true;
    } else if (!isValidEmailAddress(values.email)) {
      errors.email = "Invalid email ID";
    }
    if (!Required(values.oldPassword)) {
      errors.oldPassword = true;
    } /* else if (!validPassword(values.oldPassword)) {
      errors.oldPassword = "password min length is 8 and max length is 15 ";
    }  */
    if (!Required(values.newPassword)) {
      errors.newPassword = true;
    } else if (!validPassword(values.newPassword)) {
      errors.newPassword = "Password min length is 8 and max length is 15";
    }
    if (!Required(values.confirmPassword)) {
      errors.confirmPassword = true;
    } else if (!validPassword(values.confirmPassword)) {
      errors.confirmPassword = "Password min length is 8 and max length is 15";
    } else if (!passwordMismatch(values.newPassword, values.confirmPassword)) {
      errors.confirmPassword = "New password does not match with confirm password";
    }

    return errors;
  };
  const email = localStorage.getItem("email");

  const formik = useFormik({
    initialValues: {
      email: email,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validate,
    onSubmit: async (values) => {
     
      setLoad(true);
      setShow(true);
      await dispatch(resetknownPassword(values)).then((res) => {
       
        if (res.data.statusCode === 201) {
          setError(false);
          //setMessage(res.data.result);
          setMessage(res.data.message);
          setLoad(false);
        } else if (res.data.statusCode === 400) {
          setError(true);
          setShow(false);
          setError(res.data.responseException.exceptionMessage);
          setLoad(false);
          if (
            res.data.statusCode === 400 &&
            res.data.responseException.exceptionMessage.errors
          ) {
            setLoad(false);
            setShow(false);
            setError(true);
            setError(
              res.data.responseException.exceptionMessage.errors.NewPassword.map(
                (e) => {
                  return e;
                }
              )
            );
          }
        }
      });
    },
  });

   /******************************************************************************
  Function: reset
  Argument: 
  Return: 
  Usage:
  1.To reset the form
  *******************************************************************************/
  const reset = () => {
    if (formik && formik.values) {
      formik.resetForm();
      setError(false);
    }
  };

  return (
    <Box>
      <Spinner open={load} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100vh" }}
      >
        <Grid item xs={12} sm={12} md={7} lg={6} xl={5}>
          <Box className={classes.loginArea}>
            <BaseLayout>
              {/* Action Area - Search by Keyword, Total Record Count, Add Issue, Adv. Filter and Export */}
              <Grid
                container
                spacing={2}
                className={classes.loginBlock}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs={12} textAlign="center">
                  <img
                    className={classes.logo}
                    src={logo}
                    width="120"
                    alt="JATO Dynamics"
                  />
                </Grid>
                <Grid item xs={12} textAlign="center" sx={{ mt: 1 }}>
                  <Typography variant="h6" gutterBottom>
                    Change Password
                  </Typography>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <div className={classes.errorMessage}>{error}</div>
                </Grid>
                {show && !load ? (
                  <>
                    <Grid item xs={12} textAlign="center">
                      <div>{message}</div>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                      <MaterialUIButton
                        variant="outlined"
                        message="LOGIN"
                        onClick={() => navigate("/", { replace: true })}
                        startIcon={<KeyIcon />}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Textbox
                        required
                        type="password"
                        size="small"
                        label="Old Password"
                        fullWidth
                        name="oldPassword"
                        value={formik.values.oldPassword}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.oldPassword &&
                          Boolean(formik.errors.oldPassword)
                        }
                        helperText={
                          formik.touched.oldPassword &&
                          formik.errors.oldPassword
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Textbox
                        required
                        type="password"
                        size="small"
                        label="New Password"
                        fullWidth
                        name="newPassword"
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.newPassword &&
                          Boolean(formik.errors.newPassword)
                        }
                        helperText={
                          formik.touched.newPassword &&
                          formik.errors.newPassword
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Textbox
                        required
                        type="password"
                        size="small"
                        label="Confirm Password"
                        fullWidth
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.confirmPassword &&
                          Boolean(formik.errors.confirmPassword)
                        }
                        helperText={
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <Grid
                        container
                        spacing={1.75}
                        justifyContent="flex-end"
                        alignItems="flex-end"
                      >
                        <Grid item xs={"auto"}>
                          <MaterialUIButton
                            variant="outlined"
                            message="BACK"
                            onClick={() => navigate(-1)}
                            startIcon={<ArrowBackIcon />}
                          />
                        </Grid>
                        <Grid item xs={"auto"}>
                          <MaterialUIButton
                            variant="outlined"
                            message="CHANGE PASSWORD"
                            onClick={() => formik.handleSubmit()}
                            startIcon={<KeyIcon />}
                          />
                        </Grid>
                        <Grid item xs={"auto"}>
                          <MaterialUIButton
                            variant="outlined"
                            message="CLEAR"
                            onClick={() => reset()}
                            startIcon={<RestartAltIcon />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box className={classes.passwordHint}>
                        <Typography
                          variant="body2"
                          gutterBottom
                          className={classes.passwordHeading}
                        >
                          Password must contain:
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          className={classes.passwordSubHeading}
                        ></Typography>

                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          className={classes.passwordText}
                        >
                          - Password should be a minimum of 8-characters
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          className={classes.passwordText}
                        >
                          - There should be at least one uppercase letter
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          className={classes.passwordText}
                        >
                          - There should be at least one lowercase letter
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          className={classes.passwordText}
                        >
                          - There should be at least one special character
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          className={classes.passwordText}
                        >
                          - There should be at least one numeric
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                )}

                {/* <Grid item xs={12} textAlign="center" sx={{ mt: 1 }}>
                  <Typography variant="caption" gutterBottom>
                    © JATO Dynamics Limited. All rights reserved.
                  </Typography>
                </Grid> */}
              </Grid>
            </BaseLayout>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ChangePassword;
