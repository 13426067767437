// Default React, React Components, Router and Services Components
import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";

// Dummy Data
import { headers, roleData } from "./Data";

// Importing Custom Components
// import AdvanceFilters from './advanceFilters';
import BaseLayout from "components/commonComponents/baseLayout";
import Gridnav from "components/commonComponents/gridnav/gridnav";
import MaterialUIModal from "components/commonComponents/modal/modal";
import MaterialUIButton from "components/commonComponents/button/button";
import AgGrid from "components/commonComponents/grid/agGrid";
import UsePagination from "components/commonComponents/pagination/pagination";

// Importing useStyles
import { styled } from "@mui/material/styles";
import useStyles from "./roleManagement.styles";

// Import Material Icons
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import AddNewRole from "./addNewRole";
import {
  addRoles,
  deleteRoles,
  getMenu,
  getRoles,
  updateRoles,
  viewRoles,
} from "redux/rolemanagement/action";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProject,
  fetchRole,
  fetchRoles,
  fetchRolesid,
  getProject,
  menuName,
} from "redux/app/actions";
import { toast } from "react-toastify";
import AlertDialog from "components/commonComponents/alertDialog";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import EditRole from "./editRole";
import Spinner from "components/commonComponents/spinner/spinner";
import { useLocation } from "react-router-dom";
import { projectDropdown } from "redux/manageissues/action";
function RoleManagement() {
  const dispatch = useDispatch();
  const gridApi = useRef(null);
  const select = useSelector((state) => state.roleManagement);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [advFilterOpen, setAdvFilterOpen] = useState(false);
  const classes = useStyles();
  const [addIssuseOpen, setAddIssuseOpen] = useState(false);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [total, settotal] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  const [totalRecords, settotalRecords] = useState(0);
  const [Deletedata, setDeletedata] = useState({});
  const [showDelete, setshowDelete] = useState(false);
  const [load, setLoad] = useState(true);
  const [saveDisabled, setsaveDisabled] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [disableedit, setdisableedit] = useState(false);
  const roldId = localStorage.getItem("roleId");
  const selector = JSON.parse(localStorage.getItem("roleData"));
  const [add, setAdd] = useState([]);
  const id = useSelector((state) => state.project.role);

  const location = useLocation();
  useEffect(() => {
    getRoleManagement(page, limit, "");
    dispatch(getMenu(id)).then((res) => {
      if (res.data.result.statusCode === 200) {
        dispatch(menuName(res.data.result.result));
      } else {
        dispatch(menuName([]));
      }
    });
    dispatch(projectDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        dispatch(getProject(res.data.result));
      } else {
        dispatch(getProject([]));
      }
    });

    setAdd(
      selector &&
      selector.roleConfigurations.filter((e) =>
        e.menuName === "Manage Issue/Request"
          ? location.pathname === "/manage-issue-/-request" &&
            e.isAdd === true
          : e.menuName.replace("&", "and").toLowerCase() ===
              location.pathname
                .replace("/", "")
                .replace("-", " ")
                .replace("-", " ")
                .replace("/", "#")
                .split("#")[0] && e.isAdd === true
      )
    );
  }, []);

  /******************************************************************************
  Function: getRoleManagement
  Argument: page, limit, search
  Return:
  Usage:
  1.To get data in grid
  *******************************************************************************/

  const getRoleManagement = (page, limit, search) => {
    dispatch(getRoles(page, limit, search, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            settotalRecords(0);
            dispatch(fetchRoles([]));
          } else {
            dispatch(fetchRoles(res.data.result));
          }
          setLoad(false);

          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        }
      }
    });
  };

  /******************************************************************************
  Function: handlePageClick
  Argument: event, data
  Return:
  Usage:
  1.To handle page navigation
  *******************************************************************************/

  const handlePageClick = async (event, data) => {
    setLoad(true);
    setPage(data);
    await getRoleManagement(data, limit, search);
    gridApi.current.redrawRows(select.rolesdata);
  };

  /******************************************************************************
  Function: editBody
  Argument: data
  Return:
  Usage:
  1.To set edit data 
  *******************************************************************************/

  const editBody = (data) => {
    dispatch(fetchRolesid(data));
    setShowEdit(true);
    // dispatch(viewRoles(data.sourceId)).then((res) => {});
  };
  /******************************************************************************
  Function: deleteBody
  Argument: event
  Return:
  Usage:
  1.To set delete data 
  *******************************************************************************/

  const deleteBody = (event) => {
    setDeletedata(event.roleId);
    //setRolename(event.);
  };
  /******************************************************************************
  Function: editRole
  Argument:id, data
  Return:
  Usage:
  1.To edit already exsisting role
  *******************************************************************************/

  const editRole = (id1, data) => {
    setLoad(true);
    dispatch(updateRoles(id1, data, id)).then(async (res) => {
      if (res.data.statusCode === 200) {
        toast.success("Role saved successfully");
        dispatch(viewRoles(Number(roldId))).then((res) => {
          console.log(res);
          localStorage.setItem("roleData", JSON.stringify(res.data.result));
          dispatch(fetchRole(res.data.result));
          if (
            Number(localStorage.getItem("roleId")) === res.data.result.roleId
          ) {
            dispatch(fetchProject(res.data.result.projectDetails));
          }
        });

        await getRoleManagement(page, limit, search);
        setShowEdit(false);
        setdisableedit(false);
        setLoad(false);
      } else if (res.data.statusCode === 400) {
        toast.error(res.data.result);
        setdisableedit(false);
        setLoad(false);
        toast.error(res.data.responseException.exceptionMessage);
        setShowEdit(false);
      } else {
        setShowEdit(false);
        setdisableedit(false);
        setLoad(false);
        toast.error("Role save failed");
      }

    });
  };
  /******************************************************************************
  Function: addNewrole
  Argument:data
  Return:
  Usage:
  1.To add new role
  *******************************************************************************/

  const addNewrole = (data) => {
    setLoad(true);
    dispatch(addRoles(data, id)).then(async (res) => {
      if (res.data.statusCode === 201) {
        setPage(1);
        await getRoleManagement(1, limit, search);
        toast.success("Role saved successfully");
        setAddIssuseOpen(false);
        setsaveDisabled(false);
        setLoad(false);
      } else if (res.data.statusCode === 400) {
        setPage(1);
        setAddIssuseOpen(false);
        setPage(1);
        toast.error(res.data.responseException.exceptionMessage);
        setsaveDisabled(false);
        setLoad(false);
      } else {
        setPage(1);
        setAddIssuseOpen(false);
        setPage(1);
        toast.error("Role save failed");
        setsaveDisabled(false);
        setLoad(false);
      }
    });
  };
  /******************************************************************************
  Function: handleDelete
  Argument:data
  Return:
  Usage:
  1.To delete a role
  *******************************************************************************/

  const handleDelete = (roleId) => {
    setLoad(true);
    dispatch(deleteRoles(roleId, id)).then(async (res) => {
      if (res.data.statusCode === 200) {
        toast.success("Role deleted successfully");
        await getRoleManagement(page, limit, search);
      } else if (res.data.statusCode === 403) {
        toast.error(res.data.responseException.exceptionMessage);
        setLoad(false);
      } else {
        toast.error("Failed to delete");
        setLoad(false);
      }
      await gridApi.current.redrawRows(select.rolesdata);
    });
  };
  /******************************************************************************
  Function: handleGridAction
  Argument:action, selectedRow
  Return:
  Usage:
  1.To handle grid action
  *******************************************************************************/

  const handleGridAction = (action, selectedRow) => {
    switch (action) {
      case "Delete":
        handleDelete(selectedRow);
        break;
      default:
        break;
    }
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    boxShadow: "0px 0px 4px 0px rgb(195 0 5 / 10%)",
    position: "relative",
    borderRadius: "14px",
  }));
  const [gridKey, setGridKey] = useState(0);
  const resetAll = async () => {
    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
    if (search !== "") {
      setPage(1);
      setSearch("");
      await getRoleManagement(1, limit, "");
    }

    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
  };
  return (
    <div>
      {/* Manage Issues Page Start Here */}
      <Box>
        <Spinner open={load} />
        {/* BaseLayout Start Here */}
        <BaseLayout>
          {/* Action Area - Search by Keyword, Total Record Count, Add Issue, Adv. Filter and Export */}
          <Grid
            container
            spacing={2}
            className={classes.pageActionArea}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={12} md={6}>
              <Grid
                container
                alignItems="center"
                justifyContent="left"
                spacing={2}
              >
                <Grid item xs={"auto"}>
                  <Gridnav
                    onChange={async (e) => {
                      setSearch(e.target.value);
                      await getRoleManagement(page, limit, e.target.value);
                      gridApi.current.redrawRows(select.rolesdata);
                    }}
                    data={search}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    className={classes.totalResults}
                  >
                    Total Results:{" "}
                    <span className={classes.totalValue}>
                      {totalRecords ?? 0}
                    </span>
                    &nbsp;
                    <span className={classes.recordText}>Records</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={1.75}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    message="Clear Filters"
                    startIcon={<RestartAltOutlinedIcon />}
                    onClick={() => resetAll()}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    disabled={add.length === 0}
                    onClick={() => setAddIssuseOpen(true)}
                    message="Add Role"
                    startIcon={<AddBoxOutlinedIcon />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Grid Start here */}
          <Grid
            container
            spacing={2}
            className={classes.pageActionArea}
            alignItems="center"
            justifyContent="flex-start"
            sx={{ p: 0 }}
          >
            <Grid item xs={12} className={classes.fullHeight}>
              <AgGrid
                // onGridReady={onGridReadyManageSource}
                data={select.rolesdata}
                //data={roleData}
                header={headers}
                // actionsHandler={handleGridAction}
                //sorting={handleSort}
                showEdit={() => setShowEdit(true)}
                showDelete={() => setshowDelete(true)}
                edit={editBody}
                delete={deleteBody}
                key={gridKey}
              />
              <UsePagination
                pageCount={pageCount}
                count={total}
                t={totalRecords}
                total={totalCount}
                currentPage={page}
                limit={limit}
                value={limit}
                handlePageClick={handlePageClick}
                onChange={(e) => {
                  setLimit(e.target.value);
                  setPage(1);
                  let encodedValue = encodeURIComponent(e.target.value);

                  getRoleManagement(1, encodedValue, search);
                  // gridApi.current.redrawRows(select.rolesdata);
                }}
              />
            </Grid>
          </Grid>
        </BaseLayout>

        {/* Advance Filter Model */}
        {/* <MaterialUIModal
          open={advFilterOpen}
          onClose={() => setAdvFilterOpen(false)}
          anchor="right"
          body={<AdvanceFilters
            // filter={filterIssue} 
            close={() => setAdvFilterOpen(false)} />}
        /> */}

        <MaterialUIModal
          open={addIssuseOpen}
          // onClose={() => setAddIssuseOpen(false)}
          drawerWidth="role"
          anchor="right"
          body={
            <AddNewRole
              save={saveDisabled}
              onChangeSave={() => setsaveDisabled(true)}
              close={() => setAddIssuseOpen(false)}
              addNewrole={addNewrole}
            />
          }
        />
        <MaterialUIModal
          open={showEdit}
          drawerWidth="role"
          // onClose={() => setShowEdit(false)}
          anchor="right"
          body={
            <EditRole
              save={disableedit}
              onChangeSave={() => setdisableedit(true)}
              close={() => setShowEdit(false)}
              EditRolesdata={editRole}
            />
          }
        />
        <AlertDialog
          open={showDelete}
          onClose={() => setshowDelete(false)}
          // open={isDeleted}
          title={"Confirmation"}
          // maxWidth={"sm"}
          description={`Do you want to delete the Role ? `} /* - ${Deletedata} */
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    handleGridAction("Delete", Deletedata);
                    setshowDelete(false);
                  }}
                  message="Delete"
                  startIcon={<DoneIcon />}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setshowDelete(false);
                  }}
                  message="Cancel"
                  startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
      </Box>
    </div>
  );
}

export default RoleManagement;
