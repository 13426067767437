import React from "react";
import './unauthorized.scss'
function Unauthorized() {
  return (
    <h6 className="unauth">
      “You do not have access to this screen”
    </h6>
  );
}

export default Unauthorized;
