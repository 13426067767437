var baseApiUrl = process.env.REACT_APP_MANAGE_ISSUE_BASE_URL;
const baseurl = baseApiUrl + "api";
const basemenu=baseurl+"/v1"
const baseurlforrolemanagement = baseurl + "/v1/roles";
export const BASE_URL_ROLE = baseurlforrolemanagement;
export const ROLE_MANAGEMENT_URL = baseurlforrolemanagement + "/getroles";
export const ADD_ROLE=baseurlforrolemanagement+"/createrole"
export const ROLE_MANAGEMENT_DELETE_URL =
  baseurlforrolemanagement + "/deleterole";
export const ROLE_MANAGEMENT_UPDATE_URL = baseurlforrolemanagement;
export const MENU=basemenu+"/menus"
export const UPDATE_ROLE=baseurlforrolemanagement+"/updaterole"

export const VIEW=baseurlforrolemanagement+"/getrolebyid"