import { AppActionsTypes } from "./actions-types";

const INITIAL_STATE = {
  rolesdata: [],
  rolesdataid: {},
  menu:[]
};
const roleManagemntReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppActionsTypes.FETCH_ROLE_MANAGEMENT:
      return {
        ...state,
        rolesdata: action.payload,
      };
      case AppActionsTypes.MENU:
        return {
          ...state,
          menu: action.payload,
        };
    case AppActionsTypes.FETCH_ROLE_MANAGEMENT_ID:
      return {
        ...state,
        rolesdataid: action.payload,
      };

    default:
      return state;
  }
};

export default roleManagemntReducer;
