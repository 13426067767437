// Importing the MultiFloatFilter component from the specified path
import MultiFloatFilter from "./MultiFloatFilter";
import MultiFloatFilterHoliday from "./MultiFloatFilterHoliday";

// Define an array of header configurations for a table/grid
export const headers = [
  {
    // Header configuration for "Complexity" column
    headerName: "Scrapper Complexity",
    field: "complexityName", // Field to be used for this column
    filter: MultiFloatFilter, // Specify the filter component to use for this column
    filterParams: "complexityName", // Filter parameters for this column
    lockPosition: "left", // Lock the position of this column to the left
    sortable: true, // Enable sorting for this column
    // headerCheckboxSelection: true, // Display a checkbox in the header for selection
    // checkboxSelection: true, // Enable row selection with checkboxes
    
    minWidth: 120, // Minimum width of the column
  },
  {
    // Header configuration for "Priority" column
    headerName: "Business Priority",
    field: "priorityName",
    filter: MultiFloatFilter,
    filterParams: "priorityName",
    lockPosition: "left",
    sortable: true,
    minWidth: 120,
  },
  {
    // Header configuration for "Days" column
    headerName: "Days",
    field: "days",
    filter: MultiFloatFilter,
    filterParams: "days",
    lockPosition: "left",
    sortable: true,
    minWidth: 120,
  },
  {
    // Header configuration for "Action" column
    headerName: "Action",
    lockPosition: "right", // Lock the position of this column to the right
    field: "", // No specific field associated with this column
    cellRenderer: "iconCellRenderer", // Specify the cell renderer for this column
    cellRendererParams: {
      actionType: "sla", // Parameters for the cell renderer
    },
    minWidth: 130, // Minimum width of the column
    maxWidth: 130, // Maximum width of the column
    cellClass: ["ag-cell-align-center", "locked-col"], // Additional CSS classes for cells in this column
    width: 100, // Width of the column
    headerComponentParams: {
      // Parameters for the header component
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        "  </div>" +
        "</div>",
    },
  },
];

// Define an array of header configurations for a table/grid
export const headers2 = [
  {
    // Header configuration for "Complexity" column
    headerName: "Date",
    field: "date", // Field to be used for this column
    filter: MultiFloatFilterHoliday, // Specify the filter component to use for this column
    filterParams: "date", // Filter parameters for this column
    lockPosition: "left", // Lock the position of this column to the left
    sortable: true, // Enable sorting for this column
    // headerCheckboxSelection: true, // Display a checkbox in the header for selection
    // checkboxSelection: true, // Enable row selection with checkboxes
   
    minWidth: 120, // Minimum width of the column
  },
  {
    // Header configuration for "Priority" column
    headerName: "Name",
    field: "name",
    filter: MultiFloatFilterHoliday,
    filterParams: "name",
    lockPosition: "left",
    sortable: true,
    
    minWidth: 120,
  },
 
  {
    // Header configuration for "Action" column
    headerName: "Action",
    lockPosition: "right", // Lock the position of this column to the right
    field: "", // No specific field associated with this column
    cellRenderer: "iconCellRenderer", // Specify the cell renderer for this column
    cellRendererParams: {
      actionType: "holiday", // Parameters for the cell renderer
    },
    minWidth: 130, // Minimum width of the column
    maxWidth: 130, // Maximum width of the column
    cellClass: ["ag-cell-align-center", "locked-col"], // Additional CSS classes for cells in this column
    width: 100, // Width of the column
    headerComponentParams: {
      // Parameters for the header component
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        "  </div>" +
        "</div>",
    },
  },
];