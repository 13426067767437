import React from "react";
import { styled } from "@mui/material/styles";
import {
  Checkbox,
  FormControlLabel,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  CheckCircleRounded as CheckedIcon,
  RadioButtonUnchecked as UncheckedIcon,
} from "@mui/icons-material";

function MaterialUICheckbox(props) {
  return (
    <FormControlLabel
      disabled={props.disbaled}
      label={props.label}
      control={
        <Checkbox
          checked={props.checked}
          color={props.color}
          size={props.size}
          onChange={props.onChange}
        />
      }
    />
  );
}

const JDListItemButton = styled(ListItemButton)(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  "&.Mui-selected": {
    borderColor: theme.palette.primary.main,
  },
  "& > .MuiListItemIcon-root": {
    minWidth: theme.spacing(5),
    "& > .MuiCheckbox-root": {
      marginLeft: `-${theme.spacing(1)}`,
    },
  },
  "& .MuiListItemText-root > .MuiListItemText-primary": {
    fontSize: 13,
    fontWeight: 500,
  },
}));

function ListButtonCheckbox(props) {
  const { checked, id, label, isListItem = false, onChange, ...rest } = props;
  const [isChecked, setIsChecked] = React.useState(checked);

  const handleToggle = (e) => {
    onChange(!isChecked);
    setIsChecked(!isChecked);
  };

  const LIButtonCheckbox = () => {
    return (
      <JDListItemButton
        role={undefined}
        selected={checked}
        onClick={handleToggle}
      >
        <ListItemIcon>
          <Checkbox
            icon={<UncheckedIcon />}
            checkedIcon={<CheckedIcon />}
            edge="start"
            tabIndex={-1}
            inputProps={{ "aria-labelledby": `${id}-label` }}
            disableRipple
            checked={isChecked}
            {...rest}
          />
        </ListItemIcon>
        <ListItemText id={`${id}-label`} primary={label} />
      </JDListItemButton>
    );
  };
  const LIButtonWrapper = (props) => {
    if (props.shouldWrap) {
      return <ListItem sx={{ px: 0, py: 0.5 }}>{props.children}</ListItem>;
    } else {
      return <>{props.children}</>;
    }
  };
  return (
    <LIButtonWrapper shouldWrap={isListItem}>
      <LIButtonCheckbox />
    </LIButtonWrapper>
  );
}

export default MaterialUICheckbox;
export { MaterialUICheckbox, ListButtonCheckbox };
