import MaterialUIButton from "components/commonComponents/button/button";
import Datepicker from "components/commonComponents/datepicker/datepicker";
import Dropdown from "components/commonComponents/dropdown/dropdown";
import Textbox from "components/commonComponents/textbox/textbox";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useState } from "react";
import { formatBytes } from "utils/helper";
import { MAX_COUNT } from "constants/constants";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import useStyles from "./userManagement.styles";
import Autocompletedropdown from "components/commonComponents/autocomplete/autocompletedropdown";
import {
  isValidEmailAddress,
  onlySting,
  Required,
} from "utils/validation helper";
import { useDispatch, usedataor, useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";

import { rerunDropdown, rolesDropdown } from "redux/manageissues/action";
import { fetchRoles, fetchRolesDropdown, getRerun } from "redux/app/actions";

import { useLocation } from "react-router-dom";
import AlertDialog from "components/commonComponents/alertDialog";
import { newsourceStatus } from "redux/managesources/action";
import { getNewSourceStatus } from "redux/app/actions";
import { getRoles, viewRoles } from "redux/rolemanagement/action";
function EditUser(props) {

  const classes = useStyles();
  const selectdrop = useSelector((state) => state.userManagement);
 
  const select = useSelector((state) => state.userManagement);
  const id=useSelector((state) => state.project.user)
  const data = useSelector((state) => state.userManagement.usersdataid);
  const dispatch = useDispatch();
  const location = useLocation();

  const role = location.state.split("-")[1];
  useEffect(() => {
    dispatch(rolesDropdown(id)).then((res) => {
      if (res) {
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            dispatch(fetchRolesDropdown([]));
          } else {
            dispatch(fetchRolesDropdown(res.data.result));
          }
        }
      }
    });
  }, []);

  const [showalert, setShowalert] = useState(false);
  /******************************************************************************
  Function: validate
  Argument: values
  Return: errors
  Usage:
  1.To validate the form
  *******************************************************************************/

  const validate = (values) => {
    const errors = {};

    if (!Required(values.lastName)) {
      errors.lastName = true;
    } else if (!onlySting(values.lastName)) {
      errors.lastName = "Alphabets and underscore are allowed";
      // toast.error("only alphabets and under score  are allowed")
    } else if (values.lastName.length > 15) {
      errors.lastName = "Exceeds 15 characters";
    }
    if (!Required(values.firstName)) {
      errors.firstName = true;
    } else if (!onlySting(values.firstName)) {
      errors.firstName = "Alphabets and underscore are allowed";
      // toast.error("only alphabets and under score  are allowed")
    } else if (values.firstName.length > 15) {
      errors.firstName = "Exceeds 15 characters";
    }

    if (!Required(values.roleId)) {
      errors.roleId = true;
    }
    if (!Required(values.email)) {
      errors.email = true;
    } else if (!isValidEmailAddress(values.email)) {
      errors.email = "Invalid email ID";
      // toast.error("Invalid email address")
    } else if (values.email.length > 50) {
      errors.email = "Exceeds 50 characters";
    }

    return errors;
  };
  const Role = data.roleName && {
    id: data.roleId,
    label: data.roleName,
  };
  const name = localStorage.getItem("firstName");
  const formik = useFormik({
    initialValues: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      roleId: Role,
      createdBy: data.createdBy,
    },
    enableReinitialize: true,
    validate,
    onSubmit: (values) => {
      props.onChangeSave(true);
      props.EditUsersdata(
        {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          roleId: values.roleId.id,
          createdBy: name,
        },
        data.userId,
        values.roleId.id
      );
      /*  dispatch(viewRoles(Number(values.roleId.id))).then((res) => {

        localStorage.setItem("roleData", JSON.stringify(res.data.result));

        dispatch(fetchRoles(res.data.result));
      }); */
    },
  });
  /******************************************************************************
  Function: cancel
  Argument: 
  Return: 
  Usage:
  1.To close the model
  *******************************************************************************/

  const cancel = () => {
    props.close();
  };
  return (
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                Edit User
              </Typography>
            </Grid>
            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                        JSON.stringify(formik.values)
                          ? cancel()
                          : setShowalert(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <AlertDialog
                  open={showalert}
                  onClose={() => setShowalert(false)}
                  // open={isDeleted}
                  title={"Confirmation"}
                  // maxWidth={"sm"}
                  description={`Do you want to discard the changes ?`}
                  action={
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="right"
                      spacing={2}
                      className={classes.alertDialogbtn}
                    >
                      <Grid item xs={"auto"}>
                        <MaterialUIButton
                          variant="outlined"
                          onClick={props.close}
                          message="Yes"
                          startIcon={<DoneIcon />}
                        />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <MaterialUIButton
                          variant="outlined"
                          onClick={() => {
                            setShowalert(false);
                          }}
                          message="No"
                          startIcon={<CloseIcon />}
                        />
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}
        <Grid item xs={12}>
          <Textbox
            required
            size="small"
            label="First Name"
            fullWidth
            name="firstName"
            value={formik.values.firstName}
            onChange={(data) => {
              formik.setFieldValue("firstName", data.target.value);
            }}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={12}>
          <Textbox
            required
            size="small"
            label="Last Name"
            fullWidth
            name="lastName"
            value={formik.values.lastName}
            onChange={(data) => {
              formik.setFieldValue("lastName", data.target.value);
            }}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Textbox
            required
            size="small"
            label="Email ID"
            fullWidth
            name="email"
            value={formik.values.email}
            onChange={(data) => {
              formik.setFieldValue("email", data.target.value);
            }}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocompletedropdown
            required
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            id="Role"
            name="roleId"
            label="Role"
            placeholder="roleId"
            value={formik.values.roleId}
            limitTags={4}
            onChange={(_, e) => formik.setFieldValue("roleId", e)}
            getOptionLabel={(e) => e.roleName}
            error={formik.touched.roleId && Boolean(formik.errors.roleId)}
            helperText={formik.touched.roleId && formik.errors.roleId}
            options={selectdrop.roles.map((e) => {
              return {
                label: e.roleName,
                id: e.roleId,
              };
            })}
          />
        </Grid>

        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="SAVE"
                disabled={props.save}
                startIcon={<SaveOutlinedIcon />}
                onClick={() => formik.handleSubmit()}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="CANCEL"
                startIcon={<EventBusyOutlinedIcon />}
                onClick={() => {
                  JSON.stringify(formik.initialValues) ===
                  JSON.stringify(formik.values)
                    ? cancel()
                    : setShowalert(true);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Action Area End Here */}
      </Grid>
    </Box>
  );
}

export default EditUser;
