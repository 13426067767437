var baseApiUrl = process.env.REACT_APP_MANAGE_ISSUE_BASE_URL;
const baseurl = baseApiUrl + "api";
const baseurlformanagescraper = baseurl + "/v1/managescraper";
export const BASE_URL_SCRAPER = baseurlformanagescraper;
export const MANAGE_SCRAPER_COUNT_URL =
  baseurlformanagescraper + "/getallscraperwithstatus";
export const MANAGE_SCRAPER_URL = baseurlformanagescraper + "/getallscraper";
export const MANAGE_SCRAPER_EXPORT_URL =
  baseurlformanagescraper + "/getallscraperexport";
export const SOURCE_URL_DROPDOWN =
  baseurlformanagescraper + "/getalloemsorceurl";
export const STATUS_DROPDOWN = baseurlformanagescraper + "/getallstatus";
export const NEXT_RUN_STATUS_DROPDOWN =
  baseurlformanagescraper + "/getallnextrunstatus";
