import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import useStyles from "./autocompletedropdown.styles";
import { Chip } from "@mui/material";

export default function MultipleAutocomplete(props) {
  const classes = useStyles();
  return (
    <Autocomplete
      size="small"
      // disableClearable={true}
      className={props.readOnly ? classes.textFieldReadOnly : classes.textField}
      multiple
      readOnly={props.readOnly}
      disableCloseOnSelect
      limitTags={props.limitTags}
      id={props.id}
      disabled={props.disabled}
      onChange={props.onChange}
      value={props.value}
      options={props.options}
      defaultValue={[]}
      getOptionSelected={props.getOptionSelected}
      isOptionEqualToValue={props.isOptionEqualToValue}
      filterOptions={props.filterOptions}
      getOptionLabel={props.getOptionLabel}
      renderOption={props.renderOption}
      renderTags={props.renderTags}
      // style={{ width: 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={props.error}
          required={props.required}
          value={props.value}
          label={props.label}
          placeholder={props.placeholder}
        />
      )}
    />
  );
}
