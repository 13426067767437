import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textField: {

    padding: "0 !important",
    "& .MuiInputBase-root": {
      
      // height: 32,
      fontSize: 12,
      fontFamily: "'Inter', sans-serif",
    },
    "& .MuiFormLabel-root": {
      fontSize: "13px !important",
      marginTop: "-2px !important",
    },
  },
  iconSpace: {
    "& .MuiSelect-root:hover .MuiIconButton-root": {
      
        visibility: "visible",
     
      
    },
  },
  icons: {
    "&:hover": {
      visibility: "visible",
    },
  },
  clearIndicatorDirty: {},
  clearIndicator: {
    visibility: "hidden",
  },
}));

export default useStyles;
