import { IconButton, Link, Tooltip } from "@mui/material";
import { MANAGE_DOWNLOAD } from "apiConfig/managequality";
import axios from "axios";
import Spinner from "components/commonComponents/spinner/spinner";
import fileDownload from "js-file-download";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAttachments,
  fetchDevAttachments,
  fetchManageissueid,
} from "redux/app/actions";
import { viewAticketData } from "redux/manageissues/action";
import { downloadquality } from "redux/managequality/action";

export default function ActionCellRender(props) {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.project.quality);
  // const [isLoading, setIsLoading] = useState(false);
  const idm=useSelector((state) => state.project.manageissue)

console.log(props.data.inputFile);

  // const dounloadInputFile = async (url,name) => {
  //   dispatch(downloadquality(url,name,id)).then((res)=>{
  //     console.log(res);
  //   })
  //   // var UrlName = MANAGE_DOWNLOAD + `?projectId=${id}&Filepath=${url}`;
  //   // window.open(UrlName);
  //   // fileDownload(UrlName, name);
  // };
  const truncate = (str) => {
    return str ? (str.length > 20 ? str.substring(0, 20) + "..." : str) : "";
  };
  switch (props.actionType) {
    case "formatInputFile":
      return (
        <>
             

          {props.data.inputFile ? (
            <Tooltip
              title={props.data.inputFile}
              style={{ cursor: "pointer" }}
              placement="top"
              arrow
            >
              <Link
                onClick={() => {
                 console.log(props)
                 props.context.componentParent.handleDownload(
                    encodeURI(props.data.inputFilePath),
                    props.data.inputFile
                  );
                }}
              >
                {truncate(props.data.inputFile)}
              </Link>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>
          )}
        </>
      );
    case "formatOutputFile":
      return (
        <>
          {props.data.outputFile ? (
            <Tooltip
              title={props.data.outputFile}
              style={{ cursor: "pointer" }}
              placement="top"
              arrow
            >
              <Link
                onClick={() => {
                  props.context.componentParent.handleDownload(
                    props.data.reportFilePath,
                    props.data.outputFile
                  );
                }}
              >
                {truncate(props.data.outputFile)}
              </Link>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>
          )}
        </>
      );
    case "manageissue":
      return (
        <IconButton
          sx={{ color: "#7947F6" }}
          onClick={() => {
            dispatch(viewAticketData(props.data.ticketId,idm)).then(async (res) => {
              if (res.status === 200) {
                await dispatch(fetchManageissueid(res.data));
                await dispatch(
                  fetchAttachments(
                    res.data.attachments ? res.data.attachments : ""
                  )
                );
                await dispatch(
                  fetchDevAttachments(
                    res.data.devAttachments ? res.data.devAttachments : ""
                  )
                );
                props.context.componentParent.handleView(props.data);
                props.context.componentParent.setView();
              }
            });
          }}
        >
          {props.data.ticketId}
        </IconButton>
      );
    case "rule":
      return (
        <span
          class="d-inline-block"
          tabindex="0"
          data-toggle="tooltip"
          title={props.data.presentation_WHC_Tag_Rule}
        >
          <div className="text-truncate">
            {props.data.presentation_WHC_Tag_Rule}
          </div>
        </span>
      );
    case "position":
      return (
        <span
          class="d-inline-block"
          tabindex="0"
          data-toggle="tooltip"
          title={props.data.formulary_Position}
        >
          <div className="text-truncate">{props.data.formulary_Position}</div>
        </span>
      );
    case "nicetag":
      return (
        <span
          class="d-inline-block"
          tabindex="0"
          data-toggle="tooltip"
          title={props.data.nice_Tag_Links}
        >
          <div className="text-truncate">{props.data.nice_Tag_Links}</div>
        </span>
      );
    case "key":
      return (
        <span
          class="d-inline-block"
          tabindex="0"
          data-toggle="tooltip"
          title={props.data.composition_Key}
        >
          <div className="text-truncate">{props.data.composition_Key}</div>
        </span>
      );

    case "url":
      return (
        <span
          class="d-inline-block"
          tabindex="0"
          data-toggle="tooltip"
          title={props.data.source_Url}
        >
          <div className="text-truncate">{props.data.source_Url}</div>
        </span>
      );
    case "source":
      return (
        <span
          class="d-inline-block"
          tabindex="0"
          data-toggle="tooltip"
          title={props.data.source_Of_Data}
        >
          <div className="text-truncate">{props.data.source_Of_Data}</div>
        </span>
      );

    default:
      break;
  }
  return (
    <></>
    // <span
    //   class="d-inline-block"
    //   tabindex="0"
    //   data-toggle="tooltip"
    //   title={props.data.source_Url}
    // >

    //   <div className="text-truncate">{props.data.source_Url}</div>
    //   <div className="text-truncate">{props.data.source_Of_Data}</div>
    //   <div className="text-truncate">{props.data.composition_Key}</div>
    // </span>
  );
}
