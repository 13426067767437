import { AppActionsTypes } from "./actions-types";

const INITIAL_STATE = {
  reportsdata: [],
  filter: {
    timeline: "Week",
    quater: "",
    startdate: null,
    enddate: null,
    timeLineStart: null,
    timeLineEnd: null,
    country: [],
    make: [],
    status: [],
    exportId: [],
  },
  data: {},
  bauStatus: [],
};
const reportsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppActionsTypes.BAU_STATUS:
      return {
        ...state,
        bauStatus: action.payload,
      };
    case AppActionsTypes.BAU_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case AppActionsTypes.DATA_BAU:
      return {
        ...state,
        data: action.payload,
      };
    case AppActionsTypes.GRID_DATA_BAU:
      return {
        ...state,
        reportsdata: action.payload,
      };
    default:
      return state;
  }
};

export default reportsReducer;
