import { Stack, TextField } from "@mui/material";
import {
  DatePicker,
  DateTimePicker,
  DesktopDatePicker,
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import useStyles from "./datepicker.styles";

function EditPicker(props) {

  const classes = useStyles();
 
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Stack spacing={3}>
      
        

        
      <DesktopDatePicker
          minDate={props.min&&dayjs(props.min)}
          maxDate={props.max&&dayjs(props.max)}
          inputFormat="DD/MM/YYYY"
         
        className={classes.textField}
        label={props.label}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
        renderInput={(params) => (
          <TextField
            sx={
              props.error && {
                "& label.Mui-focused": {
                  color: "#d32f2f",
                },
                "& label": {
                  color: "#d32f2f",
                },
                "& .MuiFormHelperText-root": {
                  color: "#d32f2f",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#d32f2f",
                },

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#d32f2f",
                  },

                  "&:hover fieldset": {
                    borderColor: "#d32f2f",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#d32f2f",
                  },
                },
              }
            }
            required={props.required}
            // sx={{  minWidth: props.width }}
           error={props.error}
            // helperText={props.helperText}
            fullWidth={props.fullWidth}
            size="small"
            {...params}
          />
        )}
      />
    </Stack>
  </LocalizationProvider>
  );
}

export default EditPicker;
