import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useLocation } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import fileDownload from "js-file-download";
import axios from "axios";
import { Tooltip } from "@mui/material";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { ErrorResponseMessage } from "constants/constants";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
} from "@mui/material";
import Confirmationmodel from "components/commonComponents/modal/confirmationmodel";
import moment from "moment";
// Importing useStyles
import useStyles from "./cellRender.styles";
import { result } from "components/pages/main/managescraper/Data";
import MaterialUITooltip from "components/commonComponents/tooltip/tooltip";
import { MANAGE_DOWNLOAD } from "apiConfig/managequality";
import { PropaneSharp } from "@mui/icons-material";

function ActiveCellRenderer(props) {
  // console.log(moment().format(), props.data.resolutionDate);

  const classes = useStyles();
  const location = useLocation();
  const priority = props.data.complexity_Level
    ? props.data.complexity_Level
    : "";
  const priority_source = props.data.priority_Level
    ? props.data.priority_Level
    : "";
  const priorityscource = props.data.issuePriority
    ? props.data.issuePriority
    : "";
  const qcstatus = props.data.status ? props.data.status : "";
  /*   const [open, setOpen] = React.useState(false);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const handleClose = () => {
    setOpen(false);
  }; */
  const openFileInNewTab = async (url) => {
    var UrlName =
      process.env.REACT_APP_MANAGE_ISSUE_BASE_URL +
      `/api/v1/manageissues/getfile?FileName=${url}`;
    window.open(UrlName);
    fileDownload(UrlName, url);
  };

  const devopenFileInNewTab = async (url) => {
    var UrlName =
      process.env.REACT_APP_MANAGE_ISSUE_BASE_URL +
      `/api/v1/manageissues/getdevissuefile?FileName=${url}`;
    window.open(UrlName);
    fileDownload(UrlName, url);
  };
  const [open, setOpen] = React.useState(false);
  const format1 = "DD-MM-YYYY";
  const format2 = "DD-MM-YYYY - HH:mm:ss";
  const format3 = "DD-MM-YYYY HH:mm:ss";

  const formatdate = "DD/MM/YYYY ";
  const truncate = (str) => {
    return str ? (str.length > 20 ? str.substring(0, 20) + "..." : str) : "";
  };

  const truncatefield = (str) => {
    return str ? (str.length > 25 ? str.substring(0, 25) + "..." : str) : "";
  };
  const truncateDescription = (str) => {
    return str ? (str.length > 50 ? str.substring(0, 50) + "..." : str) : "";
  };
  const truncatemodel = (str) => {
    return str ? (str.length > 10 ? str.substring(0, 10) + "..." : str) : "";

    // const s = str[0] + "," + str[1];
    // return str
    //   ? str.length > 1
    //     ? s.length <= 14
    //       ? str[0] + "," + str[1] + "..."
    //       : s.substring(0, 10) + "..."
    //     : str
    //   : "";
  };
  console.log(props.data?.startDate);
  const projectname = (str) => {
    console.log();
    return str?.length > 0
      ? str.toString().length > 0
        ? str.toString().substring(0, 15) + "..."
        : str.toString()
      : "";
  };
  const truncatescopemodel = (str) => {
    return str ? (str.length > 15 ? str.substring(0, 15) + "..." : str) : "";
  };
  const truncatesource = (str) => {
    return str ? (str.length > 30 ? str.substring(0, 30) + "..." : str) : "";
  };

  const getTat = (data, data2) => {
    const format3 = "DD-MM-YYYY HH:mm:ss"; // For display
    
    const currentDate = moment().startOf('day'); // Current date truncated to the start of the day

    const getColorForCondition = (condition) => {
        switch (condition) {
            case "equal":
                return "orange";
            case "greater":
                return "green";
            case "less":
                return "red";
            default:
                return "black";
        }
    };

    if (data2) {
        const dataDate = moment(data).startOf('day');
        const data2Date = moment(data2).startOf('day');

        const condition = data2Date.isAfter(dataDate) ? "less" 
                       : data2Date.isBefore(dataDate) ? "greater" 
                       : "equal";

        const color = getColorForCondition(condition);
        return <div style={{ color }}>{moment(data).format(format3)}</div>;
    } else {
        const dataDate = moment(data).startOf('day');

        const condition = currentDate.isBefore(dataDate) ? "greater" 
                       : currentDate.isAfter(dataDate) ? "less" 
                       : "equal";

        const color = getColorForCondition(condition);
        return <div style={{ color }}>{moment(data).format(format3)}</div>;
    }
};
  switch (props.actionType) {
    case "priority":
      return (
        <>
          {priority.toLowerCase() === "critical" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.criticalDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priority}
              </Grid>
            </Grid>
          ) : priority.toLowerCase() === "high" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.highDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priority}
              </Grid>
            </Grid>
          ) : priority.toLowerCase() === "medium" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.mediumDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priority}
              </Grid>
            </Grid>
          ) : priority.toLowerCase() === "low" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.lowDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priority}
              </Grid>
            </Grid>
          ) : priority.toLowerCase() === "extreme" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.extremeDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priority}
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </>
      );

    case "priority_Level":
      return (
        <>
          {priority_source.toLowerCase() === "critical" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.criticalDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priority_source}
              </Grid>
            </Grid>
          ) : priority_source.toLowerCase() === "high" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.highDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priority_source}
              </Grid>
            </Grid>
          ) : priority_source.toLowerCase() === "medium" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.mediumDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priority_source}
              </Grid>
            </Grid>
          ) : priority_source.toLowerCase() === "low" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.lowDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priority_source}
              </Grid>
            </Grid>
          ) : priority_source.toLowerCase() === "extreme" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.extremeDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priority_source}
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </>
      );

    case "priorityissue":
      return (
        <>
          {priorityscource.toLowerCase() === "critical" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.criticalDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priorityscource}
              </Grid>
            </Grid>
          ) : priorityscource.toLowerCase() === "high" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.highDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priorityscource}
              </Grid>
            </Grid>
          ) : priorityscource.toLowerCase() === "medium" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.mediumDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priorityscource}
              </Grid>
            </Grid>
          ) : priorityscource.toLowerCase() === "low" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.lowDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priorityscource}
              </Grid>
            </Grid>
          ) : priorityscource.toLowerCase() === "extreme" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.extremeDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priorityscource}
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </>
      );
    case "priorityscope":
      return (
        <>
          {priorityscource.toLowerCase() === "critical" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.criticalDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priorityscource}
              </Grid>
            </Grid>
          ) : priorityscource.toLowerCase() === "high" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.highDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priorityscource}
              </Grid>
            </Grid>
          ) : priorityscource.toLowerCase() === "medium" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.mediumDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priorityscource}
              </Grid>
            </Grid>
          ) : priorityscource.toLowerCase() === "low" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.lowDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priorityscource}
              </Grid>
            </Grid>
          ) : priorityscource.toLowerCase() === "extreme" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.extremeDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {priorityscource}
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </>
      );

    case "formatprojectname":
      return (
        <>
          {props.data.projectDetails?.length > 0 ? (
            <Tooltip
              title={props.data.projectDetails
                .map((e) => e.projectName)
                .join(",")}
              placement="top"
              arrow
            >
              <span>
                {projectname(
                  props.data.projectDetails.map((e) => e.projectName)
                )}
              </span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "data":
      return (
        <>
          {props.data.dataAvailability ? (
            props.data.dataAvailability.toLowerCase() === "yes" ? (
              <>YES</>
            ) : props.data.dataAvailability.toLowerCase() === "no" ? (
              <>NO</>
            ) : (
              <>-</>
            )
          ) : (
            <>-</>
          )}
        </>
      );
    case "format":
      return <>{moment(props.data.dateRaised).format(format3)}</>;
    case "formatfile":
      return (
        <>
          {props.data.dateofFile
            ? moment(props.data.dateofFile).format(format1)
            : ""}
        </>
      );
    case "formatStartDate":
      return (
        <>
          {props.data.startDate ? (
            moment(props.data.startDate).format(format2)
          ) : (
            <></>
          )}
        </>
      );
    case "formatEndOfFile":
      return (
        <>
          {props.data.dateOfFile ? (
            moment(props.data.dateOfFile).format(format1)
          ) : (
            <></>
          )}
        </>
      );
    case "formatEndDate":
      return (
        <>
          {props.data.endDate ? (
            moment(props.data.endDate).format(format2)
          ) : (
            <></>
          )}
        </>
      );
    case "formatDaten":
      return <>{moment(props.data.date).format(format1)}</>;
    case "formatDate":
      return (
        <>
          {props.data.date.split("-").length > 1
            ? moment(props.data.date.split("-")[0]).format(formatdate) +
              "-" +
              moment(props.data.date.split("-")[1]).format(formatdate)
            : moment(props.data.date).format(formatdate)}
        </>
      );
    case "formatassign":
      return (
        <>
          {props.data.assignedDate
            ? moment(props.data.assignedDate).format(format3)
            : ""}
        </>
      );
    case "formatplanned":
      return (
        <>
          {props.data.plannedEndDate
            ? moment(props.data.plannedEndDate).format(format3)
            : ""}
        </>
      );
    case "formattat":
      return (
        <>
          {props.data.firstResponseTAT
            ? getTat(props.data.firstResponseTAT, props.data?.plannedEndDate)
            : ""}
        </>
      );
    case "formatresolutionstatus":
      return (
        <>
          {props.data.resolutionStatus ? (
            props.data.resolutionDate < moment().format() &&
            props.data.resolutionStatus.toLowerCase() === "wip" ? (
              <span style={{ color: "red" }}>
                {props.data.resolutionStatus}
              </span>
            ) : (
              <> {props.data.resolutionStatus}</>
            )
          ) : (
            ""
          )}
        </>
      );
    case "qcStatus":
      return (
        <>
          {qcstatus.toLowerCase() === "pass" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.passDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {qcstatus}
              </Grid>
            </Grid>
          ) : qcstatus.toLowerCase() === "fail" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div className={classes.failDot}></div>
              </Grid>
              <Grid item xs={"auto"}>
                {qcstatus}
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </>
      );

    case "formatresolution":
      return (
        <>
          {props.data?.resolutionDate ? (
            props.data?.resolutionDate < moment().format() &&
            props.data?.resolutionStatus?.toLowerCase() === "wip" ? (
              <span style={{ color: "red" }}>
                {moment(props.data.resolutionDate).format(format3)}
              </span>
            ) : (
              <> {moment(props.data.resolutionDate).format(format3)}</>
            )
          ) : (
            ""
          )}
        </>
      );
    case "formatclosedfate":
      return (
        <>
          {props.data.actualClosedDate
            ? moment(props.data.actualClosedDate).format(format3)
            : ""}
        </>
      );
    case "formatlastrun":
      return <>{moment(props.data.lastRuntime).format(format1)}</>;
    case "formatnextrun":
      return <>{moment(props.data.nextRuntime).format(format1)}</>;

    case "baudate":
      return (
        <>{props.data.date ? moment(props.data.date).format(format1) : <></>}</>
      );
    case "formatreceiveddate":
      return <>{moment(props.data.receivedDate).format(format1)}</>;
    case "formatissuefield":
      return (
        <>
          {props.data.issueField ? (
            <Tooltip title={props.data.issueField} placement="top" arrow>
              <span>{truncatefield(props.data.issueField)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    // case "formatissuefield":
    //   return (
    //     <>
    //       {props.data.field ? (
    //         <Tooltip title={props.data.field.issueField} placement="top" arrow>
    //           <span>{truncatefield(props.data.field.issueField)}</span>
    //         </Tooltip>
    //       ) : (
    //         <span>&nbsp;&nbsp;&nbsp;-</span>
    //       )}
    //     </>
    //   );
    case "formatmodel":
      return (
        <>
          {props.data.model ? (
            <Tooltip
              title={props.data.model}
              placement="top"
              arrow
              classes={{ tooltip: "custom-tooltip" }}
            >
              <span>{truncatemodel(props.data.model)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "formatmake":
      return (
        <>
          {props.data.make ? (
            <Tooltip
              title={props.data.make}
              placement="top"
              arrow
              classes={{ tooltip: "custom-tooltip" }}
            >
              <span>{truncatemodel(props.data.make)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "reason":
      return (
        <>
          {" "}
          {props.data.failureReason ? (
            <Tooltip title={props.data.failureReason} placement="top" arrow>
              <span>{truncatescopemodel(props.data.failureReason)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );

    case "sourceurl":
      return (
        <>
          {" "}
          {props.data.oemUrl ? (
            <Tooltip title={props.data.oemUrl} placement="top" arrow>
              <span>{truncatesource(props.data.oemUrl)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "managesourceurl":
      return (
        <>
          {" "}
          {props.data.sourceURL ? (
            <Tooltip title={props.data.sourceURL} placement="top" arrow>
              <span>{truncatesource(props.data.sourceURL)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "managepreviousSourceUrl":
      return (
        <>
          {" "}
          {props.data.previousSourceURL ? (
            <Tooltip title={props.data.previousSourceURL} placement="top" arrow>
              <span>{truncatesource(props.data.previousSourceURL)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );

    case "formatsourcecomments":
      return (
        <>
          {" "}
          {props.data.comments ? (
            <Tooltip title={props.data.comments} placement="top" arrow>
              <span>{truncate(props.data.comments)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "bau_com":
      return (
        <>
          {" "}
          {props.data.status ? (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={0.75}
            >
              <Grid item xs={"auto"}>
                <div
                  className={
                    props.data.status.toLowerCase() ===
                    "No change in Delta Differencing".toLowerCase()
                      ? classes.blueDot
                      : props.data.status
                          .toLowerCase()
                          .includes("delivered".toLowerCase())
                      ? classes.greenDot
                      : props.data.status.toLowerCase() ===
                        "Issue Fixing - Rerun Required".toLowerCase()
                      ? classes.yellowDot
                      : props.data.status.toLowerCase() === "WIP".toLowerCase()
                      ? classes.whiteDot
                      : classes.greyDot
                  }
                ></div>
              </Grid>
              <Grid item xs={"auto"}>
                <Tooltip title={props.data.status} placement="top" arrow>
                  <span>{truncate(props.data.status)}</span>
                </Tooltip>
              </Grid>
            </Grid>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );

    case "formatfeedback":
      return (
        <>
          {" "}
          {props.data.feedback ? (
            <Tooltip title={props.data.feedback} placement="top" arrow>
              <span>{truncate(props.data.feedback)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "formatmeritJustification":
      return (
        <>
          {" "}
          {props.data.meritJustification ? (
            <Tooltip
              title={props.data.meritJustification}
              placement="top"
              arrow
            >
              <span>{truncate(props.data.meritJustification)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "formatdeveloperComments":
      return (
        <>
          {" "}
          {props.data.developerComments ? (
            <Tooltip title={props.data.developerComments} placement="top" arrow>
              <span>{truncate(props.data.developerComments)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "scopesourceurl":
      return (
        <>
          {" "}
          {props.data.sourceURL ? (
            <Tooltip title={props.data.sourceURL} placement="top" arrow>
              <span>{truncatesource(props.data.sourceURL)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "scopemodel":
      return (
        <>
          {" "}
          {props.data.model ? (
            <Tooltip title={props.data.model} placement="top" arrow>
              <span>{truncatescopemodel(props.data.model)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "scopereasonforfailure":
      return (
        <>
          {" "}
          {props.data.reasonforFailure ? (
            <Tooltip title={props.data.reasonforFailure} placement="top" arrow>
              <span>{truncate(props.data.reasonforFailure)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "formatrolename":
      return (
        <>
          {" "}
          {props.data.roleName ? (
            <Tooltip title={props.data.roleName} placement="top" arrow>
              <span>{truncate(props.data.roleName)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "formatdescp":
      return (
        <>
          {" "}
          {props.data.roleDescription ? (
            <Tooltip title={props.data.roleDescription} placement="top" arrow>
              <span>{truncateDescription(props.data.roleDescription)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "formatfname":
      return (
        <>
          {" "}
          {props.data.firstName ? (
            <Tooltip title={props.data.firstName} placement="top" arrow>
              <span>{truncate(props.data.firstName)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "formatlname":
      return (
        <>
          {" "}
          {props.data.lastName ? (
            <Tooltip title={props.data.lastName} placement="top" arrow>
              <span>{truncate(props.data.lastName)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "formatemail":
      return (
        <>
          {" "}
          {props.data.email ? (
            <Tooltip title={props.data.email} placement="top" arrow>
              <span>{truncatesource(props.data.email)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "formatrole":
      return (
        <>
          {" "}
          {props.data.roleName ? (
            <Tooltip title={props.data.roleName} placement="top" arrow>
              <span>{truncate(props.data.roleName)}</span>
            </Tooltip>
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}
        </>
      );
    case "attachment":
      const fileArray = [];
      const fileSplit = props.data.attachments
        ? props.data.attachments.split(",")
        : "";
      fileArray.push(fileSplit);

      return (
        <>
          {props.data.attachments ? (
            fileSplit.map(function (item, i) {
              return (
                <Tooltip title={item} placement="top" arrow sx={{ mt: -0.5 }}>
                  <IconButton aria-label="Delete" size="small">
                    <AttachFileIcon onClick={() => openFileInNewTab(item)} />
                  </IconButton>
                </Tooltip>
              );
            })
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}

          {/*  {props.data.attachments === ".pdf" ? (
            <AttachFileIcon
              onClick={() => openInNewTab(props.data.attachments)}
            />
          ) : (
            <AttachFileIcon onClick={setOpen(true)} />
          )}
          <Confirmationmodel
            open={open}
            onClose={() => setOpen(false)}
            DialogTitle="Image"
            DialogContent={
              <>
                <img src={props.data.attachments} alt="image" />
              </>
            }
          /> */}
        </>
      );

    case "devattachment":
      const devfileArray = [];
      const devfileSplit = props.data.devAttachments
        ? props.data.devAttachments.split(",")
        : "";
      devfileArray.push(devfileSplit);

      return (
        <>
          {props.data.devAttachments ? (
            devfileSplit.map(function (item, i) {
              return (
                <Tooltip title={item} placement="top" arrow sx={{ mt: -0.5 }}>
                  <IconButton aria-label="Delete" size="small">
                    <AttachFileIcon onClick={() => devopenFileInNewTab(item)} />
                  </IconButton>
                </Tooltip>
              );
            })
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}

          {/*  {props.data.attachments === ".pdf" ? (
              <AttachFileIcon
                onClick={() => openInNewTab(props.data.attachments)}
              />
            ) : (
              <AttachFileIcon onClick={setOpen(true)} />
            )}
            <Confirmationmodel
              open={open}
              onClose={() => setOpen(false)}
              DialogTitle="Image"
              DialogContent={
                <>
                  <img src={props.data.attachments} alt="image" />
                </>
              }
            /> */}
        </>
      );
    case "devcomments":
      return (
        <>
          {props.data.developerComments ? (
            props.data.developerComments
              .split("``")
              .slice(-10)
              .map(function (item, i) {
                return (
                  <Tooltip title={item} placement="top" arrow sx={{ mt: -0.5 }}>
                    <IconButton aria-label="Delete" size="small">
                      <CommentOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                );
              })
          ) : (
            <span>&nbsp;&nbsp;&nbsp;-</span>
          )}

          {/*  {props.data.attachments === ".pdf" ? (
                <AttachFileIcon
                  onClick={() => openInNewTab(props.data.attachments)}
                />
              ) : (
                <AttachFileIcon onClick={setOpen(true)} />
              )}
              <Confirmationmodel
                open={open}
                onClose={() => setOpen(false)}
                DialogTitle="Image"
                DialogContent={
                  <>
                    <img src={props.data.attachments} alt="image" />
                  </>
                }
              /> */}
        </>
      );

    default:
      <div></div>;
  }
}
//"#ed5249"

export default ActiveCellRenderer;
