import { AppActionsTypes } from "./actions-types";

const INITIAL_STATE = {
  countrywisedata: [],
  detailcountrywisedata: [],
  sourceurlnorecordsdata: [],
  detaildeliverydata: [],
  deliverydata: [],
  country: [],
  make: [],
  model: [],
  switch: false,
  filter: {
    timeline: "Today",
    quater: "",
    startdate: null,
    enddate: null,
    timeLineStart: null,
    timeLineEnd: null,
  },
  countryFilter: {
    timeline: "Today",
    quater: "",
    startdate: null,
    enddate: null,
    timeLineStart: null,
    timeLineEnd: null,
    country: [],
    make: [],
    model: [],
    exportId: [],
  },
  detailFilter: {
    timeline: "Today",
    quater: "",
    startdate: null,
    enddate: null,
    timeLineStart: null,
    timeLineEnd: null,
    country: [],
    make: [],
    model: [],
    exportId: [],
  },
  noRecFilter: {
    timeline: "Today",
    quater: "",
    startdate: null,
    enddate: null,
    timeLineStart: null,
    timeLineEnd: null,
    country: [],
    make: [],
    model: [],
    noOfDays: "",
    exportId: [],
  },
  princePointFilter: {
    timeline: "Today",
    quater: "",
    startdate: null,
    enddate: null,
    timeLineStart: null,
    timeLineEnd: null,
    country: [],
    make: [],
    model: [],
    exportId: [],
  },
  filterdelivery: {
    timeline: "Year",
    quater: "",
    startdate: null,
    enddate: null,
    timeLineStart: null,
    timeLineEnd: null,
    country: [],
    make: [],
    model: [],
    exportId: [],
  },
};
const dashboardandReportsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppActionsTypes.FETCH_COUNTRY_WISE_STATUS:
      return {
        ...state,
        countrywisedata: action.payload,
      };
    case AppActionsTypes.DASHBOARD_COUNTRY:
      return {
        ...state,
        country: action.payload,
      };
    case AppActionsTypes.DASHBOARD_MAKE:
      return {
        ...state,
        make: action.payload,
      };
    case AppActionsTypes.DASHBOARD_MODEL:
      return {
        ...state,
        model: action.payload,
      };
    case AppActionsTypes.FETCH_DETAILED_COUNTRY_WISE_STATUS:
      return {
        ...state,
        detailcountrywisedata: action.payload,
      };
    case AppActionsTypes.IS_SWITCH_ON:
      return {
        ...state,
        switch: action.payload,
      };

    case AppActionsTypes.DETAIL_FILTER_DASHBOARD:
      return {
        ...state,
        detailFilter: action.payload,
      };
    case AppActionsTypes.PRICEPOINT_FILTER_DASHBOARD:
      return {
        ...state,
        princePointFilter: action.payload,
      };
    case AppActionsTypes.NO_FILTER_DASHBOARD:
      return {
        ...state,
        noRecFilter: action.payload,
      };
    case AppActionsTypes.FILTER_DELIVERY:
      return {
        ...state,
        filterdelivery: action.payload,
      };
    case AppActionsTypes.FILTER_DELIVERY_DETAIL:
      return {
        ...state,
        detaildeliverydata: action.payload,
      };
    case AppActionsTypes.FILTER_DELIVERY_DATA:
      return {
        ...state,
        deliverydata: action.payload,
      };
    case AppActionsTypes.FILTER_DASHBOARD:
      return {
        ...state,
        filter: action.payload,
      };
    case AppActionsTypes.COUNTRY_FILTER_DASHBOARD:
      return {
        ...state,
        countryFilter: action.payload,
      };
    case AppActionsTypes.FETCH_PRICE_POINT_CHANGES:
      return {
        ...state,
        pricepointchangesdata: action.payload,
      };
    case AppActionsTypes.FETCH_SOURCE_URL_WITH_NO_RECORDS:
      return {
        ...state,
        sourceurlnorecordsdata: action.payload,
      };

    default:
      return state;
  }
};

export default dashboardandReportsReducer;
