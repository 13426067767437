import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  textField: {
    padding: "0 !important",
    "& .MuiInputBase-root": {
      // height: 32,
      fontSize: 13,
      fontFamily: "'Inter', sans-serif",
    },
    "& .MuiFormLabel-root": {
      fontSize: "13px !important",
      marginTop: "-2px !important",
    },
    inputIcon: {
      fontSize: "13px !important",
      background: "green",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "16px !important",     
    },
    "& .MuiButtonBase-root": {
      fontSize: "16px !important",
    },
  },
  textFieldReadOnly: {
    padding: "0 !important",
    "& .MuiInputBase-root": {
      // height: 32,
      fontSize: 12,
      fontFamily: "'Inter', sans-serif",
      background: "#F1F1F1"
    },
    "& .MuiFormLabel-root": {
      fontSize: "13px !important",
      marginTop: "-2px !important",
    },
    inputIcon: {
      fontSize: "13px !important",
      background: "green",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "16px !important",     
    },
    "& .MuiButtonBase-root": {
      fontSize: "16px !important",
    },

  },
})
);

export default useStyles;
