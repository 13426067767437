import MaterialUIButton from "components/commonComponents/button/button";
import Datepicker from "components/commonComponents/datepicker/datepicker";
import Dropdown from "components/commonComponents/dropdown/dropdown";
import Textbox from "components/commonComponents/textbox/textbox";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useState } from "react";
import { formatBytes } from "utils/helper";
import { MAX_COUNT } from "constants/constants";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import useStyles from "./manageSources.styles";
import Autocompletedropdown from "components/commonComponents/autocomplete/autocompletedropdown";
import { Required } from "utils/validation helper";
import { useDispatch, usedataor, useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";

import { priorityDropdown, rerunDropdown } from "redux/manageissues/action";
import {
  getBAU,
  getMakeSource,
  getPrioritySource,
  getRerun,
  getUrlStatusSource,
} from "redux/app/actions";

import { useLocation } from "react-router-dom";
import AlertDialog from "components/commonComponents/alertDialog";
import {
  makeDependency,
  newsourceStatus,
  sourceurlStatus,
} from "redux/managesources/action";
import { getNewSourceStatus } from "redux/app/actions";
import MultipleAutocomplete from "components/commonComponents/autocomplete/multipleAutocomplete";
import { getBauStatus } from "redux/reports/actions";
function EditSource(props) {
  const classes = useStyles();
  const id = useSelector((state) => state.project.reports);
  const data2 = useSelector((state) => state.manageSource);
  const data1 = useSelector((state) => state.reports);
  const data = useSelector((state) => state.reports.data);
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    callingDropdowndata();
  }, []);
  /******************************************************************************
  Function: callingDropdowndata
  Argument:
  Return:
  Usage:
  1.To Call dropdown API 
  *******************************************************************************/

  const callingDropdowndata = () => {
    dispatch(getBauStatus(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getBAU([]));
        } else {
          dispatch(getBAU(res.data.result));
        }
      } else {
        dispatch(getBAU([]));
      }
    });
  };

  const [showalert, setShowalert] = useState(false);
  const role = localStorage.getItem("role");

  const truncatesource = (str) => {
    return str ? (str.length > 30 ? str.substring(0, 30) + "..." : str) : "";
  };
  /******************************************************************************
  Function: validate
  Argument: values
  Return: errors
  Usage:
  1.To validate the form
  *******************************************************************************/

  const validate = (values) => {
    const errors = {};
    if (!Required(values.status)) {
      errors.status = true;
    }
    if (values.status?.label === "OTHERS") {
      if (!Required(values.comments)) {
        errors.comments = true;
      } else if (values.comments.length > 400) {
        errors.comments = "Exceeds 400 characters";
      }
    }
    return errors;
  };

  const prenewsource = data.newSourceStatus && {
    id: data.newSourceStatus,
    label: data.newSourceStatus,
  };
  const prepriority = data.priority && {
    id: data.priority.priorityId,
    label: data.priority.issuePriority1,
  };
  const prestatus = data.status && {
    id: data.status.bauid,
    label: data.status.failurereason,
  };
  console.log(data);
  const formik = useFormik({
    initialValues: {
      countryId: data.country,
      make: data.make,
      status: null,
      comments: "",
    },
    enableReinitialize: true,
    validate,
    onSubmit: (values) => {
      console.log(values, data);
      props.onChangeSave(true);
      props.EditSourcedata({
        id: data.id,
        countryid: data.countryid,
        makeid: data.makeid,
        statusid: values.status.label === "OTHERS" ? 0 : values.status.id,
        comments: values.comments ? values.comments.trim() : "",
      });
    },
  });
  /******************************************************************************
  Function: cancel
  Argument: 
  Return: 
  Usage:
  1.To close the form
  *******************************************************************************/

  const cancel = () => {
    props.close();
  };
  return (
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                Edit BAU
              </Typography>
            </Grid>
            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                        JSON.stringify(formik.values)
                          ? props.close()
                          : setShow(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}

        <Grid item xs={12}>
          <Textbox
            size="small"
            readOnly
            label="country"
            name="countryId"
            value={
              data.country
                ? data.country.countryCode + "-" + data.country.region
                : ""
            }
            onChange={formik.handleChange}
            error={formik.touched.countryId && Boolean(formik.errors.countryId)}
            helperText={formik.touched.countryId && formik.errors.countryId}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Textbox
            size="small"
            readOnly
            label="make"
            name="make"
            value={data.make ? data.make.make : ""}
            onChange={formik.handleChange}
            error={formik.touched.make && Boolean(formik.errors.make)}
            helperText={formik.touched.make && formik.errors.make}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Autocompletedropdown
            required
            name="status"
            value={formik.values.status}
            clear={() => formik.setFieldValue("status", "")}
            onChange={(_, data) => formik.setFieldValue("status", data)}
            error={formik.touched.status && Boolean(formik.errors.status)}
            helperText={formik.touched.status && formik.errors.status}
            labelId="Status"
            id="Status"
            label="Status"
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            getOptionLabel={(e) => e.failurereason}
            options={data1.bauStatus.map((e) => {
              return {
                label: e.failurereason,
                id: e.bauid,
              };
            })}
          />
        </Grid>
        {formik.values.status?.label === "OTHERS" ? (
          <Grid item xs={12}>
            <Textbox
              multiline
              rows={3}
              label="Comments"
              fullWidth
              required
              name="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={formik.touched.comments && Boolean(formik.errors.comments)}
              helperText={formik.touched.comments && formik.errors.comments}
            />
          </Grid>
        ) : (
          <></>
        )}

        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="SAVE"
                disabled={props.save}
                startIcon={<SaveOutlinedIcon />}
                onClick={() => formik.handleSubmit()}
              />
            </Grid>

            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="CANCEL"
                startIcon={<EventBusyOutlinedIcon />}
                onClick={() => {
                  JSON.stringify(formik.initialValues) ===
                  JSON.stringify(formik.values)
                    ? props.close()
                    : setShow(true);
                }}
              />
            </Grid>
          </Grid>
          <AlertDialog
            open={show}
            onClose={() => setShow(false)}
            // open={isDeleted}
            title={"Confirmation"}
            // maxWidth={"sm"}
            description={`Do you want to discard the changes ?`}
            action={
              <Grid
                container
                alignItems="center"
                justifyContent="right"
                spacing={2}
                className={classes.alertDialogbtn}
              >
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      props.close();
                      setShow(false);
                    }}
                    message="Yes"
                    startIcon={<DoneIcon />}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      setShow(false);
                    }}
                    message="No"
                    startIcon={<CloseIcon />}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>

        {/* Action Area End Here */}
      </Grid>
    </Box>
  );
}

export default EditSource;
