import React from "react";
import Scrollbars from "react-custom-scrollbars-2";

function Customescrollbar(props) {
  return (
    <Scrollbars
      renderThumbVertical={props.renderThumbVertical}
      className={props.className}
      children={props.children}
    />
  );
}

export default Customescrollbar;
