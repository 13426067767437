import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "redux/usermanagement/action";
import Login from "components/pages/auth/login/login";
import Admin from "components/pages/main/admin/admin";
import AppRoutes from "routes/mainRoutes";
import clsx from "clsx";
import useStyles from "./App.styles";
import axios from "axios";
// import { Helmet } from 'react-helmet-async'; // Import Helmet component

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUrl = decodeURIComponent(window.location.href);
  const url = new URL(currentUrl);
  const searchParams = new URLSearchParams(url.search);
  const accessToken = searchParams.get("access_token");
  const error = searchParams.get("error");
  // const redirectUrl = encodeURIComponent(
  //   "https://jatodevui.codemerit.co.uk/"
  // );
  const redirectUrl = encodeURIComponent(process.env.REACT_APP_URL);
  useEffect(() => {
    if (accessToken) {
      localStorage.setItem("token", accessToken);
      const urlWithoutToken = window.location.origin + window.location.pathname;
      window.history.replaceState(null, "", urlWithoutToken);
      // Logic to remove the access token from the URL
    } else if (error) {
      const urlWithoutError = window.location.origin + window.location.pathname;
      window.history.replaceState(null, "", urlWithoutError);
      // const redirectUrl = encodeURIComponent("https://jatodashboarddevui.azurewebsites.net");

      // const redirectUrl = encodeURIComponent("http://localhost:3000");
      // localStorage.removeItem("role");
      // localStorage.removeItem("firstName");
      // localStorage.removeItem("lastName");
      // localStorage.removeItem("roleId");
      // localStorage.removeItem("email");
      // localStorage.removeItem("roleData");
      // localStorage.removeItem("token");
      // localStorage.removeItem("refreshToken");

      // window.location.href =`https://jatodashboarddevapi.azurewebsites.net/api/v1/users/signout`;
    } else {
      // const redirectUrl = encodeURIComponent("https://jatodashboarddevui.azurewebsites.net");

      window.location.href = `${process.env.REACT_APP_MANAGE_ISSUE_BASE_URL}api/v1/users/signin?redirectUrl=${redirectUrl}`;
    }
  }, []);

  useEffect(() => {
    const handleLogin = async () => {
      if (
        localStorage.getItem("token") !== null ||
        localStorage.getItem("token") !== ""
      ) {
        await dispatch(login(accessToken)).then((response) => {
          
          // localStorage.setItem("project", response.data.result.projects[0]);

          navigate("/projectselection");
        });
      } else if (error) {
        if (location.pathname !== "/error") {
          navigate("/error", {
            state: error,
          });
        }
      } else {
        // const redirectUrl = encodeURIComponent("https://jatodashboarddevui.azurewebsites.net");

        window.location.href = `${process.env.REACT_APP_MANAGE_ISSUE_BASE_URL}api/v1/users/signin?redirectUrl=${redirectUrl}`;
      }
    };

    handleLogin();
  }, [localStorage.getItem("token")]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }, [location.pathname]);

  const classes = useStyles();
  return (
    <div>
      {/* Use Helmet to set security headers */}

      <AppRoutes />

      {/* The rest of your app */}
    </div>
  );
}

export default App;
