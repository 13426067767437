var baseApiUrl = process.env.REACT_APP_MANAGE_ISSUE_BASE_URL;
const baseurl = baseApiUrl + "api";
const baseurlformanagesource = baseurl + "/v1/managesource";
export const BASE_URL_SOURCE = baseurlformanagesource;
export const MANAGE_SOURCE_URL = baseurlformanagesource + "/getallsources";
export const MANAGE_SOURCE_EXPORT_URL =
  baseurlformanagesource + "/getallsourceexport";

export const MANAGE_SOURCE_EDIT_URL = baseurlformanagesource + "/";
export const MANAGE_SOURCE_DELETE_URL = baseurlformanagesource + "/";
export const MANAGE_SOURCE_UPDATE_URL = baseurlformanagesource;
const DROPDOWN_BASIC_URL = baseurl + "/listdropdown";

export const MANAGE_SOURCE_DEPENDENCY_MAKE =
  DROPDOWN_BASIC_URL + "/getallmakeoncountry";
export const MANAGE_SOURCE_DEPENDENCY_MODEL =
  DROPDOWN_BASIC_URL + "/getallmodelonmake";

export const MANAGE_SOURCE_DEPENDENCY_SOURCE_URL =
  DROPDOWN_BASIC_URL + "/getallsourceurllinkonmodel";
export const MANAGE_SOURCE_DEPENDENCY_SOURCE_URL_ONE =
  DROPDOWN_BASIC_URL + "/getsourceurllinkonmodel";
export const MANAGE_SOURCE_SOURCE_URL_STATUS =
  DROPDOWN_BASIC_URL + "/getallsourceurlstatus";
export const MANAGE_SOURCE_NEW_SOURCE_STATUS =
  DROPDOWN_BASIC_URL + "/getallnewsourcestatus";
export const BULK_UPLOAD = baseurlformanagesource + "/bulkuploadmanagesource";
