import {
  deleteData,
  getData,
  postData,
  postFormData,
  putData,
} from "services/basicApiService";

import {
  BASE_URL_SOURCE,
  MANAGE_SOURCE_DELETE_URL,
  MANAGE_SOURCE_EXPORT_URL,
  MANAGE_SOURCE_UPDATE_URL,
  MANAGE_SOURCE_URL,
  MANAGE_SOURCE_DEPENDENCY_MAKE,
  MANAGE_SOURCE_DEPENDENCY_SOURCE_URL,
  MANAGE_SOURCE_SOURCE_URL_STATUS,
  MANAGE_SOURCE_NEW_SOURCE_STATUS,
  MANAGE_SOURCE_DEPENDENCY_MODEL,
  BULK_UPLOAD,
  MANAGE_SOURCE_DEPENDENCY_SOURCE_URL_ONE,
} from "apiConfig/managesource";
import {
  COMPLICITYMANAGESOURCE,
  REPORTEDBY_DROPDOW,
} from "apiConfig/manageissue";
import { checkTokenExpiry } from "utils/helper";
const token = localStorage.getItem("token");
/******************************************************************************
  Function: getManagesource
  Argument: pagenumber, pagesize, search,data
  Return: response
  Usage:
  1.Get Manage Source Data
  *******************************************************************************/
export const getManagesource = (pagenumber, pagesize, search, data, header) => {
  return async () => {
    ////checkTokenExpiry();
    const response = await postData(
      MANAGE_SOURCE_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: ExportManagesource
  Argument: pagenumber, pagesize, search, data
  Return: response
  Usage:
  1.Export Manage Source data 
  *******************************************************************************/

export const ExportManagesource = (
  pagenumber,
  pagesize,
  search,
  data,
  header
) => {
  return async (dispatch) => {
    ////checkTokenExpiry();
    const response = await postData(
      MANAGE_SOURCE_EXPORT_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: updateManagesource
  Argument: data
  Return: response
  Usage:
  1.Used to update the manage source data
  *******************************************************************************/
export const updateManagesource = (data, header) => {
  return async () => {
    ////checkTokenExpiry();
    const response = await putData(MANAGE_SOURCE_UPDATE_URL, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: viewSource
  Argument: id
  Return: response
  Usage:
  1.Get Source by Id Data
  *******************************************************************************/

export const viewSource = (id, header) => {
  return async () => {
    ////checkTokenExpiry();
    const response = await getData(BASE_URL_SOURCE + `/${id}`, {headers:{
      "X-Project-ID": header,
    }}).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: deletesource
  Argument: Id
  Return: response
  Usage:
  1.Used for deleting the source based on the source Id.
  *******************************************************************************/
export const deletesource = (id, header) => {
  return async () => {
    ////checkTokenExpiry();
    const response = await deleteData(MANAGE_SOURCE_DELETE_URL + `?id=${id}`,{headers:{
      "X-Project-ID": header,
    }}).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: newsourceStatus
  Argument: 
  Return: response
  Usage:
  1.Get source status data
  *******************************************************************************/
export const newsourceStatus = (header) => {
  return async () => {
    ////checkTokenExpiry();
    const response = await getData(MANAGE_SOURCE_NEW_SOURCE_STATUS, {headers:{
      "X-Project-ID": header,
    }}).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: sourceurlStatus
  Argument: 
  Return: response
  Usage:
  1.Get Status data
  *******************************************************************************/
export const sourceurlStatus = (header) => {
  return async () => {
    ////checkTokenExpiry();
    const response = await getData(MANAGE_SOURCE_SOURCE_URL_STATUS, {headers:{
      "X-Project-ID": header,
    }}).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const ComplexitySource = (header) => {
  return async () => {
    ////checkTokenExpiry();
    const response = await getData(COMPLICITYMANAGESOURCE, {headers:{
      "X-Project-ID": header,
    }}).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: sourceurlDependency
  Argument: 
  Return: response
  Usage:
  1.Get Source URL data
  *******************************************************************************/
export const sourceurlDependency = (data, header) => {
  return async () => {
    ////checkTokenExpiry();
    const response = await postData(MANAGE_SOURCE_DEPENDENCY_SOURCE_URL, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: makeDependency
  Argument: data
  Return: response
  Usage:
  1.Get Make data
  *******************************************************************************/
export const makeDependency = (data, header) => {
  return async () => {
    ////checkTokenExpiry();
    const response = await postData(MANAGE_SOURCE_DEPENDENCY_MAKE, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: modelDependency
  Argument: data
  Return: response
  Usage:
  1.Get Model Data
  *******************************************************************************/
export const modelDependency = (data, header) => {
  return async () => {
    ////checkTokenExpiry();
    const response = await postData(
      MANAGE_SOURCE_DEPENDENCY_MODEL,
      data,
      {
        "X-Project-ID": header,
      }
      //   , {headers:{
      //   authorization: `Bearer ${token}`}
      // }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: addSource
  Argument: data
  Return: response
  Usage:
  1.Add source data 
  *******************************************************************************/
export const addSource = (data, header) => {
  return async () => {
    ////checkTokenExpiry();
    const response = await postData(BASE_URL_SOURCE, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: addBulk
  Argument: data,name
  Return: response
  Usage:
  1.Upload Bulk data 
  *******************************************************************************/
export const addBulk = (data, name, header) => {
  return async () => {
    ////checkTokenExpiry();
    const response = await postFormData(
      BULK_UPLOAD + `?uploadedBy=${name}`,
      data,
      {
        "X-Project-ID": header,
      }
      // ,
      // { authorization: `Bearer ${token}` }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: dependency
  Argument: name
  Return: response
  Usage:
  1.Get Source URL data 
  *******************************************************************************/

export const dependency = (name, header) => {
  return async () => {
    const data = {};
    ////checkTokenExpiry();
    const response = await postData(
      MANAGE_SOURCE_DEPENDENCY_SOURCE_URL_ONE + `?modelId=${name}`,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
