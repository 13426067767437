import { AppActionsTypes } from "./actions-types";

const INITIAL_STATE = {
  manageQuaitydata: [],
  filter: {
    timeline: "Year",
    quater: "",
    startdate: null,
    enddate: null,
    timeLineStart: null,
    timeLineEnd: null,
    country: [],
    make: [],
    qcStatus: "All",
    ftpUploadStatus: "All",
    exportId: [],
  },
};
const manageQuaityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppActionsTypes.MANAGE_QUALITY:
      return {
        ...state,
        manageQuaitydata: action.payload,
      };
    case AppActionsTypes.MANAGE_QUALITY_FILTER:
      return {
        ...state,
        filter: action.payload,
      };

    default:
      return state;
  }
};

export default manageQuaityReducer;
