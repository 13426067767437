export const isValidEmailAddress = (email) => {
  if (email && email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
    return true;
  }
  return false;
};

export const urlValidation = (email) => {
  if (
    email &&
    email.match(
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/g
    )
  ) {
    return true;
  }
  return false;
};
//'(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
export const Required = (value) => {
 
  if (!value || (typeof value === "string" ? !value.trim() : "")) {
    return false;
  }
  return true;
};
export const onlySting = (value) => {
  const re = /^[A-Za-z_\s]+$/;
  if (value && re.test(value)) {
    return true;
  }
  return false;
};
export const RequiredRadio = (value) => {
  if (value === false || value === true) {
    return true;
  }
  return false;
};
export const passwordMismatch = (password, confirmpassword) => {
  if (password === confirmpassword) {
    return true;
  }
  return false;
};
export const validPassword = (password) => {
  if (password.length >= 8 && password.length <= 15) {
    return true;
  }

  if (password.length === 0 || !password) {
    return false;
  }
};
