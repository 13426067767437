// Default React, React Components, Router and Services Components
import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { useFormik } from "formik";
import { Required, urlValidation } from "utils/validation helper";
import { useDispatch, useSelector } from "react-redux";
import {
  countryDropdown,
  makeString,
  modelDropdown,
  priorityDropdown,
} from "redux/manageissues/action";
import {
  getBAU,
  getCountrySource,
  getMake,
  getMakeSource,
  getModelSource,
  getPrioritySource,
  getUrlStatusSource,
} from "redux/app/actions";

// Importing Custom Components

import Textbox from "components/commonComponents/textbox/textbox";
import Dropdown from "components/commonComponents/dropdown/dropdown";
import MaterialUIButton from "components/commonComponents/button/button";
import Autocompletedropdown from "components/commonComponents/autocomplete/autocompletedropdown";
import moment from "moment/moment";

// Importing useStyles
import useStyles from "./manageSources.styles";

// Import Material Icons
import CloseIcon from "@mui/icons-material/Close";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
// Import Image Icons

import Datepicker from "components/commonComponents/datepicker/datepicker";
import {
  makeDependency,
  sourceurlStatus,
  sourceurlDependency,
  modelDependency,
  dependency,
} from "redux/managesources/action";
import AlertDialog from "components/commonComponents/alertDialog";
import DoneIcon from "@mui/icons-material/Done";
import { useLocation } from "react-router-dom";
import MultipleAutocomplete from "components/commonComponents/autocomplete/multipleAutocomplete";
import { getBauStatus } from "redux/reports/actions";

function AddSource(props) {
  // Declaration of States and Component Variables Start Here
  const role = localStorage.getItem("role");
  const [show, setShow] = useState(false);
  const [showWaring, setShowWarining] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.manageSource);
  const data1 = useSelector((state) => state.reports);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  // Declaration of States and Component Variables End Here
  const classes = useStyles();
  const id = useSelector((state) => state.project.reports);
  // Function for Use Effect
  useEffect(() => {
    getDataFormAPIForDropdown();
  }, []);
  const muifilter = createFilterOptions();
  const dataS = data.urlstatus.filter(
    (e) =>
      e.urlstatus1.toLowerCase() !== "DECOMMISSIONED - REPLACED".toLowerCase()
  );
  const dataReplaced = data.urlstatus.filter(
    (e) =>
      e.urlstatus1.toLowerCase() === "DECOMMISSIONED - REPLACED".toLowerCase()
  );

  const optionRendererm = (props, option, { selected }) => {
    const selectAllProps =
      option.make1 === "ALL" // To control the state of 'select-all' checkbox
        ? {
            checked:
              formik.values.make?.length === 0
                ? false
                : data.make.length === formik.values.make?.length,
          }
        : {};
    return (
      <li {...props}>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {option.make1}
      </li>
    );
  };

  const handleToggleOptionm = (selectedOptions) => {
    formik.setFieldValue("make", selectedOptions);
  };
  /******************************************************************************
  Function: getDataFormAPIForDropdown
  Argument:
  Return:
  Usage:
  1.To call dropdown API
  *******************************************************************************/
  const getDataFormAPIForDropdown = () => {
    dispatch(countryDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getCountrySource([]));
        } else {
          dispatch(getCountrySource(res.data.result));
        }
      } else {
        dispatch(getCountrySource([]));
      }
    });
    dispatch(makeString([], id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getMake([]));
        } else {
          dispatch(getMake(res.data.result));
        }
      } else {
        dispatch(getMake([]));
      }
    });
    dispatch(getBauStatus(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getBAU([]));
        } else {
          dispatch(getBAU(res.data.result));
        }
      } else {
        dispatch(getBAU([]));
      }
    });
  };
  /******************************************************************************
  Function: validate
  Argument: values
  Return: errors
  Usage:
  1.To validate the form
  *******************************************************************************/

  const validate = (values) => {
    const errors = {};

    if (!Required(values.countryId)) {
      errors.countryId = true;
    }
    if (values.make.length === 0) {
      errors.make = true;
    }
    if (!Required(values.status)) {
      errors.status = true;
    }
    if (values.status?.label === "OTHERS") {
      if (!Required(values.comments)) {
        errors.comments = true;
      } else if (values.comments.length > 400) {
        errors.comments = "Exceeds 400 characters";
      }
    }

    return errors;
  };

  /******************************************************************************
  Function: onKeyDown
  Argument: e
  Return: 
  Usage:
  1.To handle onKeyDown event
  *******************************************************************************/

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  /******************************************************************************
  Function: reset
  Argument: 
  Return: 
  Usage:
  1.To reset the form
  *******************************************************************************/

  const reset = () => {
    if (formik && formik.values) {
      formik.resetForm();
      formik.values.countryId = null;
      formik.values.make = [];
      formik.values.status = null;
      formik.values.comments = "";
    }
  };

  const formik = useFormik({
    initialValues: {
      countryId: null,
      make: [],
      status: null,
      comments: "",
    },
    validate,
    onSubmit: (values) => {
      props.onChangeSave(true);
      props.addsource({
        countryId: values.countryId.id,

        makes: values.make.map((e) => e.make1),
        statusId: values.status.label === "OTHERS" ? 0 : values.status.id,

        comments: values.comments,
      });
      reset();
    },
  });
  /******************************************************************************
  Function: handleToggleOption
  Argument: selectedOptions
  Return: 
  Usage:
  1.To set value for modelId
  *******************************************************************************/

  const handleToggleOption = (selectedOptions) => {
    formik.setFieldValue("modelId", selectedOptions);
  };
  /******************************************************************************
  Function: optionRenderer
  Argument: props, option,selected 
  Return: Hmtl element
  Usage:
  1.To set value in  checkbox for model dropdown
  *******************************************************************************/
  const optionRenderer = (props, option, { selected }) => {
    const selectAllProps =
      option.model1 === "Select All" // To control the state of 'select-all' checkbox
        ? { checked: data.model.length === formik.values.modelId.length }
        : {};
    return (
      <li {...props}>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {option.model1}
      </li>
    );
  };
  return (
    // Add Source Form Start Here
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                Bulk Update
              </Typography>
            </Grid>
            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs="auto">
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => reset()}
                    message="RESET"
                    startIcon={<RestartAltOutlinedIcon />}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                          JSON.stringify(formik.values) &&
                        data.files.length === 0
                          ? props.close()
                          : setShow(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}

        <Grid item xs={12}>
          <Autocompletedropdown
            required
            value={formik.values.countryId}
            onChange={(_, data) => {
              formik.setFieldValue("countryId", data);
              formik.setFieldValue("make", []);
              dispatch(makeDependency([data.id], id)).then((res) => {
                if (res.data.statusCode === 200) {
                  if (res.data.result === "No records found") {
                    dispatch(getMakeSource([]));
                  } else {
                    dispatch(getMakeSource(res.data.result));
                  }
                } else {
                  dispatch(getMakeSource([]));
                }
              });
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            error={formik.touched.countryId && Boolean(formik.errors.countryId)}
            helperText={formik.touched.countryId && formik.errors.countryId}
            labelId="Country"
            id="Country"
            name="countryId"
            label="Country"
            options={data.country.map((e) => {
              return {
                label: e.countryCode + " - " + e.region,
                id: e.countryId,
              };
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            id="Make"
            required
            label="Make"
            labelId="Make"
            disabled={!formik.values.countryId ? true : false}
            name="make"
            value={formik.values.make}
            filterOptions={(options, params) => {
              const filtered = muifilter(options, params);
              return [{ make: 0, make1: "ALL" }, ...filtered];
            }}
            onChange={(_, e, value) => {
              if (value === "selectOption" || value === "removeOption") {
                if (e.find((option) => option.make === "ALL")) {
                  let result = [];
                  result = data.make.filter((el) => el.make1 !== "ALL");
                  formik.setFieldValue("make", result);
                } else {
                  handleToggleOptionm && handleToggleOptionm(e);

                  formik.setFieldValue("make", e);
                }
              } else if (value === "clear") {
                formik.setFieldValue("make", []);
              }
              if (e.find((option) => option.make1 === "ALL")) {
                data.make.length === formik.values.make?.length
                  ? formik.setFieldValue("make", [])
                  : formik.setFieldValue("make", data.make);
              }
            }}
            renderTags={(value, getTagProps) =>
              value.length === data.make.length ? (
                <Chip
                  size="small"
                  variant="outlined"
                  label="ALL"
                  //{...getTagProps({ index })}
                />
              ) : (
                value.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={option.make1}
                    //{...getTagProps({ index })}
                  />
                ))
              )
            }
            //  isOptionEqualToValue={(option, value) =>  option.value === value.value }
            isOptionEqualToValue={(option, value) => {
              return option.makeId === value.makeId;
            }}
            // getOptionSelected={(option, value) => option.value === value.value}
            error={formik.touched.make && Boolean(formik.errors.make)}
            helperText={formik.touched.make && formik.errors.make}
            placeholder="Make"
            limitTags={4}
            options={data.make}
            getOptionLabel={(e) => e.make1}
            renderOption={optionRendererm}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocompletedropdown
            required
            name="status"
            value={formik.values.status}
            clear={() => formik.setFieldValue("status", "")}
            onChange={(_, data) => formik.setFieldValue("status", data)}
            error={formik.touched.status && Boolean(formik.errors.status)}
            helperText={formik.touched.status && formik.errors.status}
            labelId="Status"
            id="Status"
            label="Status"
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            getOptionLabel={(e) => e.failurereason}
            options={data1.bauStatus.map((e) => {
              return {
                label: e.failurereason,
                id: e.bauid,
              };
            })}
          />
        </Grid>
        {formik.values.status?.label === "OTHERS" ? (
          <Grid item xs={12}>
            <Textbox
              multiline
              rows={3}
              label="Comments"
              fullWidth
              required
              name="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={formik.touched.comments && Boolean(formik.errors.comments)}
              helperText={formik.touched.comments && formik.errors.comments}
            />
          </Grid>
        ) : (
          <></>
        )}

        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="SAVE"
                disabled={props.save}
                startIcon={<SaveOutlinedIcon />}
                onClick={() => formik.handleSubmit()}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                size="small"
                variant="outlined"
                onClick={() => reset()}
                message="RESET"
                startIcon={<RestartAltOutlinedIcon />}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="CANCEL"
                startIcon={<EventBusyOutlinedIcon />}
                onClick={() => {
                  JSON.stringify(formik.initialValues) ===
                    JSON.stringify(formik.values) && data.files.length === 0
                    ? props.close()
                    : setShow(true);
                }}
              />
            </Grid>
          </Grid>
          <AlertDialog
            open={show}
            onClose={() => setShow(false)}
            // open={isDeleted}
            title={"Confirmation"}
            // maxWidth={"sm"}
            description={`Do you want to discard the changes ?`}
            action={
              <Grid
                container
                alignItems="center"
                justifyContent="right"
                spacing={2}
                className={classes.alertDialogbtn}
              >
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      reset();
                      props.close();
                      setShow(false);
                    }}
                    message="Yes"
                    startIcon={<DoneIcon />}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      setShow(false);
                    }}
                    message="No"
                    startIcon={<CloseIcon />}
                  />
                </Grid>
              </Grid>
            }
          />
          <AlertDialog
            open={showWaring}
            onClose={() => setShowWarining(false)}
            // open={isDeleted}
            title={"Warning"}
            // maxWidth={"sm"}
            description={`Do you want to replace the existing URL `}
            action={
              <Grid
                container
                alignItems="center"
                justifyContent="right"
                spacing={2}
                className={classes.alertDialogbtn}
              >
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      // console.log(data.urlstatus[1].urlstatusId,formik.values.urlstatusId,formik.values.urlstatusId.id);

                      props.onChangeSave(true);
                      props.addsource({
                        countryId: formik.values.countryId.id,
                        make: formik.values.make.id,
                        modelIds: formik.values.modelId.map((e) => e.modelId),
                        sourceUrl: formik.values.newSourceStatus,
                        priorityId: formik.values.priorityId
                          ? formik.values.priorityId.id
                          : null,

                        urlstatusId:
                          formik.values.sourceUrl !== ""
                            ? data.urlstatus[1].urlstatusId
                            : formik.values.urlstatusId
                            ? formik.values.urlstatusId.id
                            : null,
                        receivedDate: formik.values.receivedDate,
                        comments: formik.values.comments,
                        createdBy: formik.values.createdBy,
                      });

                      reset();
                      setShowWarining(false);
                    }}
                    message="Yes"
                    startIcon={<DoneIcon />}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      setShowWarining(false);
                    }}
                    message="No"
                    startIcon={<CloseIcon />}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>

        {/* Action Area End Here */}
      </Grid>
    </Box>
    // Add Source Form End Here
  );
}

export default AddSource;
