import { AppActionsTypes } from "./actions-types";

const INITIAL_STATE = {
  country: [],
  make: [],
  model: [],
  urlstatus: [],
  priority: [],
  sourceurl: [],
  newsourcestatus: [],
  manageSourcedata: [],
  manageSourcedataid: {},
  files: [],
  error: {},
  filter: {
    timeline: "Year",
    quater: "",
    startdate: null,
    enddate: null,
    timeLineStart: null,
    timeLineEnd: null,
    country: [],
    make: [],
    model: [],
    sourceurl: [],
    urlstatus: [],
    priorityLevel: [],
    receiveddate: null,
    complexityLevel: [],
    newsourcestatus: [],
    exportId: [],
  },
};
const manageSourceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppActionsTypes.FETCH_MANAGE_SOURCE:
      return {
        ...state,
        manageSourcedata: action.payload,
      };
    case AppActionsTypes.SOURCEURL_SOURCE:
      return {
        ...state,
        sourceurl: action.payload,
      };
    case AppActionsTypes.FETCH_MANAGE_SOURCE_ID:
      return {
        ...state,
        manageSourcedataid: action.payload,
      };
    case AppActionsTypes.NEW_SOURCE_STATUS:
      return {
        ...state,
        newsourcestatus: action.payload,
      };
    case AppActionsTypes.ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case AppActionsTypes.SOURCE_COUNTRY:
      return {
        ...state,
        country: action.payload,
      };
    case AppActionsTypes.SOURCE_MAKE:
      return {
        ...state,
        make: action.payload,
      };
    case AppActionsTypes.SOURCE_MODEL:
      return {
        ...state,
        model: action.payload,
      };
    case AppActionsTypes.SOURCE_PRIORITY:
      return {
        ...state,
        priority: action.payload,
      };
    case AppActionsTypes.SOURCE_URL_STATUS:
      return {
        ...state,
        urlstatus: action.payload,
      };
    case AppActionsTypes.FILTER_SOURCE:
      return {
        ...state,
        filter: action.payload,
      };

    default:
      return state;
  }
};

export default manageSourceReducer;
