import { combineReducers } from "redux";
import dashboardandReportsReducer from "./app/dashboardandreports";
import manageIssueReducer from "./app/manageIssueReducer";
import manageScopeReducer from "./app/manageScopeReducer";
import manageScraperReducer from "./app/manageScraperReducer";
import manageSourceReducer from "./app/manageSourceReducer";
import roleManagemntReducer from "./app/roleManagementReducer";
import userMangementReducer from "./app/userManagementReducer";
import projectReducer from "./app/projectsReducer";
import manageQuaityReducer from "./app/managequalityreducer";
import reportsReducer from "./app/reportsReducer";

const rootReducer = combineReducers({
  manageIssue: manageIssueReducer,
  manageScarper: manageScraperReducer,
  manageSource: manageSourceReducer,
  manageScope: manageScopeReducer,
  userManagement: userMangementReducer,
  roleManagement: roleManagemntReducer,
  dashboardandreports: dashboardandReportsReducer,
  managequality: manageQuaityReducer,
  project: projectReducer,
  reports: reportsReducer,
});

export default rootReducer;
