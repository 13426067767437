import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    totalResults: {
      fontSize: 12,
      fontFamily: "'Inter', sans-serif",
      fontWeight: "700 !important",
      color: "#333333",
    },
    totalValue: {
      fontSize: 12,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 700,
      color: "#2C0957",
    },
    recordText: {
      fontSize: 12,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400,
      color: "#666666",
    },
    textCaption: {
      fontSize: 12,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 700,
      color: "#666666",
    },
    dataBox: {
      height: 247,
      overflow: "auto",
    },
    // Page Action Area
    graphBox: {
      borderRadius: "14px !important",
      minHeight: 325,
      padding: theme.spacing(2),
      boxShadow: "0px 0px 4px 0px rgba(195, 0, 5, 0.1) !important",
      background: "#ffffff !important",
      // overflow:"auto"
    },
    pageActionArea: {
      padding: theme.spacing(0, 2, 2, 2),
    },
    statusOn: {
      fontSize: 16,
      fontFamily: "'Inter', sans-serif",
      fontWeight: "600 !important",
      color: "#333333",
      margin: 0,
    },
    status: {
      fontSize: 16,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400,
      color: "#666666",
    },
    titleLine: {
      color: "#7947F6",
      fontWeight: 600 + "!important",
      fontSize: "16px !important",
    },
    artLine: {
      color: "#333333 !important",
    },
    dividerLine: {
      marginTop: 10,
      marginBottom: 10,
      borderBottom: "1px solid #cccccc",
    },
    boxWithBorder: {
      border: "1px solid #cccccc",
      borderRadius: 6,
      padding: 15,
    },
    progressBarLabel: {
      fontSize: 13,
      fontFamily: "'Inter', sans-serif",
      fontWeight: "600 !important",
      color: "#333333",
      marginLeft: 10,
      textOverflow: "ellipsis",
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    progressResult: {
      fontSize: 13,
      fontFamily: "'Inter', sans-serif",
      fontWeight: "600 !important",
      color: "#333333",
      marginLeft: 0,
      marginRight: 10,
    },
    progress: {
      border: "none",
    },
    scrapedDetails: {
      border: "1px solid #ccc",
      padding: 20,
      borderRadius: 10,
    },
    scopeData: {
      fontSize: 13,
      fontFamily: "'Inter', sans-serif",
      fontWeight: "600 !important",
      color: "#333333",
      margin: 0,
    },
    scopeDataNum: {
      fontSize: 16,
      fontFamily: "'Inter', sans-serif",
      fontWeight: "700 !important",
      color: "#333333",
      margin: 0,
    },
    progressBar1: {
      height: "6px !important",
      borderRadius: "6px !important",
      backgroundColor: "#efefef !important",
      "& .MuiLinearProgress-bar1Determinate": {
        borderRadius: "6px !important",
        backgroundColor: "#4D79EA !important",
      },
    },
    progressBar2: {
      height: "6px !important",
      borderRadius: "6px !important",
      backgroundColor: "#efefef !important",
      "& .MuiLinearProgress-bar1Determinate": {
        borderRadius: "6px !important",
        backgroundColor: "#D6B84E !important",
      },
    },
    progressBar3: {
      height: "6px !important",
      borderRadius: "6px !important",
      backgroundColor: "#efefef !important",
      "& .MuiLinearProgress-bar1Determinate": {
        borderRadius: "6px !important",
        backgroundColor: "#79D559 !important",
      },
    },
    progressBar4: {
      height: "6px !important",
      borderRadius: "6px !important",
      backgroundColor: "#efefef !important",
      "& .MuiLinearProgress-bar1Determinate": {
        borderRadius: "6px !important",
        backgroundColor: "#FF823C !important",
      },
    },
    progressBar5: {
      height: "6px !important",
      borderRadius: "6px !important",
      backgroundColor: "#efefef !important",
      "& .MuiLinearProgress-bar1Determinate": {
        borderRadius: "6px !important",
        backgroundColor: "#9476EB !important",
      },
    },
    progressBar6: {
      height: "6px !important",
      borderRadius: "6px !important",
      backgroundColor: "#efefef !important",
      "& .MuiLinearProgress-bar1Determinate": {
        borderRadius: "6px !important",
        backgroundColor: "#BF8565 !important",
      },
    },
    fullHeight: {
      height: "calc(100vh - 140px)",
    },
    innerPage: {
      height: "calc(100vh - 220px)",
    },
  })
);

export default useStyles;
