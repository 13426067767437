import { getData, postData } from "services/basicApiService";
import {
  MANAGE_SCRAPER_COUNT_URL,
  MANAGE_SCRAPER_EXPORT_URL,
  MANAGE_SCRAPER_URL,
  NEXT_RUN_STATUS_DROPDOWN,
  SOURCE_URL_DROPDOWN,
  STATUS_DROPDOWN,
} from "apiConfig/managescraper";
import { checkTokenExpiry } from "utils/helper";
import { DROPDOWN_REASON_FOR_FAILURE_SCRAPER } from "apiConfig/manageissue";
const token = localStorage.getItem("token");
/******************************************************************************
  Function: getManagescope
  Argument: pagenumber, pagesize, search,data
  Return: response
  Usage:
  1.Get Manage Scope Data
  *******************************************************************************/
export const getManagescraperCount = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(MANAGE_SCRAPER_COUNT_URL, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: getManagescraper
  Argument: pagenumber, pagesize, search,data
  Return: response
  Usage:
  1.Get Manage Scraper Data
  *******************************************************************************/
export const getManagescraper = (
  pagenumber,
  pagesize,
  search,
  status,
  data,
  header
) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(
      MANAGE_SCRAPER_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}&urlStatus=${status}`,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: ExportManagescraper
  Argument: pagenumber, pagesize, search, data
  Return: response
  Usage:
  1.Export Manage Scraper data 
  *******************************************************************************/

export const ExportManagescraper = (
  pagenumber,
  pagesize,
  search,
  status,
  data,
  header
) => {
  return async (dispatch) => {
    //checkTokenExpiry()
    const response = await postData(
      MANAGE_SCRAPER_EXPORT_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}&urlStatus=${status}`,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: sourceUrlDropdown
  Argument: 
  Return: response
  Usage:
  1.Get Source URL data
  *******************************************************************************/
export const sourceUrlDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(SOURCE_URL_DROPDOWN, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};

export const scraperReasonFailure = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(DROPDOWN_REASON_FOR_FAILURE_SCRAPER, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: statusScraperDropdown
  Argument: 
  Return: response
  Usage:
  1.Get status data
  *******************************************************************************/

export const statusScraperDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(STATUS_DROPDOWN, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: nextRunstatusScraperDropdown
  Argument: 
  Return: response
  Usage:
  1.Get next Run status data
  *******************************************************************************/

export const nextRunstatusScraperDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(NEXT_RUN_STATUS_DROPDOWN, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
