import MaterialUIButton from "components/commonComponents/button/button";

import Dropdown from "components/commonComponents/dropdown/dropdown";
import Textbox from "components/commonComponents/textbox/textbox";
import React, { useEffect, useState } from "react";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import DoneIcon from "@mui/icons-material/Done";
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";

import Radiobutton from "components/commonComponents/radiobutton/radiobutton";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import MultipleAutocomplete from "components/commonComponents/autocomplete/multipleAutocomplete";
import {
  getCountry,
  getMake,
  getModel,
  getProvider,
  saveqcFilter,
} from "redux/app/actions";
import { getMonth } from "utils/helper";
import {
  countryDropdown,
  makeString,
  modelDropdown,
  providerDropdown,
} from "redux/manageissues/action";
import AlertDialog from "components/commonComponents/alertDialog";
import useStyles from "./manageQuality.styles";

import EditPicker from "components/commonComponents/datepicker/editpicker";
function AdvanceFilters(props) {
  const months = getMonth();
  const select = useSelector((state) => state.managequality.filter);
  const data = useSelector((state) => state.manageIssue);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const id = useSelector((state) => state.project.quality);
  const Pname = useSelector((state) => state.project.managequalityprojectname);

  const classes = useStyles();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    dispatch(countryDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getCountry([]));
        } else {
          dispatch(getCountry(res.data.result));
        }
      } else {
        dispatch(getCountry([]));
      }
    });
    dispatch(providerDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getProvider([]));
        } else {
          dispatch(getProvider(res.data.result));
        }
      } else {
        dispatch(getProvider([]));
      }
    });
    dispatch(makeString([], id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getMake([]));
        } else {
          dispatch(getMake(res.data.result));
        }
      } else {
        dispatch(getMake([]));
      }
    });
    // getSearchIssue(page, limit, "");
  }, []);

  /******************************************************************************
Function: callingDropdowndata
Argument: 
Usage:
1. Calling API for Dropdown.
*******************************************************************************/

  /******************************************************************************
Function: validate
Argument: values
Usage:
1. To validate the fileds.
*******************************************************************************/

  const validate = (values) => {
    const errors = {};
    if (new Date(values.timeLineStart) > new Date(values.timeLineEnd)) {
      errors.timeLineEnd = "Enddate should be greater than  startdate";
    }
    if (values.plannedEndDate === "Invalid") {
      errors.plannedEndDate = "invalid date";
    }
    if (values.resolutionDate === "Invalid") {
      errors.resolutionDate = "invalid date";
    }
    if (values.startdate === "Invalid") {
      errors.startdate = "invalid date";
    }
    if (values.enddate === "Invalid") {
      errors.enddate = "invalid date";
    }
    return errors;
  };
  /******************************************************************************
Function: formik
Argument: 
Usage:
1. To submit the data .
*******************************************************************************/

  const formik = useFormik({
    initialValues: {
      timeline: select.timeline,
      quater: select.quater,
      startdate: select.startdate,
      enddate: select.enddate,
      timeLineStart: select.timeLineStart,
      timeLineEnd: select.timeLineEnd,
      country: select.country,
      make: select.make,
      ftpUploadStatus: select.ftpUploadStatus,
      qcStatus: select.qcStatus,
      exportId: select.exportId,
    },
    validate,
    onSubmit: (values) => {
      dispatch(saveqcFilter(values));

      props.filter({
        timeLineStart: values.timeLineStart,
        timeLineEnd: values.timeLineEnd,
        country: values.country.map((e) => e.countryId),
        make: values.make.map((e) =>
          e.make1 ? e.make1 : e.leasingProviderName
        ),
        ftpUploadStatus: values.ftpUploadStatus,
        qcStatus: values.qcStatus,
        exportId: values.exportId,
      });
    },
  });
  /******************************************************************************
Function: cancel
Argument: 
Usage:
1. To reset the fileds.
*******************************************************************************/

  const reset = () => {
    if (formik && formik.values) {
      //formik.resetForm();
      formik.values.timeline = "Year";
      formik.values.quater = "";
      formik.values.startdate = null;
      formik.values.enddate = null;
      formik.values.timeLineStart = null;
      formik.values.timeLineEnd = null;
      formik.values.country = [];
      formik.values.make = [];
      formik.values.ftpUploadStatus = "All";
      formik.values.qcStatus = "All";
      formik.values.exportId = [];

      props.filter(
        {
          timeLineStart: null,
          timeLineEnd: null,
          country: [],
          make: [],
          qcStatus: "All",
          ftpUploadStatus: "All",
          exportId: [],
        },
        true
      );
      dispatch(
        saveqcFilter({
          timeline: "Year",
          quater: "",
          startdate: null,
          enddate: null,
          timeLineStart: null,
          timeLineEnd: null,
          country: [],
          make: [],
          qcStatus: "All",
          ftpUploadStatus: "All",
          exportId: [],
        })
      );
    }
  };

  let fmt = "[Q]Q-Y";

  let quarters = [
    moment().format(fmt),
    moment().subtract(1, "Q").format(fmt),
    moment().subtract(2, "Q").format(fmt),
    moment().subtract(3, "Q").format(fmt),
    moment().subtract(4, "Q").format(fmt),
  ];
  console.log(formik.values.timeLineStart, formik.values.timeLineEnd);
  return (
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                Advance Filter
              </Typography>
            </Grid>

            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs="auto">
                  <MaterialUIButton
                    color="secondary"
                    message="RESET"
                    onClick={() => {
                      reset();
                    }}
                    startIcon={<RestartAltOutlinedIcon />}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                        JSON.stringify(formik.values)
                          ? props.close()
                          : setShow(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}
        <Grid item xs={12} sx={{ mt: -1, mb: -1 }}>
          <Typography variant="subtitle2" gutterBottom>
            Date Of File
          </Typography>

          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Year"
                name="timeline"
                itemvalue="Year"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);

                  formik.setFieldValue(
                    "timeLineStart",
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Quarter"
                // checked={formik.values.timeline === "Quater"}
                name="timeline"
                value={formik.values.timeline}
                itemvalue="Quater"
                onChange={(e) =>
                  formik.setFieldValue("timeline", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Month"
                name="timeline"
                value={formik.values.timeline}
                itemvalue="Month"
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().startOf("month").format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().endOf("month").format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Week"
                // checked={formik.values.timeline === "Week"}
                name="timeline"
                itemvalue="Week"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue("startdate", moment().startOf("week"));
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().startOf("week").format("YYYY-MM-DD")
                  );
                  formik.setFieldValue("enddate", moment().endOf("week"));
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().endOf("week").format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Today"
                // checked={formik.values.timeline === "Today"}
                name="timeline"
                itemvalue="Today"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Custom"
                value={formik.values.timeline}
                // checked={formik.values.timeline === "Custom"}
                name="timeline"
                itemvalue="Custom"
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue("startdate", null);
                  formik.setFieldValue("enddate", null);
                  formik.setFieldValue("timeLineStart", null);
                  formik.setFieldValue("timeLineEnd", null);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {formik.values.timeline === "Week" && (
            <Textbox
              fullWidth
              disabled
              id="week"
              name="week"
              value={
                moment().startOf("week").format("ll") +
                " to " +
                moment().endOf("week").format("ll")
              }
            />
          )}
          {formik.values.timeline === "Month" && (
            <Textbox
              fullWidth
              disabled
              id="year"
              name="year"
              value={months[0]}
            />
          )}
          {formik.values.timeline === "Year" && (
            <Textbox
              fullWidth
              disabled
              id="year"
              name="year"
              value={months[months.length - 1] + " to " + months[0]}
            />
          )}
          {formik.values.timeline === "Today" && (
            <Textbox
              fullWidth
              disabled
              id="today"
              name="today"
              value={moment().format("LL")}
            />
          )}
          {formik.values.timeline === "Quater" && (
            <Dropdown
              fullWidth
              labelId="quater"
              id="quater"
              name="quater"
              label="Quarter"
              value={formik.values.quater}
              onChange={(e) => {
                const value = e.target.value.split("-");
                formik.setFieldValue("quater", e.target.value);

                if (value[0] === "Q1") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("1/1/" + value[1]),
                        moment("3/31/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("3/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("1/1/" + value[1]),
                      moment("3/31/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("1/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("1/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("3/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q2") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("4/1/" + value[1]),
                        moment("6/30/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("6/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("4/1/" + value[1]),
                      moment("6/30/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("4/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("4/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("6/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q3") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("7/1/" + value[1]),
                        moment("9/30/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("9/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("4/1/" + value[1]),
                      moment("6/30/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("7/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("7/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("9/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q4") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("10/1/" + value[1]),
                        moment("12/31/" + value[1])
                      )
                  ) {
                    console.log(
                      value[1],
                      moment("12/31/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("12/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("10/1/" + value[1]),
                      moment("12/31/" + value[1])
                    )
                  ) {
                    console.log(moment().format("YYYY-MM-DD"));
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("10/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    console.log(
                      moment("12/31/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("10/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("12/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
              }}
              menuItems={quarters.map((e) => (
                <MenuItem value={e}>{e}</MenuItem>
              ))}
            />
          )}
          {formik.values.timeline === "Custom" && (
            <>
              <EditPicker
                max={moment().format("YYYY-MM-DD")}
                min={moment().subtract(1, "year").format("YYYY-MM-DD")}
                name="timeLineStart"
                value={formik.values.startdate}
                onChange={(e) => {
                  if (e === null) {
                    formik.setFieldValue("startdate", e);
                    // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
                  } else {
                    formik.setFieldValue("startdate", e);

                    formik.setFieldValue(
                      "timeLineStart",
                      e.format("YYYY-MM-DD")
                    );
                  }
                }}
                error={
                  formik.touched.startdate && Boolean(formik.errors.startdate)
                }
                helperText={formik.touched.startdate && formik.errors.startdate}
                size="small"
                fullWidth
                label="Start Date"
              />
              <br />
              <EditPicker
                max={moment().format("YYYY-MM-DD")}
                min={formik.values.startdate}
                name="timeLineEnd"
                value={formik.values.enddate}
                onChange={(e) => {
                  if (e === null) {
                    formik.setFieldValue("enddate", e);
                    // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
                  } else {
                    formik.setFieldValue("enddate", e);
                    formik.setFieldValue("timeLineEnd", e.format("YYYY-MM-DD"));
                  }
                }}
                error={formik.touched.enddate && Boolean(formik.errors.enddate)}
                helperText={formik.touched.enddate && formik.errors.enddate}
                size="small"
                fullWidth
                label="End Date"
              />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <MultipleAutocomplete
            labelId="Country"
            id="Country"
            name="country"
            label="Country"
            value={formik.values.country}
            onChange={(_, e, value) => {
              formik.setFieldValue("country", e);
              if (value !== "clear") {
                dispatch(
                  makeString(
                    e.map((i) => i.countryId),
                    id
                  )
                ).then((res) => {
                  if (res.data.statusCode === 200) {
                    if (res.data.result === "No records found") {
                      dispatch(getMake([]));
                    } else {
                      dispatch(getMake(res.data.result));
                    }
                  } else {
                    dispatch(getMake([]));
                  }
                });
              } else {
                dispatch(makeString([], id)).then((res) => {
                  if (res.data.statusCode === 200) {
                    if (res.data.result === "No records found") {
                      dispatch(getMake([]));
                    } else {
                      dispatch(getMake(res.data.result));
                    }
                  } else {
                    dispatch(getMake([]));
                  }
                });
              }
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option.countryCode + " - " + option.region}
                  // //{...getTagProps({ index })}
                />
              ))
            }
            placeholder="Country"
            limitTags={4}
            options={data.country}
            isOptionEqualToValue={(option, value) => {
              return option.countryId === value.countryId;
            }}
            getOptionLabel={(e) => e.countryCode + " - " + e.region}
            renderOption={(props, e, { selected }) => (
              <li {...props}>
                <Checkbox
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {e.countryCode + " - " + e.region}
              </li>
            )}
          />
        </Grid>
        {Pname === "LEASE OPERATOR" ? (
          <Grid item xs={12}>
            <MultipleAutocomplete
              labelId="leaseOperator"
              // disabled={formik.values.country.length === 0 ? true : false}
              id="leaseOperator"
              name="make"
              label="Leasing Provider"
              onChange={(_, e) => formik.setFieldValue("make", e)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={option.leasingProviderName}
                    //{...getTagProps({ index })}
                  />
                ))
              }
              value={formik.values.make}
              placeholder="Leasing Provider"
              limitTags={4}
              options={data.provider}
              getOptionLabel={(e) => e.leasingProviderName}
              isOptionEqualToValue={(option, value) => {
                return option.leasingProviderName === value.leasingProviderName;
              }}
              renderOption={(props, e, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    size="small"
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {e.leasingProviderName}
                </li>
              )}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <MultipleAutocomplete
              labelId="Make"
              // disabled={formik.values.country.length === 0 ? true : false}
              id="Make"
              name="make"
              label="Make"
              onChange={(_, e, value) => {
                console.log(e, value);
                formik.setFieldValue("make", e);
                if (value !== "clear") {
                  dispatch(
                    modelDropdown(
                      e.map((i) => i.make1),
                      id
                    )
                  ).then((res) => {
                    if (res.data.statusCode === 200) {
                      if (res.data.result === "No records found") {
                        dispatch(getModel([]));
                      } else {
                        dispatch(getModel(res.data.result));
                      }
                    } else {
                      dispatch(getModel([]));
                    }
                  });
                } else if (value === "removeOption" && e.length === 0) {
                  formik.setFieldValue("model", []);
                } else {
                  formik.setFieldValue("model", []);
                }
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={option.make1}
                    //{...getTagProps({ index })}
                  />
                ))
              }
              value={formik.values.make}
              placeholder="Make"
              limitTags={4}
              options={data.make}
              getOptionLabel={(e) => e.make1}
              isOptionEqualToValue={(option, value) => {
                return option.make1 === value.make1;
              }}
              renderOption={(props, e, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    size="small"
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {e.make1}
                </li>
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sx={{ mt: -1, mb: -1 }}>
          <Typography variant="subtitle2" gutterBottom>
            QC Status
          </Typography>

          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={6} md={4}>
              <Radiobutton
                name="qcStatus"
                value={formik.values.qcStatus}
                onChange={(e) =>
                  formik.setFieldValue("qcStatus", e.target.value)
                }
                itemvalue="All"
                size="small"
                label="All"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                value={formik.values.qcStatus}
                onChange={(e) =>
                  formik.setFieldValue("qcStatus", e.target.value)
                }
                itemvalue="Pass"
                label="Pass"
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <Radiobutton
                value={formik.values.qcStatus}
                onChange={(e) =>
                  formik.setFieldValue("qcStatus", e.target.value)
                }
                itemvalue="Fail"
                label="Fail"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            FTP Upload Status
          </Typography>

          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={6} md={4}>
              <Radiobutton
                name="ftpUploadStatus"
                itemvalue="All"
                value={formik.values.ftpUploadStatus}
                onChange={(e) =>
                  formik.setFieldValue("ftpUploadStatus", e.target.value)
                }
                label="All"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                name="ftpUploadStatus"
                value={formik.values.ftpUploadStatus}
                onChange={(e) =>
                  formik.setFieldValue("ftpUploadStatus", e.target.value)
                }
                itemvalue="Yes"
                label="Yes"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                name="ftpUploadStatus"
                value={formik.values.ftpUploadStatus}
                onChange={(e) =>
                  formik.setFieldValue("ftpUploadStatus", e.target.value)
                }
                itemvalue="No"
                label="No"
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="FILTER"
                startIcon={<FilterListOutlinedIcon />}
                onClick={() => formik.handleSubmit()}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                onClick={() => {
                  reset();
                }}
                message="RESET"
                startIcon={<RestartAltOutlinedIcon />}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="CANCEL"
                startIcon={<EventBusyOutlinedIcon />}
                onClick={() => {
                  JSON.stringify(formik.initialValues) ===
                  JSON.stringify(formik.values)
                    ? props.close()
                    : setShow(true);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <AlertDialog
          open={show}
          onClose={() => setShow(false)}
          // open={isDeleted}
          title={"Confirmation"}
          // maxWidth={"sm"}
          description={`Do you want to discard the changes ?`}
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    reset();
                    props.close();
                    setShow(false);
                  }}
                  message="Yes"
                  startIcon={<DoneIcon />}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setShow(false);
                  }}
                  message="No"
                  startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
        {/* Action Area End Here */}
      </Grid>
    </Box>
  );
}

export default AdvanceFilters;
