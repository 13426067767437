import MaterialUICheckbox from "components/commonComponents/checkbox/checkbox";
import React, { useState } from "react";
import { useSelector } from "react-redux";

function SelectCellRenderer(props) {
  switch (props.actionType) {
    case "manageissue":
      return (
        <MaterialUICheckbox
          id="issue"
          name="isSelect"
          checked={props.checked}
          color={props.color}
          size={props.size}
          onChange={(e) =>
            props.context.componentParent.handleSelection(
              e.target.checked,
              e.target.name,
              props.data
            )
          }
        />
      );
    case "ticketidlink":
      return <a href="#">{props.ticketid}</a>;

    default:
      <div></div>;
  }
}

export default SelectCellRenderer;
