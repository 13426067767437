import MaterialUIButton from "components/commonComponents/button/button";
import Datepicker from "components/commonComponents/datepicker/datepicker";
import Dropdown from "components/commonComponents/dropdown/dropdown";
import Textbox from "components/commonComponents/textbox/textbox";
import React, { useState } from "react";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import DoneIcon from "@mui/icons-material/Done";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";

import Radiobutton from "components/commonComponents/radiobutton/radiobutton";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import MultipleAutocomplete from "components/commonComponents/autocomplete/multipleAutocomplete";
import { filterDashboard, getModel, saveFilters } from "redux/app/actions";
import { getMonth } from "utils/helper";
import { modelDropdown } from "redux/manageissues/action";
import AlertDialog from "components/commonComponents/alertDialog";
import useStyles from "./dashboardAndReports.styles";
import EditPicker from "components/commonComponents/datepicker/editpicker";
function AdvanceFilters(props) {
  const months = getMonth();
  const select = useSelector((state) => state.dashboardandreports.filter);
  const data = useSelector((state) => state.dashboardandreports);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const id=useSelector((state) => state.project.dashboard)

  const classes = useStyles();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  /******************************************************************************
Function: formik
Argument: 
Usage:
1. To submit the data.
*******************************************************************************/

  const formik = useFormik({
    initialValues: {
      timeline: select.timeline,
      quater: select.quater,
      startdate: select.startdate,
      enddate: select.enddate,
      timeLineStart: select.timeLineStart,
      timeLineEnd: select.timeLineEnd,
    },

    onSubmit: (values) => {
      dispatch(filterDashboard(values));

      props.filter({
        timeLineStart: values.timeLineStart,
        timeLineEnd: values.timeLineEnd,
      });
    },
  });
  /******************************************************************************
Function: reset
Argument: 
Usage:
1. To reset the fileds.
*******************************************************************************/

  const reset = () => {
    if (formik && formik.values) {
      formik.resetForm();
      formik.values.timeline = "Today";
      formik.values.quater = "";
      formik.values.startdate = null;
      formik.values.enddate = null;
      formik.values.timeLineStart = null;
      formik.values.timeLineEnd = null;

      // props.filter({
      //   timeLineStart: null,
      //   timeLineEnd: null

      // }, true);
      dispatch(
        filterDashboard({
          timeline: "Today",
          quater: "",
          startdate: null,
          enddate: null,
          timeLineStart: null,
          timeLineEnd: null,
        })
      );
    }
  };

  let fmt = "[Q]Q-Y";

  let quarters = [
    moment().format(fmt),
    moment().subtract(1, "Q").format(fmt),
    moment().subtract(2, "Q").format(fmt),
    moment().subtract(3, "Q").format(fmt),
    moment().subtract(4, "Q").format(fmt),
  ];

  return (
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                Advance Filter
              </Typography>
            </Grid>

            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs="auto">
                  <MaterialUIButton
                    color="secondary"
                    message="RESET"
                    onClick={() => {
                      reset();
                    }}
                    startIcon={<RestartAltOutlinedIcon />}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                        JSON.stringify(formik.values)
                          ? props.close()
                          : setShow(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}
        <Grid item xs={12} sx={{ mt: -1, mb: -1 }}>
          <Typography variant="subtitle2" gutterBottom>
            Timeline
          </Typography>

          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Year"
                name="timeline"
                itemvalue="Year"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);

                  formik.setFieldValue(
                    "timeLineStart",
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Quarter"
                // checked={formik.values.timeline === "Quater"}
                name="timeline"
                value={formik.values.timeline}
                itemvalue="Quater"
                onChange={(e) =>
                  formik.setFieldValue("timeline", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Month"
                name="timeline"
                value={formik.values.timeline}
                itemvalue="Month"
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().startOf("month").format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().endOf("month").format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Week"
                // checked={formik.values.timeline === "Week"}
                name="timeline"
                itemvalue="Week"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue("startdate", moment().startOf("week"));
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().startOf("week").format("YYYY-MM-DD")
                  );
                  formik.setFieldValue("enddate", moment().endOf("week"));
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().endOf("week").format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Today"
                // checked={formik.values.timeline === "Today"}
                name="timeline"
                itemvalue="Today"
                value={formik.values.timeline}
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue(
                    "timeLineStart",
                    moment().format("YYYY-MM-DD")
                  );
                  formik.setFieldValue(
                    "timeLineEnd",
                    moment().format("YYYY-MM-DD")
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Radiobutton
                label="Custom"
                value={formik.values.timeline}
                // checked={formik.values.timeline === "Custom"}
                name="timeline"
                itemvalue="Custom"
                onChange={(e) => {
                  formik.setFieldValue("timeline", e.target.value);
                  formik.setFieldValue("startdate", null);
                  formik.setFieldValue("enddate", null);
                  formik.setFieldValue("timeLineStart", null);
                  formik.setFieldValue("timeLineEnd", null);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {formik.values.timeline === "Week" && (
            <Textbox
              fullWidth
              disabled
              id="week"
              name="week"
              value={
                moment().startOf("week").format("ll") +
                " to " +
                moment().endOf("week").format("ll")
              }
            />
          )}
          {formik.values.timeline === "Month" && (
            <Textbox
              fullWidth
              disabled
              id="year"
              name="year"
              value={months[0]}
            />
          )}
          {formik.values.timeline === "Year" && (
            <Textbox
              fullWidth
              disabled
              id="year"
              name="year"
              value={months[months.length - 1] + " to " + months[0]}
            />
          )}
          {formik.values.timeline === "Today" && (
            <Textbox
              fullWidth
              disabled
              id="today"
              name="today"
              value={moment().format("LL")}
            />
          )}
          {formik.values.timeline === "Quater" && (
            <Dropdown
              fullWidth
              labelId="quater"
              id="quater"
              name="quater"
              label="Quarter"
              value={formik.values.quater}
              onChange={(e) => {
                const value = e.target.value.split("-");
                formik.setFieldValue("quater", e.target.value);

                if (value[0] === "Q1") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("1/1/" + value[1]),
                        moment("3/31/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("3/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("1/1/" + value[1]),
                      moment("3/31/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("1/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("1/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("3/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q2") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("4/1/" + value[1]),
                        moment("6/30/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("6/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("4/1/" + value[1]),
                      moment("6/30/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("4/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("4/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("6/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q3") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("7/1/" + value[1]),
                        moment("9/30/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("9/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("4/1/" + value[1]),
                      moment("6/30/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("7/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("7/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("9/30/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
                if (value[0] === "Q4") {
                  if (
                    moment(moment().subtract(1, "day"))
                      .subtract(1, "year")
                      .isBetween(
                        moment("10/1/" + value[1]),
                        moment("12/31/" + value[1])
                      )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment(moment().subtract(1, "day"))
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("12/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  } else if (
                    moment().isBetween(
                      moment("10/1/" + value[1]),
                      moment("12/31/" + value[1])
                    )
                  ) {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("10/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment().format("YYYY-MM-DD")
                    );
                  } else {
                    formik.setFieldValue(
                      "timeLineStart",
                      moment("10/1/" + value[1]).format("YYYY-MM-DD")
                    );
                    formik.setFieldValue(
                      "timeLineEnd",
                      moment("12/31/" + value[1]).format("YYYY-MM-DD")
                    );
                  }
                }
              }}
              menuItems={quarters.map((e) => (
                <MenuItem value={e}>{e}</MenuItem>
              ))}
            />
          )}
          {formik.values.timeline === "Custom" && (
            <>
              <EditPicker
                max={moment().format("YYYY-MM-DD")}
                min={moment().subtract(1, "year").format("YYYY-MM-DD")}
                name="timeLineStart"
                value={formik.values.startdate}
                onChange={(e) => {
                  if (e === null) {
                    formik.setFieldValue("startdate", e);
                    // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
                  } else {
                    formik.setFieldValue("startdate", e);

                    formik.setFieldValue(
                      "timeLineStart",
                      e.format("YYYY-MM-DD")
                    );
                  }
                }}
                error={
                  formik.touched.startdate && Boolean(formik.errors.startdate)
                }
                helperText={formik.touched.startdate && formik.errors.startdate}
                size="small"
                fullWidth
                label="Start Date"
              />
              <br />
              <EditPicker
                max={moment().format("YYYY-MM-DD")}
                min={formik.values.startdate}
                name="timeLineEnd"
                value={formik.values.enddate}
                onChange={(e) => {
                  if (e === null) {
                    formik.setFieldValue("enddate", e);
                    // formik.setFieldValue("plannedEndDate", e.format("YYYY-MM-DD"))
                  } else {
                    formik.setFieldValue("enddate", e);
                    formik.setFieldValue("timeLineEnd", e.format("YYYY-MM-DD"));
                  }
                }}
                error={formik.touched.enddate && Boolean(formik.errors.enddate)}
                helperText={formik.touched.enddate && formik.errors.enddate}
                size="small"
                fullWidth
                label="End Date"
              />
            </>
          )}
        </Grid>

        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="FILTER"
                startIcon={<FilterListOutlinedIcon />}
                onClick={() => formik.handleSubmit()}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                onClick={() => {
                  reset();
                }}
                message="RESET"
                startIcon={<RestartAltOutlinedIcon />}
              />
            </Grid>
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="CANCEL"
                startIcon={<EventBusyOutlinedIcon />}
                onClick={() => {
                  JSON.stringify(formik.initialValues) ===
                  JSON.stringify(formik.values)
                    ? props.close()
                    : setShow(true);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <AlertDialog
          open={show}
          onClose={() => setShow(false)}
          // open={isDeleted}
          title={"Confirmation"}
          // maxWidth={"sm"}
          description={`Do you want to discard the changes ?`}
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    reset();
                    props.close();
                    setShow(false);
                  }}
                  message="Yes"
                  startIcon={<DoneIcon />}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setShow(false);
                  }}
                  message="No"
                  startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
        {/* Action Area End Here */}
      </Grid>
    </Box>
  );
}

export default AdvanceFilters;
