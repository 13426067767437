// import React from 'react'

import { Switch } from "@mui/material";

function AnchorCellRender(props) {

  if (
    props.data.menuName === "Manage Scraper" ||
    props.data.menuName === "Dashboard & Reports"
  ) {
    switch (props.actionType) {
      case "add":
        return <>-</>;
      case "edit":
        return <>-</>;
      case "view":
        return (
          <Switch
            name="isView"
            size="small"
            checked={props.data.isView}
            onChange={(e) =>
              props.context.componentParent.handleSelection(
                e.target.checked,
                e.target.name,
                props.data
              )
            }
          />
        );
      case "delete":
        return <>-</>;
      default:
        break;
    }
  }
 else if (
    props.data.menuName === "Manage Quality"
  ) {
    switch (props.actionType) {
      case "add":
        return <>-</>;
      case "edit":
        return <>-</>;
      case "view":
        return (
          <Switch
            name="isView"
            size="small"
            checked={props.data.isView}
            onChange={(e) =>
              props.context.componentParent.handleSelection(
                e.target.checked,
                e.target.name,
                props.data
              )
            }
          />
        );
      case "delete":
        return  <Switch
        name="isDelete"
        size="small"
        disabled={!props.data.isView}

        checked={props.data.isDelete}
        onChange={(e) =>
          props.context.componentParent.handleSelection(
            e.target.checked,
            e.target.name,
            props.data
          )
        }
      />;
      default:
        break;
    }
  }
  else {
    switch (props.actionType) {
      case "add":
        return (
          <Switch
            name="isAdd"
            size="small"
            disabled={!props.data.isView}
            checked={props.data.isAdd}
            onChange={(e) =>
              props.context.componentParent.handleSelection(
                e.target.checked,
                e.target.name,
                props.data
              )
            }
          />
        );
      case "edit":
        return (
          <Switch
            size="small"
            name="isEdit"
            disabled={!props.data.isView}
            checked={props.data.isEdit}
            onChange={(e) =>
              props.context.componentParent.handleSelection(
                e.target.checked,
                e.target.name,
                props.data
              )
            }
          />
        );
      case "view":
        return (
          <Switch
            size="small"
            name="isView"
            checked={props.data.isView}
            onChange={(e) =>
              props.context.componentParent.handleSelection(
                e.target.checked,
                e.target.name,
                props.data
              )
            }
          />
        );
      case "delete":
        return (
          <Switch
            size="small"
            name="isDelete"
            disabled={!props.data.isView}
            checked={props.data.isDelete}
            onChange={(e) =>
              props.context.componentParent.handleSelection(
                e.target.checked,
                e.target.name,
                props.data
              )
            }
          />
        );
      default:
        break;
    }
  }
}
export default AnchorCellRender;
