import MaterialUIButton from "components/commonComponents/button/button";
import Datepicker from "components/commonComponents/datepicker/datepicker";

import Textbox from "components/commonComponents/textbox/textbox";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useState } from "react";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";

import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";

import useStyles from "./manageSources.styles";
import Autocompletedropdown from "components/commonComponents/autocomplete/autocompletedropdown";
import { Required } from "utils/validation helper";
import { useDispatch, usedataor, useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import {
  devcomplexityDropdown,
  priorityDropdown,
  rerunDropdown,
} from "redux/manageissues/action";
import {
  getDevcomplexity,
  getNewSourceStatus,
  getPrioritySource,
  getRerun,
  getUrlStatusSource,
} from "redux/app/actions";
import { useLocation } from "react-router-dom";
import AlertDialog from "components/commonComponents/alertDialog";
import EditIcon from "@mui/icons-material/Edit";
import { calendar } from "../../../../assets/index";
import moment from "moment";
import { newsourceStatus, sourceurlStatus } from "redux/managesources/action";
function ViewSource(props) {
  const classes = useStyles();
  const [viewsource, Setviewsource] = useState(true);
  const [viewedit, setViewedit] = useState(false);
  const select = useSelector((state) => state.manageSource);
  const data = useSelector((state) => state.manageSource.manageSourcedataid);
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const location = useLocation();
  const id = useSelector((state) => state.project.managesource);
  const data1 = useSelector((state) => state.manageIssue);

  const rolename = location.state.split("-")[1];
  useEffect(() => {
    callingDropdowndata();
  }, []);
  /******************************************************************************
  Function: callingDropdowndata
  Argument: 
  Return:
  Usage:
  1.To call dropdown API 
  *******************************************************************************/

  const callingDropdowndata = () => {
    dispatch(newsourceStatus(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getNewSourceStatus([]));
        } else {
          dispatch(getNewSourceStatus(res.data.result));
        }
      } else {
        dispatch(getNewSourceStatus([]));
      }
    });
    dispatch(priorityDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getPrioritySource([]));
        } else {
          dispatch(getPrioritySource(res.data.result));
        }
      } else {
        dispatch(getPrioritySource([]));
      }
    });
    dispatch(devcomplexityDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getDevcomplexity([]));
        } else dispatch(getDevcomplexity(res.data.result));
      } else {
        dispatch(getDevcomplexity([]));
      }
    });
    dispatch(sourceurlStatus(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getUrlStatusSource([]));
        } else {
          dispatch(getUrlStatusSource(res.data.result));
        }
      } else {
        dispatch(getUrlStatusSource([]));
      }
    });
  };

  const [edit, setEdit] = useState([]);
  const selector = JSON.parse(localStorage.getItem("roleData"));

  useEffect(() => {
    callingDropdowndata();
    setEdit(
      selector &&
        selector.roleConfigurations.filter((e) =>
          e.menuName === "Manage Issue/Request"
            ? location.pathname === "/manage-issue-/-request" &&
              e.isEdit === true
            : e.menuName.replace("&", "and").toLowerCase() ===
                location.pathname
                  .replace("/", "")
                  .replace("-", " ")
                  .replace("-", " ")
                  .replace("/", "#")
                  .split("#")[0] && e.isEdit === true
        )
    );
    return () => {
      setEdit(
        selector.roleConfigurations.filter(
          (e) =>
            e.menuName.replace("&", "and").toLowerCase() ===
              location.pathname
                .replace("/", "")
                .replace("-", " ")
                .replace("-", " ") && e.isEdit === true
        )
      );
    };
  }, [location.pathname]);
  const [showalert, setShowalert] = useState(false);

  const truncatesource = (str) => {
    return str ? (str.length > 30 ? str.substring(0, 30) + "..." : str) : "";
  };
  /******************************************************************************
  Function: validate
  Argument: values
  Return: errors
  Usage:
  1.To validate the form
  *******************************************************************************/

  const validate = (values) => {
    const comments = values.comments ? values.comments.length : "";
    const errors = {};
    if (comments > 400) {
      errors.comments = "Exceeds 400 Characters";
    }
    if (!Required(values.priorityId)) {
      errors.priorityId = true;
    }
    if (role !== "JATO Admin" && !Required(values.urlstatusId)) {
      errors.urlstatusId = true;
    }
    return errors;
  };
  const prenewsource = data.newSourceStatus && {
    id: data.newSourceStatus,
    label: data.newSourceStatus,
  };
  const prepriority = data.make && {
    id: data.make.issuePriority.priorityId,
    label: data.make.issuePriority.issuePriority1,
  };
  const precomplexity = data.complexity && {
    id: data.complexity.issueComplexity1,
    label: data.complexity.issueComplexity1,
  };
  const preurlstatus = data.urlstatus && {
    id: data.urlstatus.urlstatusId,
    label: data.urlstatus.urlstatus1,
  };
  const formik = useFormik({
    initialValues: {
      countryId: data.countryId,
      makeId: data.makeId,
      modelId: data.modelId,
      priorityId: data.make ? prepriority : null,
      receivedDate: data.receivedDate,
      sourceId: data.sourceId,
      sourceUrl: data.sourceUrl,
      complexityString:data.complexity?precomplexity:null,
      urlstatusId: data.urlstatus ? preurlstatus : null,
      newSourceStatus: prenewsource,
      comments: data.comments,
      updatedBy: data.updatedBy,
    },
    enableReinitialize: true,
    validate,
    onSubmit: (values) => {
      console.log(values);
      props.onChangeSave(true);
      props.EditSourcedata({
        countryId: data.countryId,
        makeId: data.makeId,
        modelId: data.modelId,
        priorityId: values.priorityId ? values.priorityId.id : null,
        complexityString:values.complexityString?values.complexityString.label:null,
        urlstatusId: values.urlstatusId ? values.urlstatusId.id : null,
        receivedDate: data.receivedDate,
        sourceId: data.sourceId,
        sourceUrl: data.sourceUrl ? data.sourceUrl : null,
        newSourceStatus: values.newSourceStatus
          ? values.newSourceStatus.id
          : null,
        comments: values.comments ? values.comments.trim() : "",
        updatedBy: localStorage.getItem("firstName"),
      });
    },
  });
  /******************************************************************************
  Function: cancel
  Argument: 
  Return: 
  Usage:
  1.To close the form
  *******************************************************************************/

  const cancel = () => {
    props.close();
  };
  return (
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              {viewsource === true ? (
                <Typography variant="h6" gutterBottom>
                  Source Details
                  <span className={classes.artSubText}>(View)</span>
                </Typography>
              ) : (
                <Typography variant="h6" gutterBottom>
                  Source Details
                  <span className={classes.artSubText}>(Edit)</span>
                </Typography>
              )}
            </Grid>
            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs="auto">
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    {role === "MeritQC" || role === "MeritDeveloper" ? (
                      <></>
                    ) : (
                      <>
                        {viewsource === true ? (
                          <Grid item xs="auto">
                            <MaterialUIButton
                              variant="outlined"
                              message="EDIT"
                              onClick={() => {
                                setViewedit(true);
                                Setviewsource(false);
                              }}
                              disabled={edit.length === 0}
                              startIcon={<EditIcon />}
                            />
                          </Grid>
                        ) : (
                          <>
                            <Grid item xs="auto">
                              <MaterialUIButton
                                variant="outlined"
                                message="SAVE"
                                disabled={props.save}
                                onClick={() => formik.handleSubmit()}
                                startIcon={<SaveOutlinedIcon />}
                              />
                            </Grid>
                            <Grid item xs="auto">
                              <MaterialUIButton
                                variant="outlined"
                                message="CANCEL"
                                onClick={() => {
                                  JSON.stringify(formik.initialValues) ===
                                  JSON.stringify(formik.values)
                                    ? cancel()
                                    : setShowalert(true);
                                }}
                                startIcon={<EventBusyOutlinedIcon />}
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton color="secondary" aria-label="Close">
                      <CloseIcon
                        onClick={() => {
                          JSON.stringify(formik.initialValues) ===
                          JSON.stringify(formik.values)
                            ? cancel()
                            : setShowalert(true);
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <AlertDialog
                  open={showalert}
                  onClose={() => setShowalert(false)}
                  // open={isDeleted}
                  title={"Confirmation"}
                  // maxWidth={"sm"}
                  description={`Do you want to discard the changes ?`}
                  action={
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="right"
                      spacing={2}
                      className={classes.alertDialogbtn}
                    >
                      <Grid item xs={"auto"}>
                        <MaterialUIButton
                          variant="outlined"
                          onClick={props.close}
                          message="Yes"
                          startIcon={<DoneIcon />}
                        />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <MaterialUIButton
                          variant="outlined"
                          onClick={() => {
                            setShowalert(false);
                          }}
                          message="No"
                          startIcon={<CloseIcon />}
                        />
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}

        <Grid item xs={12}>
          <Box className={classes.borderLine}>
            <Grid
              container
              spacing={2.5}
              alignItems="top"
              justifyContent="flex-start"
            >
              <Grid item xs={12}>
                {/* <Typography
                  variant="subtitle2"
                  gutterBottom
                  className={classes.titleLine}
                >
                  <span className={classes.artLine}>// </span> Details
                  <span className={classes.artLine}>//</span>
                </Typography> */}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Country
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {data.country ? data.country.countryCode : ""}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Textbox
                      size="small"
                      readOnly
                      label="Country"
                      name="countryCode"
                      value={data.country ? data.country.countryCode : ""}
                      fullWidth
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Make
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      {data.make ? data.make.make1 : ""}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Textbox
                      size="small"
                      readOnly
                      label="Make"
                      name="make1"
                      value={data.make ? data.make.make1 : ""}
                      fullWidth
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Model
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      {data.model ? data.model.model1 : ""}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Textbox
                      size="small"
                      readOnly
                      label="Model"
                      name="model1"
                      value={data.model ? data.model.model1 : ""}
                      fullWidth
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Business Priority
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {data.make?data.make?.issuePriority.issuePriority1 : ""}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Autocompletedropdown
                     required
                      value={formik.values.priorityId}
                      onChange={(_, data) =>
                        formik.setFieldValue("priorityId", data)
                      }
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                      }}
                      labelId="priority"
                      id="priority"
                      name="priorityId"
                      error={
                        formik.touched.priorityId &&
                        Boolean(formik.errors.priorityId)
                      }
                      helperText={
                        formik.touched.priorityId && formik.errors.priorityId
                      }
                      label="Business Priority"
                      options={select.priority.map((e) => {
                        return {
                          label: e.issuePriority1,
                          id: e.priorityId,
                        };
                      })}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Source URL
                    </Typography>
                    <Tooltip
                      title={data.sourceUrl ? data.sourceUrl : ""}
                      placement="top"
                      arrow
                    >
                      <Typography variant="body2" gutterBottom>
                        {data.sourceUrl ? truncatesource(data.sourceUrl) : ""}
                      </Typography>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip
                      title={data.sourceUrl ? data.sourceUrl : ""}
                      placement="top"
                      arrow
                    >
                      <div>
                        <Textbox
                          size="small"
                          readOnly
                          label="Source URL"
                          name="sourceUrl"
                          value={
                            data.sourceUrl ? truncatesource(data.sourceUrl) : ""
                          }
                          fullWidth
                        />
                      </div>
                    </Tooltip>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Previous Source URL
                    </Typography>
                    <Tooltip
                      title={
                        data.previousSourceUrl ? data.previousSourceUrl : ""
                      }
                      placement="top"
                      arrow
                    >
                      <Typography variant="body2" gutterBottom>
                        {data.previousSourceUrl
                          ? truncatesource(data.previousSourceUrl)
                          : ""}
                      </Typography>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip
                      title={
                        data.previousSourceUrl ? data.previousSourceUrl : ""
                      }
                      placement="top"
                      arrow
                    >
                      <div>
                        <Textbox
                          size="small"
                          readOnly
                          label="Pervious Source URL"
                          name="previousSourceUrl"
                          value={
                            data.previousSourceUrl
                              ? truncatesource(data.previousSourceUrl)
                              : ""
                          }
                          fullWidth
                        />
                      </div>
                    </Tooltip>
                  </>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Scrapper Complexity
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {data.complexity ? data.complexity.issueComplexity1 : ""}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Autocompletedropdown
                      value={formik.values.complexityString}
                      onChange={(_, data) =>
                        formik.setFieldValue("complexityString", data)
                      }
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                      }}
                      labelId="Scrapper Complexity"
                      id="Scrapper Complexity"
                      name="complexityString"
                      error={
                        formik.touched.complexityString &&
                        Boolean(formik.errors.complexityString)
                      }
                      helperText={
                        formik.touched.complexityString && formik.errors.complexityString
                      }
                      label="Scrapper Complexity"
                      options={data1.developercomplexity.map((e) => {
                        return {
                          label: e,
                          id: e,
                        };
                      })}
                    />
                  </>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      URL Status
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {data.urlstatus ? data.urlstatus.urlstatus1 : ""}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Autocompletedropdown
                      required={role !== "JATO Admin"}
                      value={formik.values.urlstatusId}
                      onChange={(_, data) => {
                        formik.setFieldValue("urlstatusId", data);
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                      }}
                      labelId="URL Status"
                      id="URL Status"
                      label="URL Status"
                      name="urlstatusId"
                      // filterOptions={() => data.urlstatus.filter(e=>e.urlstatus1==="Replaced")}
                      error={
                        formik.touched.urlstatusId &&
                        Boolean(formik.errors.urlstatusId)
                      }
                      helperText={
                        formik.touched.urlstatusId && formik.errors.urlstatusId
                      }
                      options={select.urlstatus.map((e) => {
                        return {
                          label: e.urlstatus1,
                          id: e.urlstatusId,
                        };
                      })}
                    />
                  </>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Source Received date
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <Grid item xs={"auto"} textAlign="right">
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <Grid item xs="auto">
                            <img
                              src={calendar}
                              className={classes.dataIcon}
                              width="16"
                              alt="calendar"
                            />
                          </Grid>
                          <Grid item xs="auto">
                            {data.receivedDate
                              ? moment(data.receivedDate).format("DD/MM/YYYY")
                              : ""}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Typography>
                  </>
                ) : (
                  <>
                    <Datepicker
                      readOnly
                      name="receivedDate"
                      value={data.receivedDate}
                      size="small"
                      fullWidth
                      label="Source Received date"
                    />
                  </>
                )}
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                {viewsource === true ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      New Source Status
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {data.newSourceStatus ? data.newSourceStatus : ""}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Autocompletedropdown
                      onChange={(_, data) => {
                        formik.setFieldValue("newSourceStatus", data);
                        //formik.setFieldValue("assignedToView", data.id);
                      }}
                      isOptionEqualToValue={(option, value) => {

                        return option.id === value.id;
                      }}
                      value={formik.values.newSourceStatus}
                      id="newSourceStatus"
                      name="newSourceStatus"
                      label="New Source Status"
                      Required
                      error={
                        formik.touched.newSourceStatus &&
                        Boolean(formik.errors.newSourceStatus)
                      }
                      options={select.newsourcestatus.map((e) => {
                        return {
                          label: e,
                          id: e,
                        };
                      })}
                    />
                  </>
                )}
              </Grid> */}
              {/*  */}
              <Grid item xs={12} sm={6} md={4} lg={6}>
                {viewsource === true ? (
                  <>
                    <Textbox
                      readOnly
                      multiline
                      rows={3}
                      label="Comments"
                      fullWidth
                      name="comments"
                      value={formik.values.comments}
                      onChange={(e) => {
                        formik.setFieldValue("comments", e.target.value);
                      }}
                      helperText={
                        formik.touched.comments && formik.errors.comments
                      }
                    />
                  </>
                ) : (
                  <>
                    <Textbox
                      multiline
                      rows={3}
                      label="Comments"
                      fullWidth
                      name="comments"
                      value={formik.values.comments}
                      onChange={(e) => {
                        formik.setFieldValue("comments", e.target.value);
                      }}
                      error={
                        formik.touched.comments &&
                        Boolean(formik.errors.comments)
                      }
                      helperText={
                        formik.touched.comments && formik.errors.comments
                      }
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ViewSource;
