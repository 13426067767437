import MaterialUIButton from "components/commonComponents/button/button";
import Textbox from "components/commonComponents/textbox/textbox";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  isValidnameAddress,
  onlySting,
  Required,
} from "utils/validation helper";
import MultipleAutocomplete from "components/commonComponents/autocomplete/multipleAutocomplete";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocompletedropdown from "components/commonComponents/autocomplete/autocompletedropdown";
import DoneIcon from "@mui/icons-material/Done";
import AlertDialog from "components/commonComponents/alertDialog";
import useStyles from "./slaHolidays.styles";
import { useEffect } from "react";
import { getRoles } from "redux/rolemanagement/action";
import { fetchRoles, fetchRolesDropdown } from "redux/app/actions";
import { rolesDropdown } from "redux/manageissues/action";
import Datepicker from "components/commonComponents/datepicker/datepicker";
import moment from "moment";

function AddHoliday(props) {
  const data = useSelector((state) => state.manageIssue);
  const select = useSelector((state) => state.userManagement);
  const name = localStorage.getItem("firstName");
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const id = useSelector((state) => state.project.sla);

  /******************************************************************************
  Function: validate
  Argument: values
  Return: errors
  Usage:
  1.To validate the form
  *******************************************************************************/

  const validate = (values) => {
    const errors = {};
    if (!Required(values.date)) {
      errors.date = true;
    }
    if (!Required(values.name)) {
      errors.name = true;
    } else if (!onlySting(values.name)) {
      errors.name = "Alphabets and underscore are allowed";
      // toast.error("only alphabets and under score  are allowed")
    } else if (values.name.length > 20) {
      errors.name = "Exceeds 20 characters";
    }

    return errors;
  };
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    dispatch(rolesDropdown(id)).then((res) => {
      if (res) {
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            dispatch(fetchRolesDropdown([]));
          } else {
            dispatch(fetchRolesDropdown(res.data.result));
          }
        }
      }
    });
  }, [props.action]);
  /******************************************************************************
  Function: reset
  Argument: 
  Return: 
  Usage:
  1.To reset the form
  *******************************************************************************/

  const reset = () => {
    if (formik && formik.values) {
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues: {
      id: props.action === "Edit" ? props.editHoliday.id : 0,
      date: props.action === "Edit" ? moment(props.editHoliday.date) : null,
      name: props.action === "Edit" ? props.editHoliday.name : "",
    },
    validate,
    onSubmit: (values) => {
      console.log(values);
      // props.onChangeSave(true);
      props.addNewHoliday({
        id: values.id,
        date: values.date.format("YYYY-MM-DD HH:mm:ss"),
        name: values.name,
      });
    },
  });

  return (
    <Box>
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="flex-start"
      >
        {/* Popup Title Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs>
              <Typography variant="h6" gutterBottom>
                {props.action} Holiday
              </Typography>
            </Grid>
            <Grid item xs={"auto"} textAlign="right">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                {props.action === "Edit" ? (
                  <></>
                ) : (
                  <Grid item xs="auto">
                    <MaterialUIButton
                      variant="outlined"
                      onClick={() => reset()}
                      message="RESET"
                      startIcon={<RestartAltOutlinedIcon />}
                    />
                  </Grid>
                )}
                <Grid item xs="auto">
                  <Tooltip title="Close " placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        JSON.stringify(formik.initialValues) ===
                          JSON.stringify(formik.values) &&
                        data.files.length === 0
                          ? props.close()
                          : setShow(true);
                      }}
                      color="secondary"
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Popup Title End Here */}
        <Grid item xs={12}>
          <Datepicker
            //readOnly
            name="date"
            min={moment().startOf("year").format("YYYY-MM-DD")}
            max={moment().endOf("year").format("YYYY-MM-DD")}
            required
            onKeyDown={onKeyDown}
            value={formik.values.date}
            onChange={(e) => formik.setFieldValue("date", e)}
            error={formik.touched.date && Boolean(formik.errors.date)}
            helperText={formik.touched.date && formik.errors.date}
            size="small"
            fullWidth
            label="Date"
          />
        </Grid>

        <Grid item xs={12}>
          <Textbox
            required
            size="small"
            label="Name"
            fullWidth
            name="name"
            multiline
            rows={3}
            value={formik.values.name}
            onChange={(data) => {
              formik.setFieldValue("name", data.target.value);
            }}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>

        {/* Action Area Start Here */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="SAVE"
                disabled={props.save}
                startIcon={<SaveOutlinedIcon />}
                onClick={() => formik.handleSubmit()}
              />
            </Grid>
            {props.action === "Edit" ? (
              <></>
            ) : (
              <Grid item xs="auto">
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => reset()}
                  message="RESET"
                  startIcon={<RestartAltOutlinedIcon />}
                />
              </Grid>
            )}
            <Grid item xs="auto">
              <MaterialUIButton
                variant="outlined"
                message="CANCEL"
                startIcon={<EventBusyOutlinedIcon />}
                onClick={() => {
                  JSON.stringify(formik.initialValues) ===
                    JSON.stringify(formik.values) && data.files.length === 0
                    ? props.close()
                    : setShow(true);
                }}
              />
            </Grid>
          </Grid>
          <AlertDialog
            open={show}
            onClose={() => setShow(false)}
            // open={isDeleted}
            title={"Confirmation"}
            // maxWidth={"sm"}
            description={`Do you want to discard the changes ?`}
            action={
              <Grid
                container
                alignItems="center"
                justifyContent="right"
                spacing={2}
                className={classes.alertDialogbtn}
              >
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      reset();

                      props.close();
                      setShow(false);
                    }}
                    message="Yes"
                    startIcon={<DoneIcon />}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    onClick={() => {
                      setShow(false);
                    }}
                    message="No"
                    startIcon={<CloseIcon />}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
        {/* Action Area End Here */}
      </Grid>
    </Box>
  );
}

export default AddHoliday;
