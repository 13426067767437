import moment from "moment";
import MultiFloatFilter from "./MultiFloatFilter";

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = moment(cellValue).format("DD-MM-YYYY");
    const dateParts = dateAsString.split("-");
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  inRangeInclusive: true,
  browserDatePicker: true,
};
export const headers = [
  {
    headerName: "Date",
    field: "date",
    filter: MultiFloatFilter,
    filterParams: "date",
    lockPosition: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    sortable: true,
    suppressSizeToFit: true,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatDaten",
    },
    width: 180,
    minWidth: 180,
  },
  {
    headerName: "Country",
    field: "country",
    filter: MultiFloatFilter,
    filterParams: "country",
    lockPosition: "left",
    sortable: true,
    minWidth: 130,
    //maxWidth: 120,
    suppressRowTransform: true,
  },
  {
    headerName: "Make",
    field: "make",
    filter: MultiFloatFilter,
    filterParams: "make",
    lockPosition: "left",
    sortable: true,
    minWidth: 130,
    //maxWidth: 120,
    suppressRowTransform: true,
  },
  {
    headerName: "Model",
    field: "model",
    filter: MultiFloatFilter,
    filterParams: "model",
    lockPosition: "left",
    sortable: true,
    minWidth: 220,
    //maxWidth: 120,
    suppressRowTransform: true,
  },
  {
    headerName: "Count of File Name",
    field: "fileNameCount",
    filter: MultiFloatFilter,
    filterParams: "fileNameCount",
    lockPosition: "left",
    width: 180,
    minWidth: 180,
    sortable: true,
  },
  {
    headerName: "Sum of Delivered records",
    field: "deliveredRecords",
    filter: MultiFloatFilter,
    filterParams: "deliveredRecords",
    lockPosition: "left",
    width: 200,
    minWidth: 200,
    sortable: true,
  },
  // {
  //   headerName: "Distinct of country",
  //   field: "country",
  //   filter: MultiFloatFilter,
  //   filterParams: "country",
  //   lockPosition: "left",
  //   wrapText: true,
  //   autoHeight: true,
  //   width: 200,
  //   minWidth: 200,
  //   sortable: true,
  //   cellClass: "trimText",
  // },

  // {
  //   headerName: "Distinct of make",
  //   field: "make",
  //   filter: MultiFloatFilter,
  //   filterParams: "make",
  //   lockPosition: "left",
  //   width: 180,
  //   minWidth: 180,
  //   sortable: true,
  // },
  // {
  //   headerName: "Distinct of model",
  //   field: "model",
  //   filter: MultiFloatFilter,
  //   filterParams: "model",
  //   lockPosition: "left",
  //   width: 180,
  //   minWidth: 180,
  //   sortable: true,
  // },
  {
    headerName: "Distinct of version",
    field: "version",
    filter: MultiFloatFilter,
    filterParams: "version",
    lockPosition: "left",
    width: 180,
    minWidth: 180,
    sortable: true,
  },
  {
    headerName: "New records",
    field: "newRecords",
    filter: MultiFloatFilter,
    filterParams: "newRecords",
    lockPosition: "left",
    width: 180,
    minWidth: 180,
    sortable: true,
  },
  {
    headerName: "Updated records",
    field: "updatedRecords",
    filter: MultiFloatFilter,
    filterParams: "updatedRecords",
    lockPosition: "left",
    width: 180,
    minWidth: 180,
    sortable: true,
  },
  {
    headerName: "Removed records",
    field: "removedRecords",
    filter: MultiFloatFilter,
    filterParams: "removedRecords",
    lockPosition: "left",
    width: 180,
    minWidth: 180,
    sortable: true,
  },
];
