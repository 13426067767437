// Default React, React Components, Router and Services Components
import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { headers } from "./Data";

// Importing Custom Components
import AdvanceFilters from "./advanceFilters";

import BulkUpload from "./bulkUpload";
import AgGrid from "components/commonComponents/grid/agGrid";
import Gridnav from "components/commonComponents/gridnav/gridnav";
import BaseLayout from "../../../commonComponents/baseLayout/index";
import MaterialUIModal from "components/commonComponents/modal/modal";
import MaterialUIButton from "components/commonComponents/button/button";

// Importing useStyles
import useStyles from "./manageModelScope.styles";

// Import Material Icons
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import {
  errorMessage,
  fetchmanageScope,
  fetchmanageScopeid,
  filterScope,
  reasonAction,
} from "redux/app/actions";
// Importing useStyles

// Import Material Icons
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  addBulkScope,
  deletescope,
  ExportManagescope,
  getManagescope,
  HandleBulkDelete,
  reasonDropdown,
  updateManagescope,
  viewScope,
} from "redux/managemodelscope/action";
import { useDispatch, useSelector } from "react-redux";
import { utils } from "xlsx/xlsx";
import moment from "moment";
import { writeFile } from "xlsx";
import UsePagination from "components/commonComponents/pagination/pagination";

import Toasts from "components/commonComponents/toasts/toasts";
import { toast } from "react-toastify";
import AlertDialog from "components/commonComponents/alertDialog";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ViewScope from "./viewScope";
import EditSource from "../managesources/editSource";
import EditScope from "./editScope";
import Spinner from "components/commonComponents/spinner/spinner";
import { useLocation } from "react-router-dom";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
function ManageModelScope() {
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();

  // Declaration of States and Component Variables End Here
  const [saveDisabled, setsaveDisabled] = useState(false);
  const [save, setsave] = useState(false);

  const [msg, setMsg] = useState("");
  const id = useSelector((state) => state.project.managescope);
  const dispatch = useDispatch();
  const gridApi = useRef(null);
  const select = useSelector((state) => state.manageScope);
  const [advFilterOpen, setAdvFilterOpen] = useState(false);

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [Deletedata, setDeletedata] = useState({});
  const [pageCount, setpageCount] = useState(0);
  const [total, settotal] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  const [totalRecords, settotalRecords] = useState(0);
  const [load, setLoad] = useState(true);
  const [selectExport, setselectExport] = useState([]);
  const [rows, setRows] = useState();
  const [error, setError] = useState({});
  const role = localStorage.getItem("role");
  const [bulkupload, setBulkupload] = useState(false);
  const [showEditScope, setShowEditScope] = useState(false);
  const [viewscope, SetViewscope] = useState(false);
  const [disableedit, setdisableedit] = useState(false);
  const [open, setOpen] = useState(false);
  const selector = JSON.parse(localStorage.getItem("roleData"));
  const [add, setAdd] = useState([]);
  const location = useLocation();
  const [Deletes, setDelete] = useState([]);
  const filterData = useSelector((state) => state.manageScope.filter);
  const [showDelete, setshowDelete] = useState(false);
  const [showDeletem, setshowDeletem] = useState(false);
  // Declaration of States and Component Variables End Here
  const filterBody = {
    timeLineStart: filterData.timeLineStart,
    timeLineEnd: filterData.timeLineEnd,
    country: filterData.country.map((e) => e.countryId),
    make: filterData.make.map((e) => e.make1),
    model: filterData.model.map((e) => e.model1),
    priority: filterData.priority.map((e) => e.issuePriority1),

    sourceUrl: filterData.sourceurl.map((e) => e.sourceUrl),
    dataAvailability: filterData.availability,
    reasonForFailure: filterData.reasonForFailure,
    exportId: [],
  };
  useEffect(() => {
    if (search.length > 0) {
      const timerId = setTimeout(() => {
        performSearch(search);
      }, 3000);
      return () => clearTimeout(timerId);
    } else {
      setPage(1);

      getScope(1, limit, search, filterBody);
    }
  }, [search]);

  const clear = () => {
    dispatch(reasonDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(reasonAction([]));
        } else {
          dispatch(reasonAction(res.data.result));
        }
      } else {
        dispatch(reasonAction([]));
      }
    });
  };
  /**************************************
Function: getScope
Argument:page,
    limit,
    search,
    body
Usage:
1. To get scope data
*******************************************/
  const getScope = (
    page,
    limit,
    search,

    body = {
      timeLineStart: null,
      timeLineEnd: null,
      country: [],
      make: [],
      model: [],
      sourceUrl: [],
      priority: [],
      dataAvailability: "",
      reasonForFailure: [],
      exportId: [],
    }
  ) => {
    dispatch(getManagescope(page, limit, search, body, id)).then((res) => {
      if (res) {
        setLoad(false);

        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            settotalRecords(0);
            dispatch(fetchmanageScope([]));
          } else {
            dispatch(fetchmanageScope(res.data.result));
          }
          setLoad(false);
          //setState(res.data.result);

          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        } else {
          settotalCount(0);
          settotalRecords(0);
          dispatch(fetchmanageScope([]));
        }
      }
    });
  };
  useEffect(() => {
    clear();

    setAdd(
      selector &&
        selector.roleConfigurations.filter((e) =>
          e.menuName === "Manage Issue/Request"
            ? location.pathname === "/manage-issue-/-request" &&
              e.isAdd === true
            : e.menuName.replace("&", "and").toLowerCase() ===
                location.pathname
                  .replace("/", "")
                  .replace("-", " ")
                  .replace("-", " ")
                  .replace("/", "#")
                  .split("#")[0] && e.isAdd === true
        )
    );
    setDelete(
      selector &&
        selector.roleConfigurations.filter((e) =>
          e.menuName === "Manage Issue/Request"
            ? location.pathname === "/manage-issue-/-request" &&
              e.isDelete === true
            : e.menuName.replace("&", "and").toLowerCase() ===
                location.pathname
                  .replace("/", "")
                  .replace("-", " ")
                  .replace("-", " ")
                  .replace("/", "#")
                  .split("#")[0] && e.isDelete === true
        )
    );
    dispatch(
      filterScope({
        timeline: "Year",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: null,
        timeLineEnd: null,
        country: [],
        make: [],
        model: [],
        priority: [],
        sourceurl: [],
        availability: "All",
        reasonForFailure: [],
        exportId: [],
      })
    );
    const timerId = setTimeout(() => {
      getScope(1, limit, "", {
        timeLineStart: null,
        timeLineEnd: null,
        country: [],
        make: [],
        model: [],
        priority: [],
        sourceurl: [],
        availability: "All",
        reasonForFailure: [],
        exportId: [],
      });
    }, 2000);
    return () => clearTimeout(timerId);
  }, [id]);
  const deletedata = selectExport.toString();
  const deleteIds = deletedata.split(",");
  const multipleDelete = (page, limit, search) => {
    setLoad(true);
    console.log(rows);
    dispatch(
      HandleBulkDelete(
        page,
        limit,
        search,
        {
          timeLineStart: filterData.timeLineStart,
          timeLineEnd: filterData.timeLineEnd,
          country: filterData.country.map((e) => e.countryId),
          make: filterData.make.map((e) => e.make1),
          model: filterData.model.map((e) => e.model1),
          sourceUrl: filterData.sourceurl.map((e) => e.sourceUrl),
          dataAvailability: filterData.availability,
          reasonForFailure: filterData.reasonForFailure,
          priority: filterData.priority.map((e) => e.issuePriority1),
          exportId: rows ? ["All"] : deleteIds,
        },
        id
      )
    ).then((res) => {
      if (res) {
        setShow(false);

        if (res.data.statusCode === 200) {
          getScope(page, limit, search, filterBody);
          toast.success("Scope deleted successfully");
          setLoad(false);
        } else {
          getScope(page, limit, search, filterBody);
          toast.error("Failed to delete");
          setLoad(false);
        }
      }
    });
  };

  const performSearch = (value) => {
    setPage(1);
    let encodedValue = encodeURIComponent(value);

    getScope(1, limit, encodedValue, filterBody);
    // Update the results state with the fetched data
    // setResults(responseData);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  // useEffect for fetching results on initial load
  // useEffect(() => {
  //   performSearch(search);
  // }, []);
  /**************************************
Function: handleExport
Argument:
Usage:
1. To export data
*******************************************/

  const exportdata = selectExport.toString();
  const exportIds = exportdata.split(",");
  const handleExport = (body) => {
    setLoad(true);
    dispatch(
      ExportManagescope(
        page,
        limit,
        search,
        (body = {
          timeLineStart: filterData.timeLineStart,
          timeLineEnd: filterData.timeLineEnd,
          country: filterData.country.map((e) => e.countryId),
          make: filterData.make.map((e) => e.make1),
          model: filterData.model.map((e) => e.model1),
          sourceUrl: filterData.sourceurl.map((e) => e.sourceUrl),
          dataAvailability: filterData.availability,
          reasonForFailure: filterData.reasonForFailure,
          priority: filterData.priority.map((e) => e.issuePriority1),

          exportId: rows ? ["All"] : deleteIds,
        }),
        id
      )
    ).then((res) => {
      setLoad(false);
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, [
        [
          "Country",
          "Make",
          "Business Priority",
          "Model",

          "Month",
          "Year",
          "Source URL",
          "Data Availability",
          "Reason For Failure",
        ],
      ]);
      const rows = res.data.result.map((row) => [
        row.country,
        row.make,
        row.issuePriority,
        row.model,

        row.month,
        row.year,
        row.sourceURL,
        row.dataAvailability,
        row.reasonforFailure,
      ]);
      utils.sheet_add_json(ws, rows, {
        origin: "A2",
        skipHeader: true,
      });

      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, `managemodelscope_${moment().format("DMMMyy")}.csv`);
    });
    getScope(1, limit, search, filterBody);
  };

  /**************************************
Function: handleSelect
Argument: row
Usage:
1. To select the data to export
*******************************************/
  const handleSelect = async (row) => {
    setselectExport(row.map((e) => e.scopeID));
    setRows(
      row.length === 10 ||
        row.length === 15 ||
        row.length === 25 ||
        row.length === 50 ||
        row.length === 100
    );
    if (row.length === 0) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  console.log(selectExport);
  const onGridReadyManageScope = (params) => {
    gridApi.current = params.api;

    gridApi.current.setRowData(select.manageScopedata);
  };
  /******************************************************************************
Function: handlePageClick
Argument: data
Usage:
1. Pagination change action.
*******************************************************************************/

  const handlePageClick = async (event, data) => {
    setLoad(true);
    setPage(data);
    await getScope(data, limit, search, filterBody);
  };
  /******************************************************************************
Function: editBody
Argument: data
Usage:
1. Will edit grid data in the page.
*******************************************************************************/

  const editBody = (data) => {
    setShowEditScope(true);
  };
  /******************************************************************************
Function: viewBody
Argument: data
Usage:
1. Will get grid data in the view.
*******************************************************************************/

  const viewBody = (data) => {
    SetViewscope(true);
  };
  /******************************************************************************
Function: EditIssuedata
Argument: data
Usage:
1. To edit issue.
*******************************************************************************/

  const EditScopedata = (data) => {
    setLoad(true);
    dispatch(updateManagescope(data, id)).then(async (res) => {
      if (res.data.statusCode === 200) {
        toast.success("Scope saved successfully");
        await getScope(page, limit, search);
        setShowEditScope(false);
        SetViewscope(false);
        setdisableedit(false);
        setLoad(false);
      } else {
        setShowEditScope(false);
        SetViewscope(false);
        setdisableedit(false);
        setLoad(false);
        toast.error("Scope save failed");
      }

    });
  };
  /******************************************************************************
Function: deleteBody
Argument: event
Usage:
1. get the selected record and delete
*******************************************************************************/

  const deleteBody = (event) => {
    setDeletedata(event.scopeID);
    //setRolename(event.);
  };
  /******************************************************************************
Function: handleDelete
Argument: ticketId
Usage:
1. To delete the selected records
*******************************************************************************/

  const handleDelete = (scopeId) => {
    setLoad(true);
    setShow(false);
    dispatch(deletescope(scopeId, id)).then(async (res) => {
      if (res.data.statusCode === 200) {
        toast.success("Scope deleted successfully");
        await getScope(page, limit, search);
      } else {
        setLoad(false);
        toast.error("Failed to delete");
      }
    });
  };
  /******************************************************************************
Function: handleGridAction
Argument: action, selectedRow
Usage:
 1. handleGridAction -  Performs the actions inside grid (delete).
*******************************************************************************/

  const handleGridAction = (action, selectedRow) => {
    switch (action) {
      case "Delete":
        handleDelete(selectedRow);
        break;
      default:
        break;
    }
  };
  /******************************************************************************
Function: filterScope
Argument: data, reset = false
Usage:
1. To filter the data.
*******************************************************************************/

  const FilterScope = async (data, reset = false) => {
    setLoad(true);
    setPage(1);
    dispatch(getManagescope(1, limit, search, data, id)).then((res) => {
      if (res) {
        setLoad(false);

        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            settotalRecords(0);
            dispatch(fetchmanageScope([]));
            setLoad(false);
          } else {
            dispatch(fetchmanageScope(res.data.result));
          }
          setLoad(false);
          //setState(res.data.result);
          if (!reset) {
            setAdvFilterOpen(false);
          }
          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        }
      }
    });
    // gridApi.current.redrawRows(select.manageScopedata);
    //
  };
  /******************************************************************************
Function: upload
Argument: data, name, month
Usage:
1. To upload the file to the application.
*******************************************************************************/

  const upload = (data, name, month) => {
    setMsg("Uploading....");
    dispatch(addBulkScope(data, name, month, id)).then(async (res) => {
      setMsg("");
      if (res.data.statusCode === 200) {
        toast.success("Successfully saved");
        await getScope(page, limit, search);
        setBulkupload(false);
        setsave(false);
      } else if (res.data.statusCode === 400) {
        dispatch(
          errorMessage(res.data.responseException.exceptionMessage.result)
        );
        setsave(false);
      } else {
        setBulkupload(false);
        toast.error("Save failed");
        setsave(false);
      }

    });
  };
  const [gridKey, setGridKey] = useState(0);
  const resetAll = async () => {
    dispatch(
      filterScope({
        timeline: "Year",
        quater: "",
        startdate: null,
        enddate: null,
        timeLineStart: null,
        timeLineEnd: null,
        country: [],
        make: [],
        model: [],
        priority: [],
        sourceurl: [],
        availability: "All",
        reasonForFailure: [],
        exportId: [],
      })
    );
    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
    if (search !== "") {
      setPage(1);
      setSearch("");
    }
    await getScope(1, limit, "", {
      timeLineStart: null,
      timeLineEnd: null,
      country: [],
      make: [],
      model: [],
      priority: [],
      sourceUrl: [],
      dataAvailability: "All",
      reasonForFailure: [],
      exportId: [],
    });

    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
  };
  return (
    // Manage Model Scope Start Here
    <Box>
      <Spinner open={load} />
      <BaseLayout>
        <Grid
          container
          spacing={2}
          className={classes.pageActionArea}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item xs={12} md={5}>
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={2}
            >
              <Grid item xs={"auto"}>
                <Gridnav onChange={handleInputChange} data={search} />
              </Grid>
              <Grid item xs={"auto"}>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className={classes.totalResults}
                >
                  Total Results:{" "}
                  <span className={classes.totalValue}>
                    {totalRecords ?? 0}
                  </span>
                  &nbsp;
                  <span className={classes.recordText}>Records</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid
              container
              spacing={1.75}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              {show === true ? (
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    disabled={Deletes.length === 0}
                    variant="outlined"
                    message="Delete All"
                    onClick={() => {
                      setshowDeletem(true);
                    }}
                    startIcon={<DeleteOutlineIcon />}
                  />
                </Grid>
              ) : (
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    message="Delete All"
                    disabled
                    startIcon={<DeleteOutlineIcon />}
                  />
                </Grid>
              )}
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  message="Clear Filters"
                  startIcon={<RestartAltIcon />}
                  onClick={() => resetAll()}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  disabled={add.length === 0}
                  onClick={() => setBulkupload(true)}
                  message="Bulk Upload"
                  startIcon={<DifferenceOutlinedIcon />}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  // onClick={() => setAdvFilterOpen(true)}
                  onClick={() => setAdvFilterOpen(true)}
                  message="Advance Filter"
                  startIcon={<FilterListOutlinedIcon />}
                />
              </Grid>
              {/* {show === true ? ( */}
              {show === true ? (
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    message="Export"
                    onClick={() => {
                      handleExport();
                      setShow(false);
                    }}
                    startIcon={<FileDownloadOutlinedIcon />}
                  />
                </Grid>
              ) : (
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    message="Export"
                    onClick={() => handleExport()}
                    disabled
                    startIcon={<FileDownloadOutlinedIcon />}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Grid Start here */}
        <Grid
          container
          spacing={2}
          className={classes.pageActionArea}
          alignItems="center"
          justifyContent="flex-start"
          sx={{ p: 0 }}
        >
          <Grid item xs={12} className={classes.fullHeight}>
            <AgGrid
              // onGridReady={onGridReadyManageScope}
              data={select.manageScopedata}
              header={headers}
              selectAll={handleSelect}
              showEdit={() => setShowEditScope(true)}
              showView={() => SetViewscope(true)}
              showDelete={() => setshowDelete(true)}
              edit={editBody}
              delete={deleteBody}
              view={viewBody}
              key={gridKey}
            />
            <UsePagination
              pageCount={pageCount}
              count={total}
              t={totalRecords}
              total={totalCount}
              currentPage={page}
              limit={limit}
              value={limit}
              handlePageClick={handlePageClick}
              onChange={(e) => {
                setLimit(e.target.value);
                setPage(1);
                getScope(1, e.target.value, search, filterBody);
                // gridApi.current.redrawRows(select.manageScopedata);
              }}
            />
          </Grid>
        </Grid>
        {/* Drawer Add Issuse */}

        <MaterialUIModal
          open={advFilterOpen}
          anchor="right"
          body={
            <AdvanceFilters
              filter={FilterScope}
              close={() => setAdvFilterOpen(false)}
            />
          }
        />

        <MaterialUIModal
          open={bulkupload}
          // onClose={() => setBulkupload(false)}
          anchor="right"
          body={
            <BulkUpload
              save={save}
              error={error}
              msg={msg}
              set={() => setMsg("")}
              seterror={() => setError({})}
              onChangeSave={() => setsave(true)}
              close={() => setBulkupload(false)}
              upload={upload}
            />
          }
        />
      </BaseLayout>
      <MaterialUIModal
        drawerWidth="md"
        open={showEditScope}
        //onClose={() => setShowEditScope(false)}
        anchor="right"
        body={
          <EditScope
            save={disableedit}
            onChangeSave={() => setdisableedit(true)}
            close={() => setShowEditScope(false)}
            EditScopedata={EditScopedata}
          />
        }
      />
      <MaterialUIModal
        drawerWidth="md"
        open={viewscope}
        //onClose={() => SetViewscope(false)}
        anchor="right"
        body={
          <ViewScope
            //data={data}
            EditScopedata={EditScopedata}
            save={disableedit}
            onChangeSave={() => setdisableedit(true)}
            close={() => SetViewscope(false)}
          />
        }
      />
      {/*  <Toasts open={open} onClose={() => setOpen(false)} message={message} /> */}
      <AlertDialog
        open={showDeletem}
        onClose={() => setshowDeletem(false)}
        // open={isDeleted}
        title={"Confirmation"}
        // maxWidth={"sm"}
        description={`Do you want to delete the selected records  ${
          rows ? totalRecords : selectExport.length
        } ?`} /* - ${Deletedata} */
        action={
          <Grid
            container
            alignItems="center"
            justifyContent="right"
            spacing={2}
            className={classes.alertDialogbtn}
          >
            <Grid item xs={"auto"}>
              <MaterialUIButton
                variant="outlined"
                onClick={() => {
                  multipleDelete(page, limit, search);
                  setshowDeletem(false);
                }}
                message="Delete"
                startIcon={<DoneIcon />}
              />
            </Grid>
            <Grid item xs={"auto"}>
              <MaterialUIButton
                variant="outlined"
                onClick={() => {
                  setshowDeletem(false);
                }}
                message="CANCEL"
                startIcon={<CloseIcon />}
              />
            </Grid>
          </Grid>
        }
      />
      <AlertDialog
        open={showDelete}
        onClose={() => setshowDelete(false)}
        // open={isDeleted}
        title={"Confirmation"}
        // maxWidth={"sm"}
        description={`Do you want to delete Scope? `} /* - ${Deletedata} */
        action={
          <Grid
            container
            alignItems="center"
            justifyContent="right"
            spacing={2}
            className={classes.alertDialogbtn}
          >
            <Grid item xs={"auto"}>
              <MaterialUIButton
                variant="outlined"
                onClick={() => {
                  handleGridAction("Delete", Deletedata);
                  setshowDelete(false);
                }}
                message="Delete"
                startIcon={<DoneIcon />}
              />
            </Grid>
            <Grid item xs={"auto"}>
              <MaterialUIButton
                variant="outlined"
                onClick={() => {
                  setshowDelete(false);
                }}
                message="CANCEL"
                startIcon={<CloseIcon />}
              />
            </Grid>
          </Grid>
        }
      />
    </Box>
    // Manage Model Scope End Here
  );
}

export default ManageModelScope;
